import { ORGANIZATION_CUSTOMIZATION_KEY } from '@plug/config/consts';
import { getLocalInfo } from './local-info';

export default (key: keyof OrganizationCustomization, fallbackColor?: string) => {
  const customization = getLocalInfo(ORGANIZATION_CUSTOMIZATION_KEY);

  if (customization[key]) return customization[key];

  return fallbackColor;
};
