import gql from 'graphql-tag';

export const CUSTOMER = gql`
  query Customer($email: String!) @api(name: "customer") {
    customer(email: $email) {
      profile {
        email
        firstName
        lastName
        document
        documentType
        phone
      }
    }
  }
`;

export type CustomerVariables = {
  email: string;
};

export type CustomerResponse = {
  customer: {
    profile: Customer;
  };
};
