import React from 'react';
import { theme as dsTheme } from '@gohubly/design-system';
import { ThemeStore } from '@store';
import { ThemeProvider } from 'styled-components';
import { CreateAccountContextProvider } from '@plug/contexts/CreateAccountContext';
import { CreateOrganizationContextProvider } from '@plug/contexts/CreateOrganizationContext';
import { RecoveryPasswordContextProvider } from '@plug/contexts/RecoveryPasswordContext';
import { NavigationMenuContextProvider } from '@plug/contexts/NavigationMenuContext';
import { SelfCheckoutContextProvider } from '@plug/contexts/SelfCheckoutContext';

const Providers: React.FC = ({ children }) => {
  const theme = ThemeStore.useState(s => s);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        ...dsTheme,
      }}
    >
      <SelfCheckoutContextProvider>
        <CreateAccountContextProvider>
          <CreateOrganizationContextProvider>
            <RecoveryPasswordContextProvider>
              <NavigationMenuContextProvider>{children}</NavigationMenuContextProvider>
            </RecoveryPasswordContextProvider>
          </CreateOrganizationContextProvider>
        </CreateAccountContextProvider>
      </SelfCheckoutContextProvider>
    </ThemeProvider>
  );
};

export default Providers;
