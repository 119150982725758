import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)`
  .product {
    &-list-price {
      font-size: 18px;
    }

    &-selling-price {
      font-size: 32px;
    }
  }

  .select-trigger {
    width: 100%;
    height: initial !important;
  }
`;
