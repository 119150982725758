import React, { useEffect } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { BrowserRouterProps, RouteAndRedirectType } from './types';
import AppRoute from '../route';
import LocationListener from '../location-listener';
import AllContextsProvider from '../all-contexts-provider';
import getCurrentOrganization from '@plug/helpers/get-current-organization';
import { useCrisp } from '@plug/hooks/use-crisp';
import { OrganizationCustomizationProvider } from '@plug/contexts/organization-customization-context';

const BrowserRouter: React.FC<BrowserRouterProps> = ({
  routes,
  providersWithRouter: ProvidersWithRouter,
}) => {
  const history = createBrowserHistory();
  const Provider = ProvidersWithRouter ? ProvidersWithRouter : React.Fragment;

  return (
    <Router history={history}>
      <Provider>
        <OrganizationCustomizationProvider>
          <LocationListener onLocationChange={console.log}>
            {/* <InvoiceModalWarning> */}
            <AllContextsProvider>
              <Switch>
                {(routes as RouteAndRedirectType[]).map(
                  (route, index): JSX.Element =>
                    route.redirect ? (
                      <Redirect {...route} key={index} />
                    ) : (
                      <AppRoute {...route} key={index} />
                    ),
                )}
              </Switch>
            </AllContextsProvider>
            {/* </InvoiceModalWarning> */}
          </LocationListener>
        </OrganizationCustomizationProvider>
      </Provider>
    </Router>
  );
};

export default BrowserRouter;
