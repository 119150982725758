import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const RadioTeste: React.FC<SvgProps> = props => (
  <SvgIcon fill="none" viewBox={'0 0 24 24'} width={24} height={24} {...props}>
    <rect width="24" height="24" rx="12" fill="#B9C6FA"/>
<path d="M17.5001 8.5L9.93762 15.5L6.50012 12.3182" stroke="#425DC7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

    </SvgIcon>
);

export default RadioTeste;

