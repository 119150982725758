import { ComponentProps } from 'react';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;

  width: 100%;

  outline: 4px solid #0000000d;
  border-radius: 4px;

  background: ${({ theme }): string => theme.colors.white};

  padding: 24px;
`;

export const HeaderActionDropdownBaseHr = styled.div`
  width: 100%;
  height: 1px;

  background: ${({ theme }): string => theme.colors.primaryLightest};
`;

const HeaderActionDropdownBaseCss = css<{ onClick?: () => void }>`
  padding: 12px 0;
  align-items: center;

  &:first-of-type {
    padding-top: 0;
  }

  cursor: ${({ onClick }): string => (onClick ? 'pointer' : 'default')};
`;

export const HeaderActionDropdownBaseItem = styled.div`
  ${HeaderActionDropdownBaseCss};
`;

export const HeaderActionDropdownBaseActionItem = styled.div<ComponentProps<'div'>>`
  ${HeaderActionDropdownBaseCss};

  &:hover {
    color: ${({ theme }): string => theme.colors.neutralLowDark} !important;
    font-weight: 700;

    span {
      color: ${({ theme }): string => theme.colors.neutralLowDark} !important;
    }

    svg,
    path {
      stroke: ${({ theme }): string => theme.colors.neutralLowDark} !important;
      strokewidth: 2;
    }
  }

  transition: all 0.2s linear;
`;
