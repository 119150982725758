import gql from 'graphql-tag';

export const INVITE_TEAMMATES = gql`
    mutation inviteTeammates($input: InviteTeammatesInput!) {
        inviteTeammates(input: $input){
            id
        }
    }
`;

export type ResponseOrganizationInviteMutation = {
  responseOrganizationInvite: {
    id: string
  };
};

export type ResponseOrganizationInviteVariables = {
  input: {
    emails: string[]
  };
};
