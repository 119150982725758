import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Star1: React.FC<SvgProps> = props => (
  <SvgIcon fill="none" viewBox={'0 0 49 48'} width={49} height={48} {...props}>
    <path d="M16.5024 15.4492L0.997247 23.9587L16.5024 32.4682L24.9972 48L33.4921 32.4682L48.9972 23.9587L33.4921 15.4492L24.9972 0L16.5024 15.4492Z" fill="#FFD123"/>

  </SvgIcon>
);

export default Star1;

