import { MARY_KAY_WHITELABEL } from '@plug/config/consts';
import { getIn } from 'formik';
import React, { useState } from 'react';

interface CreateAccountContextI {
  direct?: boolean;
  step: CreateAccountContextISteps;
  setStep: (step: CreateAccountContextISteps) => void;
  signupType: SignupType;
  setSignupType: (signupType: SignupType) => void;
  organizationName?: string;
  setOrganizationName: (name: string) => void;
  values: CreateAccountFormI;
  role?: Roles;
  setRole: (role: Roles) => void;
  setValues: (values: CreateAccountFormI) => void;
  addValues: (values: Partial<CreateAccountFormI>) => void;
  tokenValues: IToken;
  resetContext: () => void;
  incompleteRegistration: boolean;
  setIncompleteRegistration: (incompleteRegistration: boolean) => void;
  whitelabelCustomization: {
    requirements: IAppRequirement[];
    setRequirements: (values: IAppRequirement[]) => void;
    values?: Omit<IWhitelabelCustomization, 'organizationId'>;
    setValues: (values: Omit<IWhitelabelCustomization, 'organizationId'>) => void;
  };
  organizationId: string;
  setOrganizationId: (id: string) => void;
  blockInitialValues?: boolean;
  isCreatingAccount?: boolean;
  setIsCreatingAccount: (value?: boolean) => void;
  initialValues?: Partial<CreateAccountFormI>;
  addInitialValues: (value: Partial<CreateAccountFormI>) => void;
  isFieldBlocked: (name: string) => boolean;
  indicatedBy?: string;
  setIndicatedBy: (value: string) => void;
}

const params = new URLSearchParams(window.location.search);

const CreateAccountContextInitialValues: CreateAccountContextI = {
  step: 'PASSWORD',
  direct: false,
  setStep: () => null,
  setOrganizationName: () => null,
  organizationName: params.get('name') ? decodeURIComponent(params.get('name')) : null,
  values: {
    password: '',
    passwordConfirmation: '',
    name: '',
    document: '',
    email: '',
    phone: '',
    bank: '',
    bankType: '',
    agency: '',
    account: '',
    accountDigit: '',
    accountHolderName: '',
    customFields: {},
    termsAndPolitics: false,
  },
  setValues: () => null,
  setRole: () => null,
  tokenValues: {
    name: '',
    id: '',
  },
  resetContext: () => null,
  addValues: () => null,
  incompleteRegistration: false,
  setIncompleteRegistration: () => null,
  signupType: 'signup',
  setSignupType: () => null,
  whitelabelCustomization: {
    requirements: [],
    setRequirements: () => null,
    setValues: () => null,
  },
  organizationId: '',
  setOrganizationId: () => null,
  blockInitialValues: false,
  isCreatingAccount: false,
  setIsCreatingAccount: null,
  addInitialValues: null,
  isFieldBlocked: null,
  setIndicatedBy: () => null,
};

export const CreateAccountContext = React.createContext<CreateAccountContextI>(
  CreateAccountContextInitialValues,
);

export const useCreateAccountContext = (): CreateAccountContextI =>
  React.useContext(CreateAccountContext);

export const CreateAccountContextProvider: React.FC = ({ children }) => {
  const [values, setValues] = useState(CreateAccountContextInitialValues.values);
  const [step, setStep] = useState(CreateAccountContextInitialValues.step);
  const [tokenValues] = useState(CreateAccountContextInitialValues.tokenValues);
  const [organizationName, setOrganizationName] = useState<string | undefined>(
    CreateAccountContextInitialValues.organizationName,
  );
  const [incompleteRegistration, setIncompleteRegistration] = useState(
    CreateAccountContextInitialValues.incompleteRegistration,
  );
  const [whitelabelCustomization, setWhitelabelCustomization] = useState<
    Omit<IWhitelabelCustomization, 'organizationId'>
  >();
  const [requirements, setRequirements] = useState<IAppRequirement[]>([]);
  const [role, setRole] = useState<Roles>();
  const [signupType, setSignupType] = useState<SignupType>('signup');
  const [organizationId, setOrganizationId] = useState('');
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [indicatedBy, setIndicatedBy] = useState<string | undefined>();
  const [initialValues, setInitialValues] = useState<Partial<CreateAccountFormI>>({});

  const blockInitialValues = MARY_KAY_WHITELABEL.includes(window.location.hostname);

  const addValues = (valuesToAdd: Partial<CreateAccountFormI>): void => {
    setValues(values => ({
      ...values,
      ...valuesToAdd,
    }));
  };

  const resetContext = (): void => {
    setValues(CreateAccountContextInitialValues.values);
  };

  const isFieldBlocked = (name: string): boolean => {
    return !!getIn(initialValues, name) && blockInitialValues;
  };

  const providerValue = {
    organizationName,
    setOrganizationName,
    values,
    resetContext,
    setValues,
    step,
    setStep,
    role,
    setRole,
    addValues,
    tokenValues,
    incompleteRegistration,
    setIncompleteRegistration,
    signupType,
    setSignupType,
    whitelabelCustomization: {
      requirements,
      setRequirements,
      setValues: setWhitelabelCustomization,
      values: whitelabelCustomization,
    },
    organizationId,
    setOrganizationId,
    blockInitialValues,
    isCreatingAccount,
    setIsCreatingAccount,
    addInitialValues: setInitialValues,
    isFieldBlocked,
    indicatedBy,
    setIndicatedBy,
    direct: params.get('direct') === 'true',
  };

  return (
    <CreateAccountContext.Provider value={providerValue}>{children}</CreateAccountContext.Provider>
  );
};
