import gql from 'graphql-tag';

export const ORGANIZATION_PAYMENTS_DETAILS = gql`
  query organizationPaymentsDetails {
    organizationPaymentsDetails {
      orders
      revenue
      commissionsToPay
      finantialConciliations {
        status
        orders
        revenue
        commissionsToPay
        referenceMonth
        close_day
        payment_day
        createdAt
      }
      lastYearFinantialConciliations {
        status
        revenue
        commissionsToPay
        referenceMonth
      }
    }
  }
`;

export type OrganizationPaymentsDetailsQuery = {
  organizationPaymentsDetails: organizationPaymentsDetails;
};

export type organizationPaymentsDetails = {
  orders: number;
  revenue: number;
  commissionsToPay: number;
  finantialConciliations: FinantialConciliationI[];
  lastYearFinantialConciliations: FinantialConciliationI[];
};

type FinantialConciliationI = {
  status: string;
  orders?: number;
  revenue: number;
  commissionsToPay: number;
  referenceMonth?: string;
  createdAt?: string;
  close_day?: string;
  payment_day?: string;
};
