import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass';
import { Typography } from '@gohubly/design-system';
import { Button } from '@monitfy/ds';

const IntegrationFormWake = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems="center" style={{ gap: '12px' }}>
      <Typography fontWeight={500} color="neutralLow" size="16" textAlign="center">
        {t('hubly-dashboard.settings.admin.accordion.items.integration.body.form.shopify.text')}
      </Typography>

      <Button
        size="small"
        variant="filled"
        onClick={() =>
          window.open('https://partners.shopify.com/3526382/apps/146608259073/test', '_self')
        }
      >
        {t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.shopify.buttons.install.text',
        )}
      </Button>
    </Flex>
  );
};

export default IntegrationFormWake;
