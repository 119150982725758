import gql from 'graphql-tag';

export const GET_WHITE_LABEL_INFOS = gql`
  query getWhiteLabelInfos($input: GetWhiteLabelInfosByOrganizationIdInput) {
    getWhiteLabelInfos(input: $input) {
      primaryColor
      secondColor
      tertiaryColor
      logo
      avatar
      domain
      organizationName
      isWhiteLabel
    }
  }
`;

export type getWhiteLabelInfosVariables = {
  input: {
    organizationId?: string;
  };
};

export type getWhiteLabelInfosQuery = {
  getWhiteLabelInfos: {
    primaryColor: string;
    secondColor: string;
    tertiaryColor: string;
    logo: string;
    organizationName?: string;
    domain?: string;
    isWhiteLabel?: boolean;
  };
};
