import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Flex } from 'rebass';
import { toast } from 'react-toastify';
import { inter } from '@plug/ui/fonts';
import { TextField, Link, Button, Text } from '@plug/ui/index';
import AvatarBase from '@plug/components/avatar-base';
import { Group, Close } from '@plug/ui/icons';
import isDesktop from '@plug/helpers/is-desktop';

import { MembersToInvite } from '../../graphql/queries/find-users-to-organization';
import { INVITE_TEAMMATES } from '@plug/graphql/mutations/invite-teammates';
import { ThemeStore } from '@plug/redux/store';

const InviteMembersTitle = styled.h1`
  font-weight: bold;
  ${inter('s32')};
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.001em;
  color: #1c1637;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const InviteMembersSubtitle = styled.h1`
  ${inter('s14')};
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #666372;

  @media (max-width: 767px) {
    font-size: 14px;
    margin: 15px 0 25px;
  }
`;

const ListToInviteWrapper = styled.div`
  background: #fefefe;
  border-radius: 8px;
  width: 100%;
  padding: 0 2rem;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const InvitedMembersTitle = styled.h3`
  ${inter('s18')};
  font-weight: 600;
  border-bottom: 1px solid #eae9ec;
  padding-bottom: 26px;
  font-size: 18px;
  line-height: 24px;
  color: #1c1637;
`;

const NoMemberToInviteTitle = styled.div`
  ${inter('s20')};
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  color: #555069;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const NoMemberToInviteSubtitle = styled.p`
  ${inter('s12')};
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #aaa8b3;

  @media (max-width: 767px) {
    font-size: 8px;
    line-height: 14px;
  }
`;

const MemberToInviteUsername = styled.div`
  ${inter('s14')};
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  margin-left: 0.75rem;
  line-height: 24px;
  margin-right: 56px;
  color: #1c1637;
`;

const InviteMembers: React.FC<{ refetch: Function }> = ({ refetch }) => {
  const [membersToInvite, setMembersToInvite] = useState<Array<MembersToInvite>>([]);
  // const [emailIsEmpty, setEmailIsEmpty] = useState(true);
  const [inviteLoading, setInviteLoading] = useState(false);

  const theme = ThemeStore.useState(s => s);

  // const [findUsersToOrganization, { loading, data }] = useLazyQuery(FIND_USERS_TO_ORGANIZATION);

  const [inviteTeammatesMutation] = useMutation(INVITE_TEAMMATES);

  // function handleChange(event: any) {
  //   const {
  //     target: { value },
  //   } = event;

  //   if (value.length === 0) return setEmailIsEmpty(true);

  //   setEmailIsEmpty(false);

  //   findUsersToOrganization({
  //     variables: {
  //       input: {
  //         name: value,
  //         organizationId: organizationCreated?.id,
  //       },
  //     },
  //   });
  // }
  function addMendersToInvite(receivedItem: MembersToInvite) {
    const { email, username, id } = receivedItem;
    // setEmailIsEmpty(true);
    if (
      !membersToInvite.some(member => member.email === email) ||
      (username && !membersToInvite.some(member => member.username === username))
    ) {
      setMembersToInvite([...membersToInvite, { email, username, id }]);
    }
  }
  function removeMemberFromInvite(receivedItem: MembersToInvite) {
    const newMembersToInvite = membersToInvite.filter(item => {
      if (receivedItem.email) {
        return receivedItem.email !== item.email;
      }
      return receivedItem.username !== item.username;
    });
    setMembersToInvite(newMembersToInvite);
  }
  const onSubmit = () => {
    setInviteLoading(true);
    inviteTeammatesMutation({
      variables: {
        input: {
          emails: membersToInvite.map(item => item.email),
        },
      },
    })
      .then(() => {
        setInviteLoading(false);
        setMembersToInvite([]);
        refetch();
        toast.success('Membro convidado com sucesso!');
      })
      .catch(error => {
        setInviteLoading(false);
        // toast.error(error.graphQLErrors[0].message);
        toast.error('Ocorreu um erro ao convidar membro. Por favor, tente novamente em alguns instantes.');
      });
  };

  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Por favor, digite um email válido.')
      .required('Por favor, digite seu email.'),
  });

  return (
    <Flex
      sx={{
        '@media screen and (max-width: 1024px)': {
          flexWrap: 'wrap',
        },
      }}
      justifyContent="space-between"
    >
      <Flex
        width={6 / 12}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '100%',
          marginLeft: isDesktop ? 36 : 0,
        }}
      >
        <div style={{ width: isDesktop ? '448px' : 'auto' }}>
          <InviteMembersTitle
            style={{
              fontSize: !isDesktop ? '20px' : 'auto',
            }}
          >
            Convide sua equipe
          </InviteMembersTitle>
          <InviteMembersSubtitle>
            Convide pessoas da sua empresa que vão te ajudar com esta organização.
          </InviteMembersSubtitle>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleBlur,
              touched,
              errors,
              isValid,
              resetForm,
              handleChange: formikHandleChange,
              setValues,
            }) => (
              <Form
                style={{
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <TextField
                    label="E-mail"
                    status={touched.email && Boolean(errors.email) ? 'error' : undefined}
                    helperText={touched.email ? errors.email : ''}
                    placeholder="exemplo@email.com"
                    name="email"
                    value={values.email}
                    onChange={(e: Event) => {
                      // handleChange(e);
                      formikHandleChange(e);
                    }}
                    onBlur={handleBlur}
                    width="100%"
                    pb={isDesktop ? '20px' : '0px'}
                  />
                  {/* {!emailIsEmpty && (
                  <Suggestions
                    setValues={setValues}
                    membersToInvite={membersToInvite}
                    data={data}
                    loading={loading}
                    addMendersToInvite={addMendersToInvite}
                  />
                )} */}
                </div>
                {isValid && values.email !== '' ? (
                  <Link
                    onClick={() => {
                      addMendersToInvite(values);
                      resetForm();
                    }}
                    fontWeight="bold"
                  >
                    + adicionar novo membro
                  </Link>
                ) : (
                  <Text fontWeight="bold" fontSize="14px" color="#c3c3c3">
                    + adicionar novo membro
                  </Text>
                )}
                <div>
                  <Button
                    width="204px"
                    variant="gradient"
                    disabled={!membersToInvite.length}
                    marginTop="24px"
                    loading={inviteLoading}
                    onClick={() => onSubmit()}
                  >
                    Finalizar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Flex>
      <Flex
        width={6 / 12}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '100%',
          marginLeft: isDesktop ? 36 : 0,
          maxWidth: '416px',
        }}
      >
        <ListToInviteWrapper>
          <InvitedMembersTitle> Convidados </InvitedMembersTitle>
          <div
            style={{
              padding: isDesktop ? (!membersToInvite.length ? '72px 0' : '26px 0') : '30px 20px',
            }}
          >
            {!membersToInvite.length ? (
              <Flex flexDirection="column" justifyContent="center" alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: '72px',
                    height: '72px',
                    borderRadius: '50%',
                    background: '#EBE9F6',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Group fill={theme.primary || "#3B24A8"} width={40} height={40} />
                </Flex>
                <NoMemberToInviteTitle>
                  Ainda não há membros na sua organização
                </NoMemberToInviteTitle>
                <NoMemberToInviteSubtitle>
                  Convide pessoas para sua organização cadastrando o e-mail dela ao lado
                </NoMemberToInviteSubtitle>
              </Flex>
            ) : (
              membersToInvite.map((item, index) => {
                return (
                  <Flex
                    key={index}
                    alignItems="center"
                    padding="0.75rem"
                    style={{
                      background: '#F8F9FC',
                      borderRadius: '8px',
                    }}
                  >
                    {item.username && <AvatarBase name={item.username} rounded border={false} />}
                    <MemberToInviteUsername>{item.username || item.email}</MemberToInviteUsername>
                    <div onClick={() => removeMemberFromInvite(item)}>
                      <Close fill="#666372" />
                    </div>
                  </Flex>
                );
              })
            )}
          </div>
        </ListToInviteWrapper>
      </Flex>
    </Flex>
  );
};

export default InviteMembers;
