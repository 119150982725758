import React, { createContext, useState } from 'react';
import { HublyStoreContextType, dummyContext } from './types';
import * as Yup from 'yup';

export enum EAppView {
  Initial,
  Config,
  WithdrawlRequest,
}

export interface AppI {
  id: string;
  name: string;
  shortDescription: string;
  description: string;
  tags: string[];
  mainImage: string;
  images: string[];
  authorName: string;
  authorLogo: string;
  authorUrl: string;
  plans: string;
  isInstalled: boolean;
  displayName: string;
  configs: {
    name: string;
    displayName: string;
    exampleValue: string;
    description?: string;
    type: string;
    visible?: boolean;
    required?: boolean;
  }[];
  icon: string;
}

export const HublyStoreContext = createContext<HublyStoreContextType>(dummyContext);

export const useHublyStoreContext = (): HublyStoreContextType =>
  React.useContext(HublyStoreContext);

export const HublyStoreContextProvider: React.FC = ({ children }) => {
  const [appList, setAppList] = useState<AppI[]>([]);
  const [app, setApp] = useState<AppI | undefined>();
  const [installedApps, setInstalledApps] = useState<IInstalledApps[]>([]);
  const [appView, setAppView] = useState<EAppView>(EAppView.Initial);
  const [isSaving, setIsSaving] = useState(false);
  const [validationSchema, setValidationSchema] = useState<Yup.ObjectSchema<object> | null>(null);

  const [showWidthdrawRequestModal, setShowWidthdrawRequestModal] = useState(false);

  const resetContext = (): void => {
    setAppList([]);
  };

  return (
    <HublyStoreContext.Provider
      value={{
        appList,
        setAppList,
        app,
        setApp,
        installedApps,
        setInstalledApps,
        appView,
        setAppView,
        isSaving,
        setIsSaving,
        validationSchema,
        setValidationSchema,
        resetContext,
        showWidthdrawRequestModal,
        setShowWidthdrawRequestModal,
      }}
    >
      {children}
    </HublyStoreContext.Provider>
  );
};

export default HublyStoreContextProvider;
