import gql from 'graphql-tag';

export const UNREGISTERED_CONSULTANT = gql`
  query {
    unregisteredConsultants
  }
`;

export type unregisteredConsultantResponse = {
  unregisteredConsultants?: number;
};
