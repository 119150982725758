import React from 'react';

type LogoProps = {
  height?: string;
  style?: any;
};

const Logo: React.FC<LogoProps> = ({ height, style }) => (
  <div style={{ height: height ? height : '40px', ...style }}>
    <svg viewBox="0 0 47 40" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9755 17.1418L46.9755 14.6928L18.2259 14.6928L18.2259 -0.000855744L13.2973 -0.000855959L13.2973 14.6928L0.975467 14.6928L0.975467 17.1418C0.975466 29.7655 11.2729 39.999 23.9755 39.999C36.678 39.999 46.9755 29.7655 46.9755 17.1418ZM13.2973 19.5907L6.07065 19.5907C6.22495 20.7153 6.48399 21.8068 6.8377 22.8551L41.1132 22.8551C41.4669 21.8068 41.726 20.7153 41.8803 19.5907L18.2259 19.5907L18.2259 19.5911L13.2973 19.5911L13.2973 19.5907ZM9.39429 27.7531C12.6829 32.2085 17.9893 35.1011 23.9755 35.1011C29.9617 35.1011 35.2681 32.2085 38.5566 27.7531L9.39429 27.7531ZM34.6539 -0.000855029L34.6539 8.16247L29.7254 8.16247L29.7254 -0.000855243L34.6539 -0.000855029Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="46.9755"
          y1="-0.000856985"
          x2="1.22422"
          y2="40.2817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E2C7" />
          <stop offset="1" stopColor="#5E44D9" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Logo;
