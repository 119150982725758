import { currencyHelper } from './currency-validator';

export const formatBrazilPrice = (
  value: number,
  { digits = 2, showCurrency = true } = {},
): string => {
  const { currency, locale } = currencyHelper();
  const formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: digits,
  }).format(value);

  if (showCurrency) return formatted;

  return formatted.replace(/R\$/gi, '').trim();
};

export const formatUsaPrice = (value: number, { digits = 2, showCurrency = true } = {}): string => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: digits,
  }).format(value);

  if (showCurrency) return formatted;

  return formatted.replace(/R\$/gi, '').trim();
};
