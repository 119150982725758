import gql from 'graphql-tag';

export const HANDLE_ORGANIZATION_FINANTIAL_CONCILIATION_STATUS_ADVANCE = gql`
  mutation handleOrganizationFinantialConciliationStatusAdvance(
    $input: handleOrganizationFinantialConciliationStatusAdvanceInput!
  ) {
    handleOrganizationFinantialConciliationStatusAdvance(input: $input)
  }
`;

export type HandleOrganizationFinantialConciliationStatusAdvanceVariables = {
  input: {
    referenceMonth?: string;
  };
};

export type HandleOrganizationFinantialConciliationStatusAdvancePayload = {
  handleOrganizationFinantialConciliationStatusAdvance: string;
};
