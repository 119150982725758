import styled from 'styled-components';
import { Button } from '@gohubly/design-system';
import { HublyLogo } from '@plug/ui/icons';

export const CardContainer = styled.div`
  background-color: ${({ theme }): string => theme.colors.primaryLightest};

  display: flex;
  flex-direction: column;

  gap: 16px;
  padding: 16px;
  border-radius: 16px;
`;

export const IconContainer = styled.div`
  margin: 0 auto;
  border-radius: 16px;
  font-size: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContent = styled.span`
  color: ${({ theme }): string => theme.colors.neutralLowDark};
  text-align: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.005em;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;
