import React, { createContext, useState } from 'react';
import { SettingsContextType, dummyContext } from './types';

export const SettingsContext = createContext<SettingsContextType>(dummyContext);

export const useSettingsContext = (): SettingsContextType => React.useContext(SettingsContext);

export const SettingsContextProvider: React.FC = ({ children }) => {
  const [planType, setPlanType] = useState('');
  const [organizationApiKey, setOrganizationApiKey] = useState('');
  const [organizationDetails, setOrganizationDetails] = useState<Organization | undefined>();
  const [organizationDetailsLoading, setOrganizationDetailsLoading] = useState(true);

  const resetContext = (): void => {
    setOrganizationDetails(undefined);
    setOrganizationDetailsLoading(true);
    setOrganizationApiKey('');
    setPlanType('');
  };

  return (
    <SettingsContext.Provider
      value={{
        planType,
        setPlanType,
        organizationDetails,
        setOrganizationDetails,
        organizationApiKey,
        setOrganizationApiKey,
        organizationDetailsLoading,
        setOrganizationDetailsLoading,
        resetContext,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
