import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  status: string;
}
const AdminPaymentsStatus = ({ status }: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = ThemeStore.useState(s => s);

  const statusList = {
    OPEN: {
      color: theme.primary || '#3B24A8',
      background: '#EAECF6',
      label: t('hubly-dashboard.payments.admin.commons.status.open'),
    },
    CLOSED: {
      background: '#FFD8E0',
      color: '#F03460',
      label: t('hubly-dashboard.payments.admin.commons.status.closed'),
    },
    PAID: {
      color: '#2EB042',
      background: '#D8EED9',
      label: t('hubly-dashboard.payments.admin.commons.status.paid'),
    },
  };

  if (!statusList[status as keyof typeof statusList]) return <></>;

  const foundStatus = statusList[status as keyof typeof statusList];

  const Frame = styled.div`
    padding: 4px 12px;
    width: max-content;
    border-radius: 18px;

    background: ${foundStatus.background};
    color: ${foundStatus.color};
  `;

  return <Frame>{foundStatus.label}</Frame>;
};

export default AdminPaymentsStatus;
