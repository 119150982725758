import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Categories: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 26 26'} width={26} height={26} {...props}>
      <path
        d="M0.5 24.6071C0.5 24.8439 0.594069 25.071 0.761512 25.2385C0.928955 25.4059 1.15606 25.5 1.39286 25.5H24.6071C24.8439 25.5 25.071 25.4059 25.2385 25.2385C25.4059 25.071 25.5 24.8439 25.5 24.6071V10.3214C25.5 10.0846 25.4059 9.85753 25.2385 9.69008C25.071 9.52264 24.8439 9.42857 24.6071 9.42857H23.2679V5.85714C23.2679 5.62034 23.1738 5.39324 23.0063 5.2258C22.8389 5.05835 22.6118 4.96429 22.375 4.96429H21.0357V1.39286C21.0357 1.15606 20.9416 0.928955 20.7742 0.761512C20.6068 0.594069 20.3797 0.5 20.1429 0.5H5.85714C5.62034 0.5 5.39324 0.594069 5.2258 0.761512C5.05835 0.928955 4.96429 1.15606 4.96429 1.39286V4.96429H3.625C3.3882 4.96429 3.1611 5.05835 2.99365 5.2258C2.82621 5.39324 2.73214 5.62034 2.73214 5.85714V9.42857H1.39286C1.15606 9.42857 0.928955 9.52264 0.761512 9.69008C0.594069 9.85753 0.5 10.0846 0.5 10.3214V24.6071ZM6.75 2.28571H19.25V4.96429H6.75V2.28571ZM4.51786 6.75H21.4821V9.42857H4.51786V6.75ZM2.28571 11.2143H23.7143V23.7143H2.28571V11.2143Z"
        fill={theme.primary ||  "#3B24A8"}
      />
    </SvgIcon>
  );
}

export default Categories;
