import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const EmptyViewSVG: React.FC<SvgProps> = props => {
  return (
    <SvgIcon fill={'none'} viewBox="0 0 264 96" width={264} height={96} {...props}>
      <rect y="16" width="264" height="80" rx="12" fill="#1E1A34" />
      <g clipPath="url(#clip0_1974_9694)">
        <path
          d="M226.532 63.7274C225.176 53.8418 223.576 44.5612 221.688 35.9727L192.425 32.9373L192.452 33.0245C194.842 40.7901 197.024 49.6729 196.159 53.9781C194.406 62.6919 181.661 57.9345 178.907 56.8991C177.296 56.2942 166.346 51.4331 153.742 45.6239C153.742 45.6239 152.305 51.2315 144.539 48.3323C137.279 45.6239 133.867 36.7302 133.867 36.7302C133.867 36.7302 103.293 24.6812 82.8517 22.9101C65.8502 21.4387 50.5031 28.158 41.7738 51.4386C37.3384 63.2751 36.0051 82.4685 35.7656 98.4848L68.1577 99.3839L68.2829 99.3131C64.8488 73.7546 71.9291 59.9181 87.9782 60.5612L88.0218 60.5775L89.3715 100.398C89.3715 100.398 127.766 100.566 153.426 102.452C157.013 96.6046 160.12 90.5011 162.586 84.0597C162.586 84.0597 175.614 91.166 186.918 95.2967C203.092 101.21 229.835 95.6891 226.526 63.7328L226.532 63.7274Z"
          fill="#BEDE18"
        />
        <path
          d="M221.668 35.8867C221.674 35.9147 221.679 35.9483 221.69 35.9764L221.668 35.8867Z"
          fill="#BEDE18"
        />
        <path
          d="M213.916 5.32988C214.03 3.13368 212.349 1.25902 210.155 1.14457C207.962 1.03013 206.09 2.71405 205.976 4.91025C206.09 2.71405 204.408 0.839383 202.215 0.724941C200.022 0.610499 198.15 2.29442 198.036 4.49062C198.15 2.29442 196.468 0.419751 194.275 0.305309C192.082 0.190867 190.21 1.87482 190.095 4.07102L190.286 5.86394C185.148 5.78219 184.648 9.91302 185.497 12.7904C185.943 14.3054 187.554 18.7523 187.554 18.7523C187.554 18.7523 190.106 25.3627 192.446 32.9322L221.709 35.9677C220.387 29.9567 218.879 24.0821 217.263 18.7796C218.662 18.349 219.712 17.0847 219.794 15.537L221.878 5.74406C221.992 3.54786 220.311 1.67316 218.117 1.55872C215.924 1.44427 214.052 3.12823 213.938 5.32443L213.916 5.32988Z"
          fill="#EEEEED"
        />
        <path
          d="M139.915 24.1098L133.863 36.7312C133.863 36.7312 137.276 45.625 144.535 48.3335C152.307 51.2327 153.738 45.625 153.738 45.625L153.423 33.7067C155.055 34.1753 156.699 34.1862 158.157 33.4342C161.624 31.6467 162.571 24.3932 162.571 24.3932H165.744C165.744 24.3932 164.263 17.6302 163.398 13.9027C162.538 10.1751 160.987 8.6547 160.987 8.6547C160.987 8.6547 157.553 4.66556 151.371 5.5811C144.165 6.64923 142.446 14.9654 142.446 14.9654L140.47 14.7583C140.47 14.7583 137.553 12.731 135.371 14.3005C133.047 15.9681 134.201 19.0417 135.578 20.1916C136.954 21.3414 137.069 21.8919 138.174 23.3197C138.614 23.8864 139.246 24.1425 139.915 24.1044V24.1098Z"
          fill="#EEEEED"
        />
        <path
          d="M155.596 34.3443C154.519 34.3443 153.36 34.0882 152.113 33.5705C149.637 32.5405 147.623 30.8021 146.644 29.8593C143.079 26.4151 142.97 24.2134 142.965 24.1208L143.574 24.099C143.574 24.099 143.711 26.1753 147.063 29.4178C149.985 32.2407 154.611 34.9165 158.013 33.1617C161.3 31.4669 162.252 24.426 162.263 24.3551L162.296 24.0881H165.354C165.033 22.6221 163.83 17.1562 163.096 13.9736C162.268 10.3932 160.783 8.89454 160.766 8.87819C160.717 8.8237 157.338 5.00893 151.411 5.89177C144.511 6.9163 142.758 14.9545 142.742 15.0363L142.687 15.3088L140.358 15.0635L140.293 15.0199C140.266 14.9981 137.523 13.1398 135.542 14.5567C134.78 15.1017 134.377 15.8428 134.344 16.7474C134.295 18.039 135.025 19.3415 135.77 19.9627C136.75 20.7802 137.115 21.3033 137.61 22.0281C137.827 22.3442 138.072 22.6984 138.41 23.1399C138.943 23.832 139.901 23.9355 140.57 23.6794C140.886 23.554 141.621 23.1616 141.539 22.1317L142.149 22.0826C142.225 23.0799 141.719 23.8864 140.788 24.2461C139.765 24.6439 138.562 24.3333 137.925 23.5104C137.577 23.0581 137.327 22.693 137.104 22.3714C136.614 21.663 136.293 21.1943 135.373 20.4259C134.54 19.7338 133.67 18.2788 133.729 16.7202C133.768 15.6303 134.274 14.7038 135.183 14.0499C137.349 12.4967 140.091 14.1534 140.57 14.4641L142.203 14.633C142.584 13.1725 144.734 6.25145 151.319 5.27596C157.594 4.34408 161.066 8.28416 161.213 8.44765C161.262 8.49125 162.818 10.0553 163.694 13.8264C164.549 17.5104 166.024 24.2516 166.04 24.3224L166.122 24.693H162.834C162.622 26.0554 161.523 32.0391 158.301 33.7013C157.474 34.1263 156.57 34.3388 155.596 34.3388V34.3443Z"
          fill="#1E1A34"
        />
        <path
          d="M153.727 33.7021L153.117 33.7183L153.433 45.6324L154.042 45.6162L153.727 33.7021Z"
          fill="#1E1A34"
        />
        <path
          d="M153.683 102.611L153.16 102.289C159.968 91.199 164.387 80.2671 166.679 68.8828L167.277 69.0027C164.975 80.4633 160.523 91.4552 153.683 102.611Z"
          fill="#1E1A34"
        />
        <path
          d="M194.706 18.1152C194.635 18.1152 194.564 18.1152 194.499 18.1152C192.365 18.0007 190.716 16.1806 190.814 14.0443L189.77 4.08782V4.06603C189.829 2.92161 190.33 1.86983 191.184 1.10143C192.033 0.333034 193.133 -0.053895 194.276 0.00605096C195.419 0.0659969 196.469 0.56735 197.236 1.42294C198.004 2.27308 198.39 3.37394 198.33 4.51836L197.721 4.48563C197.77 3.5047 197.438 2.56192 196.785 1.83166C196.131 1.10141 195.228 0.670909 194.248 0.621862C192.235 0.50742 190.51 2.066 190.39 4.07691L191.435 14.0334V14.0552C191.337 15.8645 192.73 17.4122 194.537 17.5103C195.413 17.5593 196.251 17.2596 196.904 16.671C197.557 16.0825 197.938 15.2759 197.987 14.404L198.597 14.4367C198.542 15.4775 198.085 16.4312 197.312 17.1288C196.594 17.7773 195.674 18.1315 194.711 18.1315L194.706 18.1152Z"
          fill="#1E1A34"
        />
        <path
          d="M214.218 5.34651L213.608 5.31381C213.657 4.33288 213.325 3.39009 212.672 2.65984C212.014 1.92959 211.116 1.49909 210.136 1.45004C209.156 1.39554 208.215 1.73341 207.486 2.38736C206.756 3.04677 206.326 3.94597 206.277 4.92691L205.668 4.89418C205.793 2.53449 207.818 0.719788 210.169 0.83423C212.525 0.959572 214.343 2.98136 214.223 5.34105L214.218 5.34651Z"
          fill="#1E1A34"
        />
        <path
          d="M216.191 19.2484C216.12 19.2484 216.05 19.2484 215.984 19.2484C214.379 19.1612 213.046 18.1094 212.529 16.6816C212.311 17.123 212.006 17.5317 211.631 17.8751C210.858 18.5726 209.856 18.9214 208.822 18.8723C207.783 18.8178 206.831 18.3601 206.134 17.5862C205.791 17.2048 205.535 16.7742 205.367 16.3056C204.703 17.668 203.271 18.5781 201.66 18.4963C199.516 18.3819 197.867 16.5453 197.976 14.3982L197.715 4.50713C197.775 3.34091 198.275 2.28914 199.13 1.52074C199.979 0.752346 201.078 0.365417 202.221 0.425362C203.364 0.485308 204.414 0.986661 205.182 1.84225C205.949 2.69239 206.335 3.79322 206.275 4.93764L205.758 14.8124C205.715 15.6898 206.009 16.529 206.597 17.183C207.184 17.8369 207.99 18.2184 208.861 18.2674C209.737 18.3165 210.575 18.0167 211.228 17.4282C211.881 16.8396 212.262 16.0331 212.311 15.1611L213.617 5.30275C213.677 4.18013 214.178 3.12838 215.027 2.36543C215.876 1.59703 216.969 1.2101 218.118 1.27005C219.261 1.32999 220.311 1.83135 221.078 2.68694C221.846 3.53708 222.232 4.6379 222.172 5.78232V5.83139L220.088 15.6026C220.028 16.6325 219.571 17.5808 218.803 18.2729C218.085 18.9214 217.165 19.2756 216.202 19.2756L216.191 19.2484ZM212.91 15.1884C212.822 16.9922 214.21 18.5345 216.012 18.6271C216.888 18.6761 217.726 18.3764 218.379 17.7879C219.032 17.1993 219.413 16.3927 219.462 15.5208V15.4718L221.546 5.70605C221.59 4.73602 221.258 3.79867 220.61 3.07387C219.952 2.34362 219.054 1.91309 218.074 1.86404C217.095 1.81499 216.153 2.14744 215.424 2.80139C214.695 3.4608 214.265 4.35997 214.216 5.34091L212.915 15.1884H212.91ZM201.987 1.01935C201.078 1.01935 200.213 1.3518 199.533 1.96216C198.803 2.62157 198.373 3.52074 198.324 4.50167L198.586 14.3928C198.488 16.2238 199.881 17.777 201.688 17.8696C203.494 17.9677 205.04 16.5726 205.138 14.7633L205.655 4.88861C205.704 3.90768 205.372 2.96489 204.719 2.23464C204.06 1.50439 203.163 1.07385 202.183 1.02481C202.118 1.02481 202.052 1.02481 201.987 1.02481V1.01935Z"
          fill="#1E1A34"
        />
        <path
          d="M188.887 59.4392C186.177 59.4392 182.803 58.6872 178.797 57.1831C177.083 56.5401 165.883 51.5483 153.921 46.0442C153.676 46.6709 153.007 48.0006 151.456 48.8126C149.66 49.7554 147.292 49.69 144.43 48.6164L144.642 48.0442C147.336 49.0524 149.534 49.1232 151.173 48.2676C152.974 47.3248 153.442 45.5646 153.447 45.5483L153.54 45.1941L153.872 45.3467C166.95 51.3684 177.502 56.0442 179.015 56.6109C187.118 59.6518 191.167 58.9869 193.138 57.897C194.574 57.1014 195.489 55.7608 195.864 53.9188C197.551 45.5428 187.358 19.1397 187.254 18.8726C187.184 18.6819 185.633 14.3877 185.186 12.8836C184.561 10.7582 184.789 8.68739 185.801 7.35225C186.727 6.13155 188.234 5.52666 190.275 5.56481L190.264 6.17516C188.43 6.14246 187.091 6.6656 186.291 7.72282C185.393 8.90537 185.203 10.7691 185.774 12.7092C186.215 14.1969 187.81 18.6111 187.826 18.6547C188.245 19.7391 198.182 45.4884 196.463 54.0387C196.055 56.066 195.037 57.5428 193.437 58.4311C192.223 59.1068 190.705 59.4392 188.893 59.4447L188.887 59.4392Z"
          fill="#1E1A34"
        />
        <path
          d="M134.016 37.1181L133.75 37.0145C133.581 36.9491 116.797 30.366 100.008 26.2679L100.155 25.6738C115.828 29.4995 131.496 35.4832 133.711 36.3442L139.638 23.979L140.188 24.246L134.016 37.1235V37.1181Z"
          fill="#1E1A34"
        />
        <path
          d="M97.9468 25.7747C95.8244 25.2849 93.7727 24.855 91.8516 24.4958L91.9658 23.8917C93.8978 24.2509 95.955 24.6863 98.0883 25.1761L97.9523 25.7747H97.9468Z"
          fill="#1E1A34"
        />
        <path
          d="M40.3106 130.774C37.9487 130.774 36.0276 128.85 36.0276 126.485C35.995 125.984 32.833 74.4036 41.4861 51.3301C49.274 30.5673 63.2006 20.8997 82.8796 22.6054C83.7286 22.6763 84.6157 22.7744 85.5191 22.8834L85.4429 23.4937C84.545 23.3847 83.6688 23.2921 82.8252 23.2212C73.7639 22.4365 65.8727 24.0986 59.3693 28.1585C51.9679 32.7798 46.1447 40.6544 42.0576 51.5536C33.448 74.5071 36.6045 125.957 36.6371 126.474C36.6371 128.523 38.2861 130.169 40.3106 130.169C42.3351 130.169 43.9841 128.518 43.9841 126.491H44.5937C44.5937 128.856 42.6726 130.779 40.3106 130.779V130.774Z"
          fill="#1E1A34"
        />
        <path
          d="M67.7689 124.703L67.6437 124.103C71.1702 123.351 70.8056 119.978 70.7893 119.836L68.2097 100.97C67.7634 97.9673 67.4532 95.0735 67.2845 92.3596C66.4954 79.6676 68.7104 70.5505 73.8696 65.2699C77.3526 61.7058 82.1037 60.0165 87.9921 60.2508L87.9704 60.8611C82.256 60.6323 77.6628 62.2562 74.3104 65.6949C69.2764 70.8502 67.1158 79.8093 67.894 92.3215C68.0627 95.0136 68.3729 97.8964 68.8192 100.883L71.3988 119.755C71.4043 119.798 71.8396 123.836 67.7743 124.703H67.7689Z"
          fill="#1E1A34"
        />
        <path
          d="M224.439 51.9251C222.366 39.4891 219.846 28.3609 216.945 18.8459L217.528 18.666C220.434 28.2029 222.964 39.3583 225.043 51.8216L224.439 51.9197V51.9251Z"
          fill="#1E1A34"
        />
        <path
          d="M199.029 97.6736C194.638 97.6736 190.387 96.8835 186.817 95.581C175.617 91.4883 162.572 84.3983 162.441 84.3275L162.735 83.788C162.866 83.8588 175.873 90.927 187.029 95.0088C195.612 98.1478 208.2 98.2513 216.967 91.4066C224.303 85.679 227.509 76.1204 226.23 63.7661C226.001 62.1039 225.762 60.4254 225.517 58.7796L226.121 58.687C226.366 60.3328 226.605 62.0167 226.834 63.6898C227.743 72.4691 226.834 84.4801 217.342 91.8861C212.047 96.0224 205.391 97.6736 199.029 97.6736Z"
          fill="#1E1A34"
        />
        <path
          d="M209.166 27.4393L208.557 27.3684C209.096 22.8507 207.572 19.6899 203.898 17.7171L204.187 17.1776C208.051 19.2539 209.727 22.709 209.161 27.4393H209.166Z"
          fill="#1E1A34"
        />
        <path
          d="M45.9259 87.4838L45.7969 88.0795L71.9517 93.7611L72.0807 93.1654L45.9259 87.4838Z"
          fill="#1E1A34"
        />
        <path
          d="M36.2619 85.3744L36.1328 85.9701L44.0144 87.6822L44.1434 87.0865L36.2619 85.3744Z"
          fill="#1E1A34"
        />
        <path
          d="M192.473 32.6643L192.41 33.2706L221.673 36.3091L221.736 35.7028L192.473 32.6643Z"
          fill="#1E1A34"
        />
        <path
          d="M194.467 39.589L194.336 40.1842L229.869 48.0462L230 47.451L194.467 39.589Z"
          fill="#1E1A34"
        />
        <path
          d="M89.0578 89.1575L88.9375 89.755L153.358 102.762L153.478 102.164L89.0578 89.1575Z"
          fill="#1E1A34"
        />
        <path
          d="M193.385 77.8533C193.319 77.8533 193.265 77.8424 193.211 77.826C192.84 77.7007 192.068 77.4337 191.997 68.6979C191.964 64.4799 192.111 60.2674 192.117 60.2238L192.726 60.2455C192.362 70.2129 192.775 76.638 193.39 77.2266C193.417 77.2048 193.461 77.1666 193.515 77.0795C195.268 74.6217 193.869 65.1121 193.853 65.0194L193.744 64.311L194.326 64.7252C194.408 64.7796 202.31 70.3001 205.173 66.8505C205.657 66.2674 205.504 65.9241 205.455 65.8096C205.221 65.2865 204.095 64.3055 199.153 63.608C196.28 63.1993 193.499 63.0957 193.472 63.0957L193.494 62.4854C194.653 62.529 204.862 62.9758 206.016 65.5644C206.255 66.0984 206.13 66.6652 205.646 67.2483C202.876 70.5781 196.509 66.8778 194.549 65.6134C194.843 67.8968 195.638 75.1666 194.011 77.4446C193.777 77.7715 193.559 77.8642 193.379 77.8642L193.385 77.8533Z"
          fill="#1E1A34"
        />
        <path
          d="M114.896 94.6977L114.309 94.5287C120.698 72.4798 123.076 51.5916 121.373 32.4582L121.982 32.4037C123.691 51.6189 121.307 72.5779 114.896 94.7031V94.6977Z"
          fill="#1E1A34"
        />
        <path
          d="M107.96 93.2646L107.355 93.1556C112.248 65.6135 113.995 45.4556 112.863 29.7227L113.473 29.6791C114.61 45.4665 112.852 65.6735 107.954 93.2646H107.96Z"
          fill="#1E1A34"
        />
        <path
          d="M148.114 101.477L147.586 101.166C159.657 80.4142 162.851 63.2263 164.283 50.5724L164.892 50.6432C162.149 74.8502 154.487 90.5232 148.114 101.477Z"
          fill="#1E1A34"
        />
        <path
          d="M143.389 100.376L142.828 100.137C150.393 82.3765 154.823 64.8998 156.368 46.6927L156.978 46.7473C155.421 65.0142 150.981 82.5564 143.389 100.376Z"
          fill="#1E1A34"
        />
        <path
          d="M70.9243 70.9438C68.3556 70.9438 65.8685 70.4478 64.6331 69.7884C63.9256 69.4124 63.5828 68.9819 63.6154 68.5132C63.6535 67.9574 64.2195 67.4833 65.3352 67.0745C68.3719 65.9628 70.8971 67.8266 71.0005 67.9029L70.6359 68.3933C70.6359 68.3933 68.2631 66.6549 65.5474 67.6467C64.3501 68.0827 64.2358 68.4478 64.2249 68.5514C64.2141 68.7421 64.4698 69.0037 64.9215 69.2435C66.1352 69.8865 68.5025 70.328 70.8209 70.3389C73.2536 70.3389 75.2726 69.9192 76.6114 69.1181C74.7665 68.4696 69.3352 66.1318 68.41 60.7857C68.0617 58.7911 68.3393 57.6467 69.2209 57.3851C70.761 56.9273 73.6454 59.8701 74.7502 62.4805C75.7733 64.8838 76.3611 66.4151 76.3611 66.6713H76.0563L75.7515 66.704C75.7515 66.704 75.6481 66.1535 74.1896 62.7148C73.0631 60.0718 70.4127 57.6685 69.395 57.9682C68.878 58.1208 68.742 59.1072 69.0141 60.6821C70.059 66.7258 77.3026 68.704 77.3733 68.7203L77.9829 68.8729L77.4876 69.2598C75.9311 70.4806 73.3951 70.9383 70.9243 70.9383V70.9438Z"
          fill="#1E1A34"
        />
        <path
          d="M128.286 66.1034L127.82 66.4973L147.179 89.4362L147.645 89.0423L128.286 66.1034Z"
          fill="#1E1A34"
        />
        <path
          d="M124.52 61.6534L124.055 62.0471L126.859 65.3702L127.324 64.9765L124.52 61.6534Z"
          fill="#1E1A34"
        />
        <path
          d="M124.466 76.4881L124.125 76.9941L146.332 91.9933L146.673 91.4873L124.466 76.4881Z"
          fill="#1E1A34"
        />
        <path
          d="M144.112 18.11C143.726 18.11 143.35 18.0229 142.98 17.8539C140.907 16.8893 139.857 13.5269 139.813 13.3852V13.3743C139.791 13.3035 139.34 11.5487 139.405 9.47785C139.492 6.68219 140.493 4.68217 142.306 3.68489C144.923 2.24619 148.123 2.86746 151.514 3.52686C153.663 3.94104 155.884 4.37156 157.963 4.24622C163.182 3.93014 165.315 3.29798 165.337 3.29253C166.54 2.99825 167.71 3.51596 168.57 4.70942C169.642 6.19717 170.148 8.78574 168.972 10.6604C168.221 11.8539 166.556 13.1454 162.866 12.4969C160.602 12.0991 158.284 10.9111 156.042 9.75579C152.972 8.18084 150.322 6.81844 148.907 8.0882C147.639 9.22717 147.361 11.4179 147.117 13.3525C146.861 15.3743 146.616 17.2817 145.288 17.8539C144.896 18.0229 144.504 18.11 144.123 18.11H144.112ZM140.395 13.2108C140.444 13.3689 141.446 16.4697 143.236 17.2981C143.819 17.5651 144.406 17.5651 145.032 17.2981C146.039 16.8621 146.262 15.1237 146.496 13.2817C146.757 11.2435 147.051 8.93289 148.488 7.64133C150.213 6.08818 153.038 7.54321 156.308 9.2217C158.512 10.3552 160.787 11.5215 162.959 11.9029C165.615 12.3716 167.514 11.8321 168.439 10.3443C169.468 8.69853 169.011 6.3988 168.058 5.07454C167.351 4.09361 166.415 3.66309 165.484 3.88653C165.413 3.90832 163.291 4.54047 157.985 4.862C155.829 4.99279 153.566 4.55684 151.383 4.13177C148.102 3.49416 145.005 2.8947 142.583 4.22985C138.714 6.36066 140.325 12.9765 140.384 13.2163L140.395 13.2108Z"
          fill="#1E1A34"
        />
        <path
          d="M155.499 27L155.254 27.5579L161.761 27.8133L162.007 27.2554L155.499 27Z"
          fill="#1E1A34"
        />
        <path
          d="M88.0545 51.6004L87.4453 51.6212L89.1036 100.394L89.7128 100.373L88.0545 51.6004Z"
          fill="#1E1A34"
        />
        <path d="M121.516 95.9458H120.906V100.829H121.516V95.9458Z" fill="#1E1A34" />
        <path
          d="M139.587 21.0793C139.173 20.9104 138.934 20.7741 138.863 20.5289C138.787 20.2728 138.928 20.0657 139.103 19.8041C139.288 19.5262 139.571 19.112 139.788 18.4036C139.87 18.1311 139.843 17.8858 139.701 17.6461C139.206 16.8232 137.508 16.3272 136.898 16.2074L137.018 15.6079C137.122 15.6297 139.494 16.1038 140.229 17.33C140.463 17.7224 140.512 18.142 140.376 18.5834C140.131 19.3791 139.815 19.8423 139.609 20.1475C139.565 20.2129 139.511 20.2946 139.478 20.3491C139.527 20.3818 139.625 20.4308 139.821 20.5126L139.592 21.0793H139.587Z"
          fill="#1E1A34"
        />
        <path
          d="M153.593 40.0596L153.425 33.7053C153.425 33.7053 151.144 33.2094 148.298 30.8988C147.357 30.1358 145.555 28.4464 144.554 26.975C143.504 25.4273 143.275 24.1085 143.275 24.1085L142.965 24.2012L147.357 30.7516L153.599 40.0596H153.593Z"
          fill="#1E1A34"
        />
        <path
          d="M192.355 32.7201L220.742 31.8809L221.689 35.9735L192.355 32.7201Z"
          fill="#1E1A34"
        />
        <path
          d="M143.109 100.257L147.85 101.325C157.439 84.9597 163.022 67.7717 164.59 50.6111L156.655 46.9598C155.447 64.344 150.533 82.1532 143.109 100.257Z"
          fill="#1E1A34"
        />
        <path
          d="M121.679 32.4321C123.769 54.6609 120.253 74.6771 114.604 94.6171L107.66 93.2165C112.041 68.4482 114.441 46.4811 113.173 29.7073L121.679 32.4375V32.4321Z"
          fill="#1E1A34"
        />
        <path
          d="M166.242 72.3218L173.519 89.5753L162.781 84.1638L166.242 72.3218Z"
          fill="#1E1A34"
        />
        <path
          d="M140.102 13.2922C140.102 13.2922 138.262 6.25124 142.447 3.9515C146.627 1.65176 152.646 4.87251 157.979 4.55098C163.307 4.22945 165.424 3.58638 165.424 3.58638C169.756 2.52916 172.369 13.8589 162.916 12.2022C157.517 11.254 151.362 5.4774 148.695 7.86979C146.028 10.2622 147.52 16.5565 145.158 17.581C141.849 19.0088 140.102 13.3031 140.102 13.3031V13.2922Z"
          fill="#1E1A34"
        />
        <path
          d="M124.12 45.2958L111.441 44.2223L111.337 45.4587L124.015 46.5323L124.12 45.2958Z"
          fill="#EEEEED"
        />
        <path
          d="M124.288 46.8665L111.004 45.7384L111.162 43.891L124.446 45.019L124.288 46.8665ZM111.662 45.1826L123.728 46.2071L123.782 45.5804L111.717 44.5558L111.662 45.1826Z"
          fill="#EEEEED"
        />
        <path
          d="M164.337 64.9462L152.484 60.3187L152.034 61.475L163.887 66.1024L164.337 64.9462Z"
          fill="#EEEEED"
        />
        <path
          d="M164.069 66.4966L151.645 61.6465L152.319 59.9189L164.744 64.7691L164.069 66.4966ZM152.439 61.2977L163.721 65.701L163.949 65.1124L152.668 60.7091L152.439 61.2977Z"
          fill="#EEEEED"
        />
        <path
          d="M155.818 18.772C156.242 18.772 156.585 18.4285 156.585 18.0047C156.585 17.5809 156.242 17.2373 155.818 17.2373C155.394 17.2373 155.051 17.5809 155.051 18.0047C155.051 18.4285 155.394 18.772 155.818 18.772Z"
          fill="#1E1A34"
        />
        <path
          d="M219.025 41.9158C219.647 41.9158 220.152 41.4114 220.152 40.7893C220.152 40.1671 219.647 39.6627 219.025 39.6627C218.403 39.6627 217.898 40.1671 217.898 40.7893C217.898 41.4114 218.403 41.9158 219.025 41.9158Z"
          fill="#1E1A34"
        />
        <path
          d="M41.6578 95.1037C42.28 95.1037 42.7843 94.5993 42.7843 93.9771C42.7843 93.355 42.28 92.8506 41.6578 92.8506C41.0356 92.8506 40.5312 93.355 40.5312 93.9771C40.5312 94.5993 41.0356 95.1037 41.6578 95.1037Z"
          fill="#1E1A34"
        />
      </g>
      <defs>
        <clipPath id="clip0_1974_9694">
          <rect width="196" height="96" fill="white" transform="matrix(-1 0 0 1 230 0)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default EmptyViewSVG;
