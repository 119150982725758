export default () => {
  const get = <T>(key: string, args = { json: true }): T | null => {
    const { json } = args;

    const item = localStorage.getItem(key);

    return item ? (json ? JSON.parse(item) : item) : null;
  };

  const set = (key: string, data: string): void => localStorage.setItem(key, data);

  return { get, set };
};
