import React, { FC } from 'react';
import { LogoProps } from './logo.types';
import { Image } from 'rebass';

const Logo: FC<LogoProps> = ({ variant = 'dark', width = '114px', height = 'auto' }) => {
  return (
    <Image
      width={width}
      height={height}
      src={`https://plugone-production.nyc3.digitaloceanspaces.com/assets/logos/monitfy-${variant}.png`}
      alt="Logo da Monitfy"
    />
  );
};

export default Logo;
