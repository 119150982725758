import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Order: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 22 18'} width={22} height={18} {...props}>
      <path
        d="M0.518135 17.3514C0.518135 17.3694 0.518135 17.3874 0.536269 17.4234C0.536269 17.4414 0.554404 17.4775 0.554404 17.4955C0.554404 17.5135 0.572539 17.5315 0.572539 17.5676C0.590674 17.5856 0.590674 17.6036 0.608808 17.6396C0.626943 17.6577 0.626943 17.6757 0.645078 17.6937C0.663212 17.7117 0.681347 17.7297 0.681347 17.7477C0.699482 17.7658 0.717617 17.7838 0.735751 17.8018L0.753886 17.8198C0.753886 17.8198 0.772021 17.8198 0.772021 17.8378C0.826425 17.8919 0.898964 17.9279 0.971503 17.9459C0.989637 17.9459 0.989637 17.9459 1.00777 17.964C1.08031 17.982 1.15285 18 1.22539 18H20.7927C20.8653 18 20.9378 17.982 21.0104 17.964C21.0285 17.964 21.0285 17.964 21.0466 17.9459C21.1192 17.9099 21.1917 17.8739 21.2461 17.8378C21.2461 17.8378 21.2642 17.8378 21.2642 17.8198L21.2824 17.8018C21.3005 17.7838 21.3187 17.7658 21.3368 17.7477C21.3549 17.7297 21.3731 17.7117 21.3731 17.6937C21.3912 17.6757 21.3912 17.6577 21.4093 17.6396C21.4275 17.6216 21.4275 17.6036 21.4456 17.5676C21.4637 17.5495 21.4637 17.5315 21.4637 17.4955C21.4637 17.4775 21.4819 17.4414 21.4819 17.4234C21.4819 17.4054 21.5 17.3874 21.5 17.3514C21.5 17.3333 21.5 17.2973 21.5 17.2793C21.5 17.2613 21.5 17.2613 21.5 17.2432V0.738739C21.5 0.324324 21.1736 0 20.7565 0H1.24352C0.826425 0 0.5 0.324324 0.5 0.738739V17.2432C0.5 17.2613 0.5 17.2613 0.5 17.2793C0.518135 17.2973 0.518135 17.3153 0.518135 17.3514ZM2.83938 16.5045L8.3342 9.98198L10.6192 11.5676C10.7461 11.6577 10.8912 11.6937 11.0363 11.6937C11.1813 11.6937 11.3264 11.6577 11.4534 11.5676L13.7383 9.98198L19.2332 16.5045H2.83938ZM20.0855 15.2072L14.9534 9.13514L20.0674 5.58559V15.2072H20.0855ZM1.98705 1.47748H20.0674V3.8018L11.0363 10.0541L1.98705 3.8018V1.47748ZM1.98705 5.58559L7.10104 9.13514L1.98705 15.2072V5.58559Z"
        fill={theme.primary || "#3B24A8"}
      />
    </SvgIcon>
  );
}

export default Order;
