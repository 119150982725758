import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';
import { CART, CartResponse, CartVariables } from '../graphql/queries/checkout/cart';
import useLocalStorage from '@plug/hooks/use-local-storage';
import { LOCAL_CART_INFO_KEY } from '@plug/config/consts';
import {
  CART_MUTATION,
  CartMutationResponse,
  CartMutationVariables,
} from '../graphql/mutations/checkout/cart';
import {
  UPDATE_CART,
  UpdateCartResponse,
  UpdateCartVariables,
} from '../graphql/mutations/checkout/update-cart';
import { GET_USER_IN_ORGANIZATION_SERVICE } from '../graphql/queries/get-user-organization-by-service-name';
import {
  ATTACH_MARKETING_DATA,
  AttachMarketingDataResponse,
  AttachMarketingDataVariables,
} from '../graphql/mutations/checkout/attach-marketing-data';
import { useAuthContext } from '@plug/contexts/auth-context';

interface CheckoutCartContext {
  cart?: Cart;
  refetchCart: () => void;
  actions: {
    addCartItem(id: string): Promise<void>;
    removeCartItem(id: string): Promise<void>;
  };
  states: {
    isLoadingCart?: boolean;
    isAddingItem?: boolean;
    isRemovingItem?: boolean;
    updatingItemId?: string;
  };
  isAdded(id: string);
}

const initialValues: CheckoutCartContext = {
  refetchCart: null,
  actions: {
    addCartItem: null,
    removeCartItem: null,
  },
  states: {},
  isAdded: null,
};

const CheckoutCartContext = React.createContext(initialValues);

export const useCheckoutCartContext = () => React.useContext(CheckoutCartContext);

export const CheckoutCartContextProvider: FC = ({ children }) => {
  const [cart, setCart] = useState<Cart | undefined>();
  const [updatingItem, setUpdatingItem] = useState('');
  const { get, set } = useLocalStorage();
  const cartInfo: {
    id?: string;
  } = get(LOCAL_CART_INFO_KEY, { json: true });

  const { currentOrganization } = useAuthContext();

  const { data: dataUserInOrganizationService } = useQuery(GET_USER_IN_ORGANIZATION_SERVICE, {
    variables: { input: { serviceName: 'affiliate' } },
  });

  const [attach] = useMutation<AttachMarketingDataResponse, AttachMarketingDataVariables>(
    ATTACH_MARKETING_DATA,
    {
      refetchQueries: [CART],
    },
  );

  console.log('xablas masger', cartInfo);

  const { loading: isLoadingCart, refetch } = useQuery<CartResponse, CartVariables>(CART, {
    variables: {
      id: cartInfo?.id ?? '',
      metadata: [
        ...(!cartInfo?.id
          ? [
              {
                key: 'forceNewCart',
                value: 'true',
                items: [],
              },
            ]
          : []),
      ],
    },
    notifyOnNetworkStatusChange: true,
    skip: !dataUserInOrganizationService?.getUserOrganizationByServiceName?.id,
    onCompleted(data) {
      if (data.cart) {
        if (!cartInfo?.id) {
          set(
            LOCAL_CART_INFO_KEY,
            JSON.stringify({
              ...cartInfo,
              id: data.cart.id,
            }),
          );
        }

        if (!data?.cart?.marketingData?.utmSource) {
          attach({
            variables: {
              cartId: data?.cart?.id,
              data: {
                utmSource: 'checkout',
                utmCampaign: `${dataUserInOrganizationService?.getUserOrganizationByServiceName?.id}_${currentOrganization.id}`,
              },
            },
          });
        }

        setCart(data.cart as Cart | undefined);
      }
    },
  });

  const [addItems, { loading: isAddingItem }] = useMutation<
    CartMutationResponse,
    CartMutationVariables
  >(CART_MUTATION, {
    onCompleted(data) {
      if (data.cart) {
        setCart(data.cart);
      }
    },
  });

  const [updateItems, { loading: isRemovingItem }] = useMutation<
    UpdateCartResponse,
    UpdateCartVariables
  >(UPDATE_CART, {
    onCompleted(data) {
      if (data.cart) {
        setCart(data.cart);
      }
    },
  });

  const handleAddItem = async (id: string) => {
    setUpdatingItem(id);

    await addItems({
      variables: {
        id: cart.id,
        items: [
          {
            id,
            quantity: 1,
            metadata: [
              {
                key: 'seller',
                value: '1',
              },
            ],
          },
        ],
      },
    });

    setUpdatingItem('');
  };

  const handleRemoveItem = async (id: string) => {
    setUpdatingItem(id);

    await updateItems({
      variables: {
        id: cart.id,
        items: [
          {
            id,
            quantity: 0,
            metadata: [
              {
                key: 'index',
                value: String(cart?.items.findIndex(item => item.id === id)),
              },
            ],
          },
        ],
      },
    });

    setUpdatingItem('');
  };

  return (
    <CheckoutCartContext.Provider
      value={{
        cart,
        refetchCart: refetch,
        actions: {
          addCartItem: handleAddItem,
          removeCartItem: handleRemoveItem,
        },
        states: {
          isLoadingCart,
          isAddingItem,
          isRemovingItem,
          updatingItemId: updatingItem,
        },
        isAdded(id) {
          if (!cart?.items?.length) return false;

          return cart.items.filter(item => item.id === id).length === 1;
        },
      }}
    >
      {children}
    </CheckoutCartContext.Provider>
  );
};
