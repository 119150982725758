import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

const LocationListener: FC<{ onLocationChange: (path: string) => void }> = ({
  children,
  onLocationChange,
}): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location: any) => onLocationChange(location.pathname));

    return (): void => unlisten();
  }, [onLocationChange, history]);

  return <>{children}</>;
};

export default LocationListener;
