import { MenuTreeGroup } from '@plug/graphql/queries/menu-tree';
import React, { useState } from 'react';
import { useHublyStoreContext } from './hubly-store-context';
import { useSettingsContext } from './settings-context';

interface NavigationMenuContextI {
  items: MenuTreeGroup;
  setItems: (items: MenuTreeGroup) => void;
  loadingMenu: boolean;
  setLoadingMenu: (LoadingMenu: boolean) => void;
  openedOrganizationListSection: boolean;
  setOpenedOrganizationListSection: (newOpenedOrganizationListSection: boolean) => void;
  selectedItemMenuId: string;
  setSelectedItemMenuId: (newselectedItemMenu: string) => void;
  selectedSubItemMenuId: string;
  setSelectedSubItemMenuId: (newselectedItemMenu: string) => void;
  openedNavigationMenuMobile: boolean;
  setOpenedNavigationMenuMobile: (newOpenedNavigationMenuMobile: boolean) => void;
  resetContext: () => void;
}

export const NavigationMenuContextInitialValues: NavigationMenuContextI = {
  items: [],
  setItems: () => null,
  loadingMenu: false,
  setLoadingMenu: () => null,
  openedOrganizationListSection: false,
  setOpenedOrganizationListSection: () => null,
  selectedItemMenuId: '',
  setSelectedItemMenuId: () => null,
  selectedSubItemMenuId: '',
  setSelectedSubItemMenuId: () => null,
  openedNavigationMenuMobile: false,
  setOpenedNavigationMenuMobile: () => null,
  resetContext: () => null,
};

export const NavigationMenuContext = React.createContext<NavigationMenuContextI>(
  NavigationMenuContextInitialValues,
);

export const useNavigationMenuContext = (): NavigationMenuContextI =>
  React.useContext(NavigationMenuContext);

export const NavigationMenuContextProvider: React.FC = ({ children }) => {
  const [openedOrganizationListSection, setOpenedOrganizationListSection] = useState(
    NavigationMenuContextInitialValues.openedOrganizationListSection,
  );
  const [selectedItemMenuId, setSelectedItemMenuId] = useState(
    NavigationMenuContextInitialValues.selectedItemMenuId,
  );
  const [selectedSubItemMenuId, setSelectedSubItemMenuId] = useState(
    NavigationMenuContextInitialValues.selectedSubItemMenuId,
  );
  const [openedNavigationMenuMobile, setOpenedNavigationMenuMobile] = useState(
    NavigationMenuContextInitialValues.openedNavigationMenuMobile,
  );
  const [items, setItems] = useState(NavigationMenuContextInitialValues.items);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const { resetContext: resetHublyStoreContext } = useHublyStoreContext();
  const { resetContext: resetSettingsContext } = useSettingsContext();

  const resetContext = (): void => {
    setSelectedItemMenuId(NavigationMenuContextInitialValues.selectedItemMenuId);
    resetHublyStoreContext();
    resetSettingsContext();
    setItems([]);
    setSelectedItemMenuId('');
    setLoadingMenu(true);
  };

  const providerValue = {
    items,
    setItems,
    loadingMenu,
    setLoadingMenu,
    resetContext,
    selectedItemMenuId,
    setSelectedItemMenuId,
    selectedSubItemMenuId,
    setSelectedSubItemMenuId,
    openedOrganizationListSection,
    setOpenedOrganizationListSection,
    openedNavigationMenuMobile,
    setOpenedNavigationMenuMobile,
  };

  return (
    <NavigationMenuContext.Provider value={providerValue}>
      {children}
    </NavigationMenuContext.Provider>
  );
};
