import React from 'react';
import Loadable from 'react-loadable';
import { RouteOrRedirectType } from '@plug/routes/types';

import DashboardLayout from '@plug/components/layouts/dashboard';

import affiliateRouting from './domains/affiliate/routing';
import settingsRouting from './domains/settings/routing';
import { Spinner } from '@gohubly/design-system';
import { Flex } from 'rebass';
import { ENVIRONMENT } from '@plug/config/env';

const Loading = (): JSX.Element => (
  <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
    <Spinner />
  </Flex>
);

const Overview = Loadable({
  loader: () => import('./pages/overview'),
  loading: Loading,
});

const Subscription = Loadable({
  loader: () => import('./pages/subscription'),
  loading: Loading,
});

export const OVERVIEW_PATH = '/org/:organizationSlug/overview';
export const SUBSCRIPTION_PATH = '/org/:organizationSlug/subscription';

const routes: RouteOrRedirectType[] = [
  {
    component: DashboardLayout,
    path: '/org/*',
    privateRoute: true,
    routes: [
      {
        path: OVERVIEW_PATH,
        component: Overview,
        title: 'Visão Geral',
        hideHeaderTitle: true,
        exact: true,
      },
      ...[
        {
          path: SUBSCRIPTION_PATH,
          component: Subscription,
          exact: true,
        },
      ],
      ...affiliateRouting,
      ...settingsRouting,
    ],
  },
];

export default routes;
