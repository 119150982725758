import React from 'react';
import {
  useMenuState,
  Menu as BaseMenu_,
  MenuItem as MenuItem_,
  MenuButton as MenuButton_,
} from 'reakit/Menu';
import { Text } from '../';
import { Ellipsis } from '../icons';
import styled from 'styled-components';
import { Flex } from 'rebass';
import getCustomizatedColor from '@plug/helpers/get-customizated-color';

const MenuItem = styled(MenuItem_)`
  color: #666372;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  svg {
    fill: #c4c4c4;
  }
  &:hover {
    background: #f4f6fa;
    border-radius: 8px;

    svg {
      fill: ${props => getCustomizatedColor('icons', props.theme.primary) || '#3b24a8'};
    }
  }
  outline: none;
`;

const MenuButtonBase = styled.div`
  cursor: pointer;
  padding: 8px 9px;
  &:hover {
    svg {
      fill: ${props => getCustomizatedColor('icons', props.theme.primary) || '#3b24a8'};
    }
  }
`;

const MenuButton = styled(MenuButton_)`
  border: 0;
  padding: 8px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: -9px;

  &[aria-expanded='true'] {
    svg {
      fill: ${props => getCustomizatedColor('icons', props.theme.primary) || '#3b24a8'};
    }
    transform: rotate(90deg);
    border: 1px solid #d4d3d8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-sizing: border-box;
    border-bottom: none;
    position: absolute;
    z-index: 6;
    background: #fff;
  }

  &:hover {
    svg {
      fill: ${props => getCustomizatedColor('icons', props.theme.primary) || '#3b24a8'};
    }
  }

  transition: transform 0.1s linear;
`;

const BaseMenu = styled(BaseMenu_)`
  position: absolute;
  bottom: auto !important;
  left: auto !important;
  top: -16.25px !important;

  right: -11px !important;
  opacity: 1 !important;

  /* &[aria-expanded='true'] {
    right: 64px !important;
    opacity: 1 !important;
  } */

  width: max-content;

  z-index: 5;
  display: flex;
  padding: 4.75px;
  margin-top: -1px;
  flex-direction: row;
  background: #fbfcfd;
  border: 1px solid #d4d3d8;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 2px 4px rgba(28, 22, 55, 0.04), 0px 8px 16px rgba(28, 22, 55, 0.06);

  transform: none !important;
  transition: all 0.3s linear;
`;

interface MenuProps {
  onClick?: () => void;
  menuIconStyles?: any;
  items?: Array<{
    text?: string;
    icon?: any;
    onClick?: () => void;
  }>;
}

const Menu: React.FC<MenuProps> = ({ items, menuIconStyles, onClick, ...props }) => {
  const menu = useMenuState();
  if (items && items.length) {
    return (
      <>
        <MenuButton style={{ ...menuIconStyles }} {...menu}>
          <Ellipsis />
        </MenuButton>
        <BaseMenu {...menu} {...props}>
          {items.map((item, i) => {
            return (
              <MenuItem onClick={item.onClick ? item.onClick : undefined} {...menu} key={i}>
                <Flex py="6px" px="12px">
                  {item.icon && <Flex pr="12px">{item.icon}</Flex>}
                  <Text fontSize="12px" fontWeight={600}>
                    {item.text}
                  </Text>
                </Flex>
              </MenuItem>
            );
          })}
        </BaseMenu>
      </>
    );
  }
  return (
    <MenuButtonBase onClick={onClick}>
      <Ellipsis />
    </MenuButtonBase>
  );
};

export default Menu;
