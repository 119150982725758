import gql from 'graphql-tag';

export const GET_USER_IN_ORGANIZATION_SERVICE = gql`
  query getUserOrganizationByServiceName($input: GetUserOrganizationByServiceNameInput!) {
    getUserOrganizationByServiceName(input: $input) {
      id
      bankData {
        id
        name
        agency
        account
        accountDigit
        document
        brazilBank {
          id
          name
        }
      }
      # invoice {
      #   url
      #   receiptDay
      #   isLastDay
      # }
    }
  }
`;
