import React from 'react';
import { Box } from 'rebass';
import { Checkbox } from '../';

const SelectCell = ({ row }: any) => (
  <Box
    as="td"
    sx={{
      display: 'flex',
      '@media screen and (max-width: 968px)': {
        display: 'none',
      },
    }}
  >
    <Checkbox checked={row.isSelected} onClick={() => row.toggleRowSelected(!row.isSelected)} />
  </Box>
);

export default SelectCell;
