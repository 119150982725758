import React, { useEffect } from 'react';
import useLocalStorage from '@plug/hooks/use-local-storage';
import { MARY_KAY_OAUTH_CODE_VERIFIER } from '@plug/config/consts';
import { useMutation } from '@apollo/client';
import signinSsoMutation, {
  SignInWithSSOResponse,
  SignInWithSSOVariables,
} from '../../graphql/mutations/signin-sso-mutation';
import { useLogin } from '../../hooks/useLogin';
import { Flex } from 'rebass';
import { Typography } from '@gohubly/design-system';
import { Loading } from '@monitfy/ds';

const LoginMaryKayConfirmation = () => {
  const { get } = useLocalStorage();
  const { login, loading: loginLoading } = useLogin();

  const [signIn, { loading }] = useMutation<SignInWithSSOResponse, SignInWithSSOVariables>(
    signinSsoMutation,
    {
      onCompleted(data) {
        if (data?.signInWithSSO?.token) {
          login({
            id: data.signInWithSSO.user.id,
            token: data.signInWithSSO.token,
            email: data.signInWithSSO.user.email,
            username: data.signInWithSSO.user.username,
            cookieDays: 15,
            loginAnimation: true,
            redirect: true,
          });
        }
      },
    },
  );

  const getAccessToken = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const codeVerifier = get(MARY_KAY_OAUTH_CODE_VERIFIER, { json: false }) as string;

    localStorage.removeItem(MARY_KAY_OAUTH_CODE_VERIFIER);

    signIn({
      variables: {
        input: {
          provider: 'MaryKay',
          code,
          codeVerifier,
        },
      },
    });
  };

  const isLoading = loading || loginLoading;

  useEffect(() => {
    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      width="100vw"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ gap: '16px' }}
    >
      <Typography color="neutralLowDark" size="22" fontWeight={500}>
        Estamos sincronizando seus dados com nossa base...
      </Typography>

      {isLoading && <Loading />}
    </Flex>
  );
};

export default LoginMaryKayConfirmation;
