import useViewMode from '@plug/hooks/use-view-mode';
import React, { useState } from 'react';
import AccordionItem from '@plug/domains/organization/components/accordion/accordion-item';
import GreetingUser from '@plug/domains/organization/components/greeting-user';
import IntegrateBadge from '@plug/domains/organization/components/integrate-badge';
import IntegrateStore from '@plug/domains/organization/components/integrate-store';
import InviteMembers from '@plug/domains/organization/components/invite-members';
import NotMember from '@plug/domains/organization/components/not-member';
import Accordion from '@plug/domains/organization/components/accordion';
import { Flex } from 'rebass';

interface Props {
  organization: Organization;
  onRefetchOrganization: () => void;
}

const AdminGamification: React.FC<Props> = ({
  organization,
  onRefetchOrganization,
}): JSX.Element => {
  const { grant: grantIntegrations } = useViewMode('integrations');
  const [open, setOpen] = useState<number | undefined>(1);

  const onFinishFirstStep = (): void => {
    setOpen(2);
  };

  return (
    <Flex width="100%" height="100%" flexDirection="column">
      <GreetingUser />

      {organization.showFirstSteps && grantIntegrations === 'hide' && (
        <NotMember contactEmail={organization.contactEmail} />
      )}

      {grantIntegrations !== 'hide' && organization.showFirstSteps && (
        <Accordion>
          <AccordionItem
            name="Integrar loja"
            isFilled={organization.integration?.status}
            open={open}
            handleClick={setOpen}
            index={1}
            rightComponent={<IntegrateBadge updatedAt={organization.integration?.updatedAt} />}
          >
            <IntegrateStore
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              refetch={() => {}}
              onFinishFirstStep={onFinishFirstStep}
              status={organization.integration?.status}
            />
          </AccordionItem>

          <AccordionItem
            name="Convidar membros"
            isFilled={organization.hasMember ?? undefined}
            open={open}
            handleClick={setOpen}
            index={2}
          >
            <InviteMembers refetch={onRefetchOrganization} />
          </AccordionItem>
        </Accordion>
      )}
    </Flex>
  );
};

export default AdminGamification;
