import * as Yup from 'yup';
import { AppI, EAppView } from './index';

export enum EAppFormStatus {
  Initial,
  Submitted,
}

export type HublyStoreContextType = {
  appList: AppI[];
  setAppList: (appList: AppI[]) => void;
  app?: AppI;
  setApp: (app: AppI) => void;
  appView: EAppView;
  setAppView: (view: EAppView) => void;
  installedApps: IInstalledApps[];
  setInstalledApps: (apps: IInstalledApps[]) => void;
  isSaving?: boolean;
  setIsSaving: (isSaving?: boolean) => void;
  validationSchema: Yup.ObjectSchema<object>;
  setValidationSchema: (schema: Yup.ObjectSchema<object>) => void;
  resetContext: () => void;
  showWidthdrawRequestModal: boolean;
  setShowWidthdrawRequestModal: (is?: boolean) => void;
};

export const dummyContext: HublyStoreContextType = {
  appList: [],
  setAppList: () => false,
  appView: null,
  setAppView: () => false,
  installedApps: [],
  setInstalledApps: () => false,
  setIsSaving: () => false,
  validationSchema: null,
  setValidationSchema: () => false,
  setApp: () => false,
  resetContext: () => false,
  showWidthdrawRequestModal: null,
  setShowWidthdrawRequestModal: () => false,
};
