import React, { useState } from 'react';

import {
  DateInput,
  CustomCalendarSvgIcon,
  DaysSelector,
  Day,
  DaySpaceForLeftBorder,
} from './style';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';

interface Props {
  placeholder?: string;
  disableUnreachableDays?: boolean;
  value: string | number;
  onChange: any;
  name?: string;
  width?: string;
}

const DayPicker = ({
  disableUnreachableDays,
  placeholder,
  value,
  onChange,
  name,
  width,
}: Props): JSX.Element => {
  const [showCalendar, setShowCalendar] = useState(false);
  const daysToShow = disableUnreachableDays ? 28 : 31;
  const { t } = useTranslation();

  //Used timeout to have time to set new value and only after close calendar
  const onBlur = (): void => {
    setTimeout((): void => {
      setShowCalendar(false);
    }, 300);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <CustomCalendarSvgIcon />
      <DateInput
        placeholder={placeholder ?? 'Escolha uma data'}
        value={value ? `${t('hubly-dashboard.commons.day')} ${value}` : ''}
        onChange={(): void => {}}
        onFocus={(): void => setShowCalendar(true)}
        onBlur={onBlur}
        name={name}
        style={{
          width: width ?? 'auto',
        }}
      />
      <DaysSelector className="calendar-day-selector" show={showCalendar}>
        {[...Array(daysToShow)].map((_, i) => {
          const currDay = i + 1;
          return (
            <Day
              key={i + 1}
              className={value === currDay ? 'selected' : ''}
              onClick={(): void => onChange(name, currDay)}
            >
              {i + 1}
            </Day>
          );
        })}
        <DaySpaceForLeftBorder />
      </DaysSelector>
    </Box>
  );
};

export default DayPicker;
