import { GRAPHQL_ENDPOINT } from '@plug/config/env';

export default () => {
  const link = document.createElement('a');

  const download = (url: string, fileName?: string): void => {
    link.href = `${GRAPHQL_ENDPOINT.replace(
      '/graphql',
      '',
    )}/download?url=${url}&filename=${fileName}`;
    link.click();
  };

  return { download };
};
