import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { Icon, Select, TextField } from '@monitfy/ds';
import { useDebounce } from 'use-debounce';
import { useCheckoutContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutContext';
import { useQuery } from '@apollo/client';
import {
  CATEGORIES,
  CategoriesReponse,
} from '@plug/domains/organization/domains/affiliate/graphql/queries/checkout/categories';
import Skeleton from 'react-loading-skeleton';

const CheckoutCatalogFilters = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebounce(searchValue, 800);
  const {
    products: { setSearch, setCategory },
  } = useCheckoutContext();

  const { data, loading } = useQuery<CategoriesReponse>(CATEGORIES);
  const categories = data?.categories ?? [];

  useEffect(() => {
    setSearch(debounced);
  }, [debounced, setSearch]);

  return (
    <Flex
      paddingX="24px"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      marginTop="24px"
      style={{ gap: '24px' }}
    >
      <TextField
        value={searchValue}
        resizing="fluid"
        onChange={e => setSearchValue(e.currentTarget.value)}
      />

      {categories.length > 0 && !loading ? (
        <Select.Root onValueChange={item => setCategory(item)}>
          <Select.Trigger>
            <Select.Value placeholder="Todos os produtos" />
            <Select.Icon>
              <Icon name="chevron-down" />
            </Select.Icon>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content>
              <Select.Viewport>
                <Select.Group>
                  {categories
                    .filter(item => item.name)
                    .map(({ id, name }) => (
                      <Select.Item key={id} value={id}>
                        {name}
                      </Select.Item>
                    ))}
                </Select.Group>
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      ) : (
        <Skeleton width={203} height={56} />
      )}
    </Flex>
  );
};

export default CheckoutCatalogFilters;
