import { Typography } from '@gohubly/design-system';
import React from 'react';
import { Flex } from 'rebass';
import * as css from './cart-header.styles';
import { Icon } from '@monitfy/ds';

const CartHeader = () => {
  return (
    <Flex paddingX="24px" paddingBottom="16px" alignItems="center" style={{ gap: '20px' }}>
      <css.IconContainer>
        <Icon name="cart" />
      </css.IconContainer>

      <Typography color="textNeutralDefault" size="24" fontWeight={400} lineHeight="32">
        Carrinho
      </Typography>
    </Flex>
  );
};

export default CartHeader;
