import styled from 'styled-components';
import {
  color,
  fontWeight,
  ColorProps,
  FontWeightProps,
  fontSize,
  FontSizeProps,
} from 'styled-system';
import { inter } from '../fonts';
import { ComponentProps } from 'react';

const Link = styled.a<ComponentProps<'a'> & ColorProps & FontWeightProps & FontSizeProps>`
  text-decoration: none;
  color: ${props => props.theme.primary || '#3b24a8'};
  ${inter('s14')}
  ${color}
  ${fontWeight}
  ${fontSize}

  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export default Link;
