import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const AffiliateMultilevelBannerIcon: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon
      fill={theme.primary || '#3B24A8'}
      viewBox="0 0 336 168"
      width={336}
      height={168}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="336"
        height="168"
        viewBox="0 0 336 168"
        fill="none"
      >
        <rect x="168" width="168" height="168" rx="84" fill="white" />
        <path
          d="M336 84L333.329 84.5231C308.922 89.3041 296.718 91.6945 286.917 97.6849C278.256 102.978 270.978 110.256 265.685 118.917C259.695 128.718 257.304 140.922 252.523 165.329L252 168L251.477 165.329C246.696 140.922 244.305 128.718 238.315 118.917C233.022 110.256 225.744 102.978 217.083 97.6849C207.282 91.6945 195.078 89.3041 170.671 84.5231L168 84L170.671 83.4769C195.078 78.6959 207.282 76.3054 217.083 70.3151C225.744 65.0216 233.022 57.744 238.315 49.0832C244.305 39.2821 246.696 27.0783 251.477 2.67055L252 -3.67176e-06L252.523 2.67055C257.304 27.0783 259.695 39.2821 265.685 49.0832C270.978 57.7441 278.256 65.0216 286.917 70.3151C296.718 76.3054 308.922 78.6959 333.329 83.4769L336 84Z"
          fill="#1E1A34"
        />
        <path
          d="M168 84L165.329 84.5231C140.922 89.3041 128.718 91.6945 118.917 97.6849C110.256 102.978 102.978 110.256 97.6849 118.917C91.6945 128.718 89.3041 140.922 84.5231 165.329L84 168L83.4769 165.329C78.6959 140.922 76.3054 128.718 70.3151 118.917C65.0216 110.256 57.744 102.978 49.0832 97.6849C39.2821 91.6945 27.0783 89.3041 2.67054 84.5231L-3.67176e-06 84L2.67056 83.4769C27.0783 78.6959 39.2821 76.3054 49.0832 70.3151C57.744 65.0216 65.0216 57.744 70.3151 49.0832C76.3054 39.2821 78.6959 27.0783 83.4769 2.67055L84 -3.67176e-06L84.5231 2.67055C89.3041 27.0783 91.6945 39.2821 97.6849 49.0832C102.978 57.7441 110.256 65.0216 118.917 70.3151C128.718 76.3054 140.922 78.6959 165.329 83.4769L168 84Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default AffiliateMultilevelBannerIcon;
