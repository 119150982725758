export default (cpf: string): string => {
  const onlyNumbers = cpf
    .replace(/\D/g, '')
    .split(' ')
    .join('');

  const initial = onlyNumbers.slice(0, 3);
  const middle = onlyNumbers.slice(3, 6);
  const final = onlyNumbers.slice(6, 9);
  const digit = onlyNumbers.slice(9, 11);

  const hasLength = (length: number): boolean => onlyNumbers.length >= length;

  const formatedCpf = `${initial}${hasLength(4) ? '.' : ''}${middle}${
    hasLength(7) ? '.' : ''
  }${final}${hasLength(10) ? '-' : ''}${digit}`;

  return formatedCpf;
};
