import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { Link } from 'react-router-dom';
import Spinner from 'react-svg-spinner';

import theme from '../theme';
import { inter } from '../fonts';
import {
  background,
  BackgroundProps,
  boxShadow,
  BoxShadowProps,
  width,
  WidthProps,
  borderColor,
  BorderColorProps,
  color,
  ColorProps,
  fontWeight,
  FontWeightProps,
  space,
  SpaceProps,
  fontSize,
  FontSizeProps,
} from 'styled-system';
import shadeColor from '@plug/helpers/shade-color';
import { ThemeStore } from '@plug/redux/store';
import getCustomizatedColor from '@plug/helpers/get-customizated-color';

type ButtonBaseProps = {
  variant?: VariantType;
  size?: SizeType;
  as?: React.ElementType;
  loading?: boolean;
} & BackgroundProps &
  BoxShadowProps &
  BorderColorProps &
  ColorProps &
  WidthProps &
  FontWeightProps &
  FontSizeProps &
  SpaceProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

type VariantType = 'outlined' | 'fill' | 'fillRed' | 'text' | 'textSmall' | 'gradient';

const variant = (primaryString: string): any => {
  const lighten = shadeColor(getCustomizatedColor('buttons', primaryString), 30);
  const darken = shadeColor(getCustomizatedColor('buttons', primaryString), -30);
  const primary = getCustomizatedColor('buttons', primaryString);

  return {
    fillRed: `
      background: #E4000C;
      color: #fff;
    `,
    fill: `
      background: linear-gradient(232.66deg, ${lighten} -2.42%, ${primary} 79.51%);
      &:hover {
        background: linear-gradient(212.55deg, ${lighten} -2.42%, ${darken} 79.51%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(21, 0, 122, 0.2);
      }
      &:active {
        background: linear-gradient(267.81deg, ${lighten} 0%, ${lighten ||
      '#1A9BC4'} 20.31%, ${darken} 100%);
      }
      &:focus {
        background: gradient(268.12deg, ${lighten || '#12C5CB'} 0%, ${primary ||
      '#1A9BC4'} 41.91%, ${darken || '#3A2EBF'} 125.72%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(21, 0, 122, 0.2);
      }
      &:disabled {
        cursor: default;
        box-shadow: none;
        background: linear-gradient(180deg, #FBFCFD 0%, #F4F6FA 100%);
        color: #AAA8B3;
      }
    `,
    gradient: `
      background: linear-gradient(266.24deg, ${lighten || '#12C5CB'} 0%, ${lighten ||
      '#1A9BC4'} 20.31%, ${primary || darken} 100%);
      &:hover {
        background: linear-gradient(212.55deg, ${lighten} -2.42%, ${darken} 79.51%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(21, 0, 122, 0.2);
      }
      &:active {
        background: linear-gradient(267.81deg, ${lighten || '#12C5CB'} 0%, ${primary ||
      '#1A9BC4'} 20.31%, ${darken} 100%);
      }
      &:focus {
        background: gradient(268.12deg, ${lighten || '#12C5CB'} 0%, ${primary ||
      '#1A9BC4'} 41.91%, #3A2EBF 125.72%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(21, 0, 122, 0.2);
      }
      &:disabled {
        box-shadow: none;
        background: linear-gradient(180deg, #FBFCFD 0%, #F4F6FA 100%);
        color: #AAA8B3;
      }
    `,
    outlined: `
      color: ${darken};
      background: #FFFFFF;
      border: 1px solid #D4D3D8;
      transition: all 0.1s linear;
      &:hover {
        transition: all 0.1s linear;
        color: ${primary};
        border: 1px solid ${primary};
      }
      &:active {
        color: #fff;
        background: ${primary};
      }
      &:focus {
        color: #fff;
        background: ${primary || '#6C5BBE'};
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(57, 34, 163, 0.2);
        border: 1px solid ${darken};
      }
      &:disabled {
        box-shadow: none;
        background: #FFFFFF;
        border: 1px solid #D4D3D8;
        color: #AAA8B3;
      }
    `,
    text: `
      color: ${primary};
      background: transparent;
      &:hover {
        background: ${darken || 'rgba(71, 46, 189, 0.2)'};
      }
      &:active {
        background: ${darken || 'rgba(12, 7, 111, 0.25)'};
      }
      &:focus {
        background: ${darken || 'rgba(12, 7, 111, 0.35)'};
        box-shadow: 0 0 0 3px #E5E5E5;
      }
      &:disabled {
        cursor: default;
        background: #F5F5F7;
        color: #AAA8B3;
      }
    `,
    textSmall: `
      color: ${primary};
      font-size: 14px;
      background: transparent;
      &:hover {
        background: ${darken || 'rgba(71, 46, 189, 0.2)'};
      }
      &:active {
        background: ${darken || 'rgba(12, 7, 111, 0.25)'};
      }
      &:focus {
        background: ${darken || 'rgba(12, 7, 111, 0.35)'};
        box-shadow: 0 0 0 3px #E5E5E5;
      }
      &:disabled {
        background: #F5F5F7;
        color: #AAA8B3;
      }
  `,
  };
};

type SizeType = 'small' | 'medium' | 'large' | 'deprecated' | 'mobile';

const size = {
  small: `
    padding: 4px 15px;
    font-size: 12px;
    font-weight: bold;
  `,
  medium: `
    padding: 9px 24px;
    font-size: 14px;
    font-weight: bold;
  `,
  large: `
    padding: 12px 32px;
    font-size: 16px;
    font-weight: bold;
  `,
  deprecated: `
    padding: 16px 32px;
  `,
  mobile: `
    padding: 8px 26px;
  `,
};

export const ButtonBase = styled.button<ButtonBaseProps>`
  outline: 0;
  color: #ffffff;
  text-decoration: none;
  background-color: ${getCustomizatedColor('buttons', theme.colors.primary)};
  padding: 0;
  margin: 0;
  cursor: pointer;
  ${inter('s18')}
  font-weight: bold;
  border: 0;
  border-radius: 8px;

  ${(props): string => variant(props.theme.primary)[props.variant as VariantType]}
  ${(props): string => size[props.size as SizeType]}
  ${boxShadow}
  ${width}
  ${borderColor}
  ${fontWeight}
  ${background}
  ${color}
  ${space}
  ${fontSize}
`;

type ButtonProps = {
  variant?: VariantType;
  size?: SizeType;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children: string | React.ReactNode;
  to?: string;
  loading?: boolean | undefined;
} & ButtonBaseProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      startIcon,
      endIcon,
      onClick,
      variant = 'fill',
      type = 'button',
      size = 'deprecated',
      loading,
      children,
      to,
      disabled,
      ...rest
    },

    ref,
  ) => {
    if (to) {
      return (
        <ButtonBase
          lineheight="24px"
          as={Link}
          onClick={onClick}
          loading={loading}
          to={to}
          ref={ref}
          size={size}
          type={type}
          variant={variant}
          {...rest}
        >
          {children}
        </ButtonBase>
      );
    }

    const theme = ThemeStore.useState(s => s);

    const darken = shadeColor(getCustomizatedColor('buttons', theme.primary), -30);

    return (
      <ButtonBase
        lineHeight="24px"
        onClick={loading ? undefined : onClick}
        loading={loading}
        ref={ref}
        size={size}
        type={type}
        variant={variant}
        disabled={disabled}
        {...rest}
      >
        <Flex alignItems="center" justifyContent="center" style={{ position: 'relative' }}>
          {loading ? (
            <span style={{ lineHeight: '2' }}>
              <Spinner
                color={['fill', 'gradient'].includes(variant as string) ? '#fff' : darken}
                speed="fast"
                size="22px"
              />
            </span>
          ) : (
            <>
              {startIcon && <span style={{ paddingRight: '16px' }}>{startIcon}</span>}
              <span style={{ lineHeight: '1.95' }}>{children}</span>
              {endIcon && <span style={{ paddingLeft: '6px' }}>{endIcon}</span>}
            </>
          )}
        </Flex>
      </ButtonBase>
    );
  },
);

Button.displayName = 'Button';

export default Button;
