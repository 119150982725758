import { css } from 'styled-components';

const fontFamily = {
  inter: {
    regular: "'Inter', sans-serif",
  },
};

const fontSizes = {
  s10: {
    size: '10px',
  },
  s12: {
    size: '12px',
  },
  s14: {
    size: '14px',
  },
  s16: {
    size: '16px',
  },
  s18: {
    size: '18px',
  },
  s20: {
    size: '20px',
  },
  s24: {
    size: '24px',
  },
  s32: {
    size: '32px',
  },
  s40: {
    size: '40px',
  },
  s64: {
    size: '64px',
  },
};

export const inter = (size: keyof typeof fontSizes) => {
  return css`
    font-family: ${fontFamily.inter.regular};
    -webkit-font-smoothing: antialiased;
    font-size: ${fontSizes[size].size};
  `;
};
