import React from 'react';
import moment from 'moment';
import { Flex, Text } from 'rebass';
import { Spacer } from '@plug/ui/index';
import { Plug } from '@plug/ui/icons';
import { ThemeStore } from '@plug/redux/store';

type Props = {
  updatedAt: Date;
};

const IntegrateBadge: React.FC<Props> = ({ updatedAt }): JSX.Element => {

  const theme = ThemeStore.useState(s => s);
  
  return (
    <Flex flexDirection="row">
      <Flex
        justifyContent="center"
        alignItems="center"
        width="48px"
        height="48px"
        bg="#E8F9F5"
        sx={{ borderRadius: '50%' }}
      >
        <Plug fill="#18C698" width={18} height={32} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" pl="16px" pr="32px">
        <Spacer pb="2px">
          <Text
            fontFamily="Inter, sans-serif"
            fontSize="18px"
            fontWeight="600"
            color="#1C1637"
            lineHeight="24px"
          >
            Conectado
          </Text>
        </Spacer>
        <Text
          fontFamily="Inter, sans-serif"
          fontSize="12px"
          fontWeight="500"
          lineHeight="20px"
          color="#666372"
        >
          Ultima atualização em{' '}
          <span style={{ color: theme.primary || '#3B24A8' }}>{moment(updatedAt).format('DD/MM/YYY')}</span> às{' '}
          <span style={{ color: theme.primary || '#3B24A8' }}>
            {moment(updatedAt)
              .locale('pt-br')
              .format('hh:mm')}
          </span>
        </Text>
      </Flex>
    </Flex>
  );
};

export default IntegrateBadge;
