import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { Text, Checkbox, Menu } from '../';

const MenuItem = styled.div`
  display: flex;
  height: 40px;
  padding: 8px 20px;
  margin-bottom: 8px;
  color: #666372;
  cursor: pointer;
  align-items: center;
  svg {
    fill: #c4c4c4;
  }
  &:hover {
    background: #f4f6fa;
    color: ${props => getCustomizatedColor('buttons', props.theme.primary) || '#3b24a8'};

    svg {
      fill: ${props => getCustomizatedColor('icons', props.theme.primary) || '#3b24a8'};
    }
  }
`;

const SelectCellMobile = ({
  row,
  setOpenDrawerBottom,
  setDrawerContent,
  actions,
  SelectComponent,
}: any) => (
  <Box
    pb="16px"
    sx={{
      display: 'none',
      '@media screen and (max-width: 968px)': {
        display: 'flex',
      },
    }}
  >
    {SelectComponent && !row?.original?.isPaid && (
      <Checkbox checked={row.isSelected} onClick={() => row.toggleRowSelected(!row.isSelected)} />
    )}
    <Box
      sx={{
        display: 'none',
        '@media screen and (max-width: 968px)': {
          flex: 1,
          justifyContent: 'flex-end',
          display: 'flex',
        },
      }}
    >
      <Menu
        onClick={() => {
          setOpenDrawerBottom(true);
          setDrawerContent(
            actions({ row }).map(({ text, iconCard, onClick }: any, i: number) => {
              return (
                <MenuItem
                  key={i}
                  onClick={() => {
                    setOpenDrawerBottom(false);
                    onClick && onClick();
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    pr="16px"
                  >
                    {iconCard}
                  </Flex>
                  <Text fontSize="14px">{text}</Text>
                </MenuItem>
              );
            }),
          );
        }}
      />
    </Box>
  </Box>
);

export default SelectCellMobile;
