import { useCheckoutDetailedProductContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutDetailedProductContext';
import React, { useState } from 'react';
import { Tabs } from '@monitfy/ds';
import { Box, Flex } from 'rebass';
import { Typography } from '@gohubly/design-system';

const Specifications = () => {
  const { product } = useCheckoutDetailedProductContext();
  const [index, setIndex] = useState(0);

  const selectedGroup = product.specifications?.[index];

  return (
    <Flex flexDirection="column" style={{ gap: '32px' }}>
      <Tabs
        activeTab={index}
        onChange={setIndex}
        items={product.specifications.map(item => ({ text: item.key }))}
        fluid
      />

      {selectedGroup && (
        <Flex flexDirection="column" style={{ gap: '16px' }}>
          <Typography color="textNeutralSubtle" size="24" lineHeight="32">
            {selectedGroup?.key}
          </Typography>

          <Flex flexDirection="column" style={{ gap: '2px' }}>
            {selectedGroup.items.map(({ key, value }) => (
              <Typography
                key={value}
                fontWeight={400}
                size="16"
                color="textNeutralSubtle"
                lineHeight="24"
              >
                <div style={{ whiteSpace: 'pre-line' }}>{`${key}${value ? `: ${value}` : ''}`}</div>
              </Typography>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Specifications;
