import React from 'react';
import * as css from './whatsapp-button.styles';
import { MADESA_HOST_NAME } from '@plug/config/consts';

const WhatsAppButton = () => {
  const allowed = [...MADESA_HOST_NAME, 'influencer.anapaulacarvalho.com'];

  if (!allowed.includes(window.location.host)) return <></>;

  const urls = {
    'afiliados.madesa.com':
      'https://api.whatsapp.com/send?l=pt_br&phone=5551991794303&botao=mensagem&text=Vim%20pela%20plataforma%20de%20afiliados%20e%20preciso%20de%20ajuda',
    'influencer.anapaulacarvalho.com': 'https://api.whatsapp.com/send?phone=558699057056',
  };

  return (
    <css.Container href={urls[window.location.hostname]} target="_blank">
      <css.Image
        width="58px"
        height="58px"
        src="https://arquivo.tiny.com.br/gohubbly/images/whatsapp.svg"
        alt="Botão WhatsApp"
      />
    </css.Container>
  );
};

export default WhatsAppButton;
