import React from 'react';
import { Flex, Image } from 'rebass';
import getInitLetters from '@plug/helpers/get-initial-letters';
import isDesktop from '@plug/helpers/is-desktop';

import ThemeStore from '@redux/stores/theme';
import { InitialValue as InitialThemeValues } from '@redux/stores/theme';

const AvatarBase: React.FC<{
  image?: string;
  icon?: React.ReactNode;
  border?: boolean;
  name: string;
  rounded?: boolean;
  outline?: boolean;
  active?: boolean;
  style?: any;
  objectFit?: string;
}> = props => {
  const theme = ThemeStore.useState(s => s);

  const usingDefaultTheme: boolean =
    theme.logo === InitialThemeValues.logo &&
    theme.primary === InitialThemeValues.primary &&
    theme.secondary === InitialThemeValues.secondary &&
    theme.tertiary === InitialThemeValues.tertiary;

  return !props.image ? (
    <Flex
      sx={{
        overflow: 'hidden',
        backgroundPosition: 'center',
        width: isDesktop ? (props.rounded ? '40px' : '248px') : '100%',
        height: props.rounded ? '40px' : '160px',
        verticalAlign: 'middle',
        fontFamily: 'Inter',
        objectPosition: 'center',
        objectFit: 'cover',
        borderRadius: props.rounded ? '50%' : '8px',
        fontSize: props.rounded ? '16px' : '64px',
        boxShadow:
          props.active && props.border
            ? `
                inset 0 0 0 2px #12C5CB,
                inset 0 0 0 4px ${usingDefaultTheme ? '#12C5CB' : theme.primary}
            `
            : 'none',
        border: props.border ? '1px solid #6C5BBE' : 'none',
        fontWeight: 'bold',
        background: props.outline ? 'transparent' : '#EAECF6',
        color: usingDefaultTheme ? '#12C5CB' : theme.primary,
      }}
      padding="10px"
      alignItems="center"
      justifyContent="center"
      style={props.style}
    >
      {props.icon || getInitLetters(props.name)}
    </Flex>
  ) : (
    <Image
      src={props.image}
      sx={{
        overflow: 'hidden',
        backgroundPosition: 'center',
        width: props.rounded ? '40px' : '248px',
        height: props.rounded ? '40px' : '160px',
        verticalAlign: 'middle',
        objectPosition: 'center',
        objectFit: props.objectFit ?? 'contain',
        borderRadius: props.rounded ? '50%' : '8px',
      }}
      style={props.style}
    />
  );
};

AvatarBase.defaultProps = {
  rounded: false,
  outline: false,
  active: false,
  border: false,
};

export default AvatarBase;
