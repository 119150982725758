import { Store } from 'pullstate';

interface InitialValuesI {
  organizationName: string | undefined;
  organizationId: string | undefined;
  organizationSlug: string | undefined;
  organizationImage: string | undefined;
  role: string | undefined;
  freeTrial: string | undefined;
}

export const InitialValue: InitialValuesI = {
  organizationName: undefined,
  organizationId: undefined,
  organizationSlug: undefined,
  organizationImage: undefined,
  role: undefined,
  freeTrial: undefined,
};

export default new Store(InitialValue);
