import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import successIcon from './success.svg';
import errorIcon from './error.svg';
import infoIcon from './info.svg';
import warningIcon from './warning.svg';

/**
 * This orrendous z-ndex values are needed beacause Intercom
 * sets it's widget z-index to a value of 2147483000 and we want to render
 * our toast messages on top of everything, we've had to do the same to
 * the side-view component.
 *
 */
const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container {
    min-width: 480px;
    z-index: 999999999992147483002;

    @media screen and (max-width: 767px) {
      min-width: auto;
    }
  }
  .Toastify__toast {
    border-radius: 4px;
    padding: 20px;
    padding-left: 25px;
    align-items: center;

    &::before {
      content: '';
      display: block;

      background-position: center;
      background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px) {
      padding: 14px;
      padding-left: 16px;
    }
  }
  .Toastify__close-button {
    position: absolute;
    margin-left: 20px;
    right: 10px;
    top: 10px;
    svg {
      fill: #616473;
      height: 18px;
      width: 18px;
    }
  }
  .Toastify__toast--info {
    background: white;
    background: #f0f1ff;

    &::before {
      min-width: 38px;
      height: 30px;
      transform: scale(0.8);
      background-image: url(${infoIcon});
    }
  }
  .Toastify__toast--error {
    background: white;
    border-left: 3px solid #eb0045;
    color: red;

    &::before {
      width: 16px;
      height: 16px;
      background-image: url(${errorIcon});
    }
  }
  .Toastify__toast--warning {
    background: white;
    border-left: 3px solid #ffb119;
    // background: #fbf2e0;

    &::before {
      width: 16px;
      height: 16px;

      background-image: url(${warningIcon});
    }
  }
  .Toastify__toast--success {
    background: white;
    border-left: 3px solid #167e32;

    &::before {
      width: 16px;
      height: 16px;

      background-image: url(${successIcon});
    }
  }
  .Toastify__toast-body {
    flex: 1;
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #18191e;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    align-items: flex-start;
  }
  .Toastify__progress-bar {
  }

  @media screen and (max-width: 768px) {
    margin: 8px;
    width: calc(100% - 16px) !important;
  }
`;

const Snackbar: React.FC = () => (
  <StyledToastContainer
    position="top-center"
    autoClose={4000}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    icon={false}
    hideProgressBar
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export default Snackbar;
