import React from 'react';
import { NavSidebarContent, NavSidebarFlexContainer } from './style';
import NavSidebarMenuItemList from '../nav-sidebar-menu-item-list';
import NavSidebarOrganizationList from '../nav-sidebar-organization-list';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import NavSidebarBannerSection from '../nav-sidebar-banner-section';
import { useAuthContext } from '@plug/contexts/auth-context';
import isDesktop from '@plug/helpers/is-desktop';
import useIsOrganizationAdmin from '@plug/hooks/use-is-organization-admin';
import {
  ESPACO_SMART_HOST_NAME,
  HUNTER_FUN_HOST_NAME,
  IROBOT_HOST_NAME,
  KINGSPAN_HOST_NAME,
  LINDA_CASA_HOST_NAME,
  MARY_KAY_WHITELABEL,
  MARI_MARIA_MAKEUP_HOST_NAME,
  NUTRIBULLET_HOST_NAME,
  OLIST_HOST_NAME,
} from '@plug/config/consts';

const NavSidebar: React.FC = () => {
  const domainsToHideBanners = [
    ...ESPACO_SMART_HOST_NAME,
    ...LINDA_CASA_HOST_NAME,
    ...MARI_MARIA_MAKEUP_HOST_NAME,
    ...KINGSPAN_HOST_NAME,
    ...OLIST_HOST_NAME,
    ...MARY_KAY_WHITELABEL,
    ...NUTRIBULLET_HOST_NAME,
    ...IROBOT_HOST_NAME,
    ...HUNTER_FUN_HOST_NAME,
  ];

  const {
    setOpenedOrganizationListSection,
    openedOrganizationListSection,
  } = useNavigationMenuContext();

  const { currentOrganization } = useAuthContext();
  const isOrganizationAdmin = useIsOrganizationAdmin();

  const handleToggleOrganizationList = (): void => {
    setOpenedOrganizationListSection(!openedOrganizationListSection);
  };

  return (
    <NavSidebarFlexContainer>
      {/* <NavSidebarHeader onClick={handleToggleOrganizationList}>
        <Flex alignItems="center" style={{ gap: '12px' }}>
          <Avatar size="XS" name={currentOrganization?.name} />
          <Typography size="XXS" color={isDesktop ? 'white' : 'neutralLow'}>
            {currentOrganization?.name}
          </Typography>
        </Flex>
        <Icon
          iconId={openedOrganizationListSection ? 'chevronUp' : 'chevronDown'}
          stroke={isDesktop ? 'white' : 'neutralLow'}
          size='XS'
        />
      </NavSidebarHeader> */}

      <NavSidebarContent>
        <NavSidebarOrganizationList />
        <NavSidebarMenuItemList />
        {/* <Spacer mt="130px" /> */}
        {isDesktop &&
          isOrganizationAdmin &&
          !domainsToHideBanners.includes(window.location.hostname) && <NavSidebarBannerSection />}
      </NavSidebarContent>
    </NavSidebarFlexContainer>
  );
};

export default NavSidebar;
