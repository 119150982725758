import styled from 'styled-components';
import theme from '@plug/ui/theme';
import { inter } from '../fonts';
import {
  width,
  WidthProps,
  maxWidth,
  MaxHeightProps,
  justifyContent,
  JustifyContentProps,
  alignItems,
  AlignItemsProps,
  textAlign,
  TextAlignProps,
  space,
  SpaceProps,
  background,
  BackgroundProps,
  color,
  ColorProps,
} from 'styled-system';

export type CardProps = WidthProps &
  MaxHeightProps &
  JustifyContentProps &
  AlignItemsProps &
  TextAlignProps &
  SpaceProps &
  BackgroundProps &
  ColorProps &
  React.HTMLProps<HTMLInputElement>;

const Card = styled.div<CardProps>`
  ${inter('s12')}
  overflow: hidden;
  background: linear-gradient(180deg, #fbfcff 0%, #f9fafc 100%);
  border-radius: ${theme.borderRadius};
  display: flex;
  flex-direction: column;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'initial')}; ${width} ${maxWidth}
    ${justifyContent} ${alignItems} ${textAlign} ${space} ${background} ${color};
`;

export default Card;
