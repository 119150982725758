import checkUserServicePermission from '@plug/helpers/check-user-service-permission';
import checkUserOrganizationPermission from '@plug/helpers/check-user-organization-permission';

interface ReturnUseViewMode {
  grant: string;
  role: string;
}

function useViewMode(permission: string, service?: string): ReturnUseViewMode {
  let grant = '';
  let role = '';

  if (service) {
    const servicePermission = checkUserServicePermission(service, permission);

    if (servicePermission) {
      grant = servicePermission.grant;
      role = servicePermission.serviceRoleName;
    }
  } else {
    const organizationPermission = checkUserOrganizationPermission(permission);

    if (organizationPermission) {
      grant = organizationPermission.grant;
      role = organizationPermission.organizationRoleName;
    }
  }

  return { grant, role };
}

export default useViewMode;
