import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import { ExclamationTriangle } from '@plug/ui/icons';
import { ThemeStore } from '@plug/redux/store';

type Props = {
  contactEmail: string;
};

const NotMember: React.FC<Props> = ({ contactEmail }): JSX.Element => {

  const theme = ThemeStore.useState(s => s);
  
  return (
    <Box height="100%" width="100%" padding="2rem" backgroundColor="#F0F1F5">
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        padding="2rem"
        bg="#F8F9FC"
        sx={{
          borderRadius: '4px',
        }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="72px"
          height="72px"
          bg="#EBE9F6"
          m="0 auto"
          sx={{ borderRadius: 9999 }}
        >
          <ExclamationTriangle fill={theme.primary || "#3B24A8"} width={36} height={36} />
        </Flex>

        <Heading
          mb={16}
          mt={32}
          textAlign="center"
          fontFamily="Inter, sans-serif"
          fontSize="32px"
          fontWeight="bold"
          lineHeight="36px"
          color="#1C1637"
        >
          Voce não faz parte de nenhum serviço
        </Heading>

        <Text
          fontFamily="Inter, sans-serif"
          fontSize="14px"
          fontWeight="500"
          textAlign="center"
          color="#666372"
          lineHeight="20px"
        >
          entre em contato <strong>{contactEmail}</strong> para pedir liberação
        </Text>
      </Flex>
    </Box>
  );
};

export default NotMember;
