import { Flex } from 'rebass';
import styled from 'styled-components';

export const Container = styled(Flex)`
  padding: 96px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  background-color: white;
  border-radius: 16px;
  gap: 16px;
`;

export const Form = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
