import React from 'react';
import { Flex } from 'rebass';
import { Button, Typography, Icon } from '@gohubly/design-system';
import HeaderActionDropdownBase from '../header-action-dropdown-base';
import { HeaderActionDropdownBaseActionItem } from '../header-action-dropdown-base/style';
import { Spacer } from '@plug/ui';
import { useLogout } from '@plug/domains/auth/hooks/useLogout';
import { OrganizationStore } from '@plug/redux/store';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { DOMAIN, I18N_NAMESPACES, MARY_KAY_WHITELABEL } from '@plug/config/consts';

export const CustomButton = styled(Button)`
  font-size: 16px;
  padding: 12px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 12px;
  }
`;

const HeaderActionProfileConfirmLogout = ({
  handleBackToMainDropdown,
  handleCloseDropdown,
}: HeaderActionProfileConfirmLogoutI): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { t } = useTranslation();
  const organizationSlug = OrganizationStore.useState(s => s.organizationSlug) || '';
  const organizationName = OrganizationStore.useState(s => s.organizationName) || '';

  const [logout] = useLogout();

  const handleLogout = (): void => logout(organizationSlug);

  return (
    <HeaderActionDropdownBase>
      <HeaderActionDropdownBaseActionItem>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="14px"
          style={{ alignItems: 'start' }}
        >
          <Flex width={isMobile ? '90%' : '85%'}>
            <Typography color="neutralLow" size="XXS" lineHeight="LG" fontWeight={500}>
              {t('hubly-dashboard.header.logout-dropdown.title', {
                ns:
                  I18N_NAMESPACES[MARY_KAY_WHITELABEL.includes(DOMAIN) ? 'DOMAIN' : 'TRANSLATION'],
              })}
            </Typography>
          </Flex>

          <Flex>
            <Icon iconId="close" size="XS" strokeWidth={1.5} onClick={handleCloseDropdown} />
          </Flex>
        </Flex>
      </HeaderActionDropdownBaseActionItem>

      <CustomButton
        className="primary ds-button-primary"
        onClick={handleBackToMainDropdown}
        size="SM"
        fluid
      >
        {t('hubly-dashboard.header.logout-dropdown.cancel')}
      </CustomButton>

      <Spacer mt="10px" />

      <CustomButton
        className="ds-button-tonalFilled"
        hierarchy="tonalFilled"
        size="SM"
        onClick={handleLogout}
        fluid
      >
        {t('hubly-dashboard.header.logout-dropdown.confirm')}
      </CustomButton>
    </HeaderActionDropdownBase>
  );
};

export default HeaderActionProfileConfirmLogout;
