import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Anchor: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);

  return (
    <SvgIcon fill={theme.primary || "#3B24A8"} viewBox="0 0 10 14" width={10} height={14} {...props}>
      <path d="M8.90625 7.53125C9.1875 7.25 9.1875 6.78125 8.90625 6.5L2.84375 0.40625C2.53125 0.125 2.0625 0.125 1.78125 0.40625L1.0625 1.125C0.78125 1.40625 0.78125 1.875 1.0625 2.1875L5.875 7L1.0625 11.8438C0.78125 12.1562 0.78125 12.625 1.0625 12.9062L1.78125 13.625C2.0625 13.9062 2.53125 13.9062 2.84375 13.625L8.90625 7.53125Z" />
      <path
        d="M8.90625 7.53125C9.1875 7.25 9.1875 6.78125 8.90625 6.5L2.84375 0.40625C2.53125 0.125 2.0625 0.125 1.78125 0.40625L1.0625 1.125C0.78125 1.40625 0.78125 1.875 1.0625 2.1875L5.875 7L1.0625 11.8438C0.78125 12.1562 0.78125 12.625 1.0625 12.9062L1.78125 13.625C2.0625 13.9062 2.53125 13.9062 2.84375 13.625L8.90625 7.53125Z"
        fillOpacity="0.25"
      />
    </SvgIcon>
  );
}

export default Anchor;
