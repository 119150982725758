import gql from 'graphql-tag';

export const HAS_ORGANIZATION_APPROVED_USER = gql`
  query($organizationId: String!) {
    hasOrganizationApprovedUser(organizationId: $organizationId)
  }
`;

export type HasOrganizationApprovedUserVariables = {
  organizationId: string;
};

export type HasOrganizationApprovedUserResponse = {
  hasOrganizationApprovedUser?: boolean;
};
