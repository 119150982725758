import gql from 'graphql-tag';

export const GET_ORGANIZATION_CUSTOMIZATION = gql`
  query GetOrganizationCustomization($input: GetOrganizationCustomizationInput) {
    getOrganizationCustomization(input: $input) {
      header
      icons
      buttons
      banner
      graph
      highlightText
      spinner
      hideFooter
      leftSideOnboardingColor
      loginBackground
      textButton
    }
  }
`;

export type GetOrganizationCustomizationVariables = {
  input: {
    organizationId: string;
  };
};

export type GetOrganizationCustomizationResponse = {
  getOrganizationCustomization: OrganizationCustomization;
};
