import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Search: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 28 29'} width={28} height={29} {...props}>
      <path
        d="M14 0.9375C6.50781 0.9375 0.4375 7.0625 0.4375 14.5C0.4375 21.9922 6.50781 28.0625 14 28.0625C21.4375 28.0625 27.5625 21.9922 27.5625 14.5C27.5625 7.0625 21.4375 0.9375 14 0.9375ZM14 25.4375C7.92969 25.4375 3.0625 20.5703 3.0625 14.5C3.0625 8.48438 7.92969 3.5625 14 3.5625C20.0156 3.5625 24.9375 8.48438 24.9375 14.5C24.9375 20.5703 20.0156 25.4375 14 25.4375ZM19.8516 11.4922C19.8516 8.75781 16.9531 6.625 14.2734 6.625C11.7031 6.625 10.0625 7.71875 8.80469 9.63281C8.58594 9.90625 8.64062 10.2891 8.91406 10.5078L10.4453 11.6562C10.7188 11.875 11.1562 11.8203 11.375 11.5469C12.1953 10.5078 12.7969 9.90625 14.0547 9.90625C15.0391 9.90625 16.2422 10.5078 16.2422 11.4922C16.2422 12.2031 15.6406 12.5312 14.6562 13.0781C13.5625 13.7344 12.0859 14.5 12.0859 16.4688V16.9062C12.0859 17.2891 12.3594 17.5625 12.7422 17.5625H15.2031C15.5859 17.5625 15.8594 17.2891 15.8594 16.9062V16.5781C15.8594 15.2109 19.8516 15.1562 19.8516 11.4922ZM16.2969 20.625C16.2969 19.3672 15.2578 18.3281 14 18.3281C12.6875 18.3281 11.7031 19.3672 11.7031 20.625C11.7031 21.9375 12.6875 22.9219 14 22.9219C15.2578 22.9219 16.2969 21.9375 16.2969 20.625Z"
        fill={theme.primary || props.fill || '#3B24A8'}
      />
    </SvgIcon>
  );
}

export default Search;
