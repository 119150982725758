import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import styled from 'styled-components';

import { Calendar } from '../icons';
import { inter } from '../fonts';
import { useTranslation } from 'react-i18next';

interface WrapperProps {
  maxWidth?: string;
  height?: string;
  background?: string;
  borderColor?: string;
  borderColorHover?: string;
}

interface CommonProps {
  style?: WrapperProps;
  onChange?: any;
  onBlur?: any;
  minDate?: any;
  maxDate?: any;
  startDate?: any;
  endDate?: any;
  disabled?: boolean;
  placeholder?: {
    startDate: string;
    endDate: string;
  };
  numberOfMonths?: number;
}

const StyledWrapper = styled.div<WrapperProps>`
  .DateRangePicker.DateRangePicker_1 {
    width: 100%;
    max-width: ${({ maxWidth }): string => (maxWidth ? maxWidth : '380px')};
    z-index: 6;
  }

  .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
    width: 100%;
    display: flex;
    align-items: center;
    height: ${({ height }): string => (height ? height : 'auto')};
  }

  .DateRangePickerInput {
    overflow: hidden;
    background: ${({ background }): string => (background ? background : '#f4f6fa')};
    border: ${({ borderColor }): string => `1px solid ${borderColor ? borderColor : '#d4d3d8'}`};
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;

    &:hover {
      border-color: ${({ borderColorHover }): string =>
        borderColorHover ? borderColorHover : '#d4d3d8'};
    }
  }

  .DateInput_input {
    background: ${({ background }): string => (background ? background : '#f4f6fa')};
  }

  .DateInput {
    width: 100%;
    background: ${({ background }): string => (background ? background : '#f4f6fa')};
  }

  .DateRangePickerInput_arrow_svg.DateRangePickerInput_arrow_svg_1 {
    margin: 0 1rem;
  }

  .DateRangePickerInput__showClearDates {
    padding-right: 40px;
  }

  #DateInput_1_endDate {
    padding-top: 5px;
    font-weight: 600;
    ${inter('s12')};
    line-height: 16px;
    text-align: center;
    padding-bottom: 0;
    color: #1c1637;
  }

  #DateInput_1_startDate {
    font-weight: 600;
    padding-top: 5px;
    ${inter('s12')};
    padding-bottom: 0;
    line-height: 16px;
    text-align: center;
    color: #1c1637;
  }

  .DateRangePickerInput_calendarIcon {
    @media screen and (max-width: 768px) {
      margin: 0 0px 0 10px;
    }
  }

  .DateRangePickerInput_clearDates {
    &:hover {
      background: none;
    }
  }
`;

const DatePicker: React.FC<CommonProps> = ({
  style,
  onChange,
  onBlur,
  minDate,
  maxDate,
  startDate,
  endDate,
  disabled,
  placeholder,
  numberOfMonths
}) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <StyledWrapper {...style}>
      <DateRangePicker
        inputIconPosition="before"
        customInputIcon={<Calendar />}
        isOutsideRange={(day): boolean => {
          if (day.isBefore(moment().subtract(1, 'year'))) {
            return true;
          }
          return moment(day) <= moment().subtract(1, 'year');
        }}
        minDate={moment().subtract(1, 'year')}
        maxDate={maxDate}
        startDate={startDate ? startDate : dates.startDate}
        startDateId="DateInput_1_startDate"
        startDatePlaceholderText={
          placeholder?.startDate ??
          t('hubly-dashboard.commons.filters.form.date.start-date.placeholder')
        }
        endDatePlaceholderText={
          placeholder?.endDate ??
          t('hubly-dashboard.commons.filters.form.date.end-date.placeholder')
        }
        endDate={endDate ? endDate : dates.endDate}
        displayFormat="D MMM, YYYY"
        endDateId="DateInput_1_endDate"
        onClose={onBlur}
        onDatesChange={(newValues: any): void => {
          setDates({ ...dates, startDate: newValues.startDate, endDate: newValues.endDate });
          if (onChange) onChange(newValues);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any): void => setFocusedInput(focusedInput)}
        disabled={disabled}
        showClearDates={true}
        numberOfMonths={numberOfMonths}
      />
    </StyledWrapper>
  );
};

export default DatePicker;
