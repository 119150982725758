import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Users: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 64 64'} width={64} height={64} {...props}>
    <path
      d="M56.001 56V50C56.001 46.8174 54.7367 43.7652 52.4863 41.5147C50.2358 39.2643 47.1836 38 44.001 38H20.001C16.8184 38 13.7661 39.2643 11.5157 41.5147C9.26526 43.7652 8.00098 46.8174 8.00098 50V56"
      stroke={props.stroke || '#5424F6'}
      color="transparent"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.001 26C36.9715 26 41.001 21.9706 41.001 17C41.001 12.0294 36.9715 8 32.001 8C27.0304 8 23.001 12.0294 23.001 17C23.001 21.9706 27.0304 26 32.001 26Z"
      stroke={props.stroke || '#5424F6'}
      strokeWidth="4"
      color="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Users;
