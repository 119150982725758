import { Box, BoxProps, Flex, FlexProps } from 'rebass';
import styled, { css } from 'styled-components';

export type ImprovedProps = {
  borderRadius?: string;
  border?: string;
  borderColor?: string;
  pointerEvents?: string;
  cursor?: string;
};

const CommonStyles = css<ImprovedProps>`
  border: ${({ border = 'initial' }) => border};
  border-radius: ${({ borderRadius = 'initial' }) => borderRadius};

  ${({ borderColor }) => borderColor && `border-color: ${borderColor}`};
  ${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
`;

const ImprovedBox = styled(Box)<BoxProps & ImprovedProps>`
  ${CommonStyles};
`;

const ImprovedFlex = styled(Flex)<FlexProps & ImprovedProps & { gap?: string }>`
  ${CommonStyles};

  gap: ${({ gap = 'initial' }) => gap};
`;

export * from 'rebass';
export { ImprovedBox as Box, ImprovedFlex as Flex };
