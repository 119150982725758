import { useCallback } from 'react';
import { useHistory } from 'react-router';
import cookie from 'js-cookie';
import { useLazyQuery } from '@apollo/client';

import { resetUserCookies } from '../helpers/session';
import {
  MARY_KAY_WHITELABEL,
  TOKEN_LOCAL_STORAGE_KEY,
  USER_LOCAL_STORAGE_KEY,
} from '@plug/config/consts';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { UserStore, WhiteLabelStore } from '@plug/redux/store';
import useSetOrganization from '@plug/hooks/use-set-organization';
import {
  AFFILIATE_STORE,
  AffiliateStoreResponse,
} from '@plug/domains/organization/domains/affiliate/graphql/queries/affiliate-store';
import {
  GET_USER_PENDENCIES,
  GetUserPendenciesPayload,
} from '@plug/domains/organization/graphql/queries/get-user-pendencies';
import {
  HAS_ORGANIZATION_APPROVED_USER,
  HasOrganizationApprovedUserResponse,
  HasOrganizationApprovedUserVariables,
} from '@plug/graphql/queries/has-organization-approved-user';
import {
  GET_USER_ACTIVE_STATUS,
  GetUserActiveStatusResponse,
  GetUserActiveStatusVariables,
} from '../graphql/queries/get-user-active-status';

interface ConfigI {
  id: string;
  token: string;
  avatar?: string;
  email: string;
  username: string;
  cookieDays?: number;
  redirect?: boolean;
  redirectTo?: string;
  loginAnimation?: boolean;
}

export const useLogin = () => {
  const history = useHistory();
  const { resetContext } = useNavigationMenuContext();
  const { isWhiteLabel, organizationId } = WhiteLabelStore.useState(s => s);
  const { loading, setCurrentOrganization } = useSetOrganization();
  const [loadUserPendencies, { loading: loadingPendencies }] = useLazyQuery<
    GetUserPendenciesPayload
  >(GET_USER_PENDENCIES, {
    nextFetchPolicy: 'no-cache',
  });
  const [loadAffiliateStore, { loading: loadingAffiliateStore }] = useLazyQuery<
    AffiliateStoreResponse
  >(AFFILIATE_STORE);

  const [loadApprovedInfo, { loading: loadingApprovedInfo }] = useLazyQuery<
    HasOrganizationApprovedUserResponse,
    HasOrganizationApprovedUserVariables
  >(HAS_ORGANIZATION_APPROVED_USER);

  const [getUserActiveStatus, { loading: userActiveStatusLoading }] = useLazyQuery<
    GetUserActiveStatusResponse,
    GetUserActiveStatusVariables
  >(GET_USER_ACTIVE_STATUS);

  const handleDashboardRedirect = useCallback(
    async (userId: string) => {
      if (isWhiteLabel && organizationId) {
        const { data: userActiveStatus } = await getUserActiveStatus({
          variables: {
            input: {
              organizationId,
              userId,
            },
          },
        });

        if (!userActiveStatus.getUserActiveStatus) {
          return history.push('/dashboard');
        }

        if (MARY_KAY_WHITELABEL.includes(window.location.hostname)) {
          const { data: pendencyData } = await loadUserPendencies();
          const braspagData = pendencyData?.getUserPendencies?.find(
            item => item.pendency === 'Braspag',
          );

          if (braspagData) {
            const status: any = braspagData?.metadata?.find(item => item.key === 'status')?.value;
            const parsedStatus = status?.replace(/[^\d.-]+/g, '');

            if (!status || parsedStatus !== '2') return history.push('/dashboard');
          }
        }

        const { data } = MARY_KAY_WHITELABEL.includes(window.location.hostname)
          ? await loadAffiliateStore()
          : { data: null };

        const { data: approvedData } = MARY_KAY_WHITELABEL.includes(window.location.hostname)
          ? { data: { hasOrganizationApprovedUser: true } }
          : await loadApprovedInfo({
              variables: {
                organizationId,
              },
            });

        if (!approvedData?.hasOrganizationApprovedUser) return history.push('/dashboard');

        setCurrentOrganization(
          { id: organizationId },
          isWhiteLabel,
          !!organizationId,
          !data?.affiliateStore?.id,
        );
      }
    },
    [
      history,
      isWhiteLabel,
      loadAffiliateStore,
      loadApprovedInfo,
      loadUserPendencies,
      organizationId,
      setCurrentOrganization,
    ],
  );

  const login = useCallback(
    (config: ConfigI) => {
      resetUserCookies();
      resetContext();

      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * (config.cookieDays || 15));

      const cookieCommonSettings = {
        domain:
          window.location.hostname === 'localhost'
            ? window.location.hostname
            : `.${window.location.hostname}`,
        expires,
      };

      cookie.set(TOKEN_LOCAL_STORAGE_KEY, config.token, cookieCommonSettings);

      const userCookieValues = JSON.stringify({
        id: config.id,
        token: config.token,
        email: config.email,
        username: config.username,
        avatar: config.avatar,
      });

      cookie.set(USER_LOCAL_STORAGE_KEY, userCookieValues, cookieCommonSettings);

      UserStore.update(s => {
        s.username = config.username;
        s.email = config.email;
        s.avatar = config.avatar;
      });

      if (config.redirect)
        return history.push(config.redirectTo ?? '/dashboard', {
          loginAnimation: config.loginAnimation,
        });

      if (isWhiteLabel) {
        handleDashboardRedirect(config.id);
      } else {
        return history.push('/dashboard');
      }
    },
    [handleDashboardRedirect, history, isWhiteLabel, resetContext],
  );

  return {
    login,
    loading:
      loading ||
      loadingAffiliateStore ||
      loadingApprovedInfo ||
      loadingPendencies ||
      userActiveStatusLoading,
  };
};
