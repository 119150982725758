import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const UploadImage: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon
      viewBox={'0 0 53 53'}
      width={53}
      height={53}
      fill={props.fill ? props.fill : '#BFBEC5'}
      {...props}
    >
      <circle cx="26.5" cy="26.5" r="26.5" fill="#EBE9F6" />
      <path
        d="M37.75 16.5H18.25C16.9844 16.5 16 17.5312 16 18.75V19.5H15.25C13.9844 19.5 13 20.5312 13 21.75V35.25C13 36.5156 13.9844 37.5 15.25 37.5H34.75C35.9688 37.5 37 36.5156 37 35.25V34.5H37.75C38.9688 34.5 40 33.5156 40 32.25V18.75C40 17.5312 38.9688 16.5 37.75 16.5ZM35.5 35.25C35.5 35.6719 35.125 36 34.75 36H15.25C14.8281 36 14.5 35.6719 14.5 35.25V21.75C14.5 21.375 14.8281 21 15.25 21H16V32.25C16 33.5156 16.9844 34.5 18.25 34.5H35.5V35.25ZM38.5 32.25C38.5 32.6719 38.125 33 37.75 33H18.25C17.8281 33 17.5 32.6719 17.5 32.25V18.75C17.5 18.375 17.8281 18 18.25 18H37.75C38.125 18 38.5 18.375 38.5 18.75V32.25ZM21.25 24.375C22.6562 24.375 23.875 23.2031 23.875 21.75C23.875 20.3438 22.6562 19.125 21.25 19.125C19.7969 19.125 18.625 20.3438 18.625 21.75C18.625 23.2031 19.7969 24.375 21.25 24.375ZM21.25 20.625C21.8594 20.625 22.375 21.1406 22.375 21.75C22.375 22.4062 21.8594 22.875 21.25 22.875C20.5938 22.875 20.125 22.4062 20.125 21.75C20.125 21.1406 20.5938 20.625 21.25 20.625ZM32.5 21.75C32.0781 21.2812 31.375 21.2812 30.9531 21.75L26.5 26.2031L25 24.75C24.5781 24.2812 23.875 24.2812 23.4531 24.75L19.3281 28.875C19.1406 29.0156 19 29.3906 19 29.625V30.9375C19 31.2656 19.2344 31.5 19.5625 31.5H36.4375C36.7188 31.5 37 31.2656 37 30.9375V26.625C37 26.3438 36.8594 26.0625 36.625 25.875L32.5 21.75ZM35.5 30H20.5V29.8125L24.25 26.0625L26.5 28.3125L31.75 23.0625L35.5 26.8125V30Z"
        fill={theme.primary || "#3B24A8"}
      />
    </SvgIcon>
  );
}

export default UploadImage;
