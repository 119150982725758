import React from 'react';
import { Flex, Text, Box } from 'rebass';
import Card, { CardProps } from '@plug/ui/card';
import Spinner from 'react-svg-spinner';
import { ThemeStore } from '@plug/redux/store';

type CardChart = {
  title: string;
  titleRightContent?: React.ReactNode;
  icon?: React.ReactNode;
  backgroundColor?: string;
  iconBackgroundColor?: string;
  value?: React.ReactNode;
  loading?: boolean;
  cardProps?: CardProps;
  content?: JSX.Element;
};

const CardChart: React.FC<CardChart> = ({
  title,
  titleRightContent,
  icon,
  value,
  loading,
  backgroundColor,
  iconBackgroundColor,
  content,
}) => {
  const theme = ThemeStore.useState(s => s);

  return (
    <Card
      py="14px"
      px="16px"
      width="100%"
      height="100%"
      maxHeight="100%"
      m="0"
      style={{
        border: '1px solid #EBEEF4',
        borderRadius: '8px',
        ...(backgroundColor && { background: backgroundColor }),
      }}
    >
      <Flex sx={{ borderBottom: '1px solid #EBEEF4', borderRadius: '8px' }}>
        <Text fontSize="14px" fontWeight={600} color="#666372" pb="14px">
          {title}
        </Text>

        {titleRightContent && (
          <Flex flex={1} justifyContent="flex-end">
            <Text fontSize="10px" fontFamily="Inter, Sans-serif" fontWeight="500" color="#AAA8B3">
              {titleRightContent}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex pt="15px">
        {content ?? (
          <Flex alignItems="center">
            {icon && (
              <Flex
                width="48px"
                minWidth="48px"
                height="48px"
                minHeight="48px"
                alignItems="center"
                justifyContent="center"
                bg={iconBackgroundColor || '#F0F1F5'}
                sx={{ borderRadius: '50%' }}
              >
                {icon}
              </Flex>
            )}
            {loading ? (
              <Box
                style={{
                  marginLeft: '16px',
                }}
              >
                <Spinner color={theme.primary || '#3b24a8'} speed="fast" size="24px" />
              </Box>
            ) : (
              <Text pl="16px" fontSize="18px" fontWeight="600" color="#1C1637">
                {value}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default CardChart;
