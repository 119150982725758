import gql from 'graphql-tag';

export const AFFILIATE_STORE = gql`
  query AffiliateStore {
    affiliateStore {
      id
      avatar
      cover
      name
      description
      facebook
      slug
      youtube
      twitter
      tiktok
      instagram
      whatsapp
      usersOrganizationServiceRoles {
        id
      }
      allowSlugEdit
      allowBannerUpload
    }
  }
`;

export type AffiliateStoreResponse = {
  affiliateStore: {
    id: string;
    avatar: string;
    cover: string;
    name: string;
    slug: string;
    description: string;
    facebook: string;
    youtube: string;
    twitter: string;
    tiktok: string;
    instagram: string;
    whatsapp: string;
    usersOrganizationServiceRoles: {
      id: string;
    };
    allowSlugEdit: boolean;
    allowBannerUpload: boolean;
  };
};
