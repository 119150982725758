import React from 'react';
import { Flex } from 'rebass';

export type SvgProps = {
  width?: number;
  height?: number;
  viewBox?: string;
  size?: string;
  fill?: string;
  stroke?: string;
  cursor?: string;
  onClick?: any;
  className?: string;
  justifyContent?: string;
};

export type SvgContainerProps = {
  children: React.ReactNode;
  fill?: string;
} & SvgProps;

const Svg: React.FC<SvgProps> = props => {
  const {
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fill = 'currentcolor',
    size,
    cursor,
    onClick,
    ...rest
  } = props;
  return (
    <Flex onClick={onClick} justifyContent={props.justifyContent ?? 'center'} {...rest}>
      <svg
        width={size ? size : width}
        height={size ? size : height}
        viewBox={viewBox}
        cursor={cursor}
        fill={fill}
        {...rest}
      />
    </Flex>
  );
};

export default Svg;
