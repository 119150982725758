import { DEFAULT_HUBLY_RETAILER_LOGO_URL, WHITELABEL_LS_KEY } from '@plug/config/consts';
import { Store } from 'pullstate';

interface InitialValuesI {
  primary: string;
  secondary: string;
  tertiary: string;
  logo: string;
  avatar?: string;
  organizationName?: string;
  updateBySetCurrentOrganization?: boolean;
}

export const InitialValue: InitialValuesI = {
  primary: '#111111',
  secondary: '#111111',
  tertiary: '#111111',
  logo: DEFAULT_HUBLY_RETAILER_LOGO_URL,
};

const themeFromLocalStorage = localStorage.getItem(WHITELABEL_LS_KEY);

export default new Store(themeFromLocalStorage ? JSON.parse(themeFromLocalStorage) : InitialValue);
