import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Order: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 18 22'} width={18} height={22} {...props}>
      <path
        d="M9.01691 15.9918L3.0618 21.1164C2.76269 21.3571 2.40365 21.4664 2.00283 21.4664C1.78701 21.4664 1.61007 21.4396 1.39965 21.3588C0.856699 21.1415 0.499546 20.6554 0.499546 20.1213V3.71639C0.499546 1.93713 2.09138 0.5 4.08622 0.5H13.9129C15.9077 0.5 17.4995 1.93713 17.4995 3.71639V20.1525C17.4995 20.6871 17.1418 21.1735 16.598 21.3905C16.0584 21.606 15.3957 21.4967 14.9784 21.1219L9.01691 15.9918ZM2.11718 3.71639V19.896L8.44459 14.4787C8.77687 14.2064 9.26134 14.2157 9.54865 14.4737L15.9166 19.8941V3.71639C15.9166 2.71993 15.0431 1.94271 13.9476 1.94271H4.08622C2.99069 1.94271 2.11718 2.71993 2.11718 3.71639Z"
        fill={theme.primary || "#3B24A8"}
      />
    </SvgIcon>
  );
}

export default Order;
