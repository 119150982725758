//@ts-nocheck
import { useEffect } from 'react';

export function useOutsideClickEvent(ref, func, refs = []) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current 
        // && !ref.current.contains(event.target) &&
        // !refs.some(r => r.current && r.current.contains(event.target))
      ) {
        func && func(event);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...refs]);
}
