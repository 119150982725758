import { Image as RImage } from 'rebass';
import styled from 'styled-components';

export const Container = styled.a`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 99999999;

  cursor: pointer;
`;

export const Image = styled(RImage)`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;
