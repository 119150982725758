import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const HublyLogo: React.FC<SvgProps> = props => (
  <SvgIcon fill="none" viewBox={'0 0 114 32'} width={114} height={32} {...props}>
    <path d="M23.8115 4.6469C22.8184 3.65061 21.4096 3.26831 20.047 3.63903L5.84314 7.46201C4.723 7.76322 3.79918 8.5394 3.31417 9.59361C2.82916 10.6594 2.82916 11.8642 3.32572 12.9185L4.57288 15.583L4.59598 15.6525L4.67681 15.8378C4.67681 15.8378 4.67681 15.8378 4.66526 15.8378L7.20578 21.7229L7.76008 22.9045L4.75765 23.7155L5.70457 20.7729L3.69525 16.0579C3.61441 16.0927 3.54513 16.1159 3.46429 16.1506C2.48273 16.614 1.75522 17.506 1.45497 18.595L0.138521 23.5765C-0.231009 24.9435 0.150069 26.3568 1.14318 27.3531C2.13629 28.3494 3.54513 28.7317 4.90777 28.361L9.85023 27.0287C10.9473 26.7391 11.8365 25.9977 12.2868 25.013C12.2984 24.9898 12.3099 24.9666 12.3215 24.9435C12.5871 24.3179 12.5871 23.6112 12.3561 22.9741L10.4854 17.9578L7.72543 11.6441L20.174 8.29612L16.8367 20.7845L11.7556 18.5371L13.9613 24.4453L15.5664 25.1983C16.6172 25.6965 17.8182 25.7081 18.8806 25.2099C19.9315 24.7234 20.7052 23.7966 21.0054 22.6729L24.8162 8.42355C25.1857 7.06813 24.8162 5.65478 23.8115 4.6469Z" fill="#F03460" />
    <path fillRule="evenodd" clipRule="evenodd" d="M83.0635 24.283C86.9436 24.283 89.6111 21.7227 89.6111 17.6333C89.6111 13.5439 86.932 10.9952 83.0635 10.9952C80.8233 10.9952 79.0795 11.8757 77.994 13.2543V7.28809H73.6059V23.808H77.994V22.0008C79.0795 23.391 80.8117 24.283 83.0635 24.283ZM81.8395 14.378C84.149 14.378 85.4424 15.5828 85.4424 17.6333C85.4424 19.707 84.149 20.8886 81.8395 20.8886C80.3498 20.8886 78.9987 20.4021 78.0171 19.1278V16.1389C78.9872 14.8645 80.3498 14.378 81.8395 14.378ZM52.7275 8.19189V23.8198H48.1892V17.7725H38.847V23.8198H34.3087V8.19189H38.847V14.019H48.1892V8.19189H52.7275ZM70.7074 11.4468V23.8193H66.3192V21.8499C65.2684 23.3907 63.4438 24.2596 61.2498 24.2596C57.6699 24.2596 55.7299 21.9542 55.7299 18.722V11.4468H60.1181V18.4324C60.1181 20.0427 61.0304 20.9811 62.7048 20.9811C64.1714 20.9811 65.3954 20.286 66.3308 18.7684V11.4468H70.7074ZM91.8052 23.808V7.28809H96.2165V23.808H91.8052ZM105.362 28.2795L114 11.4468H109.543L105.917 19.1275L102.152 11.4468H97.6369L103.734 23.1243L100.928 28.2795H105.362Z" fill="white" />

  </SvgIcon >
);

export default HublyLogo;



