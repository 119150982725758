import React, { useState } from 'react';
import CountdownContainer from '..';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface ICountdownContainerContext {
  show?: boolean;
  toggle: (delay?: number) => void;
}

const CountdownContainerContext = React.createContext<ICountdownContainerContext>({
  toggle: () => null,
  show: false,
});

export const useCountdownContext = (): ICountdownContainerContext =>
  React.useContext(CountdownContainerContext);

export const CountdownContainerContextProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);

  const toggle = (delay = 1500000000000): void => {
    const newValue = !show;
    setShow(!show);

    setTimeout(() => {
      setShow(!newValue);
    }, delay);
  };

  return (
    <CountdownContainerContext.Provider value={{ show, toggle }}>
      <CountdownContainer>{children}</CountdownContainer>
    </CountdownContainerContext.Provider>
  );
};
