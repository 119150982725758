import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { getLocalInfo } from '@plug/helpers/local-info';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';
import { NAV_SIDEBAR_HEIGHT, MENU_HEIGHT_MOBILE, NAV_SIDEBAR_HEIGHT_MOBILE } from '../layouts/dashboard';

export const NavSidebarFlexContainer = styled(Flex)`
  flex-direction: column;
`;

export const NavSidebarHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;

  height: ${(): string => NAV_SIDEBAR_HEIGHT};
  padding: 17px 8px 17px 17px;

  background: ${({ theme }): string => getCustomizatedColor('header', theme.colors.primaryDark)};

  cursor: pointer;

  @media screen and (max-width: 768px) {
    background: ${({ theme }): string => theme.colors.white};
    height: ${(): string => NAV_SIDEBAR_HEIGHT_MOBILE};
  }
`;

export const NavSidebarCloseContainer = styled(Flex)`
  display: flex;
  padding-left: 16px;
  align-items: center;
  height: 66px;
  background-color: ${({ theme }) => theme.colors.primaryDark};

  svg {
    margin: 5px;

    path {
      stroke: ${({ theme }) => theme.colors.primaryLightest};
    }
  }
`;

export const NavSidebarContent = styled(Flex)`
  height: 100%;
  position: relative;
  flex-direction: column;

  padding: 16px;
  padding-bottom: 32px;
`;
