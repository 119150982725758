import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Search: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 20 20'} width={20} height={20} {...props}>
      <path
        d="M9.31925 18.8823C11.6197 18.8823 13.7324 18.0262 15.3521 16.6231L18.4038 19.7146C18.5915 19.9049 18.8263 20 19.061 20C19.2958 20 19.5305 19.9049 19.7183 19.7146C20.0939 19.3341 20.0939 18.7396 19.7183 18.3591L16.6667 15.2438C17.9108 13.6504 18.6385 11.629 18.6385 9.44114C18.6385 4.23306 14.4601 0 9.31925 0C4.1784 0 0 4.23306 0 9.44114C0 14.6492 4.1784 18.8823 9.31925 18.8823ZM9.31925 1.9025C13.4272 1.9025 16.7606 5.27943 16.7606 9.44114C16.7606 13.6029 13.4272 16.9798 9.31925 16.9798C5.21127 16.9798 1.87793 13.6029 1.87793 9.44114C1.87793 5.27943 5.21127 1.9025 9.31925 1.9025Z"
        fill={theme.primary || props.fill || '#DB0046'}
      />
    </SvgIcon>
  );
}

export default Search;
