import React from 'react';
import { Box, BoxProps } from 'rebass';

export const Divider: React.FC<BoxProps> = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="hr"
    {...props}
    sx={{
      color: (props.backgroundColor as string) ?? 'gray',
      m: 0,
      my: 2,
      border: 0,
      borderBottom: '1px solid',
    }}
  />
));

Divider.displayName = 'Divider';

export default Divider;
