import React from 'react';

type State = React.ReactNode;

export function useSideViewState() {
  const [isOpenSideView, setOpenSideView] = React.useState(false);
  const [renderSideViewPage, setRenderSideViewPage] = React.useState<State>(null);

  const closeSideView = () => setOpenSideView(false);
  const openSideView = () => setOpenSideView(true);
  const open = (component: State) => {
    setRenderSideViewPage(component);
    openSideView();
  };

  return {
    onCloseSideView: closeSideView,
    closeSideView,
    onOpenSideView: openSideView,
    isOpenSideView,
    setRenderSideViewPage,
    renderSideViewPage,
    open,
  };
}
