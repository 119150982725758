
import React from 'react';

const DashedBranch: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="44" viewBox="0 0 24 45" fill="none">
      <path
        d="M1 0V20C1 27.4551 1 31.1826 2.21793 34.1229C3.84183 38.0434 6.95662 41.1582 10.8771 42.7821C13.8174 44 17.5449 44 25 44V44"
        stroke="#D6D6DA"
        stroke-dasharray="4 4"
      />
    </svg>
  );
};

export default DashedBranch;
