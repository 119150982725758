import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon from './SvgIcon';

const CloseRounded: React.FC = () => {
  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 16 16'} width={16} height={16}>
      <path
        d="M1.71094 2.2647C2.20714 2.2647 2.60939 1.86966 2.60939 1.38235C2.60939 0.895042 2.20714 0.5 1.71094 0.5C1.21475 0.5 0.8125 0.895042 0.8125 1.38235C0.8125 1.86966 1.21475 2.2647 1.71094 2.2647ZM1.71094 5.7944C2.20714 5.7944 2.60939 5.39935 2.60939 4.91204C2.60939 4.42474 2.20714 4.02969 1.71094 4.02969C1.21475 4.02969 0.8125 4.42474 0.8125 4.91204C0.8125 5.39935 1.21475 5.7944 1.71094 5.7944ZM2.60939 8.44141C2.60939 8.92872 2.20714 9.32376 1.71094 9.32376C1.21475 9.32376 0.8125 8.92872 0.8125 8.44141C0.8125 7.9541 1.21475 7.55906 1.71094 7.55906C2.20714 7.55906 2.60939 7.9541 2.60939 8.44141ZM1.71094 12.8531C2.20714 12.8531 2.60939 12.4581 2.60939 11.9708C2.60939 11.4835 2.20714 11.0884 1.71094 11.0884C1.21475 11.0884 0.8125 11.4835 0.8125 11.9708C0.8125 12.4581 1.21475 12.8531 1.71094 12.8531ZM4.40621 14.6178C4.40621 15.1051 4.00396 15.5002 3.50776 15.5002C3.01156 15.5002 2.60932 15.1051 2.60932 14.6178C2.60932 14.1305 3.01156 13.7355 3.50776 13.7355C4.00396 13.7355 4.40621 14.1305 4.40621 14.6178ZM7.10207 15.5002C7.59826 15.5002 8.00051 15.1051 8.00051 14.6178C8.00051 14.1305 7.59826 13.7355 7.10207 13.7355C6.60587 13.7355 6.20362 14.1305 6.20362 14.6178C6.20362 15.1051 6.60587 15.5002 7.10207 15.5002ZM11.5942 14.6178C11.5942 15.1051 11.1919 15.5002 10.6958 15.5002C10.1996 15.5002 9.79731 15.1051 9.79731 14.6178C9.79731 14.1305 10.1996 13.7355 10.6958 13.7355C11.1919 13.7355 11.5942 14.1305 11.5942 14.6178ZM14.2894 15.5002C14.7856 15.5002 15.1879 15.1051 15.1879 14.6178C15.1879 14.1305 14.7856 13.7355 14.2894 13.7355C13.7932 13.7355 13.391 14.1305 13.391 14.6178C13.391 15.1051 13.7932 15.5002 14.2894 15.5002Z"
        fill={theme.primary || "#3B24A8"}
      />
    </SvgIcon>
  );
}

export default CloseRounded;
