import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Calendar: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);

  return (
    <SvgIcon viewBox={'0 0 20 20'} width={20} height={20} {...props}>
      <path
        d="M17.6639 1.59292H16.3525V0.752212C16.3525 0.336283 16.041 0 15.6557 0C15.2705 0 14.959 0.336283 14.959 0.752212V1.59292H5.32787V0.752212C5.32787 0.336283 5.01639 0 4.63115 0C4.2459 0 3.93443 0.336283 3.93443 0.752212V1.59292H2.33607C1.04918 1.59292 0 2.71239 0 4.10177V6.4292V7.93363V17.4779C0 18.8673 1.04508 20 2.33607 20H17.6639C18.9508 20 20 18.8673 20 17.4779V7.93363V6.4292V4.10177C20 2.71239 18.9549 1.59292 17.6639 1.59292ZM18.6066 17.4779C18.6066 18.0398 18.1844 18.4956 17.6639 18.4956H2.33607C1.81557 18.4956 1.39344 18.0398 1.39344 17.4779V7.92035H18.6066V17.4779ZM1.39344 6.41593V4.10177C1.39344 3.53982 1.81557 3.09735 2.33607 3.09735H3.93443V3.65929C3.93443 4.07522 4.2459 4.4115 4.63115 4.4115C5.01639 4.4115 5.32787 4.07522 5.32787 3.65929V3.09735H14.959V3.65929C14.959 4.07522 15.2705 4.4115 15.6557 4.4115C16.041 4.4115 16.3525 4.07522 16.3525 3.65929V3.09735H17.6639C18.1844 3.09735 18.6066 3.53982 18.6066 4.10177V6.41593H1.39344Z"
        fill={getCustomizatedColor('icons', theme.primary) || '#3B24A8'}
      />
    </SvgIcon>
  );
};

export default Calendar;
