import React from 'react';
import { Button, Typography } from '@gohubly/design-system';
import { CardContainer, IconContainer, TextContent } from './style';
import { useTranslation } from 'react-i18next';
import { useCrisp } from '@plug/hooks/use-crisp';
import { useAuthContext } from '@plug/contexts/auth-context';
import { Box, Flex } from 'rebass';
import Logo from '../logo';

const UpgradeCard: React.FC = ({ ...props }) => {
  const { open } = useCrisp();
  const { t } = useTranslation();
  const { currentOrganization } = useAuthContext();

  const testeOlist = currentOrganization.id === '96da5eff-617a-4fac-8e87-6e14aeb55a5f';

  return (
    <Box sx={{ marginTop: '32px' }}>
      {testeOlist ? (
        <Flex height="100%" flexDirection="column" justifyContent="end" style={{ rowGap: '16px' }}>
          <Logo width="80px" />
          <Typography size="XXXS" lineHeight="MD" color="neutralLowMedium">
            Decolando negócios <br />
            digitais para novos universos 🚀
          </Typography>
        </Flex>
      ) : (
        <CardContainer {...props}>
          <IconContainer>🚀</IconContainer>
          <TextContent>{t('hubly-dashboard.sidebar.card.upgrade.text')}</TextContent>
          <Button onClick={(): void => open()}>
            {t('hubly-dashboard.sidebar.card.upgrade.buttons.upgrade.text')}
          </Button>
        </CardContainer>
      )}
    </Box>
  );
};

export default UpgradeCard;
