import { Flex, Text } from 'rebass';
import styled from 'styled-components';

import { HublyLogo } from '@plug/ui/icons';
import { NAV_SIDEBAR_HEIGHT, NAV_SIDEBAR_HEIGHT_MOBILE } from '@plug/components/layouts/dashboard';

export const Container = styled(Flex)`
  /* Background overlay */
  background-image: url('/images/WhiteLabelBackgroundOnboardingOverlay.png');
  background-size: cover;
  background-position-y: 100px;
  background-repeat: no-repeat;

  @media screen and (max-width: 767px) {
    background-image: url('/images/WhiteLabelBackgroundOnboardingOverlay.png');
    background-size: cover;
    background-position-y: 100px;
    background-repeat: no-repeat;
  }
`;

export const HeaderWrapper = styled(Flex)`
  justify-content: space-between;
  position: relative;
  height: ${(): string => NAV_SIDEBAR_HEIGHT};

  @media screen and (max-width: 767px) {
    align-items: center;
    height: ${(): string => NAV_SIDEBAR_HEIGHT_MOBILE};
  }
`;

export const WhiteBox = styled.div`
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);

  padding: 48px 32px;
  border-radius: 16px;

  @media screen and (max-width: 767px) {
    align-items: center;
    padding: 40px 16px;
    margin-top: 24px;
  }
`;

export const Content = styled.div`
  max-width: 432px;
  margin: 0 auto;
`;

export const FooterWrapper = styled(Flex)`
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 24px 48px;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    opacity: 0;
  }
`;

export const FooterItem = styled(Flex)`
  align-items: center;
  width: 33.3%;
  svg {
    transform: scale(0.8);
  }
`;

export const TextFooter = styled(Text)`
  font-size: 16px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const StyledHublyLogo = styled(HublyLogo)`
  path:nth-child(2) {
    fill: black;
  }
`;
