import styled from 'styled-components';

export const Container = styled.div`
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 75px);
  padding: 0px 0px 20px 36px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px 34.5px 16px;
    gap: 40px;
    flex-direction: row-reverse;
  }
`;
