import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Table } from '@plug/ui';
import { CaretLeft } from '@plug/ui/icons';
import PaymentInfoItem from '../payment-info-item';
import FinantialGraph from './finantial-graph';
import { Views } from '../../containers/admin-payments';
import { formatBrazilPrice } from '@plug/helpers/normalize-currency';
import CapitalizeFirst from '@plug/helpers/capitalize-first';
import { Modal } from '@plug/ui';
import {
  GET_DAILY_REVENUE_AND_COMMISSIONS,
  GetDailyRevenueAndCommissionsInput,
  GetDailyRevenueAndCommissionsQuery,
} from '../../graphql/queries/get-daily-revenue-and-commissions';
import {
  GET_AFFILIATES_VALUES_BY_MONTH,
  GetAffiliatesValuesByMonthInput,
  GetAffiliatesValuesByMonthQuery,
} from '../../graphql/queries/get-affiliates-values-by-month';
import {
  HANDLE_ORGANIZATION_FINANTIAL_CONCILIATION_STATUS_ADVANCE,
  HandleOrganizationFinantialConciliationStatusAdvanceVariables,
  HandleOrganizationFinantialConciliationStatusAdvancePayload,
} from '../../graphql/mutations/handle-organization-finantial-conciliation-status-advance';
import {
  downloadFinantialConciliationCSVByReferenceMonthQuery,
  downloadFinantialConciliationCSVByReferenceMonthVariables,
  DOWNLOAD_FINATIAL_CONCILIATION_CSV_BY_REFERENCE_MONTH,
} from '../../graphql/queries/download-finantial-conciliation-CSV-by-reference-month';
import decodeBase64 from '@plug/helpers/decode-base64';
import { ThemeStore } from '@plug/redux/store';
import { useAuthContext } from '@plug/contexts/auth-context';
import { LI_ALLOWED_ORGANIZATION_IDS } from '@plug/config/consts';
import useDownload from '@plug/hooks/use-download';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Spinner } from '@gohubly/design-system';
import formatCnpj from '@plug/helpers/format-cnpj';
import formatCpf from '@plug/helpers/format-cpf';

interface Props {
  toggleView: Function;
  setAffiliateId: Function;
  yearMonth: string;
}

enum Status {
  OPEN = 'Em aberto',
  CLOSED = 'Fechado',
  PAID = 'Pago',
}

const AdminPaymentsMonthly = ({ toggleView, yearMonth, setAffiliateId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = ThemeStore.useState(s => s);
  const { currentOrganization } = useAuthContext();
  const [status, setStatus] = useState<Status>(Status.OPEN);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [GetDailyRevenueAndCommissions, { data: GraphData, loading: GraphLoading }] = useLazyQuery<
    GetDailyRevenueAndCommissionsQuery,
    GetDailyRevenueAndCommissionsInput
  >(GET_DAILY_REVENUE_AND_COMMISSIONS);

  const [
    handleOrganizationFinantialConciliationStatusAdvance,
    { loading: DoingMutation },
  ] = useMutation<
    HandleOrganizationFinantialConciliationStatusAdvancePayload,
    HandleOrganizationFinantialConciliationStatusAdvanceVariables
  >(HANDLE_ORGANIZATION_FINANTIAL_CONCILIATION_STATUS_ADVANCE, {
    // refetchQueries: ['organizationPaymentsDetails'],
    // awaitRefetchQueries: true,
  });

  const { download } = useDownload();

  const [
    GetAffiliatesValuesByMonth,
    { data: AffiliatesValuesData, loading: AffiliatesValuesLoading },
  ] = useLazyQuery<GetAffiliatesValuesByMonthQuery, GetAffiliatesValuesByMonthInput>(
    GET_AFFILIATES_VALUES_BY_MONTH,
  );

  const [
    downloadFinantialConciliationCSVByReferenceMonth,
    {
      data: downloadFinantialConciliationCSVByReferenceMonthData,
      loading: downloadFinantialConciliationCSVByReferenceMonthLoading,
    },
  ] = useLazyQuery<
    downloadFinantialConciliationCSVByReferenceMonthQuery,
    downloadFinantialConciliationCSVByReferenceMonthVariables
  >(DOWNLOAD_FINATIAL_CONCILIATION_CSV_BY_REFERENCE_MONTH);

  useEffect(() => {
    if (downloadFinantialConciliationCSVByReferenceMonthData) {
      const fileContent = decodeBase64(
        downloadFinantialConciliationCSVByReferenceMonthData.downloadFinantialConciliationCSVByReferenceMonth,
      );
      const blob = new Blob([fileContent], { type: 'data:application/octet-stream;base64' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Relatorio-${yearMonth}.csv`;
      link.click();
    }
  }, [downloadFinantialConciliationCSVByReferenceMonthData, yearMonth]);

  useEffect(() => {
    GetAffiliatesValuesByMonth({
      variables: {
        input: {
          year_month: yearMonth,
        },
      },
    });

    GetDailyRevenueAndCommissions({
      variables: {
        input: {
          year_month: yearMonth,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearMonth]);

  useEffect(() => {
    setStatus(Status[GraphData?.getDailyRevenueAndCommissions.status as keyof typeof Status]);
  }, [GraphData]);

  const parsedFields = JSON.parse(
    AffiliatesValuesData?.getAffiliatesValuesByMonth?.affiliates[0]?.plugFormFields ?? '{}',
  ) as { [key: string]: string };

  const plugFormFieldColumns = Object.entries(parsedFields).map(([key, value]) => ({
    Header: key,
    accessor: `fields.${key}`,
  }));

  const affiliatesList = AffiliatesValuesData?.getAffiliatesValuesByMonth?.affiliates ?? [];

  const affiliates = useMemo(() => {
    return affiliatesList.map(affiliates => ({
      ...affiliates,
      fields: JSON.parse(affiliates.plugFormFields ?? '{}'),
    }));
  }, [affiliatesList]);

  if (GraphLoading || AffiliatesValuesLoading) {
    return (
      <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  const columns = [
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.email'),
      accessor: 'email',
      width: 300,
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.member'),
      accessor: 'name',
      width: 300,
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.document-cpf'),
      accessor: 'document',
      width: 200,
      Cell: ({ value }: any): string => {
        const cleanedValue = value ? value.replace(/\D/g, '') : '';

        return cleanedValue.length > 0
          ? cleanedValue.length > 11
            ? formatCnpj(cleanedValue)
            : formatCpf(cleanedValue)
          : '-';
      },
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.bank'),
      accessor: 'bank',
      width: 200,
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.pix'),
      accessor: 'pix',
      width: 200,
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.agency'),
      accessor: 'agency',
      width: 120,
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.account'),
      accessor: 'account',
      width: 120,
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.sales'),
      accessor: 'revenue',
      Cell: ({ value }: any): string => formatBrazilPrice(value),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.invoice'),
      accessor: 'invoice.url',
      Cell: ({ value, row }: any): JSX.Element => {
        if (!value) {
          return (
            <>{t('hubly-dashboard.payments.admin.views.monthly.table.body.invoice.pending')}</>
          );
        }

        return (
          <Flex>
            <span
              onClick={(e): void => {
                e.stopPropagation();

                download(value, `NF_${row.original.document}`);
              }}
            >
              {t('hubly-dashboard.payments.admin.views.monthly.table.body.invoice.download')}
            </span>

            <span>&nbsp;|&nbsp;</span>

            <span>
              {row.original.invoice.received
                ? t('hubly-dashboard.payments.admin.views.monthly.table.body.invoice.validated')
                : t('hubly-dashboard.payments.admin.views.monthly.table.body.invoice.invalidated')}
            </span>
          </Flex>
        );
      },
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.monthly.table.columns.commission'),
      accessor: 'commission',
      Cell: ({ value }: any): string => formatBrazilPrice(value),
    },
    ...plugFormFieldColumns,
  ];

  const momentDate = moment(yearMonth, 'YYYY-MM');
  const revenueValue =
    AffiliatesValuesData?.getAffiliatesValuesByMonth.affiliates.reduce(
      (last, actual) => last + Number(actual.revenue),
      0,
    ) ?? 0;

  const commissionsToPayValue =
    AffiliatesValuesData?.getAffiliatesValuesByMonth.affiliates.reduce(
      (last, actual) => last + Number(actual.commission),
      0,
    ) ?? 0;

  const toggleConfirmationModal = (): void => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleAdvanceStatus = (): void => {
    handleOrganizationFinantialConciliationStatusAdvance({
      variables: {
        input: {
          referenceMonth: yearMonth,
        },
      },
    })
      .then(data => {
        toast.success(t('hubly-dashboard.payments.admin.views.monthly.states.success'));
        setShowConfirmationModal(!showConfirmationModal);
        setStatus(
          Status[
            data.data?.handleOrganizationFinantialConciliationStatusAdvance as keyof typeof Status
          ],
        );
      })
      .catch(() => {
        toast.error(t('hubly-dashboard.payments.admin.views.monthly.states.error'));
      });
  };

  const handleDownloadReport = (): void => {
    downloadFinantialConciliationCSVByReferenceMonth({
      variables: {
        input: {
          referenceMonth: yearMonth,
        },
      },
    });
  };

  const statusKeys = Object.entries(Status);
  const statusIndex = statusKeys.findIndex(([_, value]) => value === status);

  return (
    <Flex
      width="100%"
      flexDirection="column"
      sx={{ gap: '24px', '@media(max-width: 768px)': { gap: '8px' } }}
    >
      <Modal
        show={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        title={t(
          `hubly-dashboard.payments.admin.views.monthly.modal.title.${
            status === Status.OPEN ? 'close-month' : 'confirm-payment'
          }`,
        )}
        width="350px"
        height="max-content"
      >
        <Box>
          <Text fontSize={14} color="#666372" pb="24px">
            <Trans
              i18nKey={`hubly-dashboard.payments.admin.views.monthly.modal.body.${
                status === Status.OPEN ? 'close-payment' : 'confirm-payment'
              }`}
              values={{
                month: CapitalizeFirst(momentDate.format('MMMM - YYYY')),
              }}
              components={{
                green: <Text as="span" color={theme.primary || '#3B24A8'} />,
              }}
            />
          </Text>

          <Flex width={'100%'} flexDirection={'column'} sx={{ gap: '8px' }}>
            <Button onClick={handleAdvanceStatus} fluid>
              {t('hubly-dashboard.payments.admin.views.monthly.modal.buttons.confirm.text')}
            </Button>

            <Button hierarchy="tonalFilled" onClick={toggleConfirmationModal} fluid>
              {t('hubly-dashboard.payments.admin.views.monthly.modal.buttons.cancel.text')}
            </Button>
          </Flex>
        </Box>
      </Modal>

      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            onClick={(): void => toggleView(Views.GENERAL)}
            sx={{ cursor: 'pointer' }}
          >
            <Flex alignItems="center">
              <CaretLeft fill={theme.primary || '#3B24A8'} />

              <Text pl="20px">
                <Trans
                  i18nKey="hubly-dashboard.payments.admin.views.monthly.month-info"
                  values={{
                    month: CapitalizeFirst(momentDate.format('MMMM')),
                    year: momentDate.year(),
                  }}
                  components={{
                    green: <Text as="span" fontSize="16px" pl="3px" color={'#000'} />,
                  }}
                />
              </Text>
            </Flex>
          </Flex>

          <Text pl="10px" pt="2px" fontSize="14px" color={theme.primary || '#3B24A8'}>
            {t(
              `hubly-dashboard.payments.admin.commons.status.${statusKeys[
                statusIndex === -1 ? 0 : statusIndex
              ][0].toLowerCase()}`,
            )}
          </Text>
        </Flex>

        <Flex sx={{ gap: '12px' }}>
          <Button
            hierarchy="tonalFilled"
            icon="download"
            iconStrokeWidth={1}
            onClick={handleDownloadReport}
            loading={downloadFinantialConciliationCSVByReferenceMonthLoading}
            size="LG"
          >
            {t('hubly-dashboard.payments.admin.views.monthly.buttons.download-report.text')}
          </Button>

          {(status === Status.OPEN || status === Status.CLOSED) && (
            <Button onClick={toggleConfirmationModal} loading={DoingMutation} size="LG">
              {status === Status.OPEN
                ? t('hubly-dashboard.payments.admin.views.monthly.buttons.close-month.text')
                : status === Status.CLOSED
                ? t('hubly-dashboard.payments.admin.views.monthly.buttons.confirm-payment.text')
                : ''}
            </Button>
          )}
        </Flex>
      </Flex>

      <Flex
        width="100%"
        flexDirection="column"
        backgroundColor="#fff"
        sx={{ borderRadius: '12px' }}
      >
        <PaymentInfoItem
          data={[
            {
              text: !LI_ALLOWED_ORGANIZATION_IDS.includes(currentOrganization.id)
                ? t('hubly-dashboard.payments.admin.commons.info.orders')?.toString() ?? ''
                : 'Solicitações de resgate',
              value: GraphData?.getDailyRevenueAndCommissions.orders || 0,
            },
            {
              ballColor: theme.primary || '#3B24A8',
              text: t('hubly-dashboard.payments.admin.commons.info.revenue')?.toString() ?? '',
              value: formatBrazilPrice(revenueValue),
            },
            {
              ballColor: '#ffd500',
              text: t('hubly-dashboard.payments.admin.commons.info.pay')?.toString() ?? '',
              value: formatBrazilPrice(commissionsToPayValue),
            },
          ]}
        />

        <FinantialGraph
          data={
            GraphData?.getDailyRevenueAndCommissions?.days?.map(day => {
              return {
                revenue: day.revenue,
                commission: day.commission,
                day: day.day,
              };
            }) || []
          }
        />

        {AffiliatesValuesData?.getAffiliatesValuesByMonth?.affiliates && (
          <Table
            responsive
            data={affiliates}
            rowDataValue="affiliateId"
            columns={columns}
            finantialConciliationMonthlyOnClick={setAffiliateId}
            // onClickRow={(): void => toggleView(Views.AFFILIATE)}
            padding="0px"
          />
        )}
      </Flex>
    </Flex>
  );
};

export default AdminPaymentsMonthly;
