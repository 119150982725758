import React from 'react';
import { TextFieldBase } from '../text-field';
import { Close, Search as SearchIcon } from '../icons';
import { Flex } from 'rebass';

type SearchProps = {
  fieldWrapperProps?: any;
  clearSearch?: () => void;
  clear?: boolean;
} & React.HTMLProps<HTMLInputElement>;
const Search: React.FC<SearchProps> = ({
  style,
  onChange,
  value,
  fieldWrapperProps,
  placeholder,
  disabled,
  clearSearch,
  clear,
}) => {
  return (
    <TextFieldBase
      fieldWrapperProps={{
        pb: '0',
        width: '100%',
      }}
      height="45px"
      value={value}
      onChange={onChange}
      inputStart={
        <Flex height="100%" pl="18px" alignItems="center">
          <SearchIcon />
        </Flex>
      }
      name="search"
      placeholder={placeholder || 'Buscar...'}
      hideHelperArea
      width="100%"
      style={style}
      disabled={disabled}
      clearSearch={clearSearch}
      inputEnd={
        clear && (
          <Flex height="100%" p="18px" alignItems="center" sx={{ cursor: 'pointer' }}>
            <Close />
          </Flex>
        )
      }
    />
  );
};

export default Search;
