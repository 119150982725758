import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Plus: React.FC<SvgProps> = props => (
  <SvgIcon
    viewBox={'0 0 12 12'}
    width={12}
    height={12}
    fill={props.fill ? props.fill : '#B3B1B8'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1875 0C5.87684 0 5.625 0.25184 5.625 0.5625V5.25H0.5625C0.25184 5.25 0 5.50184 0 5.8125C0 6.12316 0.25184 6.375 0.5625 6.375H5.625V11.4375C5.625 11.7482 5.87684 12 6.1875 12C6.49816 12 6.75 11.7482 6.75 11.4375V6.375H11.4375C11.7482 6.375 12 6.12316 12 5.8125C12 5.50184 11.7482 5.25 11.4375 5.25H6.75V0.5625C6.75 0.25184 6.49816 0 6.1875 0Z"
    />
  </SvgIcon>
);

export default Plus;
