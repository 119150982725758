import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import CaretDown from '../icons/CaretDown';
import { inter } from '../fonts';
import useOutsideClick from '@plug/hooks/use-outside-click';

const SelectDefaultName = styled.div`
  ${inter('s12')}
  font-weight: 500;
  margin-right: 0.75rem;
  line-height: 16px;
  color: #555069;
`;

interface ISelect {
  defaultName: string;
  selectStart?: React.ReactNode;
  handleChange?: Function;
  patternIndex?: number;
  width?: string;
  options?: Array<{ value: string; label: string }>;
}

const SelectOptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 35px;
  z-index: 1;
  padding: 0.25rem;
  background: #fbfcfd;
  left: 0;
  border: 1px solid #d4d3d8;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(28, 22, 55, 0.04), 0px 8px 16px rgba(28, 22, 55, 0.06);
  border-radius: 4px;
`;

const SelectOptionsItem = styled.div`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-weight: 600;
  ${inter('s10')};
  line-height: 16px;
  border-radius: 4px;
  &:hover {
    background: #f4f6fa;
    color: ${props => props.theme.primary || "#3b24a8"};
  }
`;

const Select: React.FC<ISelect & React.HTMLProps<HTMLInputElement>> = ({
  defaultName,
  selectStart,
  style,
  handleChange,
  width,
  patternIndex,
  options,
}) => {
  const [open, setOpen] = React.useState(false);

  const wrapperRef = React.useRef();

  useOutsideClick(wrapperRef, () => setOpen(false));

  return (
    <Box
      ref={wrapperRef}
      width={width || '100px'}
      style={{
        cursor: 'pointer',
        position: 'relative',
        background: '#EBEEF4',
        borderRadius: '4px',
        minWidth: '80px',
        padding: '0.3rem 0.75rem',
        ...style,
      }}
    >
      <Flex onClick={() => setOpen(!open)} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          {selectStart && <Box style={{ marginRight: '0.75rem' }}>{selectStart}</Box>}
          <SelectDefaultName>{defaultName}</SelectDefaultName>
        </Flex>
        <CaretDown />
      </Flex>
      {open && (
        <SelectOptionsWrapper>
          {options &&
            options.map((item, idx) => {
              return (
                <SelectOptionsItem
                  onClick={() => {
                    handleChange && handleChange(item.value, patternIndex);
                    setOpen(false);
                  }}
                  key={idx}
                >
                  {item.label}
                </SelectOptionsItem>
              );
            })}
        </SelectOptionsWrapper>
      )}
    </Box>
  );
};

export default Select;
