import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Yampi: React.FC<SvgProps> = props => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.1553 36.6916C28.9681 36.6916 30.6268 36.1879 32.0926 35.2969C32.864 33.9408 33.2883 32.3522 33.2883 30.6475C33.2883 25.4556 29.0839 21.2324 23.9151 21.2324H18.7465V16.1955C18.7465 11.0036 14.542 6.78041 9.37319 6.78041C7.63741 6.78041 5.97879 7.24535 4.59015 8.09781C3.8187 9.49254 3.35583 11.0811 3.35583 12.786V26.8116C3.35583 32.0035 7.94599 36.6916 13.1148 36.6916H27.1553Z"
      fill="#FA3296"
    />
    <path
      d="M27.1553 36.6916H13.1148C7.94601 36.6916 3.35584 32.0035 3.35584 26.8116V12.7858C3.35584 11.0811 3.81872 9.49254 4.59016 8.09766C1.85149 9.72494 0 12.7471 0 16.1953V29.5237C0 34.7156 5.20734 40.1011 10.3761 40.1011H23.9151C27.4253 40.1011 30.4726 38.1638 32.0926 35.2968C30.6269 36.1879 28.9297 36.6916 27.1553 36.6916Z"
      fill="#503296"
    />
    <path
      d="M30.6268 33.282H15.699C10.5303 33.282 6.71162 29.9112 6.71162 23.8669V9.37634C6.71162 7.63271 7.17449 6.00546 8.02309 4.61063C5.20728 6.23792 3.3172 9.29872 3.3172 12.7858V26.8116C3.3172 32.0035 7.90738 36.6916 13.0761 36.6916H27.1166C30.5882 36.6916 33.6355 34.8318 35.2941 32.0422C33.944 32.8172 32.3626 33.282 30.6268 33.282Z"
      fill="#C83296"
    />
    <path
      d="M36.6443 26.8503C36.4128 21.8136 32.3241 17.8228 27.2711 17.8228H22.1023V12.9022C22.1023 7.5165 17.6665 3.13834 12.3048 3.3708C8.98754 3.52579 6.13314 5.38556 4.59024 8.09766C5.97886 7.24533 7.63749 6.78039 9.37327 6.78039C14.542 6.78039 18.7465 11.0036 18.7465 16.1955V21.2324H23.9153C29.0841 21.2324 33.2885 25.4556 33.2885 30.6475C33.2885 32.3135 32.8642 33.902 32.0926 35.2968C34.9085 33.592 36.7599 30.4149 36.6443 26.8503Z"
      fill="#FA78B4"
    />
    <path
      d="M30.6268 14.452H25.458V9.41507C25.458 4.22322 21.2535 0 16.0847 0C12.6518 0 9.64313 1.85977 7.98451 4.64942C9.25741 3.91326 10.7232 3.44832 12.2661 3.37082C17.6278 3.13836 22.0636 7.5165 22.0636 12.9022V17.8228H27.2324C32.2854 17.8228 36.3741 21.8136 36.6056 26.8503C36.6828 28.7489 36.1813 30.5311 35.2941 32.0422C38.1099 30.4149 40 27.354 40 23.8671C40 18.6365 35.8342 14.452 30.6268 14.452Z"
      fill="#FAA0C8"
    />
    <path
      d="M7.02032 7.05157C7.25176 6.16044 7.59892 5.34678 8.02322 4.61063C6.59602 5.42428 5.40027 6.62538 4.59024 8.09766C5.32311 7.63271 6.13314 7.28404 7.02032 7.05157Z"
      fill="#FAA0C8"
    />
    <path
      d="M32.9798 32.9723C32.7869 33.7858 32.4397 34.5608 32.054 35.2969C33.4041 34.4832 34.5227 33.3596 35.2942 32.0423C34.5998 32.4686 33.8284 32.7784 32.9798 32.9723Z"
      fill="#FAA0C8"
    />
  </svg>
);

export default Yampi;
