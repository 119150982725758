import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import styled from 'styled-components';
import SlimCheck from '../icons/SlimCheck';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4d3d8;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`;

const Checkbox: React.FC<React.HTMLProps<HTMLInputElement> & {
  onClick?: () => void;
  onChange?: (select: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
  style?: any;
}> = ({ checked, disabled, onClick, onChange, style }) => {

  const theme = ThemeStore.useState(s => s);

  const handleChange = (): void => {
    if (onClick) return onClick();
    if (!disabled && onChange) return onChange(!checked);
  };
  return (
    <CheckboxWrapper
      onClick={handleChange}
      style={{
        ...style,
        background: checked ? theme.primary || '#3B24A8' : '#FFFFFF',
        border: `1px solid ${checked ? theme.primary || '#3B24A8' : '#D4D3D8'}`,
      }}
    >
      {checked && <SlimCheck />}
    </CheckboxWrapper>
  );
};

export default Checkbox;
