import gql from 'graphql-tag';

export const GET_WHITE_LABEL_INFOS_BY_DOMAIN = gql`
  query getWhiteLabelInfosByDomain($input: GetWhiteLabelInfosInput!) {
    getWhiteLabelInfosByDomain(input: $input) {
      primaryColor
      secondColor
      tertiaryColor
      logo
      avatar
      isWhiteLabel
      organizationName
      organizationId
    }
  }
`;

export type getWhiteLabelInfosByDomainQuery = {
  getWhiteLabelInfosByDomain: {
    primaryColor: string;
    secondColor: string;
    tertiaryColor: string;
    logo: string;
    avatar?: string;
    isWhiteLabel: boolean;
    organizationName: string;
    organizationId: string;
  };
};

export type getWhiteLabelInfosByDomainVariables = {
  input: {
    domain: string;
  };
};
