import React, { FC, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  PRODUCTS,
  ProductsReponse,
  ProductsVariables,
} from '@plug/domains/organization/domains/affiliate/graphql/queries/checkout/products';
import * as css from './checkout-catalog-products.styles';
import { Button, Product } from '@monitfy/ds';
import CheckoutCatalogSection from '../checkout-catalog-section';
import { Box } from 'rebass';
import { CheckoutCatalogProductsProps } from './checkout-catalog-products.types';
import { useCheckoutContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutContext';
import Skeleton from 'react-loading-skeleton';
import { useCheckoutCartContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutCartContext';

const CheckoutCatalogProducts: FC<CheckoutCatalogProductsProps> = ({
  title,
  limit = 12,
  onSelectProduct,
}) => {
  const {
    products: { search, category },
  } = useCheckoutContext();
  const {
    actions: { addCartItem, removeCartItem },
    states: { isAddingItem, isRemovingItem, updatingItemId },
    isAdded,
  } = useCheckoutCartContext();

  const [products, setProducts] = useState<Product[]>([]);
  const [filters, setFilters] = useState<ProductsSearchInput>({ limit });
  const [isOver, setIsOver] = useState(false);

  const { loading } = useQuery<ProductsReponse, ProductsVariables>(PRODUCTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        query: search,
        category,
        ...filters,
      },
    },
    onCompleted(data) {
      if (products.length && !data.products.length) {
        return setIsOver(true);
      }

      if (data?.products?.length) {
        return setProducts(products => [...products, ...data.products]);
      }

      setProducts(data.products);
    },
  });

  const fakes = loading ? Array(limit).fill(0) : [];

  useEffect(() => {
    setFilters(filters => ({ ...filters, page: 1 }));
    setIsOver(false);
    setProducts([]);
  }, [category, search]);

  const isDisabled = isAddingItem || isRemovingItem;

  return (
    <CheckoutCatalogSection title={title}>
      <css.Grid>
        {products.length > 0 &&
          products.map(({ id, name, skus = [], ...rest }) => {
            const added = isAdded(skus?.[0]?.id);

            return (
              <Product.Summary
                key={id}
                title={name}
                image={{
                  url: skus?.[0].images[0],
                  altText: `${name} image`,
                }}
                listPrice={skus?.[0].bestPrice ?? 0}
                sellingPrice={skus?.[0].price ?? 0}
                showDiscount
                buttonProps={{
                  children: <>{added ? 'Remover' : 'Adicionar'}</>,
                  variant: added ? 'filledTonal' : 'filled',
                  ...(added && {
                    iconAfter: 'trash',
                  }),
                  disabled: isDisabled,
                  loading: updatingItemId === skus?.[0]?.id,
                  onClick(event) {
                    event.stopPropagation();

                    if (added) removeCartItem(skus?.[0]?.id);
                    else addCartItem(skus?.[0]?.id);
                  },
                }}
                onClick={() => onSelectProduct({ id, name, skus, ...rest })}
              />
            );
          })}

        {fakes.length > 0 &&
          fakes.map((_, idx) => <Skeleton key={idx} width="100%" height="403px" />)}
      </css.Grid>

      <Box alignSelf="center">
        <Button
          variant="text"
          iconAfter={!isOver ? 'chevron-down' : undefined}
          loading={loading}
          disabled={isOver}
          onClick={() => setFilters(filters => ({ ...filters, page: (filters.page ?? 1) + 1 }))}
        >
          {isOver ? 'Isso é tudo =)' : 'Mostrar todos os produtos'}
        </Button>
      </Box>
    </CheckoutCatalogSection>
  );
};

export default CheckoutCatalogProducts;
