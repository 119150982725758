import gql from 'graphql-tag';

export const REGISTER_ACTIVITY = gql`
  mutation {
    registerActivity
  }
`;

export type RegisterActivityResponse = {
  registerActivity?: boolean;
};
