import gql from 'graphql-tag';

export const CART_MUTATION = gql`
  mutation($id: String!, $items: [AddCartItemsInput]!) @api(name: "cart") {
    cart(id: $id, items: $items) {
      id
      items {
        id
        productId
        name
        price
        listPrice
        quantity
        imageUrl
        sku
        metadata {
          key
          value
        }
      }
      value
    }
  }
`;

export type CartMutationVariables = {
  id: string;
  items: {
    id: string;
    quantity: 1;
    metadata: {
      key: string;
      value?: string;
    }[];
  }[];
};

export type CartMutationResponse = {
  cart: Cart;
};
