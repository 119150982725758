import React, { FC, createContext, useContext, useState } from 'react';
import NotificationsDrawer from '@plug/components/notifications-drawer';
import { NotificationProps } from '@plug/components/notifications-drawer/components/notification/notification.types';

interface INotificationContext {
  notifications: NotificationProps[];
  addNotifications: (items: NotificationProps[]) => void;
  show: () => void;
  hide: () => void;
  toggle: () => void;
  clear: () => void;
}

const NotificationsContext = createContext<INotificationContext>({
  notifications: [],
  addNotifications: null,
  show: null,
  hide: null,
  toggle: null,
  clear: null,
});

export const useNotificationContext = () => useContext(NotificationsContext);

export const NotificationsContextProvider: FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const addNotifications = (items: NotificationProps[]) => {
    if (!notifications.length)
      return setNotifications(notifications => [...items, ...notifications]);

    const mappedIncomingItems = items.reduce(
      (prev, curr) => (curr.id ? { ...prev, [curr.id]: curr.unique } : prev),
      {} as any,
    );
    const incoming = notifications.filter(item => !mappedIncomingItems[item.id]);

    if (!incoming.length) return;

    return setNotifications([...items, ...incoming]);
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotifications,
        show: () => setShow(true),
        hide: () => setShow(false),
        toggle: () => setShow(show => !show),
        clear: () => setNotifications([]),
      }}
    >
      <NotificationsDrawer notifications={notifications} show={show} />
      {children}
    </NotificationsContext.Provider>
  );
};
