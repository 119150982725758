import React from 'react';

import { RouteOrRedirectType } from '@plug/routes/types';

const Onboarding = React.lazy(() => import('@plug/domains/onboarding/pages/onboarding'));

const ONBOARDING_PATH = '/onboarding';

const routes: RouteOrRedirectType[] = [
  {
    title: 'Onboarding',
    component: Onboarding,
    path: ONBOARDING_PATH,
    privateRoute: true
  },
];

export default routes;
