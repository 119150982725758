import { ComponentProps } from 'react';
import styled from 'styled-components';

export const NotificationContainer = styled.div<
  ComponentProps<'div'> & { hasNotification?: boolean }
>`
  position: relative;

  svg {
    cursor: ${({ hasNotification }): string => (hasNotification ? 'pointer' : 'initial')};

    path {
      stroke: white !important;
    }
  }

  &:after {
    content: '';

    width: 10px;
    height: 10px;

    position: absolute;
    right: 0;
    top: 0;

    background-color: ${({ theme }): string => theme.colors.helper};
    border-radius: ${({ theme }): string => theme.borderRadius.Circular};

    opacity: ${({ hasNotification }): string => (hasNotification ? '1' : '0')};
  }
`;
