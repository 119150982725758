declare global {
  interface Window {
    $crisp: any;
  }
}

type CrispHook = {
  toggle: () => void;
  close: () => void;
  show: () => void;
  hide: () => void;
  open: () => void;
};

export function useCrisp(): CrispHook {
  const toggle = (): void => {
    window.$crisp?.push(['do', 'chat:toggle']);
  };

  const close = (): void => {
    window.$crisp?.push(['do', 'chat:close']);
  };

  const hide = (): void => {
    window.$crisp?.push(['do', 'chat:hide']);
  };

  const show = (): void => {
    window.$crisp?.push(['do', 'chat:show']);
  };

  const open = (): void => {
    window.$crisp?.push(['do', 'chat:open']);
  };

  return {
    toggle,
    close,
    hide,
    show,
    open,
  };
}
