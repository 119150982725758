import React from 'react';
import { SvgProps } from './SvgIcon';

const NuvemShop: React.FC<SvgProps> = props => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 1454.000000 401.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,401.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2570 3026 c-174 -37 -356 -126 -484 -238 l-40 -35 -75 25 c-103 34
-294 42 -408 17 -100 -21 -260 -99 -340 -165 -123 -100 -220 -248 -265 -406
-33 -113 -33 -315 0 -427 127 -443 583 -691 1016 -553 68 21 77 22 88 8 29
-35 176 -135 252 -172 395 -190 868 -113 1178 193 399 393 413 1031 33 1435
-142 151 -285 240 -480 298 -78 23 -111 27 -250 30 -108 2 -181 -1 -225 -10z
m388 -236 c274 -66 495 -278 584 -560 20 -64 23 -96 23 -220 0 -124 -3 -156
-23 -220 -91 -286 -299 -488 -582 -562 -105 -27 -277 -27 -385 1 -335 87 -573
365 -610 714 l-7 67 -115 0 -115 0 6 -67 c15 -155 49 -275 112 -400 23 -46 38
-85 34 -88 -4 -3 -38 -9 -76 -14 -281 -40 -558 152 -630 439 -23 89 -15 231
18 320 64 176 220 319 394 364 77 20 221 20 294 1 224 -60 398 -261 425 -492
l7 -63 115 0 116 0 -7 70 c-9 93 -40 199 -82 287 -35 74 -138 213 -174 235
-11 7 -20 17 -20 23 0 17 120 93 199 126 102 43 178 57 306 58 78 1 135 -5
193 -19z"
      />
      <path
        d="M10552 2068 l3 -633 108 -3 107 -3 0 269 c0 299 7 345 62 408 37 42
68 59 124 68 95 16 196 -43 222 -130 8 -29 12 -130 12 -328 l0 -286 111 0 110
0 -3 338 c-3 326 -4 339 -27 397 -17 43 -40 76 -80 116 -73 72 -132 93 -252
93 -77 0 -93 -3 -152 -32 -38 -19 -79 -49 -97 -70 l-30 -37 0 233 0 232 -110
0 -110 0 2 -632z"
      />
      <path
        d="M4802 2370 c-60 -13 -139 -58 -173 -99 l-29 -36 0 58 0 57 -110 0
-110 0 2 -457 3 -458 108 -3 107 -3 0 269 c0 305 7 346 67 413 101 112 299 73
341 -67 8 -29 12 -130 12 -328 l0 -286 111 0 110 0 -3 338 c-3 326 -4 339 -27
397 -31 78 -116 165 -186 188 -61 21 -166 29 -223 17z"
      />
      <path
        d="M7597 2370 c-123 -21 -257 -118 -317 -231 -44 -82 -60 -148 -60 -244
0 -249 140 -423 379 -472 91 -19 201 -9 294 27 62 23 167 112 200 169 l18 31
-86 41 -85 42 -48 -50 c-62 -65 -118 -87 -209 -81 -37 2 -84 12 -104 22 -69
33 -125 104 -143 179 l-6 27 355 0 355 0 0 73 c0 189 -91 347 -243 422 -101
50 -191 63 -300 45z m194 -195 c65 -33 109 -87 130 -157 l8 -28 -245 0 c-273
0 -266 -2 -223 70 41 68 90 109 159 131 37 13 132 4 171 -16z"
      />
      <path
        d="M8670 2371 c-63 -14 -108 -38 -149 -81 l-41 -42 0 51 0 51 -110 0
-110 0 0 -460 0 -461 108 3 107 3 5 295 c4 242 8 301 21 330 55 121 202 157
288 71 56 -56 61 -89 61 -415 l0 -286 109 0 110 0 3 298 c3 283 4 299 25 338
77 144 249 151 324 14 11 -19 15 -99 19 -335 l5 -310 108 -3 107 -3 0 315 c0
195 -4 335 -11 368 -24 111 -90 197 -184 239 -39 18 -69 23 -135 23 -115 0
-185 -28 -259 -101 -46 -47 -56 -52 -63 -38 -23 41 -74 86 -121 109 -57 28
-158 40 -217 27z"
      />
      <path
        d="M10024 2370 c-98 -20 -187 -89 -222 -169 -24 -55 -21 -166 6 -217 41
-80 105 -121 271 -175 57 -19 114 -44 127 -56 34 -31 33 -91 -2 -124 -22 -21
-35 -24 -94 -24 -61 0 -72 3 -107 31 -21 17 -47 47 -58 66 l-19 36 -88 -44
c-48 -24 -88 -47 -88 -49 0 -19 47 -92 80 -125 162 -162 469 -137 577 47 20
34 27 63 31 119 10 160 -56 231 -292 310 -61 21 -111 44 -128 61 -73 70 10
163 119 134 39 -11 103 -68 115 -102 4 -11 26 -3 91 31 48 25 87 49 87 54 0
19 -44 78 -87 115 -77 68 -215 103 -319 81z"
      />
      <path
        d="M11904 2365 c-123 -27 -240 -105 -303 -203 -99 -156 -100 -378 -1
-532 99 -154 294 -239 484 -212 251 37 416 226 416 478 0 316 -281 538 -596
469z m239 -223 c235 -132 166 -497 -101 -529 -157 -18 -302 117 -302 282 0 79
27 144 84 201 91 90 210 107 319 46z"
      />
      <path
        d="M13065 2366 c-66 -16 -127 -49 -175 -93 l-40 -35 0 56 0 56 -110 0
-110 0 0 -630 0 -630 110 0 110 0 0 230 0 231 38 -37 c78 -77 220 -117 340
-95 147 27 256 110 322 245 147 300 -8 648 -315 705 -72 13 -106 13 -170 -3z
m137 -202 c109 -37 183 -146 183 -269 0 -56 -5 -81 -28 -127 -131 -267 -517
-172 -517 127 0 198 180 332 362 269z"
      />
      <path
        d="M5400 2048 c0 -221 4 -317 14 -353 42 -160 145 -250 319 -277 192
-29 366 49 437 197 42 87 50 162 50 468 l0 267 -110 0 -110 0 0 -273 c0 -311
-7 -353 -65 -412 -65 -65 -184 -69 -249 -8 -62 57 -66 78 -66 401 l0 292 -110
0 -110 0 0 -302z"
      />
      <path
        d="M6290 2343 c0 -5 84 -212 187 -461 l186 -453 95 3 95 3 173 420 c96
231 180 437 189 458 l15 37 -113 0 -112 0 -120 -310 c-66 -170 -123 -310 -126
-310 -3 0 -59 140 -125 310 l-119 310 -112 0 c-62 0 -113 -3 -113 -7z"
      />
    </g>
  </svg>
);

export default NuvemShop;
