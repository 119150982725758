import { RouteOrRedirectType } from './types';

import authRouting from '@plug/domains/auth/routing';
import dashboardRouting from '@plug/domains/dashboard/routing';
import onboardingRouting from '@plug/domains/onboarding/routing';
import organizationRouting from '@plug/domains/organization/routing';
import TermsAndConditions from '@plug/components/terms-and-conditions';

const routes: RouteOrRedirectType[] = [
  ...authRouting,
  ...dashboardRouting,
  ...onboardingRouting,
  ...organizationRouting,
  {
    component: TermsAndConditions,
    path: '/terms-and-conditions',
    privateRoute: true,
  },
  {
    redirect: true,
    from: '*',
    to: '/dashboard',
  },
];

export default routes;
