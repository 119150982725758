import gql from 'graphql-tag';

export default gql`
  mutation($input: SignInWithSSOInput!) @api(name: "auth") {
    signInWithSSO(input: $input) {
      token
      user {
        id
        avatar
        username
        email
      }
    }
  }
`;

export type SignInWithSSOVariables = {
  input: {
    provider: 'MaryKay';
    code: string;
    codeVerifier: string;
  };
};

export type SignInWithSSOResponse = {
  signInWithSSO: {
    token: string;
    user: User;
  };
};
