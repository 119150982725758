import styled from 'styled-components';
import { Calendar } from '../icons';

export const DateInput = styled.input`
  position: relative;

  background: white;
  border: 1px solid #d4d3d8;
  outline: none;
  color: #1c1637;

  font-size: 14px;

  border-radius: 8px;
  padding: 12px 22px 12px 50px;
`;

export const CustomCalendarSvgIcon = styled(Calendar)`
  position: absolute;
  left: 6px;
  top: 49%;
  transform: translateY(-50%);

  z-index: 1;
`;

interface DaysSelectorI {
  show: boolean;
}

export const DaysSelector = styled.div<DaysSelectorI>`
  position: absolute;

  z-index: 2;

  width: 260px;
  height: 180px;
  margin-top: 3px;
  margin-left: 3px;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: 1px solid #dcdcdc;

  background: white;

  pointer-events: ${({ show }): string => (show ? 'auto' : 'none')};
  opacity: ${({ show }): string => (show ? '1' : '0')};

  transition: all 0.1s linear;
`;

export const Day = styled.div`
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;

  cursor: pointer;

  //Styles for days on left in calendar
  &:nth-child(7n - 6) {
    border-left: none;
  }

  //Styles for the last 3 days in calendar
  &:nth-child(29),
  &:nth-child(30),
  &:nth-child(31) {
    border-bottom: none;
  }

  //Styles for the days in connors (1st, 7th, 29th)
  &:nth-child(1) {
    border-top-left-radius: 8px;
  }
  &:nth-child(7) {
    border-top-right-radius: 8px;
  }
  &:nth-child(29) {
    border-bottom-left-radius: 8px;
  }

  &:hover,
  &.selected {
    background: #d3d3d3;
  }
`;

export const DaySpaceForLeftBorder = styled.div`
  border-left: 1px solid #dcdcdc;
`;
