import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Tag: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 28 28'} width={28} height={28} {...props}>
    <path d="M26.8261 13.4425L14.5578 1.17398C14.2648 0.88104 13.9171 0.648669 13.5343 0.490133C13.1516 0.331597 12.7414 0.25 12.3271 0.25H4.98187C3.7269 0.25 2.52333 0.748535 1.63593 1.63593C0.748535 2.52333 0.25 3.7269 0.25 4.98187V12.327C0.25001 12.7413 0.331615 13.1515 0.490156 13.5342C0.648698 13.9169 0.881072 14.2647 1.17401 14.5576L13.4424 26.8261C14.034 27.4177 14.8364 27.75 15.673 27.75C16.5096 27.75 17.312 27.4177 17.9036 26.8261L26.8261 17.9036C27.4177 17.312 27.75 16.5096 27.75 15.673C27.75 14.8364 27.4177 14.0341 26.8261 13.4425ZM25.7109 16.7884L16.7883 25.7109C16.4925 26.0067 16.0913 26.1729 15.673 26.1729C15.2547 26.1729 14.8535 26.0067 14.5577 25.7109L2.28929 13.4425C2.14237 13.2963 2.02589 13.1225 1.94658 12.931C1.86728 12.7395 1.82673 12.5342 1.82729 12.327V4.98187C1.82825 4.14552 2.16092 3.3437 2.75231 2.75231C3.3437 2.16092 4.14552 1.82825 4.98187 1.82729H12.3271C12.5343 1.82671 12.7396 1.86724 12.9311 1.94652C13.1225 2.02581 13.2963 2.14228 13.4425 2.2892L25.7109 14.5578C25.8573 14.7042 25.9735 14.8781 26.0528 15.0695C26.1321 15.2608 26.1729 15.4659 26.1729 15.6731C26.1729 15.8802 26.1321 16.0853 26.0528 16.2767C25.9735 16.468 25.8573 16.642 25.7109 16.7884ZM6.75055 4.51987C6.97113 4.74046 7.12135 5.02151 7.1822 5.32747C7.24305 5.63343 7.21181 5.95057 7.09243 6.23877C6.97304 6.52698 6.77088 6.77332 6.5115 6.94663C6.25211 7.11994 5.94716 7.21244 5.63521 7.21244C5.32325 7.21244 5.0183 7.11994 4.75892 6.94663C4.49954 6.77332 4.29737 6.52698 4.17799 6.23877C4.05861 5.95057 4.02737 5.63343 4.08822 5.32747C4.14907 5.02151 4.29929 4.74046 4.51987 4.51987C4.66633 4.37339 4.84022 4.2572 5.03159 4.17793C5.22296 4.09866 5.42807 4.05786 5.63521 4.05786C5.84235 4.05786 6.04746 4.09866 6.23883 4.17793C6.4302 4.2572 6.60408 4.37339 6.75055 4.51987Z" />
  </SvgIcon>
);

export default Tag;
