import React, { useState } from 'react';

interface RecoveryPasswordContextI {
  step: RecoveryPasswordContextStepsI;
  setStep: (step: RecoveryPasswordContextStepsI) => void;
  values: RecoveryPasswordFormI;
  setValues: (values: RecoveryPasswordFormI) => void;
  addValues: (values: Partial<RecoveryPasswordFormI>) => void;
  resetContext: () => void;
}

const RecoveryPasswordContextInitialValues: RecoveryPasswordContextI = {
  step: 'EMAIL',
  setStep: () => null,
  values: {
    password: '',
    passwordConfirmation: '',
    email: '',
    code: '',
  },
  setValues: () => null,
  resetContext: () => null,
  addValues: () => null,
};

export const RecoveryPasswordContext = React.createContext<RecoveryPasswordContextI>(
  RecoveryPasswordContextInitialValues,
);

export const useRecoveryPasswordContext = (): RecoveryPasswordContextI =>
  React.useContext(RecoveryPasswordContext);

export const RecoveryPasswordContextProvider: React.FC = ({ children }) => {
  const [values, setValues] = useState(RecoveryPasswordContextInitialValues.values);
  const [step, setStep] = useState(RecoveryPasswordContextInitialValues.step);

  const addValues = (valuesToAdd: Partial<RecoveryPasswordFormI>): void => {
    setValues({
      ...values,
      ...valuesToAdd,
    });
  };

  const resetContext = (): void => {
    setValues(RecoveryPasswordContextInitialValues.values);
  };

  const providerValue = {
    values,
    resetContext,
    setValues,
    step,
    setStep,
    addValues,
  };

  return (
    <RecoveryPasswordContext.Provider value={providerValue}>
      {children}
    </RecoveryPasswordContext.Provider>
  );
};
