import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CaretLeft, Revenue, ShoppingBasket, Ticket } from '@plug/ui/icons';
import { Text, Table, Switch, Tooltip } from '@plug/ui';
import CardChart from '@plug/components/card-chart';
import { Views } from '../../containers/admin-payments';
import { formatBrazilPrice } from '@plug/helpers/normalize-currency';
import CapitalizeFirst from '@plug/helpers/capitalize-first';
import {
  GET_ORDER_LIST_BY_AFFILIATE_ID_AND_REFERENCE_MONTH,
  GetOrderListByAffiliateIdAndReferenceMonthInput,
  GetOrderListByAffiliateIdAndReferenceMonthQuery,
} from '../../graphql/queries/get-order-list-by-affiliate-id-and-reference-month';
import {
  PAID_AFFILIATE_COMMISSION,
  paidAffiliateCommissionVariables,
} from '../../graphql/mutations/paid-affiliate-commission';
import { ThemeStore } from '@plug/redux/store';
import useDownload from '@plug/hooks/use-download';
import { useAuthContext } from '@plug/contexts/auth-context';
import { LI_ALLOWED_ORGANIZATION_IDS } from '@plug/config/consts';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@gohubly/design-system';

interface Props {
  toggleView: Function;
  affiliateId: string;
  referenceMonth: string;
}

const AdminPaymentsAffiliate = ({
  toggleView,
  affiliateId,
  referenceMonth,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [orderListData, setOrderListData] = useState<any>([]);
  const theme = ThemeStore.useState(s => s);
  const { download } = useDownload();
  const { currentOrganization } = useAuthContext();

  const [getOrderListByAffiliateIdAndReferenceMonth, { data, loading }] = useLazyQuery<
    GetOrderListByAffiliateIdAndReferenceMonthQuery,
    GetOrderListByAffiliateIdAndReferenceMonthInput
  >(GET_ORDER_LIST_BY_AFFILIATE_ID_AND_REFERENCE_MONTH);

  const [paidAffiliateCommission, { loading: doingMutation }] = useMutation<
    paidAffiliateCommissionVariables,
    paidAffiliateCommissionVariables
  >(PAID_AFFILIATE_COMMISSION);

  const updatePaid = (id: string, paid: boolean): void => {
    setOrderListData(prevOrderList =>
      prevOrderList.map(order => (order.id === id ? { ...order, isPaid: paid } : order)),
    );
  };

  const handleTogglePaid = (original: any): void => {
    if (doingMutation) return;

    const { id } = original;
    const initialPaid = original.isPaid;

    paidAffiliateCommission({
      variables: {
        input: {
          affiliateCommissionSetPaid: [
            {
              _id: id,
              affiliateId,
              orderId: id,
              isPaid: !initialPaid,
            },
          ],
        },
      },
    })
      .then(() => {
        updatePaid(id, !initialPaid);
        toast.success(t('hubly-dashboard.payments.admin.views.affiliate.state.status.success'));
      })
      .catch(() => {
        toast.error(t('hubly-dashboard.payments.admin.views.affiliate.state.status.error'));
      });
  };

  const columns = [
    {
      Header: t('hubly-dashboard.payments.admin.views.affiliate.table.columns.order'),
      accessor: 'id',
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.affiliate.table.columns.client'),
      accessor: 'client',
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.affiliate.table.columns.date'),
      accessor: 'date',
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.affiliate.table.columns.value'),
      accessor: 'value',
      Cell: ({ value }: any): string => formatBrazilPrice(value),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.affiliate.table.columns.commission'),
      accessor: 'commission',
      Cell: ({ value }: any): string => formatBrazilPrice(value),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.affiliate.table.columns.status'),
      accessor: 'isPaid',
      Cell: ({ row }: any): JSX.Element | false =>
        Number(row.original.commission) > 0 && (
          <Tooltip
            arrow
            title={`${
              row.original.isPaid
                ? t('hubly-dashboard.payments.admin.commons.status.paid').toUpperCase()
                : t('hubly-dashboard.payments.admin.commons.status.pending').toUpperCase()
            } - ${t('hubly-dashboard.payments.admin.views.affiliate.table.body.status')} ${
              row.original.isPaid
                ? t('hubly-dashboard.payments.admin.commons.status.pending').toLowerCase()
                : t('hubly-dashboard.payments.admin.commons.status.paid').toLowerCase()
            }`}
          >
            <Switch
              checked={row.original.isPaid}
              onClick={(): void => handleTogglePaid(row.original)}
              size={20}
            />
          </Tooltip>
        ),
    },
  ];

  useEffect(() => {
    if (data?.getOrderListByAffiliateIdAndReferenceMonth?.orderList) {
      setOrderListData(data.getOrderListByAffiliateIdAndReferenceMonth.orderList);
    }
  }, [data]);

  useEffect(() => {
    getOrderListByAffiliateIdAndReferenceMonth({
      variables: {
        input: {
          affiliateId,
          referenceMonth,
        },
      },
    });
  }, [affiliateId, getOrderListByAffiliateIdAndReferenceMonth, referenceMonth]);

  if (loading) {
    return (
      <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      sx={{ gap: '12px', '@media(max-width: 768px)': { gap: '8px' } }}
    >
      <Flex justifyContent="space-between">
        <Flex
          alignItems="center"
          onClick={(): void => toggleView(Views.MONTHLY)}
          style={{ cursor: 'pointer' }}
        >
          <CaretLeft fill={theme.primary || '#3B24A8'} />

          <Text
            as="span"
            pl="24px"
            fontWeight="bold"
            color="#555069"
            fontSize="20px"
            style={{ cursor: 'pointer' }}
          >
            {data?.getOrderListByAffiliateIdAndReferenceMonth.name}
          </Text>

          <Text
            as="span"
            pl="14px"
            pt="3px"
            fontWeight="bold"
            color="#8C8A95"
            style={{ cursor: 'pointer' }}
          >
            {data?.getOrderListByAffiliateIdAndReferenceMonth.document}
          </Text>

          {data?.getOrderListByAffiliateIdAndReferenceMonth?.invoice && (
            <Text
              as="span"
              pl="14px"
              pt="3px"
              fontWeight="bold"
              color="#8C8A95"
              style={{ cursor: 'pointer' }}
              onClick={(): void =>
                download(
                  data?.getOrderListByAffiliateIdAndReferenceMonth?.invoice as string,
                  `NF_${data?.getOrderListByAffiliateIdAndReferenceMonth?.name}_${referenceMonth}`,
                )
              }
            >
              {t('hubly-dashboard.payments.admin.views.affiliate.invoice')}
            </Text>
          )}
        </Flex>

        <Text color="#555069" fontSize="20px" fontWeight="bold">
          {CapitalizeFirst(moment(referenceMonth, 'YYYY-MM').format('MMMM - YYYY'))}
        </Text>
      </Flex>

      <Flex
        width="100%"
        flexDirection="column"
        backgroundColor="#fff"
        sx={{ borderRadius: '12px', gap: '32px' }}
      >
        <Flex width="100%" p='24px 24px 0 24px' sx={{ gap: '12px' }}>
          <CardChart
            title={
              !LI_ALLOWED_ORGANIZATION_IDS.includes(currentOrganization.id)
                ? t('hubly-dashboard.payments.admin.views.affiliate.cards.orders')
                : 'Resgates aprovados'
            }
            loading={false}
            titleRightContent={t(
              'hubly-dashboard.payments.admin.views.affiliate.commons.month-info',
              {
                month: CapitalizeFirst(moment(referenceMonth, 'YYYY-MM').format('MMMM')),
              },
            )}
            value={data?.getOrderListByAffiliateIdAndReferenceMonth.orders}
            icon={<ShoppingBasket fill={theme.primary || '#3B24A8'} width={27} height={27} />}
            iconBackgroundColor="#EBE9F6"
            backgroundColor="#fff"
          />

          <CardChart
            title={t('hubly-dashboard.payments.admin.views.affiliate.cards.revenue')}
            loading={false}
            titleRightContent={t(
              'hubly-dashboard.payments.admin.views.affiliate.commons.month-info',
              {
                month: CapitalizeFirst(moment(referenceMonth, 'YYYY-MM').format('MMMM')),
              },
            )}
            value={
              formatBrazilPrice(
                Number(data?.getOrderListByAffiliateIdAndReferenceMonth.revenue.toFixed(2)),
              ) ?? 0
            }
            icon={<Revenue fill={theme.primary || '#3B24A8'} width={22} height={32} />}
            iconBackgroundColor="#EBE9F6"
            backgroundColor="#fff"
          />

          <CardChart
            title={t('hubly-dashboard.payments.admin.views.affiliate.cards.commission')}
            loading={false}
            titleRightContent={t(
              'hubly-dashboard.payments.admin.views.affiliate.commons.month-info',
              {
                month: CapitalizeFirst(moment(referenceMonth, 'YYYY-MM').format('MMMM')),
              },
            )}
            value={
              formatBrazilPrice(
                Number(data?.getOrderListByAffiliateIdAndReferenceMonth.commission.toFixed(2)),
              ) ?? 0
            }
            icon={<Ticket fill={theme.primary || '#3B24A8'} width={24.75} height={24.75} />}
            iconBackgroundColor="#EBE9F6"
            backgroundColor="#fff"
          />
        </Flex>

        {data?.getOrderListByAffiliateIdAndReferenceMonth.orderList && (
          <Table responsive data={orderListData} columns={columns} padding="0px" />
        )}
      </Flex>
    </Flex>
  );
};

export default AdminPaymentsAffiliate;
