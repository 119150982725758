import React, { useEffect, useState } from 'react';
import * as css from './checkout-login.styles';
import { Typography } from '@gohubly/design-system';
import { Flex } from 'rebass';
import { Formik } from 'formik';
import * as yup from 'yup';
import { YUP_VALIDATIONS } from '@plug/helpers/yup-helpers';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CUSTOMER,
  CustomerResponse,
  CustomerVariables,
} from '../../../../graphql/queries/customer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import { CHECKOUT_PATH } from '../../../../routing';
import { useAuthContext } from '@plug/contexts/auth-context';
import { Button, TextField } from '@monitfy/ds';
import { LOCAL_CART_INFO_KEY } from '@plug/config/consts';
import {
  AttachCustomerResponse,
  AttachCustomerVariables,
  ATTACH_CUSTOMER,
} from '../../../../graphql/mutations/checkout/attach-customer';
import { CART } from '../../../../graphql/queries/checkout/cart';
import useLocalStorage from '@plug/hooks/use-local-storage';
import { useCheckoutCartContext } from '../../../../contexts/CheckoutCartContext';
import { omitTypeName } from '@plug/helpers/omitTypeName';

const CheckoutLogin = () => {
  const { get } = useLocalStorage();
  const [customerEmail, setCustomerEmail] = useState(
    get<string>('customerEmail', { json: false }) ?? '',
  );

  const { currentOrganization } = useAuthContext();
  const { loadCustomer } = useCheckoutContext();
  const { cart, refetchCart } = useCheckoutCartContext();

  const { push } = useHistory();

  const params = new URLSearchParams(window.location.search);

  const [attach] = useMutation<AttachCustomerResponse, AttachCustomerVariables>(ATTACH_CUSTOMER, {
    refetchQueries: [CART],
  });

  const [findCustomer, { loading, variables }] = useLazyQuery<CustomerResponse, CustomerVariables>(
    CUSTOMER,
    {
      onCompleted: async data => {
        let customer: Customer = { email: variables.email };

        if (data.customer) {
          const { profile } = data.customer;
          customer = { ...omitTypeName(profile) };

          loadCustomer(data?.customer?.profile);
          toast.success('Oba! Encontramos esse cliente, agora iniciaremos o carrinho.');
        }

        await attach({
          variables: {
            cartId: get<{ id: string }>(LOCAL_CART_INFO_KEY, { json: true })?.id,
            data: {
              profile: {
                email: variables.email,
                ...customer,
              },
            },
          },
        });

        push(`${CHECKOUT_PATH}/catalog`.replace(':organizationSlug', currentOrganization.slug));
      },
    },
  );

  const handleSubmit = (values: Record<string, any>) => {
    if (values?.email) {
      localStorage.setItem('customerEmail', values?.email);

      findCustomer({
        variables: {
          email: values?.email,
        },
      });
    }
  };

  useEffect(() => {
    if (params.has('new') && params.get('new') === 'true') {
      localStorage.removeItem('customerEmail');
      localStorage.removeItem(LOCAL_CART_INFO_KEY);

      setCustomerEmail(undefined);

      refetchCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{ email: customerEmail ?? '' }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .email('E-mail inválido.')
          .required('Esse campo é obrigatório.'),
      })}
    >
      {({ touched, errors, values, isValid, handleChange, handleBlur, submitForm }) => (
        <css.Container marginY="96px" marginX="auto">
          <Flex flexDirection="column">
            <Typography size="48" lineHeight="48" color="textNeutralSubtle" textAlign="left">
              Checkout
            </Typography>

            <Typography
              size="18"
              lineHeight="24"
              color="textNeutralSubtle"
              fontWeight={400}
              textAlign="left"
            >
              Entre com seu e-mail para continuar
            </Typography>
          </Flex>

          <css.Form width="100%" marginTop="36px">
            <TextField
              name="email"
              value={values?.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={touched.email ? errors?.email?.toString() : ''}
              resizing="fluid"
            />

            <Button
              loading={loading}
              resizing="fluid"
              disabled={!isValid || loading}
              onClick={submitForm}
            >
              Continuar
            </Button>
          </css.Form>
        </css.Container>
      )}
    </Formik>
  );
};

export default CheckoutLogin;
