import gql from 'graphql-tag';

export const GET_ORGANIZATION_REVENUE = gql`
  query getOrganizationRevenue($input: GetOrganizationRevenueInput) {
    getOrganizationRevenue(input: $input) {
      amount
      commission
    }
  }
`;

export type getOrganizationRevenueVariables = {
  input: {
    name?: string;
    startDate?: Date;
    endDate?: Date;
    status?: string;
    affiliateIds?: string[];
  };
};

export type getOrganizationRevenueQuery = {
  getOrganizationRevenue: {
    amount: number;
    commission: number;
  };
};
