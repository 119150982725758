import gql from 'graphql-tag';

export const DOWNLOAD_FINATIAL_CONCILIATION_CSV_BY_REFERENCE_MONTH = gql`
  query downloadFinantialConciliationCSVByReferenceMonth(
    $input: downloadFinantialConciliationCSVByReferenceMonthInput!
  ) {
    downloadFinantialConciliationCSVByReferenceMonth(input: $input)
  }
`;

export type downloadFinantialConciliationCSVByReferenceMonthQuery = {
  downloadFinantialConciliationCSVByReferenceMonth: string;
};

export type downloadFinantialConciliationCSVByReferenceMonthVariables = {
  input: {
    referenceMonth: string;
  };
};
