import React from 'react';

const SolidBranch: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="52" viewBox="0 0 24 53" fill="none">
      <path
        d="M1 0V28C1 35.4551 1 39.1826 2.21793 42.1229C3.84183 46.0434 6.95662 49.1582 10.8771 50.7821C13.8174 52 17.5449 52 25 52V52"
        stroke="#1E1A34"
      />
    </svg>
  );
};

export default SolidBranch;
