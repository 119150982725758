import { useQuery } from '@apollo/client';
import { Button } from '@gohubly/design-system';
import { useNotificationContext } from '@plug/contexts/NotificationContext';
import { useAuthContext } from '@plug/contexts/auth-context';
import {
  GET_USER_PENDENCIES,
  GetUserPendenciesPayload,
} from '@plug/domains/organization/graphql/queries/get-user-pendencies';
import useLocalStorage from '@plug/hooks/use-local-storage';
import Toast from '@plug/ui2/toast';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const MaryKayAlert = () => {
  const { get } = useLocalStorage();
  const { addNotifications, show } = useNotificationContext();
  const { disableOrganization } = useAuthContext();

  useEffect(() => {
    disableOrganization(['all']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useQuery<GetUserPendenciesPayload>(GET_USER_PENDENCIES, {
    onCompleted: data => {
      if (data?.getUserPendencies?.length) {
        const isNewUser = get('isNewUser', { json: false }) === 'true';
        const braspagData = data.getUserPendencies.find(item => item.pendency === 'Braspag');
        const showToast = get('showToast', { json: false }) === 'true';
        const display = braspagData.metadata.find(item => item.key === 'display')?.value === 'true';

        if (!braspagData) return;

        const status: any = braspagData.metadata.find(item => item.key === 'status')?.value;

        if (!status) {
          disableOrganization(['all']);

          return handlePendingRequest();
        }

        if (!status) return;

        const parsedStatus = status.replace(/[^\d.-]+/g, '');

        if (parsedStatus !== '2') disableOrganization(['all']);
        else disableOrganization([]);

        if (display) {
          switch (parsedStatus) {
            case '1':
              return handlePendingRequest();
            case '2':
              return handleSuccessfullRequest();
            case '3':
            case '4':
              return handleUnsuccessfulRequest();
            default:
              return;
          }
        }
      }
    },
  });

  const handlePendingRequest = () => {
    toast(
      props => (
        <Toast
          {...props}
          title="Enviado com sucesso!"
          message="Obrigado, seu cadastro já está em análise e logo responderemos."
        />
      ),
      {
        type: 'success',
        position: 'top-right',
      },
    );

    addNotifications([
      {
        icon: <></>,
        message: 'Seu cadastro foi enviado para análise.',
        at: new Date(),
      },
    ]);
  };

  const handleSuccessfullRequest = () => {
    localStorage.removeItem('isNewUser');

    toast(
      props => (
        <Toast
          {...props}
          title="Cadastro aprovado!"
          message="Parabéns, seu cadastro foi aprovado com sucesso! Agora personalize os dados da sua loja online."
        />
      ),
      {
        type: 'success',
        position: 'top-right',
      },
    );

    addNotifications([
      {
        icon: (
          <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="7" width="32" height="32" rx="16" fill="#F1F1F7" />
            <path
              d="M17.3337 22.3335H13.3337M14.667 25.0002H13.3337M18.667 19.6668H13.3337M21.3337 19.5335V26.4668C21.3337 27.5869 21.3337 28.147 21.1157 28.5748C20.9239 28.9511 20.618 29.2571 20.2416 29.4488C19.8138 29.6668 19.2538 29.6668 18.1337 29.6668H13.867C12.7469 29.6668 12.1868 29.6668 11.759 29.4488C11.3827 29.2571 11.0767 28.9511 10.885 28.5748C10.667 28.147 10.667 27.5869 10.667 26.4668V19.5335C10.667 18.4134 10.667 17.8533 10.885 17.4255C11.0767 17.0492 11.3827 16.7432 11.759 16.5515C12.1868 16.3335 12.7469 16.3335 13.867 16.3335H18.1337C19.2538 16.3335 19.8138 16.3335 20.2416 16.5515C20.618 16.7432 20.9239 17.0492 21.1157 17.4255C21.3337 17.8533 21.3337 18.4134 21.3337 19.5335Z"
              stroke="#616473"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="20" y="1" width="18" height="18" rx="9" fill="#167E32" />
            <path
              d="M31.6663 8L27.9997 11.6667L26.333 10"
              stroke="white"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="20" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
          </svg>
        ),
        message: 'Seu cadastro foi aprovado com sucesso!',
        at: new Date(),
      },
    ]);
  };

  const handleUnsuccessfulRequest = () => {
    toast(
      props => (
        <Toast
          {...props}
          title="Precisamos da sua atenção."
          message="Parece que não conseguimos aprovar o seu cadastro. Clique aqui para mais informações."
          footer={
            <Button hierarchy="tonalFilled" onClick={show}>
              Saiba mais
            </Button>
          }
        />
      ),
      {
        type: 'error',
        position: 'top-right',
      },
    );

    addNotifications([
      {
        icon: (
          <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="7" width="32" height="32" rx="16" fill="#F1F1F7" />
            <path
              d="M17.3337 22.3335H13.3337M14.667 25.0002H13.3337M18.667 19.6668H13.3337M21.3337 19.5335V26.4668C21.3337 27.5869 21.3337 28.147 21.1157 28.5748C20.9239 28.9511 20.618 29.2571 20.2416 29.4488C19.8138 29.6668 19.2538 29.6668 18.1337 29.6668H13.867C12.7469 29.6668 12.1868 29.6668 11.759 29.4488C11.3827 29.2571 11.0767 28.9511 10.885 28.5748C10.667 28.147 10.667 27.5869 10.667 26.4668V19.5335C10.667 18.4134 10.667 17.8533 10.885 17.4255C11.0767 17.0492 11.3827 16.7432 11.759 16.5515C12.1868 16.3335 12.7469 16.3335 13.867 16.3335H18.1337C19.2538 16.3335 19.8138 16.3335 20.2416 16.5515C20.618 16.7432 20.9239 17.0492 21.1157 17.4255C21.3337 17.8533 21.3337 18.4134 21.3337 19.5335Z"
              stroke="#616473"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="20" y="1" width="18" height="18" rx="9" fill="#EB0045" />
            <path
              d="M30.6663 8.3335L27.333 11.6668M27.333 8.3335L30.6663 11.6668"
              stroke="white"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="20" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
          </svg>
        ),
        message:
          'Parece que não conseguimos aprovar o seu cadastro por aqui. Entre em contato com nossa Central de Relacionamento {{ first }} (capitais e regiões metropolitanas) ou {{ second }} (demais localidades) e nosso time irá ajudar.',
        interfere: {
          first: '4003-4620',
          second: '0800-163113',
        },
        at: new Date(),
      },
    ]);
  };

  return <></>;
};

export default MaryKayAlert;
