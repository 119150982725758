import React, { FC } from 'react';
import { ImageWheelProps } from './image-wheel.types';
import { Flex } from 'rebass';
import { Image } from '@monitfy/ds';
import Skeleton from 'react-loading-skeleton';
import * as css from './image-wheel.styles';

const ImageWheel: FC<ImageWheelProps> = ({ images = [], index = 0, onSelect }) => {
  return (
    <Flex flexDirection="column" style={{ gap: '24px' }}>
      {images.map((path, idx) => (
        <css.ImageContainer key={path} active={index === idx} onClick={() => onSelect(idx)}>
          <Image
            width={82}
            height={82}
            src={path}
            alt={`imagem ${idx + 1}`}
            skeleton={<Skeleton width={82} height={82} />}
          />
        </css.ImageContainer>
      ))}
    </Flex>
  );
};

export default ImageWheel;
