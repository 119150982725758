import gql from 'graphql-tag';

export const SEND_TERMS_AND_CONDITIONS = gql`
  mutation sendTermsAndConditions($input: SendTermsAndConditionsInput!) {
    sendTermsAndConditions(input: $input)
  }
`;

export type SendTermsAndConditionsMutation = {
  sendTermsAndConditions: boolean;
};

export type SendTermsAndConditionsVariables = {
  input: {
    termsAndConditionsId: string;
  };
};
