import { gql } from '@apollo/client';

export const ATTACH_MARKETING_DATA = gql`
  mutation($cartId: String!, $data: MarketingDataInput!) @api(name: "cart") {
    attachMarketingData(cartId: $cartId, data: $data) {
      id
    }
  }
`;

export type AttachMarketingDataVariables = {
  cartId: string;
  data: {
    utmSource: string;
    utmCampaign: string;
  };
};

export type AttachMarketingDataResponse = {
  attachCustomer: Cart;
};
