import gql from 'graphql-tag';

export const UPDATE_CART = gql`
  mutation($id: String!, $items: [UpdateCartItemsInput]!) @api(name: "cart") {
    updateCart(id: $id, items: $items) {
      id
      items {
        id
        productId
        name
        price
        listPrice
        quantity
        imageUrl
        sku
        metadata {
          key
          value
        }
      }
      value
    }
  }
`;

export type UpdateCartVariables = {
  id: string;
  items: {
    id: string;
    quantity: number;
    metadata: {
      key: string;
      value: string;
    }[];
  }[];
};

export type UpdateCartResponse = {
  cart?: Cart;
};
