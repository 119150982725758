import React, { FC, useMemo } from 'react';
import { Flex } from 'rebass';
import { NotificationProps } from './notification.types';
import { Typography } from '@gohubly/design-system';

const Notification: FC<NotificationProps> = ({ icon, message, interfere, at }) => {
  const parsedMessage = useMemo(() => {
    if (interfere && typeof message === 'string') {
      const keys = Object.keys(interfere);

      return message
        .replace(/\s+(?=[^{\}]*\})/g, '')
        .split(/\{{(.*?)\}}/)
        .map(text => {
          if (keys.includes(text)) {
            return (
              <Typography
                key={text}
                size="XXXS"
                fontWeight={600}
                color="neutralLow"
                lineHeight="16"
              >
                {interfere[text]}
              </Typography>
            );
          }

          return text;
        });
    }

    return <>{message}</>;
  }, [interfere, message]);

  return (
    <Flex style={{ gap: '20px' }}>
      {icon}

      <Flex flex={1} flexDirection="column" style={{ gap: '4px' }}>
        <Typography size="XXXS" fontWeight={400} color="neutralLow" lineHeight="16">
          {parsedMessage}
        </Typography>

        <Typography color="neutralHighDark" size="XXXS" fontWeight={400}>
          Agora
        </Typography>
      </Flex>
    </Flex>
  );
};

export default Notification;
