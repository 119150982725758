import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const ShoppingBasket: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 30 26'} width={30} height={26} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.54168 6.48521L11.8011 0.747906C12.4659 0.0102546 13.7124 1.07575 12.9645 1.89536L8.82074 6.48521H21.1598L16.9523 1.89536C16.2875 1.07575 17.534 0.0102546 18.1989 0.747906L23.4582 6.48521H28.5873C29.3352 6.48521 30 7.1409 30 7.87855V10.5833C30 11.3209 29.3352 11.9766 28.5873 11.9766H27.552L25.8456 22.3038C25.5963 24.107 24.0174 25.5003 22.106 25.5003H7.89548C5.98412 25.5003 4.40517 24.107 4.15586 22.3038L2.44948 11.9766H1.41274C0.66482 11.9766 0 11.3209 0 10.5833L0 7.87855C0 7.05893 0.66482 6.48521 1.41274 6.48521H6.54168ZM4.14477 11.9766H25.8567L24.2667 22.0579C24.1005 23.0415 23.1863 23.8611 22.106 23.8611H7.89548C6.81514 23.8611 5.90102 23.0415 5.73481 22.0579L4.14477 11.9766ZM28.3379 8.12443H1.66205V10.3374H28.3379V8.12443ZM15.7888 15.1736C15.7888 14.1081 14.1268 14.1081 14.1268 15.1736V20.0913C14.1268 21.1568 15.7888 21.1568 15.7888 20.0913V15.1736ZM8.39212 15.4193C8.14281 14.3538 9.80486 14.0259 10.0542 15.0095L11.0514 19.8452C11.3007 20.9107 9.63866 21.2385 9.47245 20.173L8.39212 15.4193ZM18.9472 19.8452C18.6979 20.9107 20.3599 21.2385 20.5261 20.173L21.6065 15.4193C21.8558 14.3538 20.1937 14.0259 19.9444 15.0095L18.9472 19.8452Z"
    />
  </SvgIcon>
);

export default ShoppingBasket;
