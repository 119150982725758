import { MK_USA_POC_WHITELABEL_IDS } from '@plug/config/consts';
import getCurrentOrganization from './get-current-organization';

export const currencyHelper = () => {
  const currentOrganization = getCurrentOrganization();

  if (currentOrganization && MK_USA_POC_WHITELABEL_IDS.includes(currentOrganization.id)) {
    return {
      currency: 'USD',
      locale: 'en-US',
    };
  }

  return {
    currency: 'BRL',
    locale: 'pt-BR',
  };
};
