import React from 'react';
import pkg from '../../../package.json';
import { CACHE_MINIMUM_UPDATE_INTERVAL, CACHE_UPDATE_DATE_STORAGE_KEY } from '@plug/config/consts';
import cookie from 'js-cookie';

const semverGreaterThan = (versionA: string, versionB: string): boolean => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster: React.FC = ({ children }) => {
  React.useEffect(() => {
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');
    fetch('/meta.json', {
      method: 'GET',
      headers,
    })
      .then(response => response.json())
      .then(meta => {
        const latest = meta.version;
        const current = pkg.version;
        // const lastUpdate = localStorage.getItem(CACHE_UPDATE_DATE_STORAGE_KEY);
        const lastUpdate = cookie.get(CACHE_UPDATE_DATE_STORAGE_KEY);
        const updateThreshold = Date.now() - CACHE_MINIMUM_UPDATE_INTERVAL;
        const shouldReload = !lastUpdate || +lastUpdate < updateThreshold;
        const versionGreaterThan = semverGreaterThan(latest, current);
        if (versionGreaterThan && shouldReload) {
          console.info(`New version available - ${latest}. Refreshing page...`);
          caches &&
            caches.keys().then(names => {
              names.forEach(name => caches.delete(name));
            });
          window.location.reload();
          // localStorage.setItem(CACHE_UPDATE_DATE_STORAGE_KEY, String(Date.now()));
          cookie.set(CACHE_UPDATE_DATE_STORAGE_KEY, String(Date.now()), {
            domain: `.${window.location.hostname}`,
          });
        } else if (versionGreaterThan && !shouldReload) {
          console.warn(`Version loop warning! user version: ${current} latest version: ${latest}`);
        } else {
          console.info(`You already have the latest version - ${latest}. No refresh is needed`);
        }
      });
  }, []);
  return <>{children}</>;
};

export default CacheBuster;
