import React, { useState } from 'react';

interface CreateOrganizationContextI {
  step: CreateOrganizationContextISteps;
  setStep: (step: CreateOrganizationContextISteps) => void;
  values: CreateOrganizationFormI;
  incompleteFlow: boolean;
  setIncompleteFlow: (incompleteFlow: boolean) => void;
  setValues: (values: CreateOrganizationFormI) => void;
  addValues: (values: Partial<CreateOrganizationFormI>) => void;
  resetContext: () => void;
}

const CreateOrganizationContextInitialValues: CreateOrganizationContextI = {
  step: 'PASSWORD',
  setStep: () => null,
  values: {
    password: '',
    passwordConfirmation: '',
    email: '',
    name: '',
    birthday: '',
    gender: '',
    organization_name: '',
    organization_type: '',
    cnpj: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighbourhood: '',
    state: '',
    city: '',
    contact_email: '',
  },
  incompleteFlow: false,
  setIncompleteFlow: () => null,
  setValues: () => null,
  resetContext: () => null,
  addValues: () => null,
};

export const CreateOrganizationContext = React.createContext<CreateOrganizationContextI>(
  CreateOrganizationContextInitialValues,
);

export const useCreateOrganizationContext = (): CreateOrganizationContextI =>
  React.useContext(CreateOrganizationContext);

export const CreateOrganizationContextProvider: React.FC = ({ children }) => {
  const [values, setValues] = useState(CreateOrganizationContextInitialValues.values);
  const [step, setStep] = useState(CreateOrganizationContextInitialValues.step);
  const [incompleteFlow, setIncompleteFlow] = useState(false);

  const addValues = (valuesToAdd: Partial<CreateOrganizationFormI>): void => {
    setValues({
      ...values,
      ...valuesToAdd,
    });
  };

  const resetContext = (): void => {
    setValues(CreateOrganizationContextInitialValues.values);
  };

  const providerValue = {
    values,
    resetContext,
    setValues,
    step,
    setStep,
    incompleteFlow,
    setIncompleteFlow,
    addValues,
  };

  return (
    <CreateOrganizationContext.Provider value={providerValue}>
      {children}
    </CreateOrganizationContext.Provider>
  );
};
