import { Typography, theme } from '@gohubly/design-system';
import React, { FC } from 'react';
import { Flex } from 'rebass';
import { ToastProps } from './toast.types';
import { ThemeProvider } from 'styled-components';

const Toast: FC<ToastProps> = ({ title, message, toastProps: { children }, footer }) => {
  return (
    <ThemeProvider theme={theme}>
      <Flex justifyContent="space-between" backgroundColor="white">
        <Flex flexDirection="column" alignItems="flex-start" style={{ gap: '16px' }}>
          <Flex flexDirection="column" style={{ gap: '4px' }}>
            {title && (
              <Typography size="XXS" color="neutralLowDark">
                {title}
              </Typography>
            )}

            <Typography size="XXXS" color="neutralLow" fontWeight={400} lineHeight="16">
              {message ?? children}
            </Typography>
          </Flex>

          {footer && footer}
        </Flex>
      </Flex>
    </ThemeProvider>
  );
};

export default Toast;
