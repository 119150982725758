import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@gohubly/design-system';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Flex } from '@plug/ui2/rebass';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '@plug/config/env';

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 340px;
  position: relative;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Description = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`;

const DropdownContent = styled.div.attrs<{ isOpen: boolean }>(({ isOpen }) => ({
  style: {
    display: isOpen ? 'block' : 'none',
  },
}))<{ isOpen: boolean }>`
  position: absolute;
  top: 78px;
  width: 20rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  z-index: 1;
`;

const CustomDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('PT');
  const { i18n } = useTranslation();

  const supportedLanguages = SUPPORTED_LANGUAGES;

  const selectedLanguageItem = supportedLanguages.find(item => item.short === selectedLanguage);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = useCallback(
    (language: string) => {
      setSelectedLanguage(language);
      i18n.changeLanguage(supportedLanguages.find(item => item.short === language).i18n);
      setIsOpen(false);
    },
    [i18n, supportedLanguages],
  );

  useEffect(() => {
    changeLanguage(supportedLanguages.find(item => item.i18n === i18n.resolvedLanguage)?.short);
  }, [changeLanguage, i18n.resolvedLanguage, supportedLanguages]);

  return (
    <div>
      <DropdownContainer onClick={toggleDropdown}>
        <Image
          src={selectedLanguageItem?.image}
          alt="Description"
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            padding: '4px',
            margin: '4px',
          }}
        />
        <Description style={{ fontSize: '16px', fontWeight: '401' }}>
          {selectedLanguage}
        </Description>
        {isOpen ? (
          <FaChevronUp style={{ marginRight: '8px' }} />
        ) : (
          <FaChevronDown style={{ marginRight: '8px' }} />
        )}
      </DropdownContainer>
      <DropdownContent isOpen={isOpen} style={{ width: 'auto', minWidth: '135px' }}>
        <Flex style={{ flexDirection: 'column', gap: '10px', padding: '11px' }}>
          {supportedLanguages.map((item, index) => (
            <Flex
              key={index}
              style={{ gap: '16px', alignItems: 'center' }}
              onClick={() => changeLanguage(item.short)}
            >
              <img src={item.image} alt="small" style={{ width: '30px', height: '30px' }} />
              <Flex flexDirection="column" justifyContent="center" style={{ gap: '1px' }}>
                <Typography color="neutralLowDark" size="XXS" lineHeight="LG">
                  {item.description}
                </Typography>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </DropdownContent>
    </div>
  );
};

export default CustomDropdown;
