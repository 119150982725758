import React, { useMemo } from 'react';
import { Box, Flex } from 'rebass';
import { Route } from 'react-router';

import { AnimatedContainer } from './style';

import { Spacer } from '@plug/ui/index';
import { LayoutProps } from '../route/types';

import Header from '@plug/components/header';
import isDesktop from '@plug/helpers/is-desktop';

const MainLayout: LayoutProps = ({ children, title, hideHeaderTitle, routes, location }) => {
  const fromLogin = useMemo(() => !!location?.state?.loginAnimation, [location]);

  return (
    <>
      <Box backgroundColor="#F1F3F9" minHeight="100%" width="100%">
        <Header />
        <Spacer py={isDesktop ? '64px' : '0'}>
          <Flex width="100%" maxWidth="1200px" margin="auto">
            {routes.map((route: any, i: any) => (
              <Route
                key={i}
                path={route.path}
                render={(props: any) => <route.component {...props} routes={route.routes} />}
              />
            ))}
          </Flex>
        </Spacer>
      </Box>
    </>
  );
};

export default MainLayout;
