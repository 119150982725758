import chroma from 'chroma-js';

export const getContrastingColor = (color: string) => {
  const isValidColor = !!chroma.valid(color);

  const finalColor = isValidColor ? color : '#1e1a34';

  const colorLuminance = chroma(finalColor).luminance();

  return colorLuminance > 0.5 ? '#1e1a34' : '#fff';
};
