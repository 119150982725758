import React from 'react';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';

import Tooltip from '../admin-payments-graph-tooltip';
import { ColouredBall } from '../payment-info-item';
import { formatBrazilPrice } from '@plug/helpers/normalize-currency';
import { ThemeStore } from '@plug/redux/store';
import { useTranslation } from 'react-i18next';

const Container = styled(Flex)`
  height: 250px;
`;

const BarGroup = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  &:not(::first-of-type),
  &:not(::last-of-type) {
    padding: 0px 10px;
  }

  &:nth-child(n):nth-child(-n + 3) div:nth-child(0n + 2) {
    left: -10px;

    &::after {
      left: 7.2%;
    }
  }

  &:nth-last-child(-n + 3) div:nth-child(0n + 2) {
    right: -10px;

    &::after {
      left: 93%;
    }
  }

  &:last-child div:nth-child(0n + 2) {
    right: -10px;
    left: inherit !important;

    &::after {
      left: 93% !important;
    }
  }
`;

interface GraphPropsI {
  revenue: string | number;
  commission: string | number;
  day: string | number;
}

interface Props {
  data: GraphPropsI[];
}

interface BarProps {
  revenueHeight: number;
  commissionHeight: number;
}

const FinantialGraph = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  if (data.length < 1) return <></>;

  const theme = ThemeStore.useState(s => s);

  const highestValue = Number(
    data.reduce((max, info) => (info.revenue > max ? info.revenue : max), data[0].revenue),
  );

  const getRevenueHeight = (revenueValue: string | number): number =>
    (Number(revenueValue) * 100) / highestValue;

  const getCommissionHeight = (commissionValue: string | number): number =>
    (Number(commissionValue) * 100) / highestValue;

  const Bar = styled(Box)<BarProps>`
    //Revenue
    position: relative;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    height: ${({ revenueHeight }): number => revenueHeight}%;
    max-height: 100%;
    width: 15px;

    background: ${props => props.theme.primary || '#3b24a8'};

    cursor: pointer;

    //Commission
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;

      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      height: ${({ commissionHeight }): number => commissionHeight}%;
      max-height: 99%;
      width: 15px;

      background: #ffd500;

      cursor: pointer;
    }

    &:hover {
      & + .tooltip {
        opacity: 1;
      }
    }
  `;

  return (
    <Container justifyContent="space-around" mb="46px">
      {data.map((info, i) => {
        const commissionHeight = getCommissionHeight(info.commission);
        const revenueHeight = getRevenueHeight(info.revenue);

        return (
          <BarGroup key={i}>
            <Bar commissionHeight={commissionHeight} revenueHeight={revenueHeight} />
            <Tooltip className="tooltip" bottomOffset={revenueHeight}>
              <Flex justifyContent="space-between" pb="8px">
                <Flex alignItems="center">
                  <ColouredBall size="6px" color={theme.primary || '#3B24A8'} />
                  <Text pl="4px" fontSize="10px" color="#666372">
                    {t('hubly-dashboard.payments.admin.commons.info.revenue')}
                  </Text>
                </Flex>
                <Text color="#1C1637" fontWeight="bold" pl="8px" fontSize="10px">
                  {formatBrazilPrice(Number(info.revenue))}
                </Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Flex alignItems="center">
                  <ColouredBall size="6px" color="#ffd500" />
                  <Text pl="4px" fontSize="10px" color="#666372">
                    {t('hubly-dashboard.payments.admin.commons.info.pay')}
                  </Text>
                </Flex>
                <Text color="#1C1637" fontWeight="bold" pl="8px" fontSize="10px">
                  {formatBrazilPrice(Number(info.commission))}
                </Text>
              </Flex>
            </Tooltip>
            <Text color="#555069" fontSize="12px" pt="4px">
              {info.day}
            </Text>
          </BarGroup>
        );
      })}
    </Container>
  );
};

export default FinantialGraph;
