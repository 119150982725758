import Drawer_ from '@material-ui/core/Drawer';
import styled from 'styled-components';

const Drawer = styled(Drawer_)`
  .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
`;

export default Drawer;
