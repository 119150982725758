import gql from 'graphql-tag';

export const PRODUCTS = gql`
  query($input: ProductsSearchInput) @api(name: "catalog") {
    products(input: $input) {
      id
      name
      description
      available
      attributes {
        key
        value
        items {
          key
          value
        }
      }
      specifications {
        key
        value
        items {
          key
          value
        }
      }
      skus {
        id
        name
        images
        bestPrice
        price
        availableQuantity
        available
        attributes {
          key
          value
        }
      }
    }
  }
`;

export type ProductsVariables = {
  input: ProductsSearchInput;
};

export type ProductsReponse = {
  products: Product[];
};
