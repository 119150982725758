import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';

import { inter } from '@plug/ui/fonts';
import { CaretDown, Check } from '@plug/ui/icons';
import isDesktop from '@plug/helpers/is-desktop';
import Spinner from 'react-svg-spinner';
import { ThemeStore } from '@plug/redux/store';

interface AccordionItem {
  active: boolean;
  content?: any;
}

const BadgeBase = css`
  display: inline-flex;
  ${inter('s14')};
  font-style: normal;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-height: 2rem;
  min-width: 2rem;
  margin-right: 0.75rem;
  letter-spacing: 0.001em;
`;

const BadgeIndex = styled.div<AccordionItem>`
  ${BadgeBase}
  color: ${(props: AccordionItem) => `${props.active ? '#FFFFFF' : '#AAA8B3'}`};
  border: ${props => `1px solid ${props.active ? props.theme.primary || '#3B24A8' : '#EAE9EC'}`};
  background: ${props => (props.active ? props.theme.primary || '#3B24A8' : '#FBFCFD')};
`;

const BadgeCheck = styled.div`
  ${BadgeBase}
  background: #18C698;
`;

const AccordionItemWrapper = styled.div`
  min-height: '96px';
  background: #f8f9fc;
`;

const AccordionItemName = styled.span`
  ${inter('s18')};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #1c1637;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const AccordionCaretWrapper = styled.span<AccordionItem>`
  transform: ${(props: AccordionItem) => (props.active ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transform-origin: 50%;
  transition: transform 350ms ease;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 10px;

  z-index: 1;
`;

const AccordionChildrenOutWrapper = styled.div<AccordionItem>`
  max-height: ${(props: AccordionItem) => (props.active && props.content ? '100%' : '0')};
  overflow: hidden;
  transition: max-height 150ms ease-in-out 10ms;
`;

const AccordionChildrenInnerWrapper = styled.div<{ padding?: string }>`
  padding: ${({ padding }): string => (!padding ? (isDesktop ? '2rem' : '1rem') : padding)};
  padding-top: ${!isDesktop && '0'};
`;

const AccordionItem: React.FC<{
  children?: React.ReactNode;
  handleClick: Function;
  name: string;
  rightComponent?: React.ReactNode;
  isFilled?: boolean;
  index?: number;
  open: number | undefined;
  padding?: string;
  loading?: boolean;
}> = ({ name, index, open, handleClick, isFilled, rightComponent, children, loading, padding }) => {
  const active = index === open;

  const theme = ThemeStore.useState(s => s);

  return (
    <AccordionItemWrapper>
      <Flex
        onClick={() => handleClick(index === open ? undefined : index)}
        justifyContent="space-between"
        alignItems="center"
        py={!padding ? (isDesktop ? '2rem' : '1.4rem') : padding}
        px={!padding ? (isDesktop ? '1.75rem' : '1.5rem') : padding}
        sx={{ cursor: 'pointer' }}
      >
        <div>
          <BadgeComputed isFilled={isFilled} index={index} active={active} />
          <AccordionItemName> {name} </AccordionItemName>
        </div>
        <Flex>
          {isFilled && rightComponent}
          {!loading ? (
            <AccordionCaretWrapper active={active}>
              <CaretDown fill={active ? theme.primary || '#3B24A8' : '#B3B1B8'} />
            </AccordionCaretWrapper>
          ) : (
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Spinner color={theme.primary || '#3b24a8'} speed="fast" size="26px" />
            </Flex>
          )}
        </Flex>
      </Flex>
      <AccordionChildrenOutWrapper active={active} content={children}>
        <AccordionChildrenInnerWrapper padding={padding}>{children}</AccordionChildrenInnerWrapper>
      </AccordionChildrenOutWrapper>
    </AccordionItemWrapper>
  );
};

type BadgeComputedProps = {
  isFilled?: boolean;
  index?: number;
  active: boolean;
};

const BadgeComputed: React.FC<BadgeComputedProps> = ({ isFilled, index, active }) => {
  if (isFilled)
    return (
      <BadgeCheck>
        <Check width={24} height={20} fill="#fff" />
      </BadgeCheck>
    );
  if (index) return <BadgeIndex active={active}>{index}</BadgeIndex>;
  return null;
};

export default AccordionItem;
