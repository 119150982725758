import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, BoxProps } from 'rebass';
import { useField, FieldAttributes } from 'formik';
import { width, padding, PaddingProps, color, ColorProps } from 'styled-system';

import { inter } from '../fonts';
import Text from '../text';
import Spacer from '../spacer';
import isDesktop from '@plug/helpers/is-desktop';
import { ThemeStore } from '@plug/redux/store';

type VariantType = 'outlined' | 'fill';

type TextFieldProps = {
  label?: string;
  error?: boolean;
  pb?: string;
  padding?: string;
  helperText?: string;
  hideHelperArea?: boolean;
  endIcon?: React.ReactNode;
  rightLabel?: React.ReactNode;
  inputEnd?: React.ReactNode;
  inputStart?: React.ReactNode;
  fieldWrapperProps?: BoxProps;
  wrapperHeight?: string;
  clearSearch?: () => void;
} & ComputedInputProps &
  React.HTMLProps<HTMLInputElement>;

const RightLabel = styled.div`
  ${inter('s14')}
  flex: 1;
  text-align: right;
`;

const statusColor = {
  success: '#18C698',
  warning: '#F38A09',
  error: '#C1050F',
};

const TextFieldBase = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      status,
      helperText,
      endIcon,
      hideHelperArea,
      variant,
      inputEnd,
      inputStart,
      rightLabel,
      style,
      padding = isDesktop ? '18px 10px' : '12px 15px',
      pb,
      type = 'text',
      fieldWrapperProps,
      wrapperHeight,
      clearSearch,
      ...rest
    },
    ref,
  ) => {
    return (
      <Box pb={pb || '20px'} {...fieldWrapperProps}>
        <Flex>
          {label && (
            <Label
              style={{ fontSize: isDesktop ? '16px' : '12px', ...style }}
              color="#666372"
              pb="10px"
            >
              {label}
            </Label>
          )}
          {rightLabel && <RightLabel>{rightLabel}</RightLabel>}
        </Flex>
        <Flex sx={{ position: 'relative', height: wrapperHeight ? wrapperHeight : 'auto' }}>
          {inputStart && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                width: isDesktop ? '100%' : '50%',
                maxWidth: '48px',
                height: '100%',
              }}
            >
              {inputStart}
            </Box>
          )}
          <Input
            autoComplete="off"
            ref={ref}
            {...(rest as any)}
            status={status}
            variant={variant}
            type={type}
            padding={padding || 'auto'}
            style={{
              paddingLeft: inputStart && (isDesktop ? '48px' : '34px'),
              fontSize: isDesktop ? '16px' : '12px',
              ...style,
            }}
            {...(type === 'number' && {
              pattern: '[0-9]*',
              inputMode: 'numeric',
              min: '0',
              max: '100',
            })}
          />
          {inputEnd && (
            <Box
              sx={{ position: 'absolute', right: 0, height: '100%' }}
              onClick={() => (clearSearch ? clearSearch() : null)}
            >
              {inputEnd}
            </Box>
          )}
        </Flex>
        {!hideHelperArea && (
          <Spacer pt="8px" height="23px">
            <Text as="span" color={status ? statusColor[status] : '#666372'}>
              {helperText && helperText}
            </Text>
          </Spacer>
        )}
      </Box>
    );
  },
);

const TextField: React.FC<TextFieldProps & FieldAttributes<any>> = props => {
  const [field, meta] = useField(props);
  return (
    <TextFieldBase
      {...field}
      {...props}
      status={meta.touched && meta.error ? 'error' : undefined}
      helperText={meta.touched && meta.error ? meta.error : ' '}
    />
  );
};

type InputProps = {
  disabled: boolean;
  error: boolean;
  padding: string | undefined;
} & ComputedInputProps &
  React.HTMLProps<HTMLInputElement>;

type ComputedInputProps = { variant?: VariantType; status?: keyof typeof statusColor };
const computedInput = (props: ComputedInputProps) => {
  const theme = ThemeStore.useState(s => s);

  const variant = {
    outlined: `
      border: 1px solid #BBBBBB;
      &:hover {
        border-color: ${theme.primary || '#3b24a8'};
      }
      &:focus {
        border: 1px solid #5E44D9;
        box-shadow: 0px 0px 10px rgba(94, 68, 217, 0.1);
      }
    `,
    fill: `
      background: #F6F6F8;
      color: #2E2E2E;
      &:focus {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(94, 68, 217, 0.3);
      }
    `,
  };
  const status = {
    error: css`
      background: #f9e6e7;
      border: 1px solid #c1050f;
      color: #c1050f;
    `,
    warning: css`
      background: #fef3e6;
      border: 1px solid #f38a09;
      color: #f38a09;
    `,
    success: css`
      background: #e8f9f5;
      color: #18c698;
      border: 1px solid #18c698;
    `,
  };

  if (props.status) return status[props.status];
  if (props.variant) return variant[props.variant];
  return;
};

const Input = styled.input<InputProps>`
  border-radius: 8px;
  padding: ${(props): string => props.padding || '18px 10px'};
  outline: 0;
  &::placeholder {
    color: #AAA8B3;
  }
  ${width}
  ${inter('s16')}
  ${computedInput}

  ${({ type }): any =>
    type === 'number' &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    `}
`;

export const Label = styled.label<PaddingProps & ColorProps>`
  display: block;
  ${color}
  ${padding}
  ${inter('s16')}
`;

TextFieldBase.defaultProps = {
  variant: 'outlined',
  hideHelperArea: false,
};

TextField.displayName = 'TextField';
TextFieldBase.displayName = 'TextFieldBase';

export { TextField, TextFieldBase };
