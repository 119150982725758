import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Done: React.FC<SvgProps> = props => (
  <SvgIcon {...props}>
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </SvgIcon>
);

export default Done;
