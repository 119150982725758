import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { getLocalInfo } from '@plug/helpers/local-info';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { NAV_SIDEBAR_HEIGHT, NAV_SIDEBAR_HEIGHT_MOBILE } from '../layouts/dashboard';
import { ComponentProps } from 'react';

export const HeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;

  height: ${(): string => NAV_SIDEBAR_HEIGHT};

  // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  background: ${({ theme }): string => getCustomizatedColor('header', theme.colors.primaryDark)};

  padding: 0px 16px;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding: 0 10px;
    height: ${(): string => NAV_SIDEBAR_HEIGHT_MOBILE};
  }
`;

export const HeaderDropdownButtonsContainer = styled(Flex)`
  gap: 24px;
`;

export const ActionWrapper = styled(Flex)`
  position: relative;
  align-items: center;
  gap: 12px;

  > div.cursor {
    display: flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;
  }

  svg {
    cursor: ${({ onClick }): string => (onClick ? 'pointer' : 'default')};
  }
`;

export const FloatingDropdown = styled.div<
  ComponentProps<'div'> & { opened: boolean; width: string; userName?: boolean }
>`
  position: absolute;
  top: 130%;
  right: 0;

  width: ${({ width }): string => width};
  opacity: ${({ opened }): number => (opened ? 1 : 0)};
  pointer-events: ${({ opened }): string => (opened ? 'all' : 'none')};

  z-index: 4;
`;

export const MenuIcon = styled.div<ComponentProps<'div'>>`
  width: 20px;
  height: 20px;
  padding: 0 1.5px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  transition: transform 0.3s ease;

  .bar {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin: 2px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    border-radius: 5px;

    &:last-child {
      width: 75%;
    }
  }

  &.open .bar:nth-child(1) {
    width: 10px;
    transform: translateY(9px) rotate(45deg);
  }

  &.open .bar:nth-child(2) {
    opacity: 0;
  }

  &.open .bar:nth-child(3) {
    width: 10px;
    transform: translateY(-9px) rotate(-45deg);
  }
`;
