import cookie from 'js-cookie';
import { USER_LOCAL_STORAGE_KEY } from '@plug/config/consts';
import { Store } from 'pullstate';

interface InitialValuesI {
  id: string;
  username?: string;
  email?: string;
  avatar?: string;
}

const user = JSON.parse(decodeURIComponent(cookie.get(USER_LOCAL_STORAGE_KEY) || '{}'));

console.log({ user });

export const InitialValue: InitialValuesI = {
  id: user.id,
  username: user.username,
  email: user.email,
  avatar: user.avatar,
};

export default new Store(InitialValue);
