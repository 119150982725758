import React from 'react';
import { Flex, Box, Text } from 'rebass';
import moment from 'moment';
import styled from 'styled-components';

import Tooltip from '../admin-payments-graph-tooltip';
import { ColouredBall } from '../payment-info-item';
import { formatBrazilPrice } from '@plug/helpers/normalize-currency';
import CapitalizeFirst from '@plug/helpers/capitalize-first';
import { ThemeStore } from '@plug/redux/store';
import getCustomizatedColor from '@plug/helpers/get-customizated-color';

const Container = styled(Flex)<{ length?: number }>`
  width: 100%;
  margin-bottom: 4px !important;
  gap: 60px;
  height: 250px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 20px;
  justify-content: ${({ length }) => (length > 2 ? 'start' : 'center')};

  @media screen and (max-width: 768px) {
    gap: 40px;
  }
`;

const BarGroup = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: max-content !important;

  &:not(::first-of-type),
  &:not(::last-of-type) {
    padding: 0px 10px;
  }

  &:first-child {
    padding-left: 24px;

    div:nth-child(0n + 2) {
      left: 6px !important;

      &::after {
        left: 5%;
      }
    }
  }

  &:nth-child(n):nth-child(-n + 3) div:nth-child(0n + 2) {
    left: -6px;

    &::after {
      left: 13%;
    }
  }

  &:nth-last-child(-n + 3) div:nth-child(0n + 2) {
    right: -10px;

    &::after {
      left: 88%;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  &:last-child div:nth-child(0n + 2) {
    right: -10px;
    left: inherit !important;

    &::after {
      left: 88% !important;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: max-content !important;

    &:nth-child(n):nth-child(-n + 3) div:nth-child(0n + 2) {
      left: -12px;

      &::after {
        left: 5%;
      }
    }

    &:first-child {
      padding-left: 24px;

      div:nth-child(0n + 2) {
        left: 6px !important;

        &::after {
          left: 5%;
        }
      }
    }

    &:nth-last-child(-n + 3) div:nth-child(0n + 2) {
      right: -28px;

      &::after {
        left: 88%;
      }
    }

    &:last-child {
      padding-right: 24px;

      div:nth-child(0n + 2) {
        right: -20px !important;
        left: inherit !important;

        &::after {
          left: 88% !important;
        }
      }
    }
  }
`;

interface GraphPropsI {
  revenue: string | number;
  commission: string | number;
  referenceMonth?: string;
}

interface Props {
  data: GraphPropsI[];
}

interface BarProps {
  revenueHeight: number;
  commissionHeight: number;
}

const lastMonths = (data: GraphPropsI[]): string[] => {
  const months = data
    .sort((a: any, b: any) => {
      return +new Date(a.referenceMonth) - +new Date(b.referenceMonth);
    })
    .map(item => {
      return moment(item.referenceMonth, 'YYYY-MM').format('MMMM');
    });

  return months;
};

const FinantialGraph = ({ data }: Props): JSX.Element => {
  const months = lastMonths(data);

  const theme = ThemeStore.useState(s => s);

  const highestValue = data.length
    ? Number(
        data.reduce((max, info) => (info.revenue > max ? info.revenue : max), data[0].revenue ?? 0),
      )
    : 0;

  const getRevenueHeight = (revenueValue: string | number): number =>
    (Number(revenueValue) * 100) / highestValue;

  const getCommissionHeight = (commissionValue: string | number): number =>
    (Number(commissionValue) * 100) / highestValue;

  const Bar = styled(Box)<BarProps>`
    //Revenue
    position: relative;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    height: ${({ revenueHeight }): number => revenueHeight}%;
    width: 15px;

    background: ${props => getCustomizatedColor('highlightText', props.theme.primary) || '#3b24a8'};

    cursor: pointer;

    //Commission
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;

      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      height: ${({ commissionHeight }): number => commissionHeight}%;
      width: 15px;

      background: #ffd500;

      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      min-width: max-content !important;
    }

    &:hover {
      & + .tooltip {
        opacity: 1;
      }
    }
  `;

  if (data.length < 1) return <></>;

  return (
    <Container length={data?.length}>
      {data
        .sort((a: any, b: any) => {
          return +new Date(a.referenceMonth) - +new Date(b.referenceMonth);
        })
        .map((info, i) => {
          const commissionHeight = getCommissionHeight(info.commission);
          const revenueHeight = getRevenueHeight(info.revenue);

          return (
            <BarGroup key={i}>
              <Bar commissionHeight={commissionHeight} revenueHeight={revenueHeight} />

              <Tooltip className="tooltip" bottomOffset={revenueHeight}>
                <Text pb="10px" fontSize="12px" color="#1C1637" fontWeight="bold">
                  {CapitalizeFirst(months[i])}
                </Text>
                <Flex justifyContent="space-between" pb="8px">
                  <Flex alignItems="center">
                    <ColouredBall size="7px" color={theme.primary || '#3B24A8'} />
                    <Text pl="4px" fontSize="11px" color="#666372">
                      Receita
                    </Text>
                  </Flex>
                  <Text color="#1C1637" fontWeight="bold" pl="8px" fontSize="10px">
                    {formatBrazilPrice(Number(info.revenue))}
                  </Text>
                </Flex>

                <Flex justifyContent="space-between">
                  <Flex alignItems="center">
                    <ColouredBall size="7px" color="#ffd500" />
                    <Text pl="4px" fontSize="11px" color="#666372">
                      Comissão a pagar
                    </Text>
                  </Flex>
                  <Text color="#1C1637" fontWeight="bold" pl="8px" fontSize="10px">
                    {formatBrazilPrice(Number(info.commission))}
                  </Text>
                </Flex>
              </Tooltip>

              <Text color="#555069" fontSize="12px" pt="4px">
                {CapitalizeFirst(months[i])}
              </Text>
            </BarGroup>
          );
        })}
    </Container>
  );
};

export default FinantialGraph;
