import React, { FC, createContext, useContext, useState } from 'react';

interface CheckoutContext {
  customer?: Customer;
  loadCustomer(customer?: Customer): void;

  products: {
    search?: string;
    setSearch: (value?: string) => void;
    category?: string;
    setCategory: (id?: string) => void;
  };

  reset: () => void;
}

const initialValues: CheckoutContext = {
  loadCustomer: null,
  products: {
    setSearch: null,
    setCategory: null,
  },
  reset: null,
};

const CheckoutContext = createContext(initialValues);

export const useCheckoutContext = () => useContext(CheckoutContext);

const CheckoutContextProvider: FC = ({ children }) => {
  const [customer, setCustomer] = useState<Customer>();
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  const reset = () => {
    setCustomer(null);
    setSearch(null);
    setCategory(null);
  };

  return (
    <CheckoutContext.Provider
      value={{
        customer,
        loadCustomer: setCustomer,
        products: {
          search,
          setSearch,
          category,
          setCategory,
        },
        reset,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContextProvider;
