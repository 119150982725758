import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Plug: React.FC<SvgProps> = props => (
  <SvgIcon fill="#1C1637" viewBox={'0 0 34 44'} width={33} height={44} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.35287 14.3846H24.6528L24.6706 14.3845H30.6V19.783C30.6 27.5181 24.5217 33.8206 17.0455 33.846C17.0316 33.8458 17.0177 33.8457 17.0037 33.8457C16.9898 33.8457 16.9759 33.8458 16.9621 33.846C15.1796 33.8397 13.4166 33.4755 11.7786 32.7751C10.129 32.0696 8.64055 31.0378 7.40368 29.7423C4.81465 27.0699 3.37788 23.4957 3.40021 19.783V14.3845H9.33499L9.35287 14.3846ZM34 19.783C34 28.8124 27.2616 36.2617 18.7037 37.1434V42.9307C18.7037 43.2142 18.5246 43.4861 18.2058 43.6865C17.887 43.887 17.4546 43.9996 17.0037 43.9996C16.5529 43.9996 16.1205 43.887 15.8017 43.6865C15.4828 43.4861 15.3037 43.2142 15.3037 42.9307V37.145C13.6354 36.9752 11.9993 36.5543 10.4519 35.894C8.3828 35.011 6.51572 33.7184 4.9642 32.0946C1.75818 28.7934 -0.0234206 24.3747 0.000232542 19.783V12.6922C0.000232542 12.2434 0.179338 11.813 0.498148 11.4956C0.816958 11.1782 1.24936 10.9999 1.70022 10.9999H7.65288V1.69231C7.65288 1.24348 7.83198 0.813036 8.15079 0.495666C8.4696 0.178296 8.902 0 9.35287 0C9.80373 0 10.2361 0.178296 10.5549 0.495666C10.8737 0.813036 11.0529 1.24348 11.0529 1.69231V10.9999H22.9528V1.69231C22.9528 1.24348 23.1319 0.813036 23.4507 0.495666C23.7695 0.178296 24.2019 0 24.6528 0C25.1036 0 25.536 0.178296 25.8548 0.495666C26.1736 0.813036 26.3528 1.24348 26.3528 1.69231V10.9999H32.3C32.7509 10.9999 33.1833 11.1782 33.5021 11.4956C33.8209 11.813 34 12.2434 34 12.6922V19.783ZM11.0462 22.8456H22.9462C23.397 22.8456 23.8294 22.6673 24.1482 22.3499C24.467 22.0325 24.6461 21.6021 24.6461 21.1532C24.6461 20.7044 24.467 20.274 24.1482 19.9566C23.8294 19.6392 23.397 19.4609 22.9462 19.4609H11.0462C10.5954 19.4609 10.163 19.6392 9.84416 19.9566C9.52535 20.274 9.34625 20.7044 9.34625 21.1532C9.34625 21.6021 9.52535 22.0325 9.84416 22.3499C10.163 22.6673 10.5954 22.8456 11.0462 22.8456Z"
    />
  </SvgIcon>
);

export default Plug;
