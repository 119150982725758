import React from 'react';
import { Flex, Image } from 'rebass';
import styled, { keyframes } from 'styled-components';
import { WhiteLabelStore } from '@plug/redux/store';

const Rotate = keyframes`
  0% { opacity: 0.8 }
  100% { opacity: 1;}
`;

const ImageContainer = styled.div`
  animation: ${Rotate} 3s;
  animation-fill-mode: forwards;
`;

const Fallback: React.FC = () => {
  const { isWhiteLabel, logo } = WhiteLabelStore.useState(s => s);

  const fallbackLogo = isWhiteLabel
    ? `https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/${window.location.hostname}/loader.png` ??
      logo
    : 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/logos/monitfy-dark.png';

  return (
    <Flex alignItems="center" justifyContent="center" flex={1} height="100%" width="100%">
      <Flex maxWidth="500px" maxHeight="500px" alignItems="center" justifyContent="center">
        <ImageContainer>
          <Image src={fallbackLogo} maxWidth={'350px'} />
        </ImageContainer>
      </Flex>
    </Flex>
  );
};

const Suspense: React.FC = ({ children }) => {
  const [isCompletedAnimation, setCompletedAnimation] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setCompletedAnimation(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  return isCompletedAnimation ? (
    <React.Suspense fallback="">{children}</React.Suspense>
  ) : (
    <Fallback />
  );
};

export default Suspense;
