import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import styled from 'styled-components';
import { inter } from '../fonts';

const PaginationStyled = styled.div`
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    ${inter('s14')}
    background: #fff;

    li {
      color: #aaa8b3;
      &:hover {
        color: ${props => props.theme.primary || "#3b24a8"};
      }
      &.previous {
        margin-right: 15px;
      }
      &.next {
        margin-left: 15px;
      }
      &.previous,
      &.next {
        &.disabled a {
          cursor: auto;
          background: #f1f3f8;
          border: 0;
          &:hover svg {
            fill: #aaa8b2;
          }
        }
        a {
          svg {
            fill: #aaa8b2;
          }
          &:hover {
            svg {
              fill: ${props => props.theme.primary || "#3b24a8"};
            }
          }
          border: 1px solid #e3e6ed;
          height: 32px;
          border-radius: 8px;
        }
      }

      &.selected a {
        color: ${props => props.theme.primary || "#3b24a8"};
        position: relative;

        &::after {
          background: ${props => props.theme.primary || "#3b24a8"};
          width: 30px;
          height: 4px;
          content: '';
          position: absolute;
          bottom: 0;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 64px;
        outline: none;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
`;

const Pagination: React.FC<ReactPaginateProps> = props => (
  <PaginationStyled>
    <ReactPaginate {...props} />
  </PaginationStyled>
);

export default Pagination;
