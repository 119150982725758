import React, { useMemo, useRef, useState } from 'react';
import { Drawer, Icon, Typography } from '@gohubly/design-system';
import { Avatar, Header as MonitfyHeader, Icon as IconM } from '@monitfy/ds';
import isDesktop from '@plug/helpers/is-desktop';
import { UserStore, WhiteLabelStore } from '@plug/redux/store';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { SideView, useSideViewState } from '@plug/ui';
import { useQuery } from '@apollo/client';
import CustomDropdown from '../language-dropdown/index'
import {
  GetUserPendenciesPayload,
  GET_USER_PENDENCIES,
} from '@plug/domains/organization/graphql/queries/get-user-pendencies';
import {
  ActionWrapper,
  MenuIcon,
  FloatingDropdown,
  HeaderContainer,
  HeaderDropdownButtonsContainer,
} from './style';
import HeaderActionHelpDropdown from '../header-actions-dropdown/header-action-help-dropdown';
import HeaderActionProfileDropdown from '../header-actions-dropdown/header-action-profile-dropdown';
import useOutsideClick from '@plug/hooks/use-outside-click';
import HeaderActionNotificationDropdown from '../header-actions-dropdown/header-action-notification-dropdown';
import { NotificationContainer } from '../header-actions-dropdown/header-action-notification-dropdown/style';
import { MARY_KAY_WHITELABEL, OLIST_HOST_NAME } from '@plug/config/consts';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex } from 'rebass';
import NotificationsDrawer from '../notifications-drawer';
import { useNotificationContext } from '@plug/contexts/NotificationContext';
import { useAuthContext } from '@plug/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import getCurrentOrganization from '@plug/helpers/get-current-organization';

const Header: React.FC<{ title?: string | boolean; hideHeaderTitle?: boolean }> = ({
  title,
  hideHeaderTitle,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isOlist = OLIST_HOST_NAME.includes(window.location.hostname);
  const user = UserStore.useState(s => s);
  const helpDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const [helpDropdownOpened, setHelpDropdownOpened] = useState(false);
  const [profileDropdownOpened, setProfileDropdownOpened] = useState(false);
  const [notificationDropdownOpened, setNotificationDropdownOpened] = useState(false);
  const { data, loading } = useQuery<GetUserPendenciesPayload>(GET_USER_PENDENCIES);
  const { openedNavigationMenuMobile, setOpenedNavigationMenuMobile } = useNavigationMenuContext();
  const sideViewState = useSideViewState();
  const [showLogoutConfirmation, handleShowLogoutConfirmation] = useState(false);
  const { currentOrganization } = useAuthContext();
  const { push } = useHistory();
  const organizationInfo = getCurrentOrganization();
  const { isWhiteLabel, avatar } = WhiteLabelStore.useState(s => s);

  const isEfizi = window.location.host.includes('parceiro.efizi');

  const handleOpenHelpDropdown = (): void => setHelpDropdownOpened(true);
  const handleOpenProfileDropdown = (): void => setProfileDropdownOpened(true);
  const handleOpenNotificationDropdown = (): void => setNotificationDropdownOpened(true);

  const { show, notifications = [] } = useNotificationContext();

  const handleCloseProfileDropdownIfOpened = (event?: React.MouseEvent): void | false => {
    if (event) {
      event.stopPropagation();
    }
    return (
      profileDropdownOpened && setProfileDropdownOpened(false), handleShowLogoutConfirmation(false)
    );
  };

  const handleCloseHelpDropdownIfOpened = (): void | false =>
    helpDropdownOpened && setHelpDropdownOpened(false);

  const handleCloseNotificationDropdownIfOpened = (): void | false =>
    notificationDropdownOpened && setNotificationDropdownOpened(false);

  // Hook to handle click outside dropdowns
  useOutsideClick(profileDropdownRef, handleCloseProfileDropdownIfOpened, [profileDropdownOpened]);
  useOutsideClick(helpDropdownRef, handleCloseHelpDropdownIfOpened, [helpDropdownOpened]);
  useOutsideClick(notificationDropdownRef, handleCloseNotificationDropdownIfOpened, [
    notificationDropdownOpened,
  ]);

  const handleToggleSidebar = (): void => {
    setOpenedNavigationMenuMobile(!openedNavigationMenuMobile);
  };

  const handleShowMobile = (item: Record<string, any>): void => {
    sideViewState.open(
      <HeaderActionProfileDropdown handleCloseDropdown={sideViewState.closeSideView} />,
    );
  };

  const handleChangeOrgClick = () => {
    return push('/dashboard');
  };

  return (
    <>
      <SideView
        position="bottom"
        height="max-content"
        width="100%"
        borderradiustopright="16px"
        borderradiustopleft="16px"
        {...sideViewState}
      >
        {sideViewState.renderSideViewPage}
      </SideView>

      <MonitfyHeader>
        {!isDesktop && organizationInfo && (
          <MenuIcon
            className={`menu-icon ${openedNavigationMenuMobile ? 'open' : ''}`}
            onClick={handleToggleSidebar}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </MenuIcon>
        )}

        <Box marginRight="auto" onClick={handleChangeOrgClick}>
          <Avatar
            size="large"
            persona="org"
            name={currentOrganization?.name}
            image={
              avatar ??
              currentOrganization?.logo ??
              'https://plugone-production.nyc3.digitaloceanspaces.com/assets/monitfy-avatar.png'
            }
            twoLetter={!currentOrganization?.logo || !avatar}
            transparent={!isWhiteLabel}
          />
        </Box>

        <HeaderDropdownButtonsContainer>
          {/* {(!isOlist || !isEfizi) && (
            <ActionWrapper onClick={handleOpenHelpDropdown}>
              <Icon size="MD" fill="primaryLight" stroke="primaryDark" iconId="helpCircle" />

              <FloatingDropdown width="200px" opened={helpDropdownOpened} ref={helpDropdownRef}>
                <HeaderActionHelpDropdown />
              </FloatingDropdown>
            </ActionWrapper>
          )} */}

          <ActionWrapper
            style={{
              display: MARY_KAY_WHITELABEL.includes(window.location.hostname) ? 'flex' : 'none',
            }}
          >
            <NotificationContainer hasNotification={notifications.length > 0}>
              <Icon
                size="MD"
                fill="primaryLight"
                stroke="primaryLight"
                iconId="bell"
                onClick={() => show()}
              />
            </NotificationContainer>

            {/* <FloatingDropdown
              width="340px"
              opened={notificationDropdownOpened}
              ref={notificationDropdownRef}
            >
              <HeaderActionNotificationDropdown
                notifications={data?.getUserPendencies ?? []}
                handleCloseDropdown={handleCloseNotificationDropdownIfOpened}
                loading={loading}
              />
            </FloatingDropdown> */}
          </ActionWrapper>

          <CustomDropdown />

          <ActionWrapper onClick={isMobile ? handleShowMobile : handleOpenProfileDropdown}>
            <div className="cursor">
              <Avatar size={isMobile ? 'medium' : 'large'} persona="profile" image={user.avatar} />

              {isDesktop && (
                <>
                  <Typography className='user-name-header' size="XXS">{user.username}</Typography>

                  <Icon
                    iconId={profileDropdownOpened ? 'chevronUp' : 'chevronDown'}
                    stroke="white"
                    size="XS"
                  />
                </>
              )}
            </div>

            {!isMobile && (
              <FloatingDropdown
                width="280px"
                opened={profileDropdownOpened}
                ref={profileDropdownRef}
                userName={user.username !== null}
              >
                <HeaderActionProfileDropdown
                  handleCloseDropdown={handleCloseProfileDropdownIfOpened}
                  showLogoutConfirmation={showLogoutConfirmation}
                  handleShowLogoutConfirmation={handleShowLogoutConfirmation}
                />
              </FloatingDropdown>
            )}
          </ActionWrapper>
        </HeaderDropdownButtonsContainer>
      </MonitfyHeader>
    </>
  );
};

export default Header;
