import styled from 'styled-components';

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;

  @media screen and (max-width: 1649px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;
