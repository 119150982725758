import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

import { inter } from '@plug/ui/fonts';

const AccordionGroupTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  ${inter('s18')};
  padding-left: 30px;
  line-height: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  letter-spacing: 0.001em;
  color: #1c1637;
  height: 72px;
  background: #fbfcfd;
  width: '100%';
  border-bottom: 1px solid #ebeef4;
`;

const AccordionContainer = styled.div`
  padding: 2rem;
  background: #F0F1F5;
  height: auto;

  @media (max-width: 768px) {
    padding-bottom: 5rem;
  }
`;

type AccordionProps = {
  children: React.ReactNode;
};

const Accordion: React.FC<AccordionProps> = ({ children }) => {
  return (
    <AccordionContainer>
      <Box width="100%">
        <AccordionGroupTitle>Primeiros passos</AccordionGroupTitle>
        {children}
      </Box>
    </AccordionContainer>
  );
};

export default Accordion;
