import React from 'react';
import * as css from './checkout-catalog-header.styles';
import { Typography } from '@gohubly/design-system';
import { Button } from '@monitfy/ds';
import { useCheckoutContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutContext';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '@plug/contexts/auth-context';
import { CHECKOUT_PATH } from '@plug/domains/organization/domains/affiliate/routing';

const CheckoutCatalogHeader = () => {
  const { reset } = useCheckoutContext();
  const { replace } = useHistory();
  const { currentOrganization } = useAuthContext();

  const handleCustomerChange = () => {
    reset();
    replace(
      `${CHECKOUT_PATH}/onboarding?new=true`.replace(':organizationSlug', currentOrganization.slug),
    );
  };

  return (
    <css.Container>
      <Typography size="32" lineHeight="48" color="textNeutralDefault">
        Catálogo
      </Typography>

      <Button variant="text" iconBefore="refresh" onClick={handleCustomerChange}>
        Trocar usuário
      </Button>
    </css.Container>
  );
};

export default CheckoutCatalogHeader;
