import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const CaretDown: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 24 24'} width={24} height={24} {...props}>
    <path
      d="M7.5 9.75L12 14.25L16.5 9.75"
      stroke="#1E1044"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      color="transparent"
      fill={props.fill || '#FFF'}
    />
  </SvgIcon>
);

export default CaretDown;
