import cookie from 'js-cookie';

import { USER_ORGANIZATION_PERMISSIONS_STORAGE_KEY } from '@plug/config/consts';

export default function checkUserOrganizationPermission(
  permissionName: string,
): OrganizationPermission | undefined {
  const userOrganizationPermissions = JSON.parse(
    decodeURIComponent(cookie.get(USER_ORGANIZATION_PERMISSIONS_STORAGE_KEY || '') || '[]'),
  );

  if (userOrganizationPermissions.length) {
    return userOrganizationPermissions.find((item: any) => item.permissionName === permissionName);
  }
}
