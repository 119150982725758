import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';

import { AppRouteProps } from './types';
import { useAuthContext } from '../../contexts/auth-context';
import TermsAndConditions from '../terms-and-conditions';

import {
  GET_CURRENT_TERMS_AND_CONDITIONS,
  GetTermsAndConditionsQuery,
} from '../../graphql/queries/get-current-terms-and-conditions';

import {
  GET_WHITE_LABEL_INFOS,
  getWhiteLabelInfosQuery,
  getWhiteLabelInfosVariables,
} from '../../graphql/queries/get-white-label-infos';

import {
  GET_WHITE_LABEL_INFOS_BY_DOMAIN,
  getWhiteLabelInfosByDomainQuery,
  getWhiteLabelInfosByDomainVariables,
} from '../../graphql/queries/get-white-label-infos-by-domain';

import { ThemeStore, WhiteLabelStore, OrganizationStore, UserStore } from '@store';
import { InitialValue as InitialThemeValues } from '@redux/stores/theme';
import cookie from 'js-cookie';
import {
  I18N_NAMESPACES,
  MARY_KAY_WHITELABEL,
  ORGANIZATION_CUSTOMIZATION_KEY,
  USER_LOCAL_STORAGE_KEY,
  WHITELABEL_LS_KEY,
} from '@plug/config/consts';
import Providers from './Providers';
import { saveLocalInfo, getLocalInfo } from '@plug/helpers/local-info';
import useLocalStorage from '@plug/hooks/use-local-storage';
import { useOrganizationCustomization } from '@plug/contexts/organization-customization-context';
import {
  REGISTER_ACTIVITY,
  RegisterActivityResponse,
} from '@plug/domains/auth/graphql/register-activity';
import MaryKayAlert from '../mary-kay-alert';
import {
  UNREGISTERED_CONSULTANT,
  unregisteredConsultantResponse,
} from '@plug/graphql/queries/unregistered-consultant';
import { useNotificationContext } from '@plug/contexts/NotificationContext';
import { Icon, Link, Loading } from '@monitfy/ds';
import { useReport } from '@plug/hooks/use-report';
import { useTranslation } from 'react-i18next';
import { ENVIRONMENT } from '@plug/config/env';

export const cookieSource = 'utmSource';
export const cookieCampaign = 'utmCampaign';
export const cookieMedium = 'utmMedium';

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component,
  layout: Layout,
  privateRoute,
  requiredProfiles,
  hideHeaderTitle,
  title,
  ...props
}) => {
  const { verifySession, verifyProfiles, currentOrganization } = useAuthContext();

  const theme = ThemeStore.useState(s => s);
  const organization = OrganizationStore.useState(s => s);
  const user = UserStore.useState(s => s);
  const searchParams = new URLSearchParams(window.location.search);
  const organizationId = searchParams.get('organizationId');
  const { get } = useLocalStorage();
  const { t } = useTranslation(I18N_NAMESPACES.TRANSLATION);
  const localStorageWhitelabelInfo = get<any>('WHITE_LABEL_INFO');
  const { addNotifications } = useNotificationContext();

  const [registerActivity] = useMutation<RegisterActivityResponse>(REGISTER_ACTIVITY);

  const { send: generateReport, loading: loadingReport } = useReport('unregisteredConsultant');

  const [getWhiteLabelInfoByOrganization, { data: dataWhiteLabelByOrganizaationId }] = useLazyQuery<
    getWhiteLabelInfosQuery,
    getWhiteLabelInfosVariables
  >(GET_WHITE_LABEL_INFOS, {
    fetchPolicy: 'cache-and-network',
    ...(organizationId && {
      variables: {
        input: { organizationId },
      },
    }),
  });

  const [getWhiteLabelInfoByDomain, { data: dataWhiteLabelByDomain }] = useLazyQuery<
    getWhiteLabelInfosByDomainQuery,
    getWhiteLabelInfosByDomainVariables
  >(GET_WHITE_LABEL_INFOS_BY_DOMAIN, {
    fetchPolicy: 'cache-and-network',
  });

  useQuery<unregisteredConsultantResponse>(UNREGISTERED_CONSULTANT, {
    onCompleted(data) {
      if (data?.unregisteredConsultants && data?.unregisteredConsultants > 0) {
        addNotifications([
          {
            id: 'unregistered',
            icon: <Icon name="information" />,
            message: t('unregisteredConsultants', {
              count: data.unregisteredConsultants,
            }),
            at: new Date(),
            unique: true,
            interfere: {
              count: data.unregisteredConsultants.toString(),
              btn: (
                <Link
                  onClick={() =>
                    generateReport({
                      email: user.email,
                      organizationId: currentOrganization.id,
                    })
                  }
                >
                  {loadingReport ? <Loading /> : 'Clique aqui'}
                </Link>
              ),
            },
          },
        ]);
      }
    },
    skip:
      !MARY_KAY_WHITELABEL.includes(window.location.hostname) ||
      !currentOrganization ||
      ENVIRONMENT !== 'staging',
  });

  useEffect(() => {
    const GetWhiteLabelInfos = async (): Promise<void> => {
      if (!organizationId)
        await getWhiteLabelInfoByDomain({
          variables: {
            input: {
              domain: window.location.hostname,
            },
          },
        });
    };

    if (
      !localStorageWhitelabelInfo ||
      (!localStorageWhitelabelInfo?.organizationId && localStorageWhitelabelInfo?.isWhiteLabel)
    )
      GetWhiteLabelInfos();
  }, [
    getWhiteLabelInfoByDomain,
    getWhiteLabelInfoByOrganization,
    localStorageWhitelabelInfo,
    organizationId,
  ]);

  useEffect(() => {
    if (dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain.isWhiteLabel) {
      localStorage.setItem(
        WHITELABEL_LS_KEY,
        JSON.stringify(dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain),
      );

      return WhiteLabelStore.update(s => {
        s.isWhiteLabel = true;
        s.logo = dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain?.logo;
        s.domain = window.location.hostname;
        s.avatar = dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain?.avatar;
        s.organizationId = dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain?.organizationId;
      });
    }

    if (dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos.isWhiteLabel) {
      return WhiteLabelStore.update(s => {
        s.isWhiteLabel = true;
        s.logo = dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos?.logo;
        s.domain = dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos.domain;
        s.avatar = dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain?.avatar;
        s.organizationId = dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain?.organizationId;
      });
    }
  }, [dataWhiteLabelByDomain, dataWhiteLabelByOrganizaationId]);

  useEffect(() => {
    if (currentOrganization) {
      registerActivity();
    }
  }, [currentOrganization, registerActivity]);

  let params = {};

  const paramSource = 'utm_source';
  const paramCampaign = 'utm_campaign';
  const paramMedium = 'utm_medium';

  const paramsSeparated = props?.location?.search?.replace('?', '');
  const paramsSeparatedSplit = paramsSeparated?.split('&');

  if (paramsSeparated && paramsSeparated.length > 2)
    paramsSeparatedSplit?.forEach(paramSeparated => {
      const paramSplited = paramSeparated.split('=');
      params = {
        ...params,
        [paramSplited[0]]: paramSplited[1],
      };
    });

  if (!(Cookies.get(cookieSource) || Cookies.get(cookieCampaign)) || Cookies.get(cookieMedium)) {
    if (params[paramSource as keyof typeof params])
      Cookies.set(cookieSource, params[paramSource as keyof typeof params], {
        expires: 7,
      });
    if (params[paramCampaign as keyof typeof params])
      Cookies.set(cookieCampaign, params[paramCampaign as keyof typeof params], {
        expires: 7,
      });
    if (params[paramMedium as keyof typeof params])
      Cookies.set(cookieMedium, params[paramMedium as keyof typeof params], {
        expires: 7,
      });
  }

  if (!organization.organizationName && currentOrganization?.name) {
    OrganizationStore.update(s => {
      s.organizationId = currentOrganization?.organization?.id;
      s.organizationName = currentOrganization?.organization?.name;
      s.organizationSlug = currentOrganization?.organization?.slug;
      s.organizationImage = currentOrganization?.organization?.logo;
      s.role = currentOrganization?.organization?.organizationRole[0]?.name;
    });
  }

  //Middleware: Check login
  if (
    (!privateRoute && !requiredProfiles) ||
    (privateRoute && !requiredProfiles && verifySession()) ||
    (requiredProfiles && verifyProfiles(requiredProfiles))
  ) {
    /**
     * Middleware: Check if has theme & update if necessary
     *
     * For ALL public routes && ONLY Dashboard from private routes
     * -> get theme by domain
     *
     * For ALL private routes & EXCEPT dashboard
     * -> get theme by organization
     */

    // console.log({ theme });
    // console.log({ InitialThemeValues });
    // console.log(
    //   theme.logo === InitialThemeValues.logo &&
    //     theme.primary === InitialThemeValues.primary &&
    //     theme.secondary === InitialThemeValues.secondary &&
    //     theme.tertiary === InitialThemeValues.tertiary,
    // );

    if (
      theme.logo === InitialThemeValues.logo &&
      theme.primary === InitialThemeValues.primary &&
      theme.secondary === InitialThemeValues.secondary &&
      theme.tertiary === InitialThemeValues.tertiary
    ) {
      if (
        (!privateRoute && !requiredProfiles) ||
        (privateRoute && verifySession() && window.location.pathname.includes('dashboard'))
      ) {
        //TODO: Query getWhiteLabelInfo by Domain
        console.log('domain', dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain);

        if (dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain) {
          localStorage.setItem(
            WHITELABEL_LS_KEY,
            JSON.stringify({
              isWhiteLabel: dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain?.isWhiteLabel,
            }),
          );

          ThemeStore.update(s => {
            s.logo = dataWhiteLabelByDomain.getWhiteLabelInfosByDomain.logo;
            s.primary = dataWhiteLabelByDomain?.getWhiteLabelInfosByDomain.primaryColor;
            s.secondary = dataWhiteLabelByDomain.getWhiteLabelInfosByDomain.secondColor;
            s.tertiary = dataWhiteLabelByDomain.getWhiteLabelInfosByDomain.tertiaryColor;
            s.organizationName = dataWhiteLabelByDomain.getWhiteLabelInfosByDomain.organizationName;
          });
        } else {
          if (dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos) {
            //TODO: remove this when refactory is in prod
            ThemeStore.update(s => {
              s.logo = dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.logo;
              s.organizationName =
                dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.organizationName;
              s.primary = dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos.primaryColor;
              s.secondary = dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.secondColor;
              s.tertiary = dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.tertiaryColor;
            });
          }
        }
      } else {
        if (dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos) {
          //Query getWhiteLabelInfo by OrganizationId
          if (
            dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos &&
            !theme.updateBySetCurrentOrganization
          ) {
            localStorage.setItem(WHITELABEL_LS_KEY, JSON.stringify({ isWhiteLabel: true }));

            ThemeStore.update(s => {
              s.logo = dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.logo;
              s.primary = dataWhiteLabelByOrganizaationId?.getWhiteLabelInfos.primaryColor;
              s.secondary = dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.secondColor;
              s.tertiary = dataWhiteLabelByOrganizaationId.getWhiteLabelInfos.tertiaryColor;
            });
          }
        }
      }
    }

    if (privateRoute && verifySession()) {
      const userFromLocalStorage = JSON.parse(
        decodeURIComponent(cookie.get(USER_LOCAL_STORAGE_KEY) || ''),
      );

      //@ts-ignore
      if (!window.dataLayer.find(x => x.email === userFromLocalStorage.email)) {
        //@ts-ignore
        window.dataLayer.push({
          email: userFromLocalStorage.email,
        });
        //@ts-ignore
        window.dataLayer.push({
          username: userFromLocalStorage.username,
        });
      }
    }

    return (
      <Providers>
        {MARY_KAY_WHITELABEL.includes(window.location.hostname) && <MaryKayAlert />}
        <Route
          {...props}
          render={(renderProps): JSX.Element | null => {
            if (!Component) return null;
            return <Component {...props} {...renderProps} />;
          }}
        />
      </Providers>
    );
  } else {
    return <Redirect to={{ pathname: '/login', state: { from: props.location?.pathname } }} />;
  }
};

export default AppRoute;
