import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const CaretLeft: React.FC<SvgProps> = props => {

  const theme = ThemeStore.useState(s => s);
  return (
    <SvgIcon viewBox={'0 0 6 10'} width={6} height={10} {...props}>
      <path
        d="M6 0.677774C6 0.0723255 5.21893 -0.230399 4.7574 0.20687L0.213017 4.51228C-0.0710058 4.78137 -0.0710058 5.21863 0.213017 5.48772L4.7574 9.79313C5.21893 10.2304 6 9.92768 6 9.32223L6 0.677774Z"
        fill={theme.primary || "#3B24A8"}
      />
      <path d="M6 0.677774C6 0.0723255 5.21893 -0.230399 4.7574 0.20687L0.213017 4.51228C-0.0710058 4.78137 -0.0710058 5.21863 0.213017 5.48772L4.7574 9.79313C5.21893 10.2304 6 9.92768 6 9.32223L6 0.677774Z" />
    </SvgIcon>
  );
}


export default CaretLeft;
