import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Camera: React.FC<SvgProps> = props => (
  <SvgIcon fill="#FFF" viewBox={'0 0 22 18'} width={22} height={18} {...props}>
    <path d="M13.9219 1.28571C14.0508 1.28571 14.1797 1.40625 14.2227 1.52679L15.168 3.85714H19.9375C20.2812 3.85714 20.625 4.17857 20.625 4.5V16.0714C20.625 16.433 20.2812 16.7143 19.9375 16.7143H2.0625C1.67578 16.7143 1.375 16.433 1.375 16.0714V4.5C1.375 4.17857 1.67578 3.85714 2.0625 3.85714H6.78906L7.64844 1.72768C7.73438 1.48661 7.99219 1.28571 8.29297 1.28571H13.9219ZM13.9219 0H8.29297C7.43359 0 6.66016 0.522321 6.35938 1.28571L5.84375 2.57143H2.0625C0.902344 2.57143 0 3.45536 0 4.5V16.0714C0 17.1562 0.902344 18 2.0625 18H19.9375C21.0547 18 22 17.1562 22 16.0714V4.5C22 3.45536 21.0547 2.57143 19.9375 2.57143H16.1562L15.5117 1.04464C15.2539 0.441964 14.6523 0 13.9219 0ZM11 15.1071C13.8359 15.1071 16.1562 12.9777 16.1562 10.2857C16.1562 7.63393 13.8359 5.46429 11 5.46429C8.12109 5.46429 5.84375 7.63393 5.84375 10.2857C5.84375 12.9777 8.12109 15.1071 11 15.1071ZM11 6.75C13.0625 6.75 14.7812 8.35714 14.7812 10.2857C14.7812 12.2545 13.0625 13.8214 11 13.8214C8.89453 13.8214 7.21875 12.2545 7.21875 10.2857C7.21875 8.35714 8.89453 6.75 11 6.75Z" />
  </SvgIcon>
);

export default Camera;
