import React from 'react';
import { TextField } from '@plug/ui';
import { useTranslation } from 'react-i18next';

interface CommonProps {
  values?: any;
}

const IntegrationFormVtex: React.FC<CommonProps> = ({ values }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.vtex.account-name.label',
        )}
        name="vtexName"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.vtex.account-name.placeholder',
        )}
        width="100%"
      />
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.vtex.account-key.label',
        )}
        name="vtexKey"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.vtex.account-key.placeholder',
          {
            key: values?.vtexName,
          },
        )}
        width="100%"
      />
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.vtex.account-token.label',
        )}
        name="vtexToken"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.vtex.account-token.placeholder',
        )}
        width="100%"
      />
    </>
  );
};

export default IntegrationFormVtex;
