import React, { useRef, useState } from 'react';
import { Icon } from '@gohubly/design-system';
import { Flex } from 'rebass';
import { NavSidebarFlexContainer, Overlay, Content } from './style';
import NavSidebarMenuItemList from '../nav-sidebar-menu-item-list';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { useAuthContext } from '@plug/contexts/auth-context';
import useOutsideClick from '@plug/hooks/use-outside-click';
import { useOutsideClickEvent } from '@plug/hooks/use-outside-click-event';

const FloatingNavSidebar: React.FC<{
  showMenu?: boolean;
  setShowMenu: (show: boolean) => void;
}> = ({ showMenu, setShowMenu }): JSX.Element => {
  const {
    setOpenedOrganizationListSection,
    openedOrganizationListSection,
  } = useNavigationMenuContext();

  const { currentOrganization } = useAuthContext();

  const orgListRef = useRef(null);
  const menuRef = useRef(null);
  const navSidebarHeaderRef = useRef(null);
  const [showOrgList, setShowOrgList] = useState(false);

  const handleToggleOrganizationList = (): void => {
    setOpenedOrganizationListSection(!openedOrganizationListSection);
    setShowOrgList(!showOrgList);
    setShowMenu(false);
  };

  const handleToggleSidebar = (): void => {
    setShowMenu(!showMenu);
    setShowOrgList(false);
  };

  useOutsideClick(menuRef, () => {
    setShowMenu(false);
  });

  useOutsideClickEvent(orgListRef, event => {
    if (
      !orgListRef.current.contains(event.target) &&
      !navSidebarHeaderRef.current.contains(event.target)
    ) {
      setShowOrgList(false);
      setOpenedOrganizationListSection(false);
    }
  });

  return (
    <NavSidebarFlexContainer>
      <Flex
        width="44px"
        height="44px"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={handleToggleSidebar}
      >
        <Icon iconId={'arrowRigth'} size="SM" stroke={'neutralLow'} strokeWidth={1.5} />
      </Flex>

      <Overlay show={showMenu} zIndex={999}>
        <Content show={showMenu} width="244px" zIndex={9999} ref={menuRef}>
          <NavSidebarMenuItemList />
        </Content>
      </Overlay>
    </NavSidebarFlexContainer>
  );
};

export default FloatingNavSidebar;
