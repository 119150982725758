import gql from 'graphql-tag';

export const GET_USER_PENDENCIES = gql`
  {
    getUserPendencies {
      pendency
      metadata {
        key
        value
      }
    }
  }
`;

export type GetUserPendenciesPayload = {
  getUserPendencies: {
    pendency: string;
    metadata: { key: string; value?: string }[];
  }[];
};
