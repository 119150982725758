import styled from 'styled-components';
import { inter } from '../fonts';
import {
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  position,
  PositionProps,
  top,
  left,
  width,
  WidthProps,
  LeftProps,
  TopProps,
  maxWidth,
  MaxWidthProps,
  background,
  color,
  ColorProps,
  BackgroundProps,
  textAlign,
  TextAlignProps,
  letterSpacing,
  LetterSpacingProps,
  LineHeightProps,
  lineHeight,
  padding,
  PaddingProps,
} from 'styled-system';
import { ComponentProps } from 'react';

const variant = {
  h2: `
    color: #1C1637;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
`,
  h3: `
    color: #1C1637;
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0.001em;
`,
  subtitle1: `
    color: #666372;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001em;
`,
};

type VariantProps = keyof typeof variant;

type TextProps = ComponentProps<'p'> & {
  variant?: VariantProps;
  cursor?: string;
} & FontSizeProps &
  FontWeightProps &
  PositionProps &
  TopProps &
  LeftProps &
  BackgroundProps &
  TextAlignProps &
  ColorProps &
  MaxWidthProps &
  PaddingProps &
  LineHeightProps &
  LetterSpacingProps &
  WidthProps;

const Text = styled.p<TextProps>`
  padding: 0;
  margin: 0;
  cursor: ${({ cursor }): string => cursor ?? 'auto'}; ${inter('s12')}
    ${props => variant[props.variant as VariantProps]} ${fontSize} ${fontWeight} ${maxWidth}
    ${color} ${top} ${left} ${width} ${position} ${padding} ${background} ${lineHeight}
    ${letterSpacing} ${textAlign};
`;

export default Text;
