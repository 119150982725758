import { Typography } from '@gohubly/design-system';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Flex, Text } from 'rebass';
import { Container } from './style';
import Logo from '../logo';

const DashboardFooter = (): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();
  const year = moment().year();

  const isEfizi = window.location.host.includes('parceiro.efizi');

  if (isEfizi) return <></>;

  return (
    <Container>
      {!isMobile && (
        <Text fontWeight="300">
          <Typography color="neutralLow" size="XXS">
            {t('hubly-dashboard.footer', { year })}
          </Typography>
        </Text>
      )}

      {isMobile && (
        <Typography color="neutralLowMedium" size="XXXS" lineHeight="MD">
          Decolando negócios digitais para novos universos 🚀
        </Typography>
      )}

      <Flex style={{ gap: '16px' }} alignItems="center" justifyContent="space-between">
        <Logo />
        {!isMobile && (
          <Text fontWeight="300">
            <Typography color="neutralLow" size="XXS">
              v1.0.2
            </Typography>
          </Text>
        )}
      </Flex>
    </Container>
  );
};

export default DashboardFooter;
