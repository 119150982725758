import React, { FC, createContext, useContext, useState } from 'react';

interface CheckoutDetailedProductContext {
  product?: Product;
  loadProduct(product?: Product): void;

  imageIndex: number;
  changeImage(index: number): void;

  selectedSku?: SKU;
  changeSku(sku: SKU): void;

  getFilteredAttributes(attributes: KeyValuePair[]): KeyValuePair[];
}

const CheckoutDetailedProductContext = createContext<CheckoutDetailedProductContext>({
  changeImage: null,
  loadProduct: null,
  changeSku: null,
  imageIndex: 0,
  getFilteredAttributes: null,
});

export const useCheckoutDetailedProductContext = () => useContext(CheckoutDetailedProductContext);

export const CheckoutDetailedProductContextProvider: FC = ({ children }) => {
  const [product, setProduct] = useState<Product>();
  const [imageIndex, setImageIndex] = useState(0);
  const [sku, setSku] = useState<SKU>();

  const getFilteredAttributes = (attributes: KeyValuePair[]) => {
    return attributes.filter(attr => {
      return sku?.attributes?.filter(item => item.value?.includes(attr.value));
    });
  };

  return (
    <CheckoutDetailedProductContext.Provider
      value={{
        product,
        loadProduct: setProduct,
        imageIndex,
        changeImage: setImageIndex,
        selectedSku: sku,
        changeSku: setSku,
        getFilteredAttributes,
      }}
    >
      {children}
    </CheckoutDetailedProductContext.Provider>
  );
};
