import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 24px;
  padding-bottom: 24px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #eeeeed;
`;
