import cookie from '@plug/helpers/cookie';

import { USER_SERVICES_PERMISSIONS_STORAGE_KEY } from '@plug/config/consts';
import { getLocalInfo } from './local-info';

export default function checkUserServicePermission(
  service: string,
  permissionName: string,
): ServicePermission | undefined {
  const userServicesPermissions = getLocalInfo(USER_SERVICES_PERMISSIONS_STORAGE_KEY, {
    json: true,
  });

  if (userServicesPermissions.length) {
    return userServicesPermissions.find(
      (item: any) => item.permissionName === permissionName && item.service.name === service,
    );
  }
}
