import { css } from 'styled-components';

const customScrollBar = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: white;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }): string => theme.colors.neutralHighLight};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }): string => theme.colors.neutralLowLight};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-corner {
    background: white;
  }
`;

export default customScrollBar;
