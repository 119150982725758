import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Vtex: React.FC<SvgProps> = props => (
  <SvgIcon width={112} height={40} viewBox="0 0 112 40" fill="none" {...props}>
    <path
      d="M81.922 15.288H78.0302V28.3852C78.0299 28.5045 77.9816 28.6189 77.8956 28.7033C77.8097 28.7877 77.6932 28.8352 77.5717 28.8354H74.5703C74.4488 28.8352 74.3323 28.7877 74.2464 28.7033C74.1604 28.6189 74.112 28.5045 74.1118 28.3852V15.288H70.1972C70.1382 15.2904 70.0794 15.2811 70.0242 15.2607C69.9689 15.2403 69.9184 15.2093 69.8757 15.1694C69.8329 15.1295 69.7987 15.0815 69.7752 15.0284C69.7517 14.9753 69.7392 14.9181 69.7386 14.8602V12.5436C69.7392 12.4856 69.7517 12.4284 69.7752 12.3753C69.7987 12.3222 69.8329 12.2742 69.8757 12.2343C69.9184 12.1944 69.9689 12.1634 70.0242 12.143C70.0794 12.1226 70.1382 12.1134 70.1972 12.1157H81.921C82.0425 12.1101 82.1614 12.152 82.2516 12.2322C82.3418 12.3123 82.396 12.4243 82.4024 12.5436V14.8611C82.3957 14.98 82.3415 15.0916 82.2516 15.1716C82.1616 15.2515 82.0432 15.2933 81.922 15.288Z"
      fill="#302C44"
    />
    <path
      d="M94.4153 28.741C93.6133 28.8531 92.2644 29.0333 89.7901 29.0333C86.8344 29.0333 84.223 28.2907 84.223 24.1965V16.7236C84.223 12.6294 86.8581 11.9092 89.8129 11.9092C92.2863 11.9092 93.6133 12.0895 94.4153 12.2016C94.7359 12.2464 94.8738 12.3594 94.8738 12.6518V14.747C94.8736 14.8664 94.8252 14.9807 94.7393 15.0651C94.6533 15.1495 94.5368 15.197 94.4153 15.1973H89.5846C88.5077 15.1973 88.1186 15.5569 88.1186 16.7273V18.7749H94.2327C94.3542 18.7752 94.4707 18.8227 94.5566 18.9071C94.6425 18.9914 94.6909 19.1058 94.6912 19.2252V21.3624C94.6909 21.4817 94.6425 21.5961 94.5566 21.6805C94.4707 21.7649 94.3542 21.8124 94.2327 21.8126H88.1186V24.1974C88.1186 25.3669 88.5077 25.7275 89.5846 25.7275H94.4153C94.5368 25.7278 94.6533 25.7753 94.7393 25.8597C94.8252 25.944 94.8736 26.0584 94.8738 26.1778V28.2702C94.8738 28.5607 94.7359 28.6961 94.4153 28.741Z"
      fill="#302C44"
    />
    <path
      d="M111.702 28.8281H108.06C107.762 28.8281 107.624 28.7347 107.464 28.4908L104.306 23.5671L101.444 28.3825C101.283 28.6524 101.124 28.8327 100.873 28.8327H97.483C97.2537 28.8327 97.1396 28.6973 97.1396 28.5404C97.144 28.4853 97.1595 28.4316 97.1852 28.3825L102.148 20.2557L97.1339 12.539C97.1084 12.4978 97.0928 12.4515 97.0882 12.4035C97.0964 12.3209 97.1366 12.2445 97.2005 12.1901C97.2644 12.1357 97.3471 12.1074 97.4316 12.1112H101.12C101.372 12.1112 101.555 12.3363 101.691 12.539L104.622 17.0835L107.458 12.539C107.572 12.3363 107.779 12.1112 108.029 12.1112H111.42C111.504 12.1074 111.587 12.1357 111.651 12.1901C111.715 12.2445 111.755 12.3209 111.763 12.4035C111.758 12.4515 111.743 12.4978 111.717 12.539L106.728 20.3033L111.931 28.3825C111.972 28.4512 111.995 28.5283 112 28.6076C112.001 28.7384 111.886 28.8281 111.702 28.8281Z"
      fill="#302C44"
    />
    <path
      d="M64.2459 12.16C64.1641 12.1602 64.0849 12.1883 64.0218 12.2394C63.9586 12.2905 63.9155 12.3614 63.8997 12.4403L60.5739 24.5268C60.5282 24.7743 60.4597 24.864 60.2533 24.864C60.0469 24.864 59.9784 24.7706 59.9327 24.5268L56.6032 12.4375C56.5873 12.3586 56.5442 12.2877 56.481 12.2366C56.4179 12.1855 56.3387 12.1574 56.2569 12.1572H52.9835C52.9302 12.1573 52.8777 12.1691 52.8298 12.1919C52.7818 12.2146 52.7398 12.2477 52.7067 12.2887C52.6736 12.3296 52.6503 12.3773 52.6386 12.4283C52.6269 12.4793 52.6271 12.5323 52.6391 12.5832C52.6391 12.5832 56.7011 26.4445 56.7459 26.5809C57.2881 28.2324 58.6038 29.0217 60.2781 29.0217C61.8724 29.0217 63.2642 28.195 63.8083 26.5855C63.873 26.3987 67.8447 12.5822 67.8447 12.5822C67.8566 12.5314 67.8566 12.4786 67.8448 12.4277C67.8331 12.3768 67.8098 12.3292 67.7767 12.2884C67.7436 12.2475 67.7016 12.2146 67.6537 12.1919C67.6059 12.1691 67.5535 12.1573 67.5003 12.1572L64.2459 12.16Z"
      fill="#302C44"
    />
    <path
      d="M44.0779 0H8.49917C5.74039 0 3.97382 2.87987 5.26474 5.274L8.82356 11.8913H2.37182C1.96324 11.8914 1.56162 11.9951 1.20585 12.1924C0.850087 12.3897 0.552236 12.6738 0.341152 13.0174C0.130067 13.3609 0.0129017 13.7521 0.00100597 14.1531C-0.0108897 14.5541 0.0828861 14.9514 0.273251 15.3064L11.7203 36.5808C11.9221 36.955 12.2239 37.268 12.5933 37.4864C12.9627 37.7048 13.3858 37.8201 13.8169 37.8201C14.2481 37.8201 14.6712 37.7048 15.0406 37.4864C15.41 37.268 15.7118 36.955 15.9136 36.5808L19.0225 30.8341L22.9228 38.0847C24.2955 40.6348 28.0142 40.6395 29.3917 38.0922L47.2248 5.14042C48.4853 2.80981 46.7653 0 44.0779 0ZM28.096 14.0789L20.4066 28.2905C20.2722 28.5394 20.0714 28.7475 19.8256 28.8927C19.5798 29.0379 19.2984 29.1146 19.0115 29.1146C18.7247 29.1146 18.4433 29.0379 18.1975 28.8927C17.9517 28.7475 17.7508 28.5394 17.6165 28.2905L10.0013 14.1387C9.87399 13.9028 9.81092 13.6386 9.81816 13.3716C9.8254 13.1047 9.9027 12.8442 10.0426 12.6152C10.1825 12.3862 10.3802 12.1966 10.6166 12.0647C10.8531 11.9328 11.1202 11.8631 11.3921 11.8623H26.7394C27.0046 11.8623 27.2652 11.9297 27.4959 12.0579C27.7267 12.1861 27.9197 12.3707 28.0563 12.5938C28.1928 12.817 28.2682 13.071 28.2752 13.3313C28.2822 13.5915 28.2204 13.8491 28.096 14.0789Z"
      fill="#302C44"
    />
  </SvgIcon>
);

export default Vtex;
