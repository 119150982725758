import React from 'react';
import Loadable from 'react-loadable';

import { RouteOrRedirectType } from '@plug/routes/types';
import { Flex } from 'rebass';
import { Spinner } from '@gohubly/design-system';

const Loading = (): JSX.Element => (
  <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
    <Spinner />
  </Flex>
);

const SettingsPage = Loadable({
  loader: () => import('./pages/settings'),
  loading: Loading,
});

const OrganizationInfosPage = Loadable({
  loader: () => import('./pages/organization-infos'),
  loading: Loading,
});

const TeammatesPage = Loadable({
  loader: () => import('./pages/teammates'),
  loading: Loading,
});

const PublicOrganizationPage = Loadable({
  loader: () => import('./pages/public-organization'),
  loading: Loading,
});

const IntegrationDomainPage = Loadable({
  loader: () => import('./pages/integration-domain'),
  loading: Loading,
});

const CustomizationPage = Loadable({
  loader: () => import('./pages/customization'),
  loading: Loading,
});

const ApikeyPage = Loadable({
  loader: () => import('./pages/api-key'),
  loading: Loading,
});

const WebhookPage = Loadable({
  loader: () => import('./pages/webhook'),
  loading: Loading,
});

const MembersSolicitationsPage = Loadable({
  loader: () => import('./pages/members-solicitations'),
  loading: Loading,
});
export const SETTINGS_PATH = '/org/:organizationSlug/settings';
export const ORGANIZATION_INFOS_PATH = '/org/:organizationSlug/settings/organization-infos';
export const SETTINGS_ADMIN_PATH = '/org/:organizationSlug/settings/teammates';
export const PUBLIC_ORGANIZATION_ADMIN_PATH = '/org/:organizationSlug/settings/public-organization';
export const INTEGRATION_DOMAIN_ADMIN_PATH = '/org/:organizationSlug/settings/integration';
export const CUSTOMIZATION_ADMIN_PATH = '/org/:organizationSlug/settings/layout-customization';
export const API_KEY_ADMIN_PATH = '/org/:organizationSlug/settings/api-key';
export const WEBOOK_ADMIN_PATH = '/org/:organizationSlug/settings/webhook';
export const MEMBERS_SOLICITATIONS_ADMIN_PATH =
  '/org/:organizationSlug/settings/members-solicitation';

const routes: RouteOrRedirectType[] = [
  {
    path: ORGANIZATION_INFOS_PATH,
    component: OrganizationInfosPage,
    exact: false,
    title: 'Informações da organização - Configurações',
  },
  {
    path: MEMBERS_SOLICITATIONS_ADMIN_PATH,
    component: MembersSolicitationsPage,
    exact: false,
    title: 'Solicitações de Membros - Configurações',
  },
  {
    path: WEBOOK_ADMIN_PATH,
    component: WebhookPage,
    exact: false,
    title: 'Webhook - Configurações',
  },
  {
    path: API_KEY_ADMIN_PATH,
    component: ApikeyPage,
    exact: false,
    title: 'API Key - Configurações',
  },
  {
    path: CUSTOMIZATION_ADMIN_PATH,
    component: CustomizationPage,
    exact: false,
    title: 'Customização - Configurações',
  },
  {
    path: INTEGRATION_DOMAIN_ADMIN_PATH,
    component: IntegrationDomainPage,
    exact: false,
    title: 'Integração e Domínio - Configurações',
  },
  {
    path: PUBLIC_ORGANIZATION_ADMIN_PATH,
    component: PublicOrganizationPage,
    exact: false,
    title: 'Organização Publica - Configurações',
  },
  {
    path: SETTINGS_ADMIN_PATH,
    component: TeammatesPage,
    exact: false,
    title: 'Administradores - Configurações',
  },
  {
    path: SETTINGS_PATH,
    component: SettingsPage,
    exact: false,
    title: 'Configurações',
  },
];

export default routes;
