import {
  Col as _Col,
  ColProps,
  Row as _Row,
  RowProps,
  Container as _Container,
  ContainerProps,
} from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { background, BackgroundProps } from 'styled-system';

export const Grid = styled(_Container)<ContainerProps>`
  overflow: hidden;
`;

export const Row = styled(_Row)<RowProps>``;

export const Col = styled(_Col)<ColProps & BackgroundProps>`
  ${background}
`;

Grid.displayName = 'Grid';
Row.displayName = 'Row';
Col.displayName = 'Col';
