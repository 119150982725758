import React from 'react';
import { TextField } from '@plug/ui';
import { useTranslation } from 'react-i18next';

const IntegrationFormWake = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.wake.token.label',
        )}
        name="wakeToken"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.wake.token.placeholder',
        )}
        width="100%"
      />

      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.wake.storeFrontToken.label',
        )}
        name="storeFrontToken"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.wake.storeFrontToken.placeholder',
        )}
        width="100%"
      />
    </>
  );
};

export default IntegrationFormWake;
