import React, { FC } from 'react';
import * as css from './checkout-catalog-section.styles';
import { CheckoutCatalogSectionProps } from './checkout-catalog-section.types';
import { Typography } from '@gohubly/design-system';

const CheckoutCatalogSection: FC<CheckoutCatalogSectionProps> = ({ title, children }) => {
  return (
    <css.Section>
      {title && (
        <css.Title>
          <Typography color="textNeutralDefault" size="20" fontWeight={400} lineHeight="28">
            {title}
          </Typography>
        </css.Title>
      )}

      {children}
    </css.Section>
  );
};

export default CheckoutCatalogSection;
