import React from 'react';
import { Flex, Text } from 'rebass';
import { useSideViewContext } from './side-view';
import { Close } from '../icons';
import { ThemeStore } from '@plug/redux/store';
import isDesktop from '@plug/helpers/is-desktop';

type SideViewHeaderProps = {
  title: string | React.ReactNode;
  style?: any;
};

const SideViewHeader: React.FC<SideViewHeaderProps> = ({ title, style }) => {
  const { onCloseSideView } = useSideViewContext();
  const theme = ThemeStore.useState(s => s);
  return (
    <Flex px={isDesktop ? '32px' : '20px'} py="16px" style={style} justifyContent='space-between'>
      <Flex alignItems="center">
        <Text
          fontSize={isDesktop ? '24px' : '20px'}
          lineHeight={isDesktop ? '40px' : '36px'}
          fontWeight={500}
          color="#1C1637"
        >
          {title}
        </Text>
      </Flex>
      <Flex
        onClick={onCloseSideView}
        alignItems="center"
        style={{ cursor: 'pointer', padding: '5px', zIndex: 5 }}
      >
        <Close
          fill={theme.primary || '#3B24A8'}
          width={isDesktop ? 16 : 14}
          height={isDesktop ? 16 : 14}
        />
      </Flex>
    </Flex>
  );
};

export default SideViewHeader;
