// import { User } from '../../types';

import { GetUserPendenciesPayload } from '@plug/domains/organization/graphql/queries/get-user-pendencies';
import { Integrations } from '@plug/graphql/types';

// import { ApolloError } from 'apollo-client';

export type AuthContextType = {
  // token?: string;
  user?: User;
  selectCurrentOrganization?: (organization: any) => void;
  currentOrganization?: any;
  setCurrentOrganization?: (currentOrganization: any) => void;
  // authenticate(email: string, password: string): Promise<void>;
  // logout(): void;
  verifySession(): boolean;
  resetContext(): void;
  verifyProfiles(requestedProfiles: string[]): boolean;
  disableOrganization: (ids: string[]) => void;
  disabledOrganizations: string[];
  pendencies: GetUserPendenciesPayload['getUserPendencies'];
  // loading?: boolean;
  // error?: ApolloError;
};

export type CurrentOrganizationType = {
  id: string;
  logo: string;
  contactEmail: string;
  name: string;
  active: boolean;
  slug: string;
  domain: string;
  public: boolean;
  freeTrial: boolean;
  freeTrialExpires: string;
  freePlan: string;
  organizationRole: OrganizationRole[];
  integration: Integration;
  __typename: string;
};

type Integration = {
  type: Integrations;
  subtype: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  asSaas: boolean;
  __typename: string;
};

type OrganizationRole = {
  name: string;
  __typename: string;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const dummyContext: AuthContextType = {
  // authenticate: (email: string, password: string) =>
  //   new Promise((resolve, reject) => {
  //     reject('No authenticate method set');
  //   }),
  // logout: () => undefined,
  verifySession: () => false,
  resetContext: () => null,
  verifyProfiles: requestedProfiles => false,
  disabledOrganizations: [],
  disableOrganization: null,
  pendencies: [],
};
