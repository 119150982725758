import styled from 'styled-components';
import { Box } from 'rebass';

interface TooltipI {
  bottomOffset: number;
}
export default styled(Box)<TooltipI>`
  position: absolute;
  bottom: ${({ bottomOffset }): number => (bottomOffset > 95 ? bottomOffset - 45 : bottomOffset + 12)}%;

  opacity: 0;

  width: 230px;
  padding: 15px;
  border-radius: 4px;

  border: 1px solid #eae9ec;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #fff;

  pointer-events: none;
  z-index: 9999999;

  transition: all 0.1s linear;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -6px;
  //   left: 50%;
  //   transform: translateX(-50%) rotate(45deg);

  //   background: #fff;

  //   height: 14px;
  //   width: 14px;
  // }
`;
