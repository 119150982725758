import React from 'react';
import { RouteOrRedirectType } from '@plug/routes/types';

import Main from '@plug/components/layouts/main';

const Dashboard = React.lazy(() => import('@plug/domains/dashboard/pages/dashboard'));

export const DASHBOARD_PATH = '/dashboard';

const routes: RouteOrRedirectType[] = [
  {
    component: Main,
    path: DASHBOARD_PATH,
    exact: true,
    privateRoute: true,
    routes: [
      {
        component: Dashboard,
        // requiredProfiles // quais profiles o cara precisa para entrar na rota, improve no context/index = verifyProfiles //consultar README
        title: 'Dashboard',
        exact: true,
      },
    ],
  },
];

export default routes;
