import styled, { css } from 'styled-components';

export const Container = styled.div<{ empty?: boolean }>`
  padding: 24px 0;

  flex: 1;
  max-height: 764px;

  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: white;
  border-radius: 16px;

  ${({ empty }) =>
    empty &&
    css`
      max-height: 434;
    `}
`;
