import React from 'react';
import { Box } from 'rebass';
import { Menu } from '../';

const ActionsCell = ({ row, actions }: any) => (
  <td style={{ width: '100px', flex: '0 0 auto', minWidth: 0 }}>
    <Box
      sx={{
        '@media screen and (max-width: 968px)': {
          display: 'none',
        },
      }}
    >
      <Menu
        items={actions({ row }).map(({ text, iconTable, onClick }: any) => ({
          text,
          icon: iconTable,
          onClick,
        }))}
      />
    </Box>
  </td>
);

export default ActionsCell;
