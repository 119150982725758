import React, { FC, createContext, useContext, useState } from 'react';
import { ORGANIZATION_CUSTOMIZATION_KEY } from '@plug/config/consts';
import _ from 'lodash';
import { getLocalInfo, saveLocalInfo } from '@plug/helpers/local-info';
import { ThemeStore, WhiteLabelStore } from '@plug/redux/store';
import { useAuthContext } from './auth-context';
import { useQuery } from '@apollo/client';
import {
  GET_ORGANIZATION_CUSTOMIZATION,
  GetOrganizationCustomizationResponse,
  GetOrganizationCustomizationVariables,
} from '@plug/domains/organization/domains/settings/graphql/queries/get-organization-customization';

interface OrganizationCustomizationContext {
  customization?: OrganizationCustomization;
  update(customization: Partial<OrganizationCustomization>): void;
}

const OrganizationCustomizationContext = createContext<OrganizationCustomizationContext>({
  update: null,
});

export const useOrganizationCustomization = () => useContext(OrganizationCustomizationContext);

export const OrganizationCustomizationProvider: FC = ({ children }) => {
  const [customization, setCustomization] = useState<OrganizationCustomization>(
    getLocalInfo<ColorCustomization>(ORGANIZATION_CUSTOMIZATION_KEY),
  );
  const { currentOrganization } = useAuthContext();
  const isWhiteLabel = WhiteLabelStore.useState(s => s.isWhiteLabel);
  const key = isWhiteLabel
    ? window.location.hostname.replace(/\./gi, '-')
    : currentOrganization?.id;

  useQuery<GetOrganizationCustomizationResponse, GetOrganizationCustomizationVariables>(
    GET_ORGANIZATION_CUSTOMIZATION,
    {
      variables: {
        ...(!isWhiteLabel &&
          currentOrganization?.id && { input: { organizationId: currentOrganization?.id } }),
      },
      onCompleted(data) {
        if (data?.getOrganizationCustomization) {
          update(data.getOrganizationCustomization);
        }
      },
      skip: !isWhiteLabel && !currentOrganization?.id,
    },
  );

  const update = (data: OrganizationCustomization) => {
    setCustomization(data);
    saveLocalInfo(ORGANIZATION_CUSTOMIZATION_KEY, data);

    ThemeStore.update(s => {
      s.primary = data.buttons;
    });
  };

  return (
    <OrganizationCustomizationContext.Provider
      value={{
        customization,
        update,
      }}
    >
      {children}
    </OrganizationCustomizationContext.Provider>
  );
};
