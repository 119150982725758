import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { getLocalInfo } from '@plug/helpers/local-info';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';
import {
  NAV_SIDEBAR_HEIGHT,
  MENU_HEIGHT_MOBILE,
  NAV_SIDEBAR_WIDTH,
  NAV_SIDEBAR_HEIGHT_MOBILE,
} from '../layouts/dashboard';
import { ComponentProps } from 'react';

export const NavSidebarFlexContainer = styled(Flex)`
  flex-direction: column;
  position: relative;
`;

export const NavSidebarHeader = styled(Flex)`
  width: 244px;
  gap: 16px;
  align-items: center;

  height: ${(): string => NAV_SIDEBAR_HEIGHT};
  padding: 16px 16px 16px 24px;

  background: ${({ theme }): string => getCustomizatedColor('header', theme.colors.primaryDark)};

  cursor: pointer;

  @media screen and (max-width: 768px) {
    background: ${({ theme }): string => theme.colors.white};
    height: ${(): string => NAV_SIDEBAR_HEIGHT_MOBILE};
  }
`;

export const Overlay = styled.div<ComponentProps<'div'> & { show?: boolean; zIndex?: number }>`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: ${(): string => NAV_SIDEBAR_HEIGHT};
  left: 0;

  display: flex;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.1);

  z-index: 5;
  opacity: ${({ show }) => (show ? '1' : '0')};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  transition: opacity 1s, visibility 0.5s;
  cursor: pointer;
`;

export const Content = styled.div<
  ComponentProps<'div'> & {
    show?: boolean;
    width?: string;
    minWidth?: string;
    zIndex?: number;
  }
>`
  position: fixed;
  left: 0;
  top: ${(): string => NAV_SIDEBAR_HEIGHT};

  height: auto;
  background: ${({ theme }): string => theme.colors.white};

  z-index: 2;
  margin: 16px;
  padding: 16px;
  border-radius: 16px;
  width: ${(): string => NAV_SIDEBAR_WIDTH};

  @media screen and (max-width: 768px) {
    top: ${(): string => MENU_HEIGHT_MOBILE};
    height: ${(): string => `calc(100vh - ${MENU_HEIGHT_MOBILE})`};
    width: 100%;
    margin: 0;
  }

  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(-100%)')};

  transition: transform 0.5s;
`;

export const MenuIcon = styled.div<{ opened: boolean }>`
  display: grid;
  place-items: center;
  height: 100%;

  .menu {
    width: 15px;
    cursor: pointer;
  }

  .menu span {
    width: 100%;
    height: 1.5px;
    display: block;
    background: ${({ theme }) => theme.colors.white};
    transition: all 0.4s;
    border-radius: 20px;
  }

  .stuffing {
    margin: 3px 0;
  }

  .top-bun {
    transform: ${({ opened }): string =>
      opened ? 'translateX(-1px) translateY(1.25px) rotate(-45deg)' : ''}!important;
    width: ${({ opened }): string => (opened ? '70%' : '100%')}!important;
    border-radius: ${({ opened }): string => (opened ? '0 20px 20px 0' : '20px')}!important;
  }

  .stuffing {
    width: ${({ opened }): string => (opened ? '0%' : '100%')}!important;
  }

  .bottom-bun {
    transform: ${({ opened }): string =>
      opened ? 'translateX(-1px) translateY(-1.25px) rotate(45deg)' : ''}!important;
    width: ${({ opened }): string => (opened ? '70%' : '70%')}!important;
    border-radius: ${({ opened }): string => (opened ? '0 20px 20px 0' : '20px')}!important;
  }
`;
