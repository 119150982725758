import gql from 'graphql-tag';

export const GET_ORDER_LIST_BY_AFFILIATE_ID_AND_REFERENCE_MONTH = gql`
  query getOrderListByAffiliateIdAndReferenceMonth(
    $input: getOrderListByAffiliateIdAndReferenceMonthInput!
  ) {
    getOrderListByAffiliateIdAndReferenceMonth(input: $input) {
      id
      orders
      revenue
      commission
      name
      document
      invoice
      orderList {
        id
        client
        date
        value
        commission
        isPaid
      }
    }
  }
`;

export type GetOrderListByAffiliateIdAndReferenceMonthQuery = {
  getOrderListByAffiliateIdAndReferenceMonth: {
    id: string;
    orders: number;
    revenue: number;
    commission: number;
    name: string;
    document: string;
    invoice?: string;
    orderList: {
      id: string;
      client: string;
      date: string;
      value: number;
      commission: number;
      isPaid: boolean;
    }[];
  };
};

export type GetOrderListByAffiliateIdAndReferenceMonthInput = {
  input: {
    affiliateId: string;
    referenceMonth: string;
  };
};
