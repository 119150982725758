import { Icon, Tooltip, TooltipV3 } from '@gohubly/design-system';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';

interface Props {
  data: {
    ballColor?: string;
    text: string | number;
    value: string | number;
    help?: string;
  }[];
}

interface ColouredBallI {
  color: string;
  size?: string;
}

export const Container = styled(Flex)`
  gap: 64px;
  align-items: center;
  margin-bottom: 48px !important;
  padding: 14px 24px 0 24px;

  @media screen and (max-width: 768px) {
    width: 100% !important;

    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    white-space: nowrap;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
`;

export const Items = styled(Flex)`
  min-width: max-content !important;
  flex-direction: column;
`;

export const ColouredBall = styled(Box)<ColouredBallI>`
  height: ${({ size }): string => (size ? size : '10px')};
  width: ${({ size }): string => (size ? size : '10px')};
  border-radius: 100%;
  margin-right: 10px !important;

  background: ${({ color }): string => color};
`;

const PaymentInfoItem = ({ data }: Props): JSX.Element => {
  return (
    <Container>
      {data.map(item => (
        <Items key={item.value}>
          <Flex width="max-content" height="36px" alignItems="center">
            {item.ballColor && <ColouredBall color={item.ballColor} />}

            <Text
              sx={{ textTransform: 'uppercase' }}
              color="#8C8A95"
              fontSize="14px"
              width="max-content"
            >
              {item.text}
            </Text>

            {item.help && (
              <TooltipV3
                children={
                  <Icon
                    iconId="helpCircle"
                    size="XS"
                    stroke="neutralLowMedium"
                    fill="transparent"
                    strokeWidth={2.25}
                  />
                }
                text={
                  <Box p={'8px 4px'}>
                    <Text
                      sx={{ textTransform: 'uppercase', marginBottom: '8px' }}
                      fontSize="14px"
                      lineHeight="20px"
                    >
                      {item.help === 'netRevenue' ? 'Receita bruta:' : 'Receita líquida:'}
                    </Text>
                    <Text fontSize="14px">
                      {item.help === 'netRevenue'
                        ? 'Total da soma dos pedidos, incluindo valores de cupons ou descontos aplicados.'
                        : 'Total da soma dos pedidos, excluindo valores de cupons ou descontos aplicados.'}
                    </Text>
                  </Box>
                }
                pointerOrientation="up"
                size="LG"
                pointerPosition="center"
                position="down"
                maxWidth="330px"
              />
            )}
          </Flex>

          <Text color="#1C1637" fontWeight="bold" fontSize="20px" width="max-content">
            {item.value}
          </Text>
        </Items>
      ))}
    </Container>
  );
};

export default PaymentInfoItem;
