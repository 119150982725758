import isDesktop from '@plug/helpers/is-desktop';
import React from 'react';
import { Box, BoxProps } from 'rebass';

const HEADER_HEIGHT_DESKTOP = '72px';
const HEADER_HEIGHT_MOBILE = '68px';

type SideViewContentProps = {
  children: React.ReactNode;
} & BoxProps;

const SideViewContent: React.FC<SideViewContentProps> = ({ children, ...rest }) => {
  return (
    <Box
      px={isDesktop ? '24px' : '20px'}
      pt={isDesktop ? '24px' : '20px'}
      pb={isDesktop ? '24px' : '20px'}
      width="100%"
      height={`calc(100% - ${isDesktop ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE})`}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default SideViewContent;
