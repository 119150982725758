import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Check: React.FC<SvgProps> = props => (
  <SvgIcon {...props}>
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </SvgIcon>
);

export default Check;
