import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const CaretDown: React.FC<SvgProps> = props => {
  return (
    <SvgIcon viewBox={'0 0 10 6'} width={10} height={6} {...props}>
      <path
        d="M0.677774 0C0.0723255 0 -0.230399 0.781065 0.20687 1.2426L4.51228 5.78698C4.78137 6.07101 5.21863 6.07101 5.48772 5.78698L9.79313 1.2426C10.2304 0.781065 9.92768 0 9.32223 0H0.677774Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default CaretDown;
