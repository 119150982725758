import React from 'react';
import { SvgProps } from './SvgIcon';

const Tray: React.FC<SvgProps> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 125 55" fill="none">
    <path
      d="M87.8172 11.4662C87.8172 21.9925 93.8387 32.0677 96.2473 35.5263C96.8495 36.3534 97.7527 36.8797 98.7312 36.8797H114.086C115.065 36.8797 116.043 36.3534 116.57 35.5263C118.978 31.9925 125 21.9173 125 11.4662H87.8172Z"
      fill="#43A0D6"
    />
    <path
      d="M89.172 37.7068C89.0968 37.6316 89.0215 37.5564 88.9462 37.406C89.0215 37.5564 89.0968 37.6316 89.172 37.7068Z"
      fill="#002C6A"
    />
    <path
      d="M75.7742 15.8271H79.3871C79.7634 17.9323 80.4408 20.7143 81.5699 23.8722C80.4408 20.7143 79.7634 17.9323 79.3871 15.8271H75.7742Z"
      fill="#002C6A"
    />
    <path
      d="M85.1828 46.0526C86.086 44.5489 86.914 43.0451 87.6667 41.5414C86.914 43.1203 86.086 44.6241 85.1828 46.0526Z"
      fill="#002C6A"
    />
    <path
      d="M89.4731 37.9323C89.0215 38.8346 88.5699 39.812 88.1183 40.7143C88.5699 39.812 89.0215 38.9098 89.4731 37.9323Z"
      fill="#002C6A"
    />
    <path
      d="M101.215 19.0601C102.344 14.5489 102.72 11.5414 102.72 11.5414H97.3763C97.0752 14.2481 96.4731 17.2556 95.7204 20.4135C95.6451 20.6391 95.5699 20.8647 95.5699 21.0902C94.8172 23.9474 93.914 26.9549 92.7849 29.9624C92.4086 31.015 91.957 32.0677 91.5806 33.1203C91.5054 33.0451 91.4301 32.9699 91.4301 32.8947C91.3548 32.8195 91.3548 32.8195 91.2795 32.7444C90.3763 31.2406 88.9462 28.8346 87.6666 25.6015C87.6666 25.5263 87.5914 25.4511 87.5914 25.3759C87.4408 24.9248 87.215 24.4737 87.0645 23.9474C85.7849 20.4887 84.6559 16.2782 84.2043 11.5414H75.7742V15.7519H79.3871C79.7634 17.8571 80.4408 20.6391 81.5699 23.797C83 27.8571 85.1828 32.4436 88.5699 36.8797C88.7204 37.0301 88.8709 37.2556 89.0215 37.406C89.0968 37.4812 89.172 37.6316 89.2473 37.7068C89.3226 37.782 89.3978 37.8571 89.4731 37.9323C89.0215 38.8346 88.5699 39.812 88.1183 40.7143C87.9677 41.015 87.8172 41.2406 87.6666 41.5413C86.8387 43.0451 86.0107 44.6241 85.1828 46.0526H90.0752C91.129 44.3985 92.1828 42.7444 93.086 41.015C94.1398 39.0601 95.043 37.1053 95.8709 35.2256C96.9247 32.7444 97.8279 30.3383 98.5806 28.0075C98.7312 27.5564 98.8817 27.1053 99.0322 26.6541C99.1828 26.203 99.3333 25.7519 99.4838 25.3007C99.8602 23.9474 100.237 22.6692 100.538 21.4662C100.763 20.7143 100.914 19.9624 101.064 19.2857C101.14 19.2105 101.14 19.1353 101.215 19.0601Z"
      fill="#002C6A"
    />
    <path
      d="M89.0968 51.7669C89.0968 53.5714 87.6667 55 85.8602 55C84.0538 55 82.6237 53.5714 82.6237 51.7669C82.6237 49.9624 84.0538 48.5338 85.8602 48.5338C87.5914 48.5338 89.0968 49.9624 89.0968 51.7669Z"
      fill="#002C6A"
    />
    <path
      d="M118.376 51.7669C118.376 53.5714 116.946 55 115.14 55C113.333 55 111.903 53.5714 111.903 51.7669C111.903 49.9624 113.333 48.5338 115.14 48.5338C116.946 48.5338 118.376 49.9624 118.376 51.7669Z"
      fill="#002C6A"
    />
    <path
      d="M25.4194 38.6842C20 38.6842 16.8387 35.9023 16.8387 29.3609V15.6767H13V11.391H16.8387V5.30075L22.1828 5V11.391H28.8065V15.6767H22.1828V29.2857C22.1828 32.8195 23.6882 34.1729 26.3226 34.1729C27.4516 34.1729 28.5806 34.0226 29.4086 33.797L29.7097 38.0827C28.4301 38.4586 27.0753 38.6842 25.4194 38.6842Z"
      fill="#002C6A"
    />
    <path
      d="M46.2688 10.7143C47.7742 10.7143 49.0538 10.8647 50.0323 11.0902L49.2796 15.6015C48.4516 15.3008 47.4731 15.2256 46.6452 15.2256C44.0108 15.2256 41.9785 15.8271 40.4731 16.579V38.0827H35.129V13.2707C37.914 11.7669 42.2043 10.7143 46.2688 10.7143Z"
      fill="#002C6A"
    />
    <path
      d="M62.6775 38.6842C55.6775 38.6842 52.2151 35.3007 52.2151 30.7143C52.2151 25.2256 56.6559 22.5188 62.6775 22.5188C64.4839 22.5188 66.6667 22.7443 68.3226 23.1203V20.9398C68.3226 17.2556 66.2904 15.2256 62.0753 15.2256C59.5914 15.2256 57.0323 15.6767 54.9248 16.5789L53.871 12.2932C56.0538 11.391 59.4409 10.7895 62.6022 10.7895C69.9033 10.7895 73.5162 14.5489 73.5162 21.2406V36.3534C71.3334 37.5564 67.6452 38.6842 62.6775 38.6842ZM68.2473 33.5714V26.6541C66.742 26.2782 65.0861 26.0526 63.2796 26.0526C59.9678 26.0526 57.5592 27.1804 57.5592 30.4887C57.5592 33.2707 59.5914 34.6992 63.1291 34.6992C65.5377 34.6992 67.2689 34.1729 68.2473 33.5714Z"
      fill="#002C6A"
    />
  </svg>
);

export default Tray;
