import { Typography } from '@gohubly/design-system';
import { Button, CurrencyHelpers } from '@monitfy/ds';
import { USER_LOCAL_STORAGE_KEY } from '@plug/config/consts';
import { CHECKOUT_FRONT_URL } from '@plug/config/env';
import { useAuthContext } from '@plug/contexts/auth-context';
import { useCheckoutCartContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutCartContext';
import cookies from 'js-cookie';
import React from 'react';
import { Flex } from 'rebass';

const CartTotalizer = () => {
  const { cart } = useCheckoutCartContext();
  const { currentOrganization } = useAuthContext();
  const user = JSON.parse(decodeURIComponent(cookies.get(USER_LOCAL_STORAGE_KEY) || '{}'));

  const searchParams = new URLSearchParams({
    cartId: cart.id,
    userId: user.id,
    organizationId: currentOrganization.id,
  });
  const checkoutUrl = new URL(`?${searchParams}`, CHECKOUT_FRONT_URL);

  return (
    <Flex
      paddingX="24px"
      paddingTop="16px"
      flexDirection="column"
      style={{ gap: '16px', borderTop: '1px solid #eeeeed' }}
    >
      <Flex justifyContent="space-between">
        <Typography color="textNeutralSubtle" size="20" lineHeight="28" fontWeight={400}>
          Total
        </Typography>

        <Flex flexDirection="column">
          <Typography color="textNeutralDefault" size="20" lineHeight="28">
            {CurrencyHelpers.format('pt-br', cart?.value ?? 0)}
          </Typography>

          {/* <Typography color="textNeutralSubtle" size="14" lineHeight="20" fontWeight={400}>
            em até 10x de R$ 689,90
          </Typography> */}
        </Flex>
      </Flex>

      <Button iconAfter="chevron-right" onClick={() => window.location.replace(checkoutUrl)}>
        Finalizar compra
      </Button>
    </Flex>
  );
};

export default CartTotalizer;
