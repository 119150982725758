import styled from 'styled-components';

export const ImageContainer = styled.div<{ active?: boolean }>`
  position: relative;
  cursor: pointer;
  
  width: 82px;
  height 82px;

  &:after {
    content: '';
    transition: all .5s;

    position: absolute;
    left: 0;
    bottom: 0;

    width: inherit;
    height: inherit;

    border-bottom: ${({ active }) => (active ? '2px solid #1e1a34' : '0')};
    background: ${({ active }) => (active ? 'transparent' : 'rgba(255, 255, 255, 0.8)')};
  }

  &:hover {
    &:after {
      background: transparent;
    }
  }
`;
