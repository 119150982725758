import React, { Component } from 'react';
import { Flex, Text } from 'rebass';
import MediaQuery from 'react-responsive';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <MediaQuery maxWidth={768}>
          {(matches) => (
            <Flex
              justifyContent="center"
              alignItems="center"
              margin="auto"
              height={matches ? '60vh' : '100vh'}
              width="100%"
            >
              <Flex
                width="96%"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="1rem"
                textAlign='center'
              >
                <Text fontSize={matches ? '1.5rem' : '2rem'}>
                  Ops! Tivemos um pequeno problema
                </Text>
                <Text
                  width={matches ? '100%' : '50%'}
                  fontSize=".9rem"
                  textAlign="center"
                  marginTop="1.2rem"
                  color="#373737"
                >
                  Desculpe, parece que estamos com algum problema para exibir seu conteúdo. <br /> Entre
                  em contato conosco para que possamos ajudar!
                </Text>
              </Flex>
            </Flex>
          )}
        </MediaQuery>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
