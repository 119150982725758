import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass';
import { Label, Radio } from '@rebass/forms';
import { ThemeStore } from '@plug/redux/store';
import { Spacer } from '@plug/ui';
import getCustomizatedColor from '@plug/helpers/get-customizated-color';

type IntegrationMethod = {
  title: string;
  type: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  handleChangeIntegrationMethod: (
    type: string,
    onSubmit: any,
    initialValues: any,
    validationSchema: any,
  ) => void;
  onSubmit: any;
  initialValues: any;
  validationSchema: any;
  selected: boolean;
  error: boolean;
  checked?: boolean;
  ref?: any;
};

interface AnimatedContent {
  readonly selected: boolean;
}

const AnimatedContent = styled.div<AnimatedContent>`
  max-height: ${props => (props.selected ? '100%' : 0)};
  overflow: hidden;
  transition: max-height 250ms;
`;

const IntegrationMethod = forwardRef(
  (
    {
      title,
      type,
      selected,
      handleChangeIntegrationMethod,
      onSubmit,
      initialValues,
      validationSchema,
      error,
      icon,
      children,
      checked,
    }: IntegrationMethod,
    ref,
  ) => {
    const theme = ThemeStore.useState(s => s);

    return (
      <Label>
        <Box
          onClick={(): void => {
            handleChangeIntegrationMethod(type, onSubmit, initialValues, validationSchema);
          }}
          ref={ref}
          bg={selected ? '#FFFFFF' : '#F8F9FC'}
          p={16}
          mb={12}
          sx={{
            borderRadius: 8,
            border: '1px solid',
            borderColor: error ? '#C1050F' : selected ? theme.primary || '#3B24A8' : '#EBEEF4',
            svg: {
              fill: checked ? getCustomizatedColor('buttons', theme.primary) || '#3B24A8' : 'auto',
            },
            '&:hover': {
              bg: '#fff',
              borderColor: getCustomizatedColor('buttons', theme.primary) || '#3B24A8',
              h2: {
                color: getCustomizatedColor('buttons', theme.primary) || '#3B24A8',
              },
              svg: {
                fill: getCustomizatedColor('buttons', theme.primary) || '#3B24A8',
              },
            },
          }}
          width="100%"
        >
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" style={{ gap: '10px' }}>
              {icon}
              <Text fontSize="16px" lineHeight="24px">
                {title}
              </Text>
            </Flex>

            <Flex>
              <Radio
                id={`integration-method-${type}`}
                name="integrationMethod"
                value={type}
                checked={checked}
                margin={0}
                sx={{
                  'input:focus ~ &': {
                    color: getCustomizatedColor('buttons', theme.primary) || 'red',
                    bg: '#fff',
                  },
                }}
              />
            </Flex>
          </Flex>
          {children}
        </Box>
      </Label>
    );
  },
);

export default IntegrationMethod;
