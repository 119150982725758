import React from 'react';
import { TextField } from '@plug/ui';
import { useTranslation } from 'react-i18next';

const IntegrationFormLojaIntegrada = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.loja-integrada.token.label',
        )}
        name="lojaIntegradaToken"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.loja-integrada.token.placeholder',
        )}
        width="100%"
      />
    </>
  );
};

export default IntegrationFormLojaIntegrada;
