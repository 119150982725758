import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const CaretRight: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);
  return (
   <SvgIcon viewBox={'0 0 6 10'} width={6} height={10} {...props}>
     <path
       d="M0 9.32223C0 9.92767 0.781065 10.2304 1.2426 9.79313L5.78698 5.48772C6.07101 5.21863 6.07101 4.78137 5.78698 4.51228L1.2426 0.206869C0.781065 -0.230399 0 0.0723248 0 0.677773L0 9.32223Z"
       fill={theme.primary || "#3B24A8"}
     />
     <path d="M0 9.32223C0 9.92767 0.781065 10.2304 1.2426 9.79313L5.78698 5.48772C6.07101 5.21863 6.07101 4.78137 5.78698 4.51228L1.2426 0.206869C0.781065 -0.230399 0 0.0723248 0 0.677773L0 9.32223Z" />
   </SvgIcon>
 );
}

export default CaretRight;
