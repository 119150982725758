import { Store } from 'pullstate';

interface InitialValuesI {
  isWhiteLabel: boolean;
  logo?: string;
  avatar?: string;
  organizationId?: string;
  domain?: string;
  organizationName?: string;
}

const cached = JSON.parse(localStorage.getItem('WHITE_LABEL_INFO') || '{}');
const hasCached = !!Object.keys(cached).length;

export const InitialValue: InitialValuesI = {
  isWhiteLabel: false,
  domain: undefined,
  ...(hasCached &&
    cached?.isWhiteLabel &&
    window.location.host !== 'retailer.gohubly.com' && {
      ...cached,
      isWhiteLabel: true,
      domain: window.location.host,
    }),
};

export default new Store(InitialValue);
