import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Users: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 10 19'} width={10} height={19} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0ZM8.5 0C7.67157 0 7 0.671573 7 1.5C7 2.32843 7.67157 3 8.5 3C9.32843 3 10 2.32843 10 1.5C10 0.671573 9.32843 0 8.5 0ZM0 9.5C0 8.67157 0.671573 8 1.5 8C2.32843 8 3 8.67157 3 9.5C3 10.3284 2.32843 11 1.5 11C0.671573 11 0 10.3284 0 9.5ZM8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8ZM0 17.5C0 16.6716 0.671573 16 1.5 16C2.32843 16 3 16.6716 3 17.5C3 18.3284 2.32843 19 1.5 19C0.671573 19 0 18.3284 0 17.5ZM8.5 16C7.67157 16 7 16.6716 7 17.5C7 18.3284 7.67157 19 8.5 19C9.32843 19 10 18.3284 10 17.5C10 16.6716 9.32843 16 8.5 16Z"
    />
  </SvgIcon>
);

export default Users;
