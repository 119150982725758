import gql from 'graphql-tag';

export const CATEGORIES = gql`
  query @api(name: "catalog") {
    categories {
      id
      name
      url
    }
  }
`;

export type CategoriesReponse = {
  categories: Category[];
};
