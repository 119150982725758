import gql from 'graphql-tag';

export const CART = gql`
  query($id: String!, $metadata: [MetadataInput]!) @api(name: "cart") {
    cart(id: $id, metadata: $metadata) {
      id
      items {
        id
        productId
        name
        price
        listPrice
        quantity
        imageUrl
        sku
        metadata {
          key
          value
        }
      }
      marketingData {
        utmSource
        utmCampaign
      }
      value
    }
  }
`;

export type CartVariables = {
  id?: string;
  metadata: MetadataInput[];
};

export type CartResponse = {
  cart: Partial<Cart>;
};
