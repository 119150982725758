import { Store } from 'pullstate';

export enum MenuGroups {
  MENUITEMS = 'menuitems',
  SERVICES = 'services',
}

interface InitialValuesI {
  mobileMenuActive: boolean;
  activeItem: number | undefined;
  activeSubItem: number | undefined;
  group: MenuGroups;
  listMyOrganizations: any[];
  menuTree: any[];
  isOpen: boolean;
}

export const InitialValue: InitialValuesI = {
  mobileMenuActive: false,
  activeItem: undefined,
  activeSubItem: undefined,
  group: MenuGroups.MENUITEMS,
  listMyOrganizations: [],
  menuTree: [],
  isOpen: false
};

export default new Store(InitialValue);
