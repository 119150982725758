import React from 'react';
import styled from 'styled-components';
import ReactSelect_, { Props } from 'react-select';
import { Flex, Box, BoxProps } from 'rebass';
import { Text, Spacer } from '../';
import { inter } from '../fonts';
import isDesktop from '@plug/helpers/is-desktop';

const statusColor = {
  success: '#18C698',
  warning: '#F38A09',
  error: '#C1050F',
};

export type ReactSelectI = {
  value: string;
  label: string;
};

const ReactSelectStyled = styled(ReactSelect_) <ReactSelectProps>`
  ${inter('s16')}
  .react-select {
    &__menu {
      padding: 10px;
      border-top: 0;
      border-left: 1px solid ${props => props.theme.primary || '#3b24a8'};
      border-right: 1px solid ${props => props.theme.primary || '#3b24a8'};
      border-bottom: 1px solid ${props => props.theme.primary || '#3b24a8'};
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-top: -8px;
      box-shadow: none;
    }
    &__value-container {
      padding-left: 18px;
      padding-right: 18px;
    }
    &__option {
      border-radius: 8px;
      &--is-focused {
        background-color: #fff;
      }
      &--is-selected {
        background-color: #ebeef4;
        color: ${props => props.theme.primary || '#BBBBBB'};
      }
      &:hover {
        background-color: #f9f9f9;
        cursor: pointer;
      }
    }
    &__control {
      border-color: ${props => (props.status === 'error' ? '#c1050f' : '#BBBBBB')};
      background-color: ${props => (props.status === 'error' ? '#f9e6e7' : 'hsl(0,0%,100%)')};
      border-radius: 8px;
      min-height: ${props => (props.height ? props.height : isDesktop ? '58px' : '42px')};
      &:hover {
        border-color: ${props => props.theme.primary || '#3b24a8'};
        cursor: pointer;
      }
      &--menu-is-open {
        box-shadow: none;
        border-color: ${props => props.theme.primary || '#BBBBBB'};
        &:hover {
          border-color: ${props => props.theme.primary || '#3b24a8'};
          cursor: pointer;
        }
        .react-select__dropdown-indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    &__indicator-separator {
      display: none;
    }
  }
`;

const ReactSelectBase: React.FC<any> = props => (
  <ReactSelectStyled className="react-select" classNamePrefix="react-select" {...props} />
);

type ReactSelectProps = {
  label?: string;
  status?: keyof typeof statusColor;
  hideHelperArea?: boolean;
  helperText?: string;
  pb?: string;
  height?: string;
  wrapProps?: {
    style: any;
  } & BoxProps;
} & Props;
const ReactSelect: React.FC<ReactSelectProps> = ({
  label,
  status,
  hideHelperArea,
  helperText,
  wrapProps,
  height,
  pb,
  ...props
}) => (
  <Box pb={pb ? pb : isDesktop ? '20px' : '0px'} {...wrapProps}>
    <Flex>
      {label && (
        <Text color="#666372" pb="10px" fontSize={isDesktop ? '16px' : '12px'}>
          {label}
        </Text>
      )}
    </Flex>
    <ReactSelectBase height={height} status={status} {...props} />
    {!hideHelperArea && (
      <Spacer pt="8px" height="23px">
        <Text as="span" color={status ? statusColor[status] : '#666372'}>
          {helperText && helperText}
        </Text>
      </Spacer>
    )}
  </Box>
);

ReactSelect.defaultProps = {
  hideHelperArea: false,
};

export { ReactSelect, ReactSelectBase };
