import gql from 'graphql-tag';

export const MENU_TREE = gql`
  query menuTree($input: MenuTreeInput) {
    menuTree(input: $input) {
      name
      slug
      disabled
      children {
        name
        slug
        disabled
      }
    }
  }
`;

export type MenuTreeItem = {
  name: string;
  slug: string;
  disabled?: boolean
  children: MenuTreeItem[];
};

export type MenuTreeGroup = MenuTreeItem[];

export type MenuTreeResponse = MenuTreeGroup;

export type MenuTreeVariables = {
  input: {
    organizationId: string;
  };
};

export type MenuTreeQuery = {
  menuTree: MenuTreeResponse;
};
