import { gql } from '@apollo/client';

export const ATTACH_CUSTOMER = gql`
  mutation($cartId: String!, $data: CustomerInput!) @api(name: "cart") {
    attachCustomer(cartId: $cartId, data: $data) {
      id
    }
  }
`;

export type AttachCustomerVariables = {
  cartId: string;
  data: {
    profile: Customer;
  };
};

export type AttachCustomerResponse = {
  attachCustomer: Cart;
};
