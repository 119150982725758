export type SettingsContextType = {
  organizationDetailsLoading: boolean;
  setOrganizationDetailsLoading: (organizationLoading: boolean) => void;
  organizationDetails?: Organization;
  setOrganizationDetails: (organization: Organization) => void;
  organizationApiKey?: string;
  setOrganizationApiKey: (organization: string) => void;
  planType?: string;
  setPlanType: (planType: string) => void;
  resetContext: () => void;
};

export const dummyContext: SettingsContextType = {
  organizationDetails: undefined,
  setOrganizationDetails: () => false,
  organizationDetailsLoading: true,
  setOrganizationDetailsLoading: () => false,
  organizationApiKey: undefined,
  setOrganizationApiKey: () => false,
  planType: undefined,
  setPlanType: () => false,
  resetContext: () => false,
};
