// 92.847.445/0001-20
// 92 847 445 0001 20
export default (cnpj: string): string => {
  const onlyNumbers = cnpj
    .replace(/\D/g, '')
    .split(' ')
    .join('');

  const initial = onlyNumbers.slice(0, 2);
  const middle = onlyNumbers.slice(2, 5);
  const final = onlyNumbers.slice(5, 8);
  const beforeDigit = onlyNumbers.slice(8, 12);
  const afterDigit = onlyNumbers.slice(12, 14);

  const hasLength = (length: number): boolean => onlyNumbers.length >= length;

  const formatedCnpj = `${initial}${hasLength(3) ? '.' : ''}${middle}${
    hasLength(6) ? '.' : ''
  }${final}${hasLength(9) ? '/' : ''}${beforeDigit}${hasLength(13) ? '-' : ''}${afterDigit}`;

  return formatedCnpj;
};
