import { ORGANIZATION_CUSTOMIZATION_KEY } from '@plug/config/consts';
import cookie from './cookie';
import jsCookie from 'js-cookie';

export const getLocalInfo = <T = any>(key: string, options?: { json?: boolean }): T => {
  const { json = true } = options ?? {};
  const item = localStorage.getItem(key);

  if (key === ORGANIZATION_CUSTOMIZATION_KEY && !item) return {} as T;

  if (!item) return cookie.get(key, { json });

  return JSON.parse(item || '{}') as T;
};

export const saveLocalInfo = <T = any>(key: string, data?: any) => {
  localStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data || '{}'));

  return data as T;
};
