export default (base64: string): string => {
  return decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
};

export const base64ToBuffer = (str: string) => {
  str = window.atob(str);
  const buffer = new ArrayBuffer(str.length),
    view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    view[i] = str.charCodeAt(i);
  }
  return buffer;
};
