import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box, Flex, Heading } from 'rebass';
import moment from 'moment';
import { inter } from '../fonts';
import { Text } from '../';
import { CaretLeft, CaretRight, Close, ExclamationTriangle } from '../icons';
import SelectCell from './select-cell';
import SelectCellMobile from './select-cell-mobile';
import ActionsCell from './actions-cell';
import { v4 as uuid } from 'uuid';
import {
  useGlobalFilter,
  useTable,
  useResizeColumns,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  useFilters,
  UseFiltersState,
  TableInstance,
  useFlexLayout,
  useRowSelect,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseRowSelectState,
  UseFiltersInstanceProps,
} from 'react-table';
import Pagination from './pagination';
import Drawer from '../drawer';
import { Views } from '@plug/domains/organization/domains/affiliate/containers/admin-payments';

import decodeBase64 from '@plug/helpers/decode-base64';
import { useLazyQuery } from '@apollo/client';

import {
  downloadFinantialConciliationCSVByReferenceMonthQuery,
  downloadFinantialConciliationCSVByReferenceMonthVariables,
  DOWNLOAD_FINATIAL_CONCILIATION_CSV_BY_REFERENCE_MONTH,
} from '@plug/domains/organization/domains/affiliate/graphql/queries/download-finantial-conciliation-CSV-by-reference-month';
import { ThemeStore } from '@plug/redux/store';

type Props = {
  title?: React.ReactNode;
  columns: any;
  sideviewState?: any;
  hasCheckboxSpace?: boolean;
  filterComponent?: any;
  actions?: any;
  selectComponent?: any;
  extensionComponent?: any;
  data: any;
  background?: string;
  responsive?: boolean;
  padding?: string;
  finantialConciliationGeneralOnClick?: Function;
  finantialConciliationMonthlyOnClick?: Function;
  tableBackground?: string;
  tablePadding?: string;
  onClickRow?: Function;
  rowDataValue?: string;
  customFinantialConciliationGeneralIndexClick?: boolean;
  informationComponent?: any;
  count?: any;
  selectPage?:any;
  setCurrentPage?: (page: number) => void;
};

interface TableStyledI {
  responsive?: boolean;
}

const TableStyled = styled.div<TableStyledI>`
  overflow-x: ${({ responsive }): string => (responsive ? 'auto' : 'none')};
  table {
    text-align: left;
    width: 100%;
    margin-bottom: ${({ responsive }): string => (responsive ? '15px' : '0')};

    th {
      padding: 10px;
    }
    tr {
      position: relative;
      display: flex;
      padding-left: 10px;
    }
    td {
      font-weight: 500;
      ${inter('s12')}
      color: #666372;
      padding: 10px;
      display: flex;
      align-items: center;
      word-break: break-all;
    }
    thead {
      background-color: #fbfcfd;
      color: #aaa8b3;
      ${inter('s12')}
      font-weight: 500;
    }
    tbody tr:nth-child(odd) {
      background-color: #fbfcfd;
    }
    tbody tr {
      background-color: #f4f6fa;
    }
  }

  @media screen and (max-width: 968px) {
    table {
      min-width: auto !important;

      thead {
        display: none;
      }
      tbody {
        padding-bottom: 8px;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(1, minmax(180px, 1fr));

        tr {
          display: flex;
          flex-direction: column;
          padding: 16px 14px;
          border-radius: 8px;

          td {
            padding: 0;
            padding-bottom: 5px;
            width: auto !important;
          }
        }
      }
    }
  }
`;

interface ContentWrapperI {
  background?: string;
  padding?: string;
}

const ContentWrapper = styled.div<ContentWrapperI>`
  padding: ${({ padding }): string => (padding ? padding : '2rem 2rem 0')};
  background: ${({ background }): string => background || '#FFFFFF'};
  height: calc(100% - 90px);
`;

type TableToolbar = {
  numSelected?: number;
  globalFilter: any;
  setGlobalFilter: (value: any) => void;
};

export const EnhancedTable = ({
  title,
  sideviewState,
  columns,
  actions,
  hasCheckboxSpace,
  responsive,
  filterComponent: FilterComponent,
  selectComponent: SelectComponent,
  extensionComponent: ExtensionComponent,
  data,
  background,
  padding,
  tablePadding,
  tableBackground,
  onClickRow,
  rowDataValue,
  finantialConciliationGeneralOnClick,
  finantialConciliationMonthlyOnClick,
  customFinantialConciliationGeneralIndexClick,
  count,
  selectPage,
  setCurrentPage
}: Props): JSX.Element => {
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>('');
  const [initialDate, setInitialDate] = useState<string>('');
  const [finalDate, setFinalDate] = useState<string>('');

  const theme = ThemeStore.useState(s => s);

  const filteredWithDate = useMemo(() => {
    if (!initialDate || !finalDate) return data;

    return data.filter((row: any): boolean => {
      const rowDate = row.creationDate || row.createdAt || row.created_at || row.date;

      if (!initialDate || !finalDate) return true;

      const momentRow = moment(rowDate);
      const momentInitialDate = moment(initialDate);
      const momentFinalDate = moment(finalDate);

      const isDateBetweenFilter = momentRow.isBetween(
        momentInitialDate,
        momentFinalDate,
        undefined,
        '[]',
      );

      return isDateBetweenFilter;
    });
  }, [data, initialDate, finalDate]);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    pageOptions,
    page,
    setGlobalFilter,
    setFilter,
    gotoPage,
    // @ts-ignore
    selectedFlatRows,
    state: { globalFilter, filters, pageIndex, selectedRowIds },
  } = useTable(
    {
      columns,
      data: filteredWithDate,
    },
    useResizeColumns,
    useGlobalFilter,
    useFilters,
    useFlexLayout,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [...columns]);
    },
  ) as UseGlobalFiltersInstanceProps<any> &
    UseFiltersInstanceProps<any> &
    TableInstance<any> &
    UsePaginationInstanceProps<any> & {
      state: UseGlobalFiltersState<any> &
        UseFiltersState<any> &
        UsePaginationState<any> &
        UseRowSelectState<any>;
    };

  const [
    downloadFinantialConciliationCSVByReferenceMonth,
    { data: downloadFinantialConciliationCSVByReferenceMonthData },
  ] = useLazyQuery<
    downloadFinantialConciliationCSVByReferenceMonthQuery,
    downloadFinantialConciliationCSVByReferenceMonthVariables
  >(DOWNLOAD_FINATIAL_CONCILIATION_CSV_BY_REFERENCE_MONTH);

  useEffect(() => {
    if (downloadFinantialConciliationCSVByReferenceMonthData) {
      const fileContent = decodeBase64(
        downloadFinantialConciliationCSVByReferenceMonthData.downloadFinantialConciliationCSVByReferenceMonth,
      );
      const blob = new Blob([fileContent], { type: 'data:application/octet-stream;base64' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Relatorio-${selectedYearMonth}.csv`;
      link.click();
    }
  }, [downloadFinantialConciliationCSVByReferenceMonthData, selectedYearMonth]);

  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [filteredWithDate, globalFilter, setGlobalFilter]);

  const [isOpenDrawerBottom, setOpenDrawerBottom] = React.useState(false);
  const [drawerContent, setDrawerContent] = React.useState<null | React.ReactNode>(null);

  const handleDownloadReport = (yearMonth: string): any => {
    if (!yearMonth) return;
    setSelectedYearMonth(yearMonth);
    downloadFinantialConciliationCSVByReferenceMonth({
      variables: {
        input: {
          referenceMonth: yearMonth,
        },
      },
    });
  };

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={isOpenDrawerBottom}
        onClose={(): void => setOpenDrawerBottom(false)}
      >
        <Box pb="20px">
          <Flex justifyContent="flex-end" pt="20px" pr="13px" pb="5px">
            <Flex
              onClick={(): void => setOpenDrawerBottom(false)}
              width="32px"
              height="32"
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: 'pointer' }}
            >
              <Close width={16} height={16} />
            </Flex>
          </Flex>
          {drawerContent}
        </Box>
      </Drawer>

      {(FilterComponent || SelectComponent) && (
        <Flex
          // px="2rem"
          // pt="2rem"
          // pb="0.875rem"
          padding={tablePadding}
          minHeight="90px"
          width="100%"
          alignItems="center"
          backgroundColor={background || 'transparent'}
        >
          {Object.keys(selectedRowIds).length ? (
            <>
              <Text fontSize="14px">
                <span style={{ color: theme.primary || '#3B24A8' }}>
                  {Object.keys(selectedRowIds).length}
                </span>{' '}
                itens selecionados
              </Text>

              <Flex flex="1" justifyContent="flex-end">
                <SelectComponent
                  selectedRowIds={selectedRowIds}
                  selectedFlatRows={selectedFlatRows}
                />
              </Flex>
            </>
          ) : (
            <FilterComponent
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              setFilter={setFilter}
              filter={filters}
              setInitialDate={setInitialDate}
              initialDate={initialDate}
              setFinalDate={setFinalDate}
              finalDate={finalDate}
            />
          )}
        </Flex>
      )}

      <ContentWrapper padding={padding} background={tableBackground}>
        {ExtensionComponent && (
          <Box pb="32px">
            <ExtensionComponent />
          </Box>
        )}

        {title && (
          <Flex pb="16px" alignItems="center">
            {title}
          </Flex>
        )}

        {page.length ? (
          <>
            <TableStyled responsive={responsive}>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${uuid()}`}>
                      {SelectComponent && (
                        <th colSpan={1} style={{ position: 'relative', width: '35px' }}></th>
                      )}
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} key={`header-group-${uuid()}`}>
                          {column.render('Header')}
                        </th>
                      ))}
                      {actions && (
                        <th colSpan={1} style={{ position: 'relative', width: '100px' }}></th>
                      )}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map(
                    (row: any, i): JSX.Element => {
                      prepareRow(row);
                      return (
                        <tr
                          data-value={rowDataValue ? row.original[rowDataValue] : ''}
                          key={row.id}
                          {...row.getRowProps()}
                          style={{
                            cursor:
                              onClickRow ||
                              finantialConciliationGeneralOnClick ||
                              finantialConciliationMonthlyOnClick
                                ? 'pointer'
                                : 'initial',
                          }}
                        >
                          {SelectComponent &&
                          !row.original?.isPaid &&
                          (row.original.plugoneAffiliateStatus === 'Approved' ||
                            row.original.status === 'approved') ? (
                            <SelectCell row={row} />
                          ) : hasCheckboxSpace ? (
                            <div style={{ width: '40px' }}></div>
                          ) : (
                            SelectComponent && <SelectCell row={row} />
                          )}

                          <SelectCellMobile
                            SelectComponent={SelectComponent}
                            setOpenDrawerBottom={setOpenDrawerBottom}
                            setDrawerContent={setDrawerContent}
                            actions={actions}
                            row={row}
                          />
                          {row.cells.map((cell: any, tdIndex: number) => {
                            return (
                              <td
                                key={cell}
                                {...cell.getCellProps()}
                                onClick={(): void => {
                                  if (
                                    customFinantialConciliationGeneralIndexClick &&
                                    tdIndex === 6
                                  ) {
                                    handleDownloadReport(row.original.referenceMonth);
                                    return;
                                  }
                                  if (onClickRow) onClickRow();
                                  if (finantialConciliationMonthlyOnClick && rowDataValue)
                                    finantialConciliationMonthlyOnClick(row.original[rowDataValue]);
                                  if (finantialConciliationGeneralOnClick && rowDataValue)
                                    finantialConciliationGeneralOnClick(
                                      Views.MONTHLY,
                                      row.original[rowDataValue],
                                    );
                                }}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                          {actions && <ActionsCell actions={actions} row={row} />}
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </TableStyled>

            <Pagination
              previousLabel={<CaretLeft />}
              nextLabel={<CaretRight />}
              pageCount={count ? count : pageOptions.length}
              breakLabel={'...'}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              forcePage={selectPage ? selectPage : pageIndex}
              onPageChange={page => {
                gotoPage(page.selected)
                setCurrentPage(page.selected)
              }}
            />
          </>
        ) : (
          <Box height="100%" width="100%" backgroundColor={background || '#F0F1F5'}>
            <Flex
              width="100%"
              height="100%"
              flexDirection="column"
              alignContent="center"
              justifyContent="center"
              padding="4rem 2rem"
              bg="#F8F9FC"
              sx={{
                borderRadius: '0 0 12px 12px',
                gap: '28px',
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="72px"
                height="72px"
                bg="#EBE9F6"
                m="0 auto"
                sx={{ borderRadius: 9999 }}
              >
                <ExclamationTriangle fill={theme.primary || '#3B24A8'} width={36} height={36} />
              </Flex>

              <Heading
                textAlign="center"
                fontFamily="Inter, sans-serif"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="28px"
                color="#1C1637"
              >
                Não encontramos nenhum resultado.
              </Heading>
            </Flex>
          </Box>
        )}
      </ContentWrapper>
    </>
  );
};

EnhancedTable.defaultProps = {
  hasCheckboxSpace: false,
  responsive: false,
};

export default EnhancedTable;
