import gql from 'graphql-tag';

export const GET_DAILY_REVENUE_AND_COMMISSIONS = gql`
  query getDailyRevenueAndCommissions($input: getDailyRevenueAndCommissionsInput!) {
    getDailyRevenueAndCommissions(input: $input) {
      status
      orders
      days {
        day
        revenue
        commission
      }
    }
  }
`;

export type GetDailyRevenueAndCommissionsQuery = {
  getDailyRevenueAndCommissions: {
    status: string;
    orders: number;
    days: {
      day: number;
      revenue: number;
      commission: number;
    }[];
  };
};

export type getDailyRevenueAndCommissionsInput = {
  input: {
    year_month: string;
  };
};

export type GetDailyRevenueAndCommissionsInput = {
  input: {
    year_month: string;
  };
};
