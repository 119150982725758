import React from 'react';

import * as Styles from './style';

import { Text } from '@plug/ui';
import { Box } from 'rebass';

interface CommonProps {
  show: boolean;
  toggleModal: any;
  title?: string;
  hideCloseIcon?: boolean;
  scrollable?: boolean;
  width?: string;
  height?: string;
  minHeight?: string;
  backdropPosition?: {
    width?: string;
    left?: string;
  };
  children: JSX.Element | React.ReactChild;
}

const Modal: React.FC<CommonProps> = ({
  title,
  height,
  hideCloseIcon,
  width,
  show,
  minHeight,
  toggleModal,
  children,
  backdropPosition,
  scrollable,
}) => {
  return (
    <Styles.Container show={show} width={backdropPosition?.width} left={backdropPosition?.left}>
      <Styles.Wrapper
        minHeight={minHeight}
        width={width || 'auto'}
        height={height}
        className="modal-wrapper"
        scrollable={scrollable}
      >
        <Styles.Header>
          <Text fontSize="18px" pr="8px">
            {title || ''}
          </Text>
          {!hideCloseIcon && <Styles.HeaderCloseIcon size="14px" onClick={toggleModal} />}
        </Styles.Header>
        <Styles.Hr />
        <Box pt={2}>{children}</Box>
      </Styles.Wrapper>
    </Styles.Container>
  );
};

export default Modal;
