import customScrollBar from '@plug/styles/custom-scroll-bar';
import { Icon } from '@gohubly/design-system';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const Wrapper = styled(Flex)<{ opened: boolean }>`
  flex-direction: column;
  gap: 24px;

  opacity: ${({ opened }): string => (opened ? '1' : '0')};

  height: 100vh;
  max-height: ${({ opened }): string => (opened ? '100vh' : '0px')};
  overflow: hidden;

  transition: max-height 0.4s ease-out, padding 0.5s ease-out, opacity 0.2s ease-out;
`;

export const OrganizationSidebarItem = styled(Flex)<{ active?: boolean }>`
  align-items: center;
  gap: 10px;

  padding: 16px 10px 16px 16px;

  cursor: pointer;

  transition: all 0.5s;
  border: ${({ theme }): string => `2px solid ${theme.colors.primaryLightest}`};
  border-radius: ${({ theme }): string => theme.borderRadius.SM};

  div:first-of-type {
    transition: all 0.5s;
  }

  svg {
    opacity: 0;

    transition: all 0.5s;
  }

  &:hover {
    background-color: ${({ theme }): string => theme.colors.primaryLightest};
    border-color: transparent;

    div:first-of-type {
      color: ${({ theme }): string => theme.colors.neutralHigh};
      background-color: ${({ theme }): string => theme.colors.primary};
    }

    svg {
      opacity: 1;
    }

    span {
      color: ${({ theme }): string => theme.colors.primary};
    }
  }

  // Margin to support better style when scroll appears
  margin-right: 5px !important;
`;

export const Hr = styled.div`
  width: 100%;
  height: 1px;

  background: ${({ theme }): string => theme.colors.neutralHighLight};
`;

export const OverflowYFlex = styled(Flex)`
  overflow-y: auto;
  flex-direction: column;
  gap: 12px;

  max-height: 70vh;

  ${customScrollBar};
`;

export const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
