import { Typography } from '@gohubly/design-system';
import React from 'react';
import { Flex } from 'rebass';

const CartEmptyState = () => {
  return (
    <Flex paddingX="24px" flexDirection="column" style={{ gap: '40px' }}>
      <Typography color="textNeutralSubtle" fontWeight={400} size="16" lineHeight="24">
        Escolha os melhores produtos do catálogo e adicione ao carrinho para continuar comprando.
      </Typography>

      <svg
        width="100%"
        height="238"
        viewBox="0 0 340 238"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ flex: 1 }}
      >
        <g clipPath="url(#clip0_989_740)">
          <path
            d="M282.281 65.0514C290.376 55.0448 282.093 33.9551 271.049 34.514C275.87 26.7017 262.342 20.4365 257.991 25.1133C253.311 20.3895 240.113 27.3135 241.938 35.4376L246.005 103.442L290.87 140.115C309.137 138.892 317.567 121.585 307.171 113.449C331.784 106.901 319.622 63.5571 282.275 65.0455"
            fill="#1E1A34"
          />
          <path
            d="M267.317 77.7225C267.029 77.7225 266.734 77.7167 266.446 77.7049C260.076 77.399 255.625 73.9576 255.107 68.9396L255.932 68.8572C256.308 72.5104 259.275 76.5401 266.481 76.8813C272.845 77.1755 280.315 73.9164 281.646 67.263L282.458 67.4277C281.793 70.7397 279.721 73.487 276.459 75.3753C273.834 76.8931 270.543 77.7343 267.311 77.7343L267.317 77.7225Z"
            fill="#EEEEED"
          />
          <path
            d="M151.904 171.376L151.797 171.549C151.863 171.496 151.904 171.442 151.904 171.376Z"
            fill="#EEEEED"
          />
          <path
            d="M76.0962 29.3307C75.896 25.5716 72.6876 22.6832 68.9258 22.8832C65.1641 23.0832 62.2736 26.2893 62.4737 30.0484L66.0471 46.8025C66.1884 49.4498 67.9898 51.6146 70.3858 52.35C66.5181 64.9979 56.8752 92.788 61.3317 112.554L135.078 129.585L142.843 172.064C146.834 172.235 151.09 172.093 151.797 171.546L154.911 166.564C153.704 146.692 152.58 137.962 150.961 120.678C137.427 121.466 114.473 119.725 109.216 101.547C105.066 87.1994 121.314 52.3088 121.314 52.3088C121.314 52.3088 123.892 47.0849 124.658 44.4965C126.118 39.5785 125.246 32.5015 116.445 32.6486H116.581L114.579 6.87624C114.379 3.11716 111.171 0.228723 107.409 0.428737C103.647 0.628751 100.757 3.83485 100.957 7.59394L103.2 26.8541L103.335 27.9012C103.135 24.1421 99.9266 21.2537 96.1648 21.4537C92.4031 21.6537 89.5126 24.8598 89.7127 28.6189C89.5126 24.8598 86.3042 21.9714 82.5424 22.1714C78.7806 22.3714 75.8901 25.5775 76.0903 29.3366L76.0962 29.3307Z"
            fill="#EEEEED"
          />
          <path
            d="M256.355 95.1177L252.693 66.8746C253.558 69.2395 257.202 69.6454 258.815 67.5688C260.705 65.1274 260.905 64.1862 263.266 62.2155C265.627 60.2448 267.605 54.9915 263.619 52.1383C259.875 49.4617 254.877 52.9266 254.877 52.9266L251.492 53.2796C251.492 53.2796 248.537 39.0492 236.192 37.2197C225.601 35.649 219.708 42.473 219.708 42.473C219.708 42.473 217.047 45.0731 215.57 51.45C214.092 57.827 211.555 69.3983 211.555 69.3983H216.989C216.989 69.3983 218.607 81.8109 224.553 84.87C225.996 85.6112 227.544 85.9112 229.127 85.8877L226.985 94.4706C226.985 94.4706 215.47 97.2414 202.642 103.265C199.816 104.589 196.82 105.989 193.723 107.395L193.6 107.448C190.191 132.073 258.651 135.209 270.648 111.525L270.713 111.601C262.477 101.348 256.366 95.1295 256.366 95.1295L256.355 95.1177Z"
            fill="#EEEEED"
          />
          <path
            d="M260.582 199.166L253.141 180.218L253.106 180.136C269.613 172.065 284.807 164.641 299.931 157.246C304.623 168.611 308.461 179.983 310.863 191.913C313.918 207.091 314.731 222.256 312.811 229.469C306.895 251.694 289.894 250.476 278.443 249.699C248.12 247.64 199.446 208.049 199.446 208.049L183.345 204.555L182.097 204.179L147.211 196.931L145.51 187.501C147.346 185.536 150.667 182.895 156.571 180.612C173.179 174.194 236.893 190.36 268.506 202.037"
            fill="#EEEEED"
          />
          <path
            d="M199.445 208.044L183.345 204.55L182.097 204.173L76.1428 182.16C71.3567 181.289 50.0812 175.024 40.3265 200.055C33.1739 218.403 31.5903 220.88 31.5903 220.88C31.5903 220.88 18.4152 232.657 15.2422 238.34C8.1307 251.059 18.2504 256.171 18.2504 256.171L14.5887 263.201L35.0636 289.138L43.7704 281.349C44.73 290.503 45.8662 297.768 45.5659 303.309C44.9419 314.781 53.8018 320.516 62.1613 320.516L65.823 328.658L98.3308 325.499L97.6009 313.692L96.1998 269.825C96.1998 269.825 108.827 272.189 125.311 275.749C146.881 280.408 170.611 286.379 190.362 275.625C213.774 262.871 225.884 227.01 225.884 227.01C210.613 217.174 199.44 208.032 199.44 208.032L199.445 208.044Z"
            fill="#1E1A34"
          />
          <path
            d="M65.5759 329.111L61.9025 320.94C57.0281 320.869 52.4657 318.969 49.3573 315.687C46.3432 312.504 44.8891 308.222 45.1599 303.292C45.3895 299.121 44.7773 293.821 44.0709 287.685C43.8648 285.926 43.6588 284.108 43.4527 282.202L35.0226 289.744L14.1062 263.248L17.709 256.336C16.7023 255.724 13.9178 253.753 12.7875 250.047C11.722 246.553 12.4284 242.546 14.8951 238.134C18.0034 232.569 30.2895 221.504 31.2844 220.61C31.5257 220.204 33.3448 216.868 39.9559 199.902C49.2161 176.136 68.7255 180.195 75.1364 181.531C75.5602 181.619 75.9311 181.695 76.2313 181.748L182.191 203.761L183.474 204.144L199.64 207.65L199.716 207.715C199.828 207.803 211.049 216.951 226.119 226.663L226.39 226.84L226.284 227.145C226.255 227.234 223.176 236.27 217.271 246.741C211.814 256.424 202.748 269.36 190.568 275.99C171.677 286.279 148.735 281.279 128.496 276.866C127.401 276.631 126.312 276.39 125.234 276.155C110.782 273.031 99.167 270.807 96.6473 270.331L98.0367 313.681L98.7902 325.876L65.5877 329.105L65.5759 329.111ZM44.1003 280.508L44.1827 281.314C44.4123 283.514 44.6537 285.591 44.8833 287.597C45.5956 293.768 46.2078 299.097 45.9782 303.339C45.7251 308.033 47.0968 312.104 49.9519 315.116C52.9602 318.293 57.4107 320.111 62.1615 320.111H62.4323L66.0822 328.229L97.8954 325.134L97.1889 313.722L95.7702 269.331L96.2765 269.425C96.4001 269.448 109.098 271.831 125.393 275.349C126.477 275.584 127.566 275.819 128.661 276.061C149.742 280.655 171.535 285.408 190.156 275.266C212.014 263.36 224.13 230.699 225.378 227.187C211.061 217.939 200.27 209.25 199.251 208.426L183.251 204.956L181.973 204.573L76.0547 182.572C75.7604 182.519 75.3836 182.442 74.948 182.348C68.7078 181.048 49.7165 177.095 40.7094 200.208C33.6215 218.386 32.0026 221.004 31.9378 221.11L31.8672 221.198C31.7377 221.316 18.7098 232.993 15.6015 238.552C13.2526 242.752 12.5638 246.541 13.5587 249.806C14.8126 253.936 18.3978 255.794 18.4331 255.812L18.804 256L15.0717 263.16L35.1109 288.538L44.0944 280.502L44.1003 280.508Z"
            fill="#1E1A34"
          />
          <path
            d="M299.931 157.246L253.106 180.136L244.263 194.09C211.491 184.395 169.611 175.571 156.577 180.606C150.672 182.889 147.346 185.53 145.515 187.495L144.732 182.836L155.229 166.058L155.158 166.176L150.967 120.673C151.656 120.626 152.645 120.555 153.274 120.496C165.555 119.402 180.455 113.396 193.594 107.437C190.186 132.062 258.645 135.197 270.643 111.513C277.66 120.202 285.867 131.35 293.608 144.686C295.892 148.616 298.017 152.869 299.936 157.24L299.931 157.246Z"
            fill="#BEDE18"
          />
          <path
            d="M147.211 196.932L76.1435 182.166C73.9712 181.772 68.1608 180.295 61.8088 181.113L49.6875 112.614L51.6773 110.408L135.072 129.58L147.205 196.932L144.721 196.414"
            fill="#1E1A34"
          />
          <path
            d="M147.729 197.461L76.0612 182.572C75.7963 182.525 75.4607 182.454 75.0781 182.378C72.376 181.83 67.3544 180.813 61.8677 181.519L61.4733 181.572L49.2461 112.485L51.5361 109.949L135.437 129.239L147.729 197.461ZM144.815 196.008L146.693 196.396L134.713 129.921L51.8246 110.861L50.1291 112.732L62.1503 180.642C67.6134 180.013 72.5526 181.013 75.237 181.554C75.6138 181.63 75.9435 181.695 76.2143 181.748L144.809 195.996L144.815 196.008Z"
            fill="#1E1A34"
          />
          <path
            d="M228.894 86.2883C227.317 86.2883 225.78 85.9648 224.355 85.2353C218.798 82.3763 216.944 71.9344 216.62 69.8107H211.027L211.139 69.3048C211.163 69.1872 213.7 57.657 215.16 51.3507C216.649 44.9326 219.299 42.2853 219.41 42.1736C219.446 42.1265 225.55 35.2201 236.247 36.8026C247.615 38.4851 251.224 50.5506 251.806 52.8213L254.732 52.5154C255.462 52.0331 260.166 49.1505 263.863 51.7919C265.399 52.8919 266.241 54.4391 266.312 56.2745C266.406 58.8982 264.94 61.3572 263.533 62.5279C261.95 63.8456 261.361 64.6986 260.549 65.8752C260.172 66.4223 259.742 67.0458 259.142 67.8165C258.082 69.1813 256.069 69.699 254.361 69.0342C252.819 68.4401 251.971 67.0929 252.101 65.4399L252.931 65.5046C252.789 67.3341 254.096 68.04 254.662 68.2577C255.845 68.7107 257.534 68.5342 258.488 67.3047C259.071 66.5517 259.495 65.9399 259.866 65.3987C260.713 64.1692 261.331 63.275 263.003 61.8867C264.299 60.8101 265.564 58.5453 265.482 56.3039C265.423 54.7156 264.717 53.4214 263.38 52.4684C259.925 49.9976 255.162 53.2273 255.115 53.2625L255.027 53.3214L251.159 53.7273L251.082 53.3626C251.053 53.2214 248.039 39.3969 236.123 37.6262C225.874 36.1084 220.07 42.6736 220.017 42.7442C219.97 42.7971 217.397 45.3797 215.972 51.5389C214.689 57.0863 212.57 66.6811 212.069 68.9813H217.356L217.403 69.3401C217.421 69.4637 219.063 81.5763 224.744 84.4941C230.631 87.5237 238.608 82.9234 243.647 78.0701C249.446 72.4815 249.664 68.9283 249.664 68.893L250.494 68.9225C250.494 69.0813 250.305 72.8051 244.224 78.6643C240.18 82.5646 234.275 86.2825 228.906 86.2825L228.894 86.2883Z"
            fill="#1E1A34"
          />
          <path
            d="M116.169 32.6731L114.168 6.90075C114.08 5.18298 113.326 3.61229 112.054 2.47103C110.783 1.32978 109.146 0.747385 107.433 0.835626C103.907 1.02387 101.187 4.04173 101.376 7.5655L103.618 26.8021L102.794 26.8962L100.551 7.63609C100.339 3.62993 103.412 0.217937 107.392 0.0120402C109.323 -0.0820839 111.177 0.565019 112.614 1.85923C114.05 3.15343 114.898 4.92414 114.998 6.85368L117 32.6143L116.169 32.679V32.6731Z"
            fill="#1E1A34"
          />
          <path
            d="M75.678 29.3488C75.5779 27.4192 76.2313 25.5662 77.5265 24.1308C78.8216 22.6954 80.5936 21.8483 82.5245 21.7482C84.4554 21.6541 86.3098 22.3012 87.7463 23.5954C89.1827 24.8896 90.0304 26.6603 90.1305 28.5899L89.3004 28.6311C89.2121 26.9251 88.4586 25.3544 87.187 24.2131C85.9154 23.0719 84.2788 22.4895 82.5657 22.5777C80.8585 22.666 79.2867 23.4189 78.1446 24.6896C77.0025 25.9603 76.4197 27.6016 76.508 29.3076L75.678 29.3488Z"
            fill="#1E1A34"
          />
          <path
            d="M72.1828 53.0332C68.733 53.0332 65.8366 50.333 65.6365 46.8504L62.0631 30.061C61.8511 26.0784 64.9241 22.6723 68.9096 22.4605C70.8405 22.3605 72.6949 23.0135 74.1314 24.3077C75.5678 25.6019 76.4155 27.3726 76.5156 29.3022L78.7467 46.0916C78.7467 46.0916 78.7467 46.1151 78.7467 46.1269C78.7467 46.1269 78.7467 46.1739 78.7467 46.1974C78.9704 49.333 81.6549 51.7155 84.7691 51.5449C87.9245 51.3802 90.3617 48.6741 90.191 45.5209L89.3021 28.6257C89.0901 24.643 92.1631 21.2369 96.1486 21.0251C98.0795 20.9251 99.9339 21.5781 101.37 22.8723C102.807 24.1665 103.654 25.9372 103.755 27.8668V32.6377H102.925V27.8903C102.836 26.2078 102.083 24.6371 100.811 23.49C99.5395 22.3488 97.897 21.7664 96.1898 21.8546C92.6635 22.0428 89.9438 25.0607 90.1321 28.5845L91.0211 45.4797C91.2094 49.0918 88.4249 52.1861 84.8103 52.3743C82.0022 52.5273 79.512 50.8801 78.4465 48.4447C78.1698 49.3271 77.6989 50.1507 77.0631 50.8566C75.8916 52.1567 74.2785 52.9273 72.5301 53.0214C72.4124 53.0273 72.2946 53.0332 72.1828 53.0332ZM62.8873 29.9904L66.4606 46.774C66.6255 49.9271 69.3276 52.3685 72.4889 52.192C74.0195 52.1096 75.4265 51.439 76.4508 50.3036C77.4752 49.1682 77.9932 47.6975 77.9167 46.168L75.6855 29.3786C75.4971 25.8196 72.4712 23.1017 68.9508 23.29C65.4363 23.4724 62.7224 26.4726 62.8931 29.9846L62.8873 29.9904Z"
            fill="#1E1A34"
          />
          <path
            d="M96.7358 51.7444C95.111 51.7444 93.5686 51.1562 92.3559 50.062C91.0548 48.8913 90.2836 47.2794 90.1895 45.5323L91.0195 45.4911C91.1019 47.0206 91.7731 48.4266 92.9092 49.4502C94.0454 50.4738 95.5172 50.9973 97.0478 50.915C98.5784 50.8326 99.9854 50.162 101.01 49.0266C102.034 47.8912 102.552 46.4205 102.476 44.891L103.306 44.8499C103.4 46.597 102.805 48.2795 101.628 49.5796C100.456 50.8797 98.8433 51.6503 97.0949 51.7444C96.9771 51.7503 96.8594 51.7562 96.7416 51.7562L96.7358 51.7444Z"
            fill="#1E1A34"
          />
          <path
            d="M60.9264 112.637C59.3251 105.519 59.3899 96.5713 61.1324 86.0353L61.9507 86.1706C60.2258 96.6007 60.1552 105.442 61.7388 112.455L60.9264 112.637Z"
            fill="#1E1A34"
          />
          <path
            d="M62.5917 82.5705L61.7734 82.4116C61.9913 81.288 62.2326 80.1173 62.4858 78.9408L63.2982 79.1173C63.045 80.2879 62.8037 81.4469 62.5858 82.5646L62.5917 82.5705Z"
            fill="#1E1A34"
          />
          <path
            d="M64.6581 73.3702L63.8516 73.1702C65.5352 66.4991 67.4132 60.4928 68.9202 55.669C69.3147 54.41 69.6797 53.2394 70.0034 52.1805L70.7982 52.4217C70.4744 53.4806 70.1094 54.6512 69.715 55.916C68.2079 60.734 66.3359 66.7227 64.6581 73.3761V73.3702Z"
            fill="#1E1A34"
          />
          <path
            d="M84.3377 67.1399C83.3781 59.0923 86.2274 53.2213 92.8091 49.6917L93.2035 50.4211C86.8632 53.8213 84.2376 59.257 85.1619 67.0399L84.3377 67.1399Z"
            fill="#1E1A34"
          />
          <path
            d="M217.401 72.5525C217.336 72.5525 217.313 72.5407 217.283 72.5231L217.719 71.8171L217.495 72.1701L217.407 71.7642C217.66 71.7054 222.946 70.1406 226.437 69.0993L226.673 69.8935C218.979 72.176 217.713 72.5584 217.395 72.5584L217.401 72.5525Z"
            fill="#1E1A34"
          />
          <path
            d="M145.47 121.262C132.453 121.262 113.621 118.256 108.823 101.655C107.081 95.6188 108.594 85.4417 113.321 71.4054C116.829 60.9871 120.903 52.21 120.944 52.1218C120.974 52.0629 123.517 46.892 124.265 44.3683C125.26 41.0151 124.918 37.7914 123.358 35.7383C121.963 33.9029 119.632 32.991 116.453 33.0499H100.187C98.6564 33.0499 97.2141 33.644 96.1309 34.7265C95.0477 35.8089 94.4531 37.2443 94.4531 38.7797C94.4531 40.3092 95.0477 41.7505 96.1309 42.8329C97.2141 43.9153 98.6505 44.5095 100.187 44.5095H107.752V45.3389H100.193C98.4386 45.3389 96.7902 44.6565 95.5539 43.4153C94.3118 42.174 93.6289 40.5269 93.6289 38.7797C93.6289 37.0325 94.3118 35.3794 95.5539 34.1441C96.696 33.0028 98.1854 32.3322 99.7808 32.2381V32.2263H100.199H116.459C119.914 32.1675 122.463 33.1852 124.029 35.2441C125.748 37.5031 126.137 41.0034 125.071 44.6095C124.306 47.2038 121.81 52.2688 121.704 52.4865C121.545 52.8277 105.574 87.3948 109.63 101.431C115.364 121.268 142.043 121.085 153.246 120.091C168.723 118.715 189.027 109.19 202.461 102.89C214.159 97.4013 224.896 94.5717 226.639 94.1305L228.723 85.7946L229.53 85.9947L227.322 94.8188L227.075 94.8776C226.963 94.907 215.454 97.7131 202.809 103.643C189.322 109.967 168.935 119.526 153.311 120.921C151.168 121.109 148.478 121.274 145.47 121.274V121.262Z"
            fill="#1E1A34"
          />
          <path
            d="M268.37 202.42C234.355 189.86 172.483 174.906 156.73 180.995C150.813 183.283 147.528 185.948 145.821 187.777L145.215 187.213C146.987 185.318 150.366 182.559 156.435 180.218C161.692 178.189 174.732 177.218 207.01 184.13C227.568 188.53 251.763 195.402 268.659 201.643L268.37 202.42Z"
            fill="#1E1A34"
          />
          <path
            d="M242.218 151.305L241.445 151.608L260.195 199.343L260.967 199.04L242.218 151.305Z"
            fill="#1E1A34"
          />
          <path
            d="M285.815 250.434C283.242 250.434 280.764 250.264 278.515 250.111H278.421C248.874 248.099 201.496 210.214 199.27 208.426L183.27 204.955L183.446 204.143L199.641 207.655L199.718 207.72C200.2 208.114 248.633 247.252 278.479 249.281H278.574C284.243 249.675 291.301 250.152 297.618 247.799C305.006 245.046 309.845 239.016 312.418 229.362C314.478 221.621 313.725 207.543 310.404 191.701C306.749 174.264 300.656 157.646 293.256 144.904C276.925 116.784 256.274 95.6238 256.068 95.4179L255.968 95.3179L252.288 66.9336L253.113 66.8277L256.757 94.9355C258.322 96.5533 278.167 117.272 293.974 144.486C309.892 171.888 317.522 213.438 313.224 229.574C308.415 247.64 296.323 250.44 285.821 250.44L285.815 250.434Z"
            fill="#1E1A34"
          />
          <path
            d="M18.1759 256.67C18.011 256.588 14.1727 254.576 12.7893 250.023C11.7297 246.516 12.4302 242.522 14.8792 238.139C17.9875 232.574 30.2736 221.509 31.2685 220.615C31.5099 220.209 33.3289 216.873 39.94 199.907C44.3376 188.63 51.5903 182.177 61.5098 180.73L61.6276 181.553C52.0259 182.953 44.991 189.23 40.7112 200.213C33.6233 218.391 32.0044 221.009 31.9396 221.115L31.869 221.203C31.7395 221.321 18.7116 232.998 15.6033 238.557C13.272 242.728 12.5892 246.505 13.5782 249.781C14.8497 253.993 18.5114 255.923 18.5526 255.941L18.1759 256.682V256.67Z"
            fill="#1E1A34"
          />
          <path
            d="M142.761 196.425L76.0615 182.571C75.7672 182.518 75.3904 182.441 74.9548 182.347C72.4528 181.824 67.7903 180.859 62.6039 181.43L62.5156 180.606C67.8316 180.024 72.5765 181.006 75.1255 181.541C75.5494 181.63 75.9202 181.706 76.2205 181.759L142.926 195.619L142.755 196.431L142.761 196.425Z"
            fill="#EEEEED"
          />
          <path
            d="M146.204 196.282L146.035 197.094L182.001 204.563L182.17 203.751L146.204 196.282Z"
            fill="#1E1A34"
          />
          <path
            d="M244.617 194.313L243.916 193.866L252.817 179.812L252.923 179.759L299.748 156.87L300.113 157.617L253.394 180.454L244.617 194.313Z"
            fill="#1E1A34"
          />
          <path
            d="M145.087 183.06L144.381 182.619L163.878 151.458C160.8 147.375 159.675 143.122 160.546 138.81C162.218 130.545 170.913 122.073 187.138 112.908L187.544 113.632C171.761 122.544 162.954 131.074 161.359 138.981C160.523 143.128 161.647 147.234 164.709 151.187L164.885 151.417L145.087 183.066V183.06Z"
            fill="#1E1A34"
          />
          <path
            d="M42.7532 274.789C41.929 264.271 41.8996 254.017 42.659 243.429C43.4655 232.234 46.6621 224.904 52.1488 221.651C58.4714 217.903 65.4121 220.998 65.7065 221.133L76.2971 225.492L75.9792 226.257L65.3709 221.892C65.2885 221.856 58.5067 218.839 52.5609 222.368C47.3215 225.48 44.2661 232.581 43.4832 243.487C42.712 254.176 42.7414 264.106 43.5773 274.725L42.7473 274.789H42.7532Z"
            fill="#EEEEED"
          />
          <path
            d="M97.1961 313.715L95.7773 269.336L96.2836 269.43C96.4072 269.453 109.105 271.836 125.401 275.354C126.484 275.589 127.573 275.825 128.668 276.066C149.749 280.66 171.543 285.413 190.163 275.272C202.167 268.736 211.127 255.941 216.531 246.358C222.4 235.951 225.455 226.98 225.491 226.886L226.28 227.151C226.25 227.239 223.171 236.275 217.267 246.746C211.809 256.429 202.744 269.365 190.563 275.995C171.672 286.284 148.731 281.284 128.491 276.872C127.396 276.636 126.307 276.395 125.23 276.16C110.777 273.036 99.1624 270.812 96.6427 270.336L98.0321 313.686L97.202 313.709L97.1961 313.715Z"
            fill="#1E1A34"
          />
          <path
            d="M156.49 280.978C128.073 273.454 84.0978 231.274 83.6562 230.851L84.2332 230.251C84.6747 230.674 128.491 272.707 156.702 280.172L156.49 280.972V280.978Z"
            fill="#EEEEED"
          />
          <path
            d="M146.04 172.542C144.48 172.542 143.156 172.489 142.826 172.477L142.861 171.647C147.524 171.847 151.115 171.583 151.545 171.218C151.527 171.23 151.486 171.289 151.486 171.377H152.316C152.316 171.494 152.281 171.659 152.116 171.818C151.492 172.418 148.478 172.542 146.035 172.542H146.04Z"
            fill="#1E1A34"
          />
          <path
            d="M146.805 197.002L134.719 129.921L51.8013 110.855L49.735 112.684L49.1816 112.067L51.5659 109.955L51.7778 110.002L135.437 129.238L147.623 196.855L146.805 197.002Z"
            fill="#1E1A34"
          />
          <path
            d="M144.321 196.484L132.724 132.126L77.0098 119.32L77.1982 118.508L133.448 131.438L145.14 196.337L144.321 196.484Z"
            fill="#EEEEED"
          />
          <path
            d="M57.151 113.904L56.9648 114.712L69.8903 117.684L70.0765 116.876L57.151 113.904Z"
            fill="#EEEEED"
          />
          <path
            d="M61.403 181.183L49.1699 112.066L52.4431 112.819L52.2606 113.625L50.2119 113.155L62.2213 181.036L61.403 181.183Z"
            fill="#1E1A34"
          />
          <path
            d="M164.878 179.442L164.172 179.001L200.341 120.973C194.831 117.391 192.423 112.82 193.177 107.378L194.001 107.49C193.283 112.69 195.679 117.061 201.136 120.491L201.483 120.708L164.878 179.436V179.442Z"
            fill="#1E1A34"
          />
          <path
            d="M232.513 183.254C230.464 183.254 228.769 182.954 228.116 182.277C227.792 181.942 227.391 181.218 228.369 180.118C230.712 177.477 237.176 175.918 241.161 175.182C237.123 174.8 230.647 174.129 229.105 173.665C227.109 173.065 223.447 166.511 224.083 163.852C224.236 163.205 224.772 162.17 226.626 162.493C231.854 163.405 245.665 172.712 246.247 173.106L245.782 173.794C245.641 173.7 231.536 164.193 226.485 163.311C225.278 163.099 225.001 163.593 224.889 164.04C224.324 166.405 227.898 172.429 229.346 172.865C231.501 173.518 244.411 174.641 244.54 174.653L248.461 174.994L244.552 175.477C244.428 175.494 232.195 177.047 228.987 180.659C228.375 181.348 228.616 181.595 228.71 181.689C229.375 182.371 232.03 182.601 235.168 182.248C238.453 181.877 241.326 181.012 242.48 180.042L243.015 180.677C241.155 182.236 236.251 183.236 232.519 183.236L232.513 183.254Z"
            fill="#1E1A34"
          />
          <path
            d="M151.604 245.37L150.15 234.281L70.3164 204.42L70.6049 203.643L150.909 233.681L152.204 243.54L170.742 224.145L174.893 244.375L174.08 244.54L170.248 225.862L151.604 245.37Z"
            fill="#EEEEED"
          />
          <path
            d="M58.3222 199.053L58.0312 199.83L64.9671 202.424L65.2581 201.648L58.3222 199.053Z"
            fill="#EEEEED"
          />
          <path
            d="M150.185 197.684L149.467 197.261C156.325 185.636 169.447 185.272 169.583 185.272L169.6 186.101C169.471 186.101 156.808 186.466 150.185 197.684Z"
            fill="#1E1A34"
          />
          <path
            d="M161.686 200.067L161.08 199.502C168.651 191.419 179.683 190.878 179.789 190.872L179.824 191.702C179.718 191.702 169.016 192.243 161.686 200.067Z"
            fill="#1E1A34"
          />
          <path
            d="M176.547 203.19L176.105 202.484C182.717 198.343 189.463 198.019 189.746 198.008L189.781 198.837C189.716 198.837 182.964 199.167 176.547 203.184V203.19Z"
            fill="#1E1A34"
          />
          <path
            d="M151.383 120.624L150.557 120.7L154.749 166.205L155.576 166.129L151.383 120.624Z"
            fill="#1E1A34"
          />
          <path
            d="M303.066 142.765L262.57 165.16L262.972 165.885L303.468 143.491L303.066 142.765Z"
            fill="#1E1A34"
          />
          <path
            d="M257.814 167.795L249.578 172.35L249.98 173.075L258.216 168.521L257.814 167.795Z"
            fill="#1E1A34"
          />
          <path
            d="M157.896 109.54L157.066 109.577L157.954 129.252L158.783 129.215L157.896 109.54Z"
            fill="#1E1A34"
          />
          <path
            d="M159.552 146.179L158.723 146.216L159.286 158.716L160.116 158.679L159.552 146.179Z"
            fill="#1E1A34"
          />
          <path
            d="M166.036 157.492L166.027 158.321L216.535 158.859L216.544 158.029L166.036 157.492Z"
            fill="#1E1A34"
          />
          <path
            d="M205.794 171.354L205.602 172.161L215.021 174.408L215.214 173.601L205.794 171.354Z"
            fill="#1E1A34"
          />
          <path
            d="M161.158 160.706L160.965 161.513L200.829 171.025L201.022 170.219L161.158 160.706Z"
            fill="#1E1A34"
          />
          <path
            d="M243.258 78.9989L226.975 94.4646L229.117 85.764C234.157 85.8229 238.79 82.9874 243.258 78.993V78.9989Z"
            fill="#1E1A34"
          />
          <path
            d="M150.961 120.673L155.152 166.176L151.432 172.13L149.342 171.753L149.83 120.779L150.961 120.673Z"
            fill="#1E1A34"
          />
          <path
            d="M253.143 180.218L253.896 181.418L302.705 164.229L300.745 159.223L299.532 157.246L253.143 180.218Z"
            fill="#1E1A34"
          />
          <path
            d="M244.117 157.246L246.837 190.036L253.142 180.218L244.117 157.246Z"
            fill="#1E1A34"
          />
          <path
            d="M226.81 60.8039C227.415 60.8039 227.905 60.314 227.905 59.7097C227.905 59.1054 227.415 58.6155 226.81 58.6155C226.205 58.6155 225.715 59.1054 225.715 59.7097C225.715 60.314 226.205 60.8039 226.81 60.8039Z"
            fill="#1E1A34"
          />
          <path
            d="M196.968 150.24C197.573 150.24 198.063 149.75 198.063 149.146C198.063 148.541 197.573 148.051 196.968 148.051C196.363 148.051 195.873 148.541 195.873 149.146C195.873 149.75 196.363 150.24 196.968 150.24Z"
            fill="#1E1A34"
          />
          <path
            d="M184.14 174.312C184.745 174.312 185.235 173.822 185.235 173.218C185.235 172.614 184.745 172.124 184.14 172.124C183.535 172.124 183.045 172.614 183.045 173.218C183.045 173.822 183.535 174.312 184.14 174.312Z"
            fill="#1E1A34"
          />
          <path
            d="M221.464 32.6375C212.61 32.9493 211.727 43.7206 220.663 44.1148C228.581 44.4618 232.767 39.8733 232.767 39.8733C232.767 39.8733 234.762 55.8979 242.562 54.1507C250.363 52.4036 243.811 61.8807 245.306 62.816C246.801 63.7514 250.681 64.8397 252.17 58.9452C253.659 53.0507 255.478 35.1318 244.87 35.4436C234.262 35.7554 230.318 32.3257 221.464 32.6375Z"
            fill="#1E1A34"
          />
          <path
            d="M225.715 66.6163C221.736 66.6163 218.498 63.3808 218.498 59.4041C218.498 55.4273 221.736 52.1918 225.715 52.1918C229.695 52.1918 232.933 55.4273 232.933 59.4041C232.933 63.3808 229.695 66.6163 225.715 66.6163ZM225.715 53.0213C222.195 53.0213 219.328 55.8862 219.328 59.4041C219.328 62.922 222.195 65.7869 225.715 65.7869C229.236 65.7869 232.103 62.922 232.103 59.4041C232.103 55.8862 229.236 53.0213 225.715 53.0213Z"
            fill="#1E1A34"
          />
          <path
            d="M208.305 66.6163C204.326 66.6163 201.088 63.3808 201.088 59.4041C201.088 55.4273 204.326 52.1918 208.305 52.1918C212.285 52.1918 215.523 55.4273 215.523 59.4041C215.523 63.3808 212.285 66.6163 208.305 66.6163ZM208.305 53.0213C204.785 53.0213 201.918 55.8862 201.918 59.4041C201.918 62.922 204.785 65.7869 208.305 65.7869C211.826 65.7869 214.693 62.922 214.693 59.4041C214.693 55.8862 211.826 53.0213 208.305 53.0213Z"
            fill="#1E1A34"
          />
          <path d="M218.915 58.9927H211.551V59.8222H218.915V58.9927Z" fill="#1E1A34" />
          <path
            d="M246.394 56.2867L232.477 57.6823L232.559 58.5076L246.477 57.1121L246.394 56.2867Z"
            fill="#1E1A34"
          />
        </g>
        <defs>
          <clipPath id="clip0_989_740">
            <rect width="340" height="238" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Flex>
  );
};

export default CartEmptyState;
