import React, { FC } from 'react';
import { Drawer, Icon, Typography } from '@gohubly/design-system';
import { Flex } from 'rebass';
import Notification from './components/notification';
import { NotificationsDrawerProps } from './notifications-drawer.types';
import { useNotificationContext } from '@plug/contexts/NotificationContext';

const NotificationsDrawer: FC<NotificationsDrawerProps> = ({ notifications, ...props }) => {
  const { hide } = useNotificationContext();

  return (
    <Drawer {...props} width="360px" position="right" zIndex={9999999}>
      <Flex flexDirection="column" style={{ gap: '24px' }}>
        <Flex
          padding="24px"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          style={{ borderBottom: '1px solid #E0E2E8' }}
        >
          <Typography size="XS" color="neutralLowDark">
            Minhas notificações
          </Typography>

          <div style={{ cursor: 'pointer' }}>
            <Icon iconId="close4" stroke="neutralHighDark" strokeWidth={1} onClick={hide} />
          </div>
        </Flex>

        <Flex paddingX="24px">
          {notifications.map(item => (
            <Notification key={item.message.toString()} {...item} />
          ))}
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default NotificationsDrawer;
