import styled from 'styled-components';

export const IconContainer = styled.div`
  padding: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f2f8d1;
  border-radius: 8px;
`;
