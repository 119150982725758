import { ENVIRONMENT } from '@plug/config/env';
import React, { FC } from 'react';

const Environment: FC<{ allow?: 'production' | 'staging' }> = ({ children, allow }) => {
  if (!allow || ENVIRONMENT.toLowerCase() === allow) return <>{children}</>;

  return <></>;
};

export default Environment;
