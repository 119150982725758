import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from 'rebass';
import AdminPaymentsGeneral from '../components/admin-payments-general';
import AdminPaymentsMonthly from '../components/admin-payments-monthly';
import AdminPaymentsAffiliate from '../components/admin-payments-affiliate';
import {
  ORGANIZATION_PAYMENTS_DETAILS,
  OrganizationPaymentsDetailsQuery,
} from '../graphql/queries/organization-payments-details';
import styled, { keyframes } from 'styled-components';
import { ThemeStore } from '@plug/redux/store';
import { Spinner } from '@gohubly/design-system';
import {
  GET_ORGANIZATION_REVENUE,
  getOrganizationRevenueQuery,
  getOrganizationRevenueVariables,
} from '@plug/graphql/queries/get-organization-revenue';
import moment from 'moment';

const toTheLeftAnimation = keyframes`
  0% { transform: translateX(100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
`;

const AnimatedBox = styled(Box)`
  &.animation {
    animation: ${toTheLeftAnimation} 1s 1;
  }
`;

export enum Views {
  GENERAL,
  MONTHLY,
  AFFILIATE,
}

const AdminPaymentsContainer = (): JSX.Element => {
  const theme = ThemeStore.useState(s => s);

  const { data: GeneralData, loading: GeneralLoading } = useQuery<OrganizationPaymentsDetailsQuery>(
    ORGANIZATION_PAYMENTS_DETAILS,
  );

  const finalDate = moment().subtract(1, 'month');
  finalDate.set('day', finalDate.endOf('month').get('day'));

  const { data: organizationRevenueData, loading: organizationRevenueLoading } = useQuery<
    getOrganizationRevenueQuery,
    getOrganizationRevenueVariables
  >(GET_ORGANIZATION_REVENUE, {
    variables: {
      input: {
        status: 'Approved',
        startDate: moment('1822-08-18T21:25:57.037Z').toDate(),
        endDate: finalDate.toDate(),
      },
    },
  });

  const [doingAnimation, setDoingAnimation] = useState<boolean>(false);
  const [view, setView] = useState<Views>(Views.GENERAL);
  const [yearMonth, setYearMonth] = useState<string>('2020-10');
  const [affiliateId, setAffiliateId] = useState<string>('');

  const handleAffiliateView = (affiliateId: string): void => {
    setView(Views.AFFILIATE);
    setAffiliateId(affiliateId);
  };

  const toggleView = (newView: Views, yearMonth?: string): void => {
    setView(newView);
    if (yearMonth) setYearMonth(yearMonth);
  };

  useEffect(() => {
    // setDoingAnimation(true);
  }, [view, yearMonth]);

  const RenderViewComponent = (): JSX.Element => {
    switch (view) {
      case Views.GENERAL:
        return (
          <AdminPaymentsGeneral
            toggleView={toggleView}
            data={GeneralData?.organizationPaymentsDetails}
            loading={GeneralLoading && organizationRevenueLoading}
            dataRevenue={organizationRevenueData?.getOrganizationRevenue?.amount}
          />
        );

      case Views.MONTHLY:
        return (
          <AdminPaymentsMonthly
            toggleView={toggleView}
            yearMonth={yearMonth}
            setAffiliateId={handleAffiliateView}
          />
        );

      case Views.AFFILIATE:
        return (
          <AdminPaymentsAffiliate
            toggleView={toggleView}
            affiliateId={affiliateId}
            referenceMonth={yearMonth}
          />
        );

      default:
        return (
          <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        );
    }
  };

  return (
    <AnimatedBox
      width="100%"
      height="100%"
      backgroundColor="#F0F1F5"
      sx={{ padding: '20px 16px 0 24px', '@media(max-width: 768px)': { padding: '8px' } }}
      overflow="hidden"
      className={doingAnimation ? 'animation' : ''}
      onAnimationEnd={(): void => setDoingAnimation(false)}
    >
      <RenderViewComponent />
    </AnimatedBox>
  );
};

export default AdminPaymentsContainer;
