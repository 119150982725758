import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Search: React.FC<SvgProps> = props => {
  const theme = ThemeStore.useState(s => s);

  return (
    <SvgIcon viewBox={'0 0 16 10'} width={16} height={10} {...props}>
      <rect width="16" height="2" rx="1" fill={theme.primary || props.fill || "#3B24A8"}/>
      <rect y="4" width="16" height="2" rx="1" fill={theme.primary || props.fill || "#3B24A8"}/>
      <rect y="8" width="8" height="2" rx="1" fill={theme.primary || props.fill || "#3B24A8"}/>
    </SvgIcon>
  );
}

export default Search;
