import { useState } from 'react';

export default function(initialValue = false) {
  const [show, setShow] = useState(initialValue);

  return {
    display: () => setShow(true),
    hide: () => setShow(false),
    toggle: () => setShow(!show),
    update: value => setShow(value),
    isDisplayed: show,
  };
}
