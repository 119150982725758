import styled from 'styled-components';

export const Section = styled.section`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const Title = styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeed;
`;
