import React from 'react';
import styled from 'styled-components';
import { Box, Flex, Heading } from 'rebass';
import { inter } from '../fonts';
import { Text } from '../';
import { CaretLeft, CaretRight, Close, ExclamationTriangle } from '../icons';
import SelectCell from './select-cell';
import SelectCellMobile from './select-cell-mobile';
import ActionsCell from './actions-cell';
import theme from '../theme';
import {
  useGlobalFilter,
  useTable,
  useResizeColumns,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  useFilters,
  UseFiltersState,
  TableInstance,
  useFlexLayout,
  useRowSelect,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseRowSelectState,
  UseFiltersInstanceProps,
} from 'react-table';
import Pagination from './pagination';
import Drawer from '../drawer';
import { ThemeStore } from '@plug/redux/store';

type Props = {
  title?: React.ReactNode;
  columns: any;
  sideviewState?: any;
  hasCheckboxSpace?: boolean;
  toolbarComponent?: any;
  actions?: any;
  selectComponent?: any;
  extensionComponent?: any;
  data: any;
  responsive?: boolean;
};

interface TableStyledI {
  responsive?: boolean;
}
const TableStyled = styled.div<TableStyledI>`
  overflow-x: ${({ responsive }): string => (responsive ? 'auto' : 'hidden')};
  table {
    text-align: left;
    width: 100%;
    border-spacing: 0;

    td,
    th {
      padding: 10px 16px;
    }

    th {
      font-weight: 500;
      border-bottom: 1px solid #F0F1F5;
    }
    tr {
      position: relative;
      display: flex;
    }
    td {
      font-weight: 500;
      ${inter('s12')}
      color: #666372;
      border-bottom: 1px solid #F0F1F5;
      display: flex;
      align-items: center;
    }
    tr {
      background-color: #fdfdfe;
    }
    thead tr {
      border-top-left-radius: ${theme.borderRadius};
      border-top-right-radius: ${theme.borderRadius};
      color: #8c8a95;
      ${inter('s12')}
      font-weight: 500;
    }
  }

  @media screen and (max-width: 968px) {
    overflow-x: auto;
    table {
      min-width: auto !important;

      thead {
        display: none;
      }
      td,
      th {
        border: 0;
      }
      tbody {
        padding-bottom: 8px;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(1, minmax(180px, 1fr));

        tr {
          display: flex;
          flex-direction: column;
          padding: 16px 14px;
          border-radius: 8px;

          td {
            padding: 0;
            padding-bottom: 5px;
            width: auto !important;
          }
        }
      }
    }
  }
`;

type TableToolbar = {
  numSelected?: number;
  globalFilter: any;
  setGlobalFilter: (value: any) => void;
};

export const EnhancedTable = ({
  title,
  sideviewState,
  columns,
  actions,
  hasCheckboxSpace,
  toolbarComponent: ToolbarComponent,
  selectComponent: SelectComponent,
  extensionComponent: ExtensionComponent,
  data,
  responsive,
}: Props) => {
  const theme = ThemeStore.useState(s => s);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    pageOptions,
    page,
    setGlobalFilter,
    setFilter,
    gotoPage,
    // @ts-ignore
    selectedFlatRows,
    state: { globalFilter, filters, pageIndex, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useResizeColumns,
    useGlobalFilter,
    useFilters,
    useFlexLayout,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [...columns]);
    },
  ) as UseGlobalFiltersInstanceProps<any> &
    UseFiltersInstanceProps<any> &
    TableInstance<any> &
    UsePaginationInstanceProps<any> & {
      state: UseGlobalFiltersState<any> &
        UseFiltersState<any> &
        UsePaginationState<any> &
        UseRowSelectState<any>;
    };

  const [isOpenDrawerBottom, setOpenDrawerBottom] = React.useState(false);
  const [drawerContent, setDrawerContent] = React.useState<null | React.ReactNode>(null);

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={isOpenDrawerBottom}
        onClose={() => setOpenDrawerBottom(false)}
      >
        <Box pb="20px">
          <Flex justifyContent="flex-end" pt="20px" pr="13px" pb="5px">
            <Flex
              onClick={() => setOpenDrawerBottom(false)}
              width="32px"
              height="32"
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: 'pointer' }}
            >
              <Close width={16} height={16} />
            </Flex>
          </Flex>
          {drawerContent}
        </Box>
      </Drawer>
      <Flex pb="24px" minHeight="68px" width="100%" alignItems="center">
        {Object.keys(selectedRowIds).length ? (
          <>
            <Text fontSize="14px">
              <span style={{ color: theme.primary || '#3B24A8' }}>
                {Object.keys(selectedRowIds).length}
              </span>{' '}
              itens selecionados
            </Text>

            <Flex flex="1" justifyContent="flex-end">
              <SelectComponent
                selectedRowIds={selectedRowIds}
                selectedFlatRows={selectedFlatRows}
              />
            </Flex>
          </>
        ) : (
          <>
            <ToolbarComponent
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              setFilter={setFilter}
              filter={filters}
            />
          </>
        )}
      </Flex>
      {ExtensionComponent && (
        <Box pb="32px">
          <ExtensionComponent />
        </Box>
      )}
      {title && (
        <Flex pb="16px" alignItems="center">
          {title}
        </Flex>
      )}
      {page.length ? (
        <>
          <TableStyled responsive={responsive}>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {SelectComponent && (
                      <th colSpan={1} style={{ position: 'relative', width: '35px' }}></th>
                    )}
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                    {actions && (
                      <th colSpan={1} style={{ position: 'relative', width: '100px' }}></th>
                    )}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row: any, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {SelectComponent &&
                      !row.original?.isPaid &&
                      row.original.plugoneAffiliateStatus === 'Approved' ? (
                        <SelectCell row={row} />
                      ) : hasCheckboxSpace ? (
                        <div style={{ width: '40px' }}></div>
                      ) : (
                        SelectComponent && <SelectCell row={row} />
                      )}
                      <SelectCellMobile
                        SelectComponent={SelectComponent}
                        setOpenDrawerBottom={setOpenDrawerBottom}
                        setDrawerContent={setDrawerContent}
                        actions={actions}
                        row={row}
                      />
                      {row.cells.map((cell: any) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                      {actions && <ActionsCell actions={actions} row={row} />}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableStyled>
          <Pagination
            previousLabel={<CaretLeft />}
            nextLabel={<CaretRight />}
            pageCount={pageOptions.length}
            breakLabel={'...'}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            forcePage={pageIndex}
            onPageChange={page => gotoPage(page.selected)}
          />
        </>
      ) : (
        <Box height="100%" width="100%" padding="2rem" backgroundColor="#F0F1F5">
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            alignContent="center"
            justifyContent="center"
            padding="2rem"
            bg="#F8F9FC"
            sx={{
              borderRadius: '4px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="72px"
              height="72px"
              bg="#EBE9F6"
              m="0 auto"
              sx={{ borderRadius: 9999 }}
            >
              <ExclamationTriangle fill={theme.primary || '#3B24A8'} width={36} height={36} />
            </Flex>

            <Heading
              mb={16}
              mt={32}
              textAlign="center"
              fontFamily="Inter, sans-serif"
              fontSize="32px"
              fontWeight="bold"
              lineHeight="36px"
              color="#1C1637"
            >
              Não encontramos resultado para essa pesquisa.
            </Heading>
          </Flex>
        </Box>
      )}
    </>
  );
};

EnhancedTable.defaultProps = {
  hasCheckboxSpace: false,
};

export default EnhancedTable;
