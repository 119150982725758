import React from 'react';
import CheckoutCatalogHeader from './components/checkout-catalog-header';
import CheckoutCatalogFilters from './components/checkout-catalog-filters';
import { Box, Flex } from 'rebass';
import CheckoutCatalogProducts from './components/checkout-catalog-products';
import CheckoutCart from '../checkout-cart';
import { Modal } from '@monitfy/ds';
import withContext from '@plug/hocs/with-context';
import {
  CheckoutDetailedProductContextProvider,
  useCheckoutDetailedProductContext,
} from '../../../../contexts/CheckoutDetailedProductContext';
import CheckoutCatalogDetailedProduct from './components/checkout-catalog-detailed-product';
import '@monitfy/ds/dist/style.css';

const CheckoutCatalog = () => {
  const { product, loadProduct, changeSku } = useCheckoutDetailedProductContext();

  return (
    <Flex width="100%" padding="32px" style={{ gap: '16px' }}>
      <Modal.Root
        open={!!product}
        onOpenChange={open => {
          if (!open) loadProduct(undefined);
        }}
      >
        <Modal.Portal>
          <Modal.Overlay style={{ zIndex: '9' }} />
          <Modal.Content
            style={{ zIndex: '9', width: '90vw', maxHeight: '90vh', overflowY: 'auto' }}
          >
            {product && <CheckoutCatalogDetailedProduct />}

            <Modal.Close asChild />
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
      <Flex
        width="calc(100% - 340px)"
        paddingY="24px"
        flexDirection="column"
        backgroundColor="white"
        style={{ borderRadius: '16px' }}
      >
        <CheckoutCatalogHeader />
        <CheckoutCatalogFilters />

        <Box marginTop="32px">
          <CheckoutCatalogProducts
            title="Promoções"
            type="promotions"
            limit={4}
            onSelectProduct={product => {
              loadProduct(product);
              changeSku(product.skus?.[0]);
            }}
          />
          <CheckoutCatalogProducts
            title="Todos os produtos"
            type="normal"
            onSelectProduct={product => {
              loadProduct(product);
              changeSku(product.skus?.[0]);
            }}
          />
        </Box>
      </Flex>

      <CheckoutCart />
    </Flex>
  );
};

export default withContext(CheckoutDetailedProductContextProvider, CheckoutCatalog);
