import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { ThemeStore } from '@plug/redux/store';
import React from 'react';
import { Box } from 'rebass';

const Switch = React.forwardRef<HTMLButtonElement, any>(({ checked, size, ...props }, ref) => {
  const theme = ThemeStore.useState(s => s);
  return (
    <Box
      ref={ref}
      as="button"
      type="button"
      role="switch"
      tx="forms"
      variant="switch"
      aria-checked={checked}
      {...props}
      __css={{
        appearance: 'none',
        m: 0,
        p: 0,
        width: size ? size * 1.6 : 40,
        height: size || 24,
        bg: '#F0EFF1',
        border: '1px solid',
        borderColor: '#D4D3D8',
        borderRadius: 9999,
        '&[aria-checked=true]': {
          bg: getCustomizatedColor('buttons', theme.primary) || '#3B24A8',
          borderColor: getCustomizatedColor('buttons', theme.primary) || '#3B24A8',
        },
        ':focus': {
          outline: 'none',
        },
        ...props.style,
      }}
    >
      <Box
        aria-hidden
        style={{
          transform: checked ? 'translateX(70%)' : 'translateX(0)',
        }}
        sx={{
          mt: '-1px',
          ml: '-1px',
          width: size || 24,
          height: size || 24,
          borderRadius: 9999,
          border: '2px solid',
          borderColor: checked
            ? getCustomizatedColor('buttons', theme.primary) || '#3B24A8'
            : '#D4D3D8',
          bg: '#fff',
          transitionProperty: 'transform',
          transitionTimingFunction: 'ease-out',
          transitionDuration: '0.1s',
        }}
      />
    </Box>
  );
});

Switch.displayName = 'Switch';
export default Switch;
