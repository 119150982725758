import React from 'react';

export default function withContext<T>(ContextWrapper: React.FC, Component: React.FC<T>) {
  return class extends React.Component<T> {
    render(): JSX.Element {
      return (
        <ContextWrapper>
          <Component {...this.props} />
        </ContextWrapper>
      );
    }
  };
}
