import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Spinner from 'react-svg-spinner';

import { GetTermsAndConditionsQuery } from '../../graphql/queries/get-current-terms-and-conditions';
import {
  SEND_TERMS_AND_CONDITIONS,
  SendTermsAndConditionsMutation,
  SendTermsAndConditionsVariables,
} from '../../graphql/mutations/send-terms-and-conditions';

import { useLogout } from '@plug/domains/auth/hooks/useLogout';
import { Text } from '@plug/ui';
import { ThemeStore, OrganizationStore } from '@plug/redux/store';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;

  height: 100vh;

  padding: 20px 8vw;

  font-family: 'Inter', sans-serif;

  > div {
    margin: 10px 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 120px;
  }
`;

const TextWrapper = styled.div<ComponentProps<'div'>>`
  flex: 1;
  overflow-y: auto;
  word-break: break-word;

  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 20px;

  padding: 10px 15px;
  border-radius: 5px;

  background: #f6f6f6;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type ButtonsProps = ComponentProps<'button'> & {
  color?: string;
};

const Button = styled.button<ButtonsProps>`
  outline: none;
  border: none;

  font-size: 14px;
  font-weight: bold;

  padding: 10px 25px;
  border-radius: 5px;

  border: 1px solid black;
  background: transparent;

  cursor: pointer;
  transition: all 0.2s linear, color 0.15s linear;

  &:hover {
    background: ${({ color }): string => color || 'transparent'};
    border-color: ${({ color }): string => color || 'black'};
    color: ${({ color }): string => (color ? 'white' : 'black')};
  }
`;

interface CommonProps {
  redirect: string;
  refetch: Function;
  terms: GetTermsAndConditionsQuery;
}

enum ButtonTypes {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE',
}

const TermsAndConditions: React.FC<CommonProps> = ({ redirect, terms, refetch }) => {
  const theme = ThemeStore.useState(s => s);
  const organizationSlug = OrganizationStore.useState(s => s.organizationSlug) || '';
  const [logout] = useLogout();
  const [sendTermsAndConditions, { loading: doingMutation }] = useMutation<
    SendTermsAndConditionsMutation,
    SendTermsAndConditionsVariables
  >(SEND_TERMS_AND_CONDITIONS);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const clickedButton = event.currentTarget.dataset['name'];

    if (clickedButton === ButtonTypes.AGREE) {
      sendTermsAndConditions({
        variables: {
          input: {
            termsAndConditionsId: terms?.getTermsAndConditions.termsAndConditionsId,
          },
        },
      })
        .then(() => {
          refetch();
          window.location.href = redirect;
          // history.push(redirect);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao aceitar os termos. Por favor, tente novamente em alguns instantes.',
          );
        });
    } else if (clickedButton === ButtonTypes.DISAGREE) {
      logout(organizationSlug);
    }
  };

  return (
    <Container>
      <Text fontSize="30px">Termos & Condições</Text>
      {terms?.getTermsAndConditions.personalizedTermsAndConditions && (
        <TextWrapper
          dangerouslySetInnerHTML={{
            __html: terms?.getTermsAndConditions?.personalizedTermsAndConditions,
          }}
        ></TextWrapper>
      )}
      <TextWrapper
        dangerouslySetInnerHTML={{
          __html: terms?.getTermsAndConditions?.text,
        }}
      ></TextWrapper>
      <Footer>
        <Button
          color="#18C698"
          data-name={ButtonTypes.AGREE.toString()}
          style={{ marginRight: '20px' }}
          onClick={handleButtonClick}
          disabled={doingMutation}
        >
          {doingMutation ? (
            <Spinner color={theme.primary || '#000'} speed="fast" size="14px" />
          ) : (
            'Concordo'
          )}
        </Button>

        <Button
          color="#FD3A57"
          data-name={ButtonTypes.DISAGREE.toString()}
          onClick={handleButtonClick}
          disabled={doingMutation}
        >
          Discordo
        </Button>
      </Footer>
    </Container>
  );
};

export default TermsAndConditions;
