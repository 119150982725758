import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Search: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 12 12'} width={12} height={12} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0ZM2 8C0.895431 8 0 8.89543 0 10C0 11.1046 0.895431 12 2 12C3.10457 12 4 11.1046 4 10C4 8.89543 3.10457 8 2 8ZM8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2ZM10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8Z" fill={props.fill || "#AAA8B3"} />
  </SvgIcon>
);

export default Search;
