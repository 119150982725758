import gql from 'graphql-tag';

export const GET_AFFILIATES_VALUES_BY_MONTH = gql`
  query getAffiliatesValuesByMonth($input: getAffiliatesValuesByMonthInput!) {
    getAffiliatesValuesByMonth(input: $input) {
      affiliates {
        affiliateId
        revenue
        commission
        name
        email
        document
        pix
        agency
        account
        bank
        plugFormFields
        invoice {
          id
          url
          received
        }
      }
      hasMoreItems
    }
  }
`;

export type GetAffiliatesValuesByMonthQuery = {
  getAffiliatesValuesByMonth: {
    affiliates: {
      affiliateId: string;
      revenue: string;
      commission: string;
      name: string;
      email: string;
      document: string;
      pix?: string;
      agency: string;
      account: string;
      bank: string;
      plugFormFields: string;
      invoice: {
        id: string;
        url: string;
        received?: boolean;
      };
    }[];
    hasMoreItems?: boolean;
  };
};

export type getAffiliatesValuesByMonthInput = {
  input: {
    year_month: string;
  };
};

export type GetAffiliatesValuesByMonthInput = {
  input: {
    year_month: string;
    search?: string;
    page?: number;
    limit?: number;
  };
};
