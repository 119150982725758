import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const SlimCheck: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 12 10'} width={12} height={10} {...props}>
    <path
      d="M11.4743 1.09032C11.2734 0.88656 10.9595 0.88656 10.7586 1.09032L4.11663 7.82719L1.24135 4.91085C1.04046 4.70709 0.726562 4.70709 0.52567 4.91085C0.324777 5.11462 0.324777 5.43299 0.52567 5.63676L3.75251 8.90968C3.85296 9.01156 3.97852 9.0625 4.11663 9.0625C4.24219 9.0625 4.3803 9.01156 4.48075 8.90968L11.4743 1.81622C11.6752 1.61246 11.6752 1.28135 11.4743 1.09032Z"
      fill="#FBFCFD"
    />
  </SvgIcon>
);

export default SlimCheck;
