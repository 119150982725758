import React, { memo } from 'react';
import { Heading } from 'rebass';
import cookie from 'js-cookie';

import { USER_LOCAL_STORAGE_KEY } from '@plug/config/consts';
import isDesktop from '@plug/helpers/is-desktop';
import { ThemeStore } from '@plug/redux/store';

const GreetingUser = ({ noMargin = false }: { noMargin?: boolean }): JSX.Element => {
  // const user = JSON.parse(secureLs.get(USER_LOCAL_STORAGE_KEY) || '{}');
  const user = JSON.parse(decodeURIComponent(cookie.get(USER_LOCAL_STORAGE_KEY) || '{}'));

  const theme = ThemeStore.useState(s => s);

  if (!user?.username) return <React.Fragment />;

  return (
    <Heading
      mt={noMargin ? 0 : 32}
      px={noMargin ? 0 : 32}
      textAlign="left"
      fontFamily="Inter, sans-serif"
      fontSize={isDesktop ? '32px' : '20px'}
      fontWeight="bold"
      lineHeight={isDesktop ? '36px' : '28px'}
      color="#1C1637"
    >
      Olá, <span style={{ color: theme.primary || '#3B24A8' }}>{user.username}</span>
    </Heading>
  );
};

// GreetingUser.displayName = 'GreetingUser';

export default memo(GreetingUser);
