import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from 'rebass';
import { position, PositionProps, textAlign, TextAlignProps } from 'styled-system';

const Spacer: React.SFC<BoxProps> = props => <Box {...props} />;

const SpacerStyled = styled(Spacer)<PositionProps & TextAlignProps>`
  ${position}
  ${textAlign}
`;

export default SpacerStyled;
