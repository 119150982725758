import React from 'react';
import * as css from './checkout-cart.styles';
import CartHeader from './components/cart-header';
import CartEmptyState from './components/cart-empty-state';
import CartTotalizer from './components/cart-totalizer';
import { Flex } from 'rebass';
import { Cart } from '@monitfy/ds';
import { useCheckoutCartContext } from '../../../../contexts/CheckoutCartContext';
import Skeleton from 'react-loading-skeleton';

const CheckoutCart = () => {
  const {
    cart,
    states: { isLoadingCart = true, isAddingItem, isRemovingItem, updatingItemId },
    actions: { removeCartItem },
  } = useCheckoutCartContext();

  return (
    <css.Container empty={!cart?.items?.length}>
      <CartHeader />

      <Flex
        paddingX="24px"
        paddingBottom="30px"
        marginBottom="auto"
        flexDirection="column"
        overflowY="auto"
        style={{ gap: '12px' }}
      >
        {(cart?.items ?? []).map((item, idx) => (
          <Cart.Default
            key={item.id}
            title={item.name}
            image={{
              url: item.imageUrl,
              altText: `Imagem do produto ${item.name}`,
            }}
            price={item.price}
            buttonProps={{
              disabled: isAddingItem || isRemovingItem,
              loading: updatingItemId === item.id,
              onClick() {
                removeCartItem(item.id);
              },
            }}
          />
        ))}

        {isLoadingCart && !isAddingItem && !isRemovingItem && (
          <>
            <Skeleton width="100%" height="148px" />
            <Skeleton width="100%" height="148px" />
          </>
        )}
      </Flex>

      {!cart?.items?.length && !isLoadingCart && <CartEmptyState />}
      {cart?.items?.length > 0 && <CartTotalizer />}
    </css.Container>
  );
};

export default CheckoutCart;
