import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const styles = {
  cls1: { fill: '#f06337' },
  cls2: { fill: '#9dcb28' },
  cls3: { fill: '#47c1c4' },
  cls4: { fill: '#1f437d' },
};

const LojaIntegrada: React.FC<SvgProps> = props => (
  <SvgIcon viewBox="0 0 330.09 57.04" {...props}>
    <title>Partner_Logo02</title>
    <path
      style={styles.cls1}
      d="M47.38,38.44A5.88,5.88,0,0,1,44.7,43l-14.61,8.3c-1.47.84-2.67.14-2.67-1.55V32.47a5.87,5.87,0,0,1,2.67-4.61l14.61-8.3c1.47-.84,2.68-.14,2.68,1.55Z"
    />
    <path
      style={styles.cls2}
      d="M25,32.47a5.86,5.86,0,0,0-2.68-4.61L7.67,19.56C6.2,18.72,5,19.42,5,21.12V38.44A5.87,5.87,0,0,0,7.67,43l14.61,8.3c1.48.84,2.68.14,2.68-1.55Z"
    />
    <path
      style={styles.cls3}
      d="M29,5.61a6.07,6.07,0,0,0-5.37,0L8.84,14.07c-1.47.83-1.46,2.19,0,3L23.4,25.14a6.07,6.07,0,0,0,5.37,0l14.76-8.37c1.48-.83,1.47-2.19,0-3Z"
    />
    <path
      style={styles.cls4}
      d="M142.93,42.41h6.61V16.64h-6.61ZM318.07,16.62a27.79,27.79,0,0,0-4.62-.41,21.48,21.48,0,0,0-8.14,1.53v5.39a26.93,26.93,0,0,1,7.85-1.41,13.28,13.28,0,0,1,1.7.1q3.61,0,3.62,4.11v1.49q-14.37,0-14.37,7.62t10.49,7.61h10.49V25.93q0-8.13-7-9.31m.41,20.62H314.6c-2.58,0-3.88-.89-3.88-2.68q0-2.93,7.76-2.93Zm-112-20.84Q196,16.4,196,25.93v7.43q0,9.28,11.21,9.29A34.54,34.54,0,0,0,217,41.31V36.38a33.24,33.24,0,0,1-7.9.9c-.62,0-1.25,0-1.87,0-3.06,0-4.6-1.29-4.6-3.88V31.63H217v-5.7q0-9.53-10.49-9.53m3.88,11h-7.76V26q0-4,4-4.14,3.79,0,3.8,4.14Zm84.82-9.34a11.9,11.9,0,0,0-6.28-1.68q-8.1,0-8.09,9.53v7.19q0,9.52,10.49,9.53l10.49-.24V9.46h-6.61Zm0,19.16h-4.36q-3.41,0-3.41-3.88V25.7q0-3.89,3.41-3.88a12.2,12.2,0,0,1,4.36.81ZM219.29,25.93v7.19q0,9.3,8.1,9.53l.86,0a10.2,10.2,0,0,0,5.41-1.46V42.7q0,3.88-3.87,3.88a28.15,28.15,0,0,1-2.83.15,17.14,17.14,0,0,1-5.27-.75v4.91A17.37,17.37,0,0,0,228.37,52c.46,0,.93,0,1.42,0q10.49,0,10.49-9.29V16.88l-10.49-.24q-10.5,0-10.5,9.29m14.37,10.5a12.12,12.12,0,0,1-4.36.81q-3.39,0-3.39-4.12V25.93q0-4.11,3.39-4.11l4.36.26Zm10-17.32v23.3h6.61V22.89a7.63,7.63,0,0,1,3.88-.84h2.09V16.69l-2.09-.05q-8.26,0-10.49,2.47M186.42,9.46h-6.61v7.18h-3.59v5.41h3.59V34.32q0,8.09,8.09,8.09a12.93,12.93,0,0,0,5.7-1.07V36.57a18.63,18.63,0,0,1-3.78.43q-3.4,0-3.4-3.4V22.05h7.18V16.64h-7.18Zm-22,7.18-10.49.24V42.41h6.61V22.05h4.36c2.27,0,3.4,1.3,3.4,3.88V42.41h6.61V26.18q0-9.54-10.49-9.54m107.12,0a27.79,27.79,0,0,0-4.62-.41,21.53,21.53,0,0,0-8.15,1.53v5.39a27,27,0,0,1,7.86-1.41,13.39,13.39,0,0,1,1.7.1q3.62,0,3.61,4.11v1.49q-14.35,0-14.37,7.62t10.49,7.61h10.49V25.93q0-8.13-7-9.31m.4,20.62h-3.88q-3.87,0-3.87-2.68,0-2.93,7.75-2.93ZM149.51,10.62h-6.58v4.24h6.58Z"
    />
    <path
      style={styles.cls4}
      d="M74,42.77a16.41,16.41,0,0,0,2.63-.19V37.47l-.76,0c-2.21,0-3.31-1.11-3.31-3.31V10H66.12V34.88q0,7.89,7.89,7.89"
    />
    <path
      style={styles.cls4}
      d="M89.54,16.25q-10.92,0-10.93,9.75v7q0,9.77,10.93,9.76,10.69,0,10.69-9.76V26q0-9.75-10.69-9.75M93.79,33c0,3-1.41,4.48-4.25,4.48q-4.49,0-4.48-4.48V26q0-4.47,4.48-4.47c2.84,0,4.25,1.49,4.25,4.47Z"
    />
    <path
      style={styles.cls4}
      d="M96.73,50.36a12.48,12.48,0,0,0,5.55,1q7.89,0,7.9-7.89V17h-6.45V42.82c0,2.21-1.1,3.31-3.32,3.31a18.29,18.29,0,0,1-3.68-.42Z"
    />
    <path
      style={styles.cls4}
      d="M126,16.92a27.41,27.41,0,0,0-4.51-.39A20.8,20.8,0,0,0,113.58,18v5.26a26.14,26.14,0,0,1,7.65-1.38,14.72,14.72,0,0,1,1.66.09q3.52,0,3.52,4v1.45q-14,0-14,7.42t10.22,7.42h10.23V26q0-7.94-6.84-9.09M126.41,37h-3.78c-2.51,0-3.78-.88-3.78-2.62q0-2.85,7.56-2.85Z"
    />
    <rect style={styles.cls4} x="103.75" y="10.66" width="6.44" height="4.24" />
  </SvgIcon>
);

export default LojaIntegrada;
