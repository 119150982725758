import { NotificationsContextProvider } from '@plug/contexts/NotificationContext';
import HublyStoreContextProvider from '@plug/contexts/hubly-store-context';
import { useOrganizationCustomization } from '@plug/contexts/organization-customization-context';
import SettingsContextProvider from '@plug/contexts/settings-context';
import Customized from '@plug/styles/customized';
import React from 'react';

const AllContextsProvider: React.FC = ({ children }) => {
  const { customization } = useOrganizationCustomization();

  return (
    <NotificationsContextProvider>
      {customization && <Customized customization={customization} />}

      <SettingsContextProvider>
        <HublyStoreContextProvider>{children}</HublyStoreContextProvider>
      </SettingsContextProvider>
    </NotificationsContextProvider>
  );
};

export default AllContextsProvider;
