import * as cookie from 'js-cookie';

import { PUBLIC_DOMAIN } from '@plug/config/env';
import {
  ORGANIZATION_RULES_PERMISSION_STORAGE_KEY,
  USER_SERVICES_PERMISSIONS_STORAGE_KEY,
} from '@plug/config/consts';

export const resetUserCookies = (): void => {
  Object.keys(cookie.get()).forEach(function(cookieName) {
    const neededAttributes = {
      domain: `.${PUBLIC_DOMAIN}`,
    };
    cookie.remove(cookieName, neededAttributes);
    const neededAttributesWhite = {
      domain: `.${window.location.hostname}`,
    };
    const neededAttributesLocal = {
      domain: PUBLIC_DOMAIN,
    };
    cookie.remove(cookieName, neededAttributes);
    const neededAttributesWhiteLocal = {
      domain: window.location.hostname,
    };
    cookie.remove(cookieName, neededAttributes);
    cookie.remove(cookieName, neededAttributesWhite);
    cookie.remove(cookieName, neededAttributesLocal);
    cookie.remove(cookieName, neededAttributesWhiteLocal);
    localStorage.removeItem(ORGANIZATION_RULES_PERMISSION_STORAGE_KEY);
    localStorage.removeItem(USER_SERVICES_PERMISSIONS_STORAGE_KEY);
  });
};

type ResetServiceWorkerPages = () => Promise<Array<Array<boolean>>>;
export const resetServiceWorkerPages: ResetServiceWorkerPages = () => {
  if ('serviceWorker' in window.navigator && !!window.caches) {
    return caches
      .keys()
      .then(names =>
        Promise.all(
          names.map(name =>
            caches.open(name).then(cache =>
              cache.keys().then(files =>
                Promise.all(
                  files.map(req => {
                    const isPage = SW_CACHED_ROUTES.some(route => req.url.endsWith(route));
                    if (isPage) {
                      return cache.delete(req);
                    }
                    return Promise.resolve(false);
                  }),
                ),
              ),
            ),
          ),
        ),
      )
      .catch(() => []);
  }
  return Promise.resolve([]);
};

// export const logoutUserSession = () =>
//   resetServiceWorkerPages().then(() => {
//     resetUserCookies();
//   });

// ensuring the execution of both functions
export const logoutUserSession = async () => {
  resetUserCookies();
  await resetServiceWorkerPages();
};
