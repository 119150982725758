import gql from 'graphql-tag';

export const PAID_AFFILIATE_COMMISSION = gql`
  mutation changeAffiliateCommissionPayStatus($input: ChangeAffiliateCommissionPayStatusInput!)
    @api(name: "orders") {
    changeAffiliateCommissionPayStatus(input: $input)
  }
`;

export type paidAffiliateCommissionVariables = {
  input: {
    affiliateCommissionSetPaid: {
      _id: string;
      orderId: string;
      affiliateId: string;
      isPaid: boolean;
    }[];
  };
};
