import { Avatar, Button, Typography } from '@gohubly/design-system';
import getCurrentOrganization from '@plug/helpers/get-current-organization';
import { UserStore } from '@plug/redux/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
  color: #333333;
`;
interface IHandleProfileDropdownClose {
  handleCloseDropdown: (event?: React.MouseEvent) => void;
}

const HeaderActionsAccountInfo = ({
  handleCloseDropdown,
}: IHandleProfileDropdownClose): JSX.Element => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const org = getCurrentOrganization();
  const history = useHistory();
  const user = UserStore.useState(s => s);

  return (
    <Flex
      style={{ gap: isMobile ? '24px' : '18px' }}
      flexDirection="column"
      justifyContent="center"
    >
      <Flex style={{ gap: '16px' }}>
        <Avatar size="MD" name={user.avatar ? '' : user.username} image={user.avatar} twoLetter />

        <Flex flexDirection="column" justifyContent="center" style={{ gap: '1px' }}>
          <Typography color="neutralLowDark" size="XXS" lineHeight="LG">
            {user.username}
          </Typography>
          <Flex style={{ wordBreak: 'break-word' }}>
            <Typography color="neutralLow" size="XXXS" lineHeight="MD" fontWeight={400}>
              {user.email}
            </Typography>
          </Flex>
        </Flex>
      </Flex>

      {org && (
        <CustomButton
          hierarchy="tonalFilled"
          onClick={(e): void => {
            history.push(`/org/${org.slug}/affiliate/account`);
            handleCloseDropdown(e);
          }}
          fluid
        >
          {t('hubly-dashboard.header.user-dropdown.manage-my-account')}
        </CustomButton>
      )}
    </Flex>
  );
};

export default HeaderActionsAccountInfo;
