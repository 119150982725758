import { ListPlanByIdentifierResponse } from '@plug/domains/auth/graphql/queries/list-plan-by-identifier';
import useToggler from '@plug/hooks/use-toggler';
import React, { useEffect, useState } from 'react';

interface ISelfCheckoutContext {
  step: ISelfCheckoutSteps;
  setStep: (step: ISelfCheckoutSteps) => void;
  plan: string;
  setPlan: (step: string) => void;
  isCouponModalDisplayed?: boolean;
  displayCouponModal: (value: boolean) => void;
  values: ISelfCheckoutForm;
  setValues: (values: ISelfCheckoutForm) => void;
  addValues: (values: Partial<ISelfCheckoutForm>) => void;
  incompleteFlow: boolean;
  setIncompleteFlow: (incompleteFlow: boolean) => void;
  resetContext: () => void;
}

const SelfCheckoutContextInitialValues: ISelfCheckoutContext = {
  step: 'PERSONAL_DATA',
  setStep: () => null,
  plan: new URLSearchParams(window.location.search).get('plan') || 'plan_pro',
  setPlan: () => null,
  isCouponModalDisplayed: false,
  displayCouponModal: () => null,
  values: {
    organizationEmail: '',
    termsAndConditions: false,
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    DDIphone: '',
    phone: '',
    organizationName: '',
    organizationDocument: '',
    organizationSector: '',
    organizationQuantityPeople: '',
    organizationZipCode: '',
    organizationAddress: '',
    organizationAddressNumber: '',
    organizationAddressComplement: '',
    organizationAddressNeighborhood: '',
    organizationAddressState: '',
    organizationAddressCity: '',
    coupon: '',
    percentage: 0,
    amount: 0,
    paymentType: 'credit_card',
    signatureType:
      (new URLSearchParams(window.location.search).get('frequency') as any) || 'monthly',
    useAndPrivacyTerms: false,
  },
  setValues: () => null,
  addValues: () => null,
  incompleteFlow: false,
  setIncompleteFlow: () => null,
  resetContext: () => null,
};

export const SelfCheckoutContext = React.createContext<ISelfCheckoutContext>(
  SelfCheckoutContextInitialValues,
);

export const useSelfCheckoutContexContext = (): ISelfCheckoutContext =>
  React.useContext(SelfCheckoutContext);

export const SelfCheckoutContextProvider: React.FC = ({ children }) => {
  const [values, setValues] = useState(SelfCheckoutContextInitialValues.values);
  const [step, setStep] = useState(SelfCheckoutContextInitialValues.step);
  const [plan, setPlan] = useState(SelfCheckoutContextInitialValues.plan);
  const [incompleteFlow, setIncompleteFlow] = useState(false);
  const { update, isDisplayed } = useToggler(false);

  const addValues = (valuesToAdd: Partial<ISelfCheckoutForm>): void => {
    setValues({
      ...values,
      ...valuesToAdd,
    });
  };

  const resetContext = (): void => {
    setValues(SelfCheckoutContextInitialValues.values);
  };

  const providerValue = {
    values,
    setValues,
    isCouponModalDisplayed: isDisplayed,
    displayCouponModal: update,
    plan,
    setPlan,
    step,
    setStep,
    addValues,
    incompleteFlow,
    setIncompleteFlow,
    resetContext,
  };

  return (
    <SelfCheckoutContext.Provider value={providerValue}>{children}</SelfCheckoutContext.Provider>
  );
};
