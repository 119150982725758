import React from 'react';
import { TextField } from '@plug/ui';
import { useTranslation } from 'react-i18next';

const IntegrationFormYampi = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.yampi.alias.label',
        )}
        name="yampiAlias"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.yampi.alias.placeholder',
        )}
        width="100%"
      />
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.yampi.userToken.label',
        )}
        name="yampiUserToken"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.yampi.userToken.placeholder',
        )}
        width="100%"
      />
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.yampi.userSecretKey.label',
        )}
        name="yampiSecretKey"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.yampi.userSecretKey.placeholder',
        )}
        width="100%"
      />
    </>
  );
};

export default IntegrationFormYampi;
