import gql from 'graphql-tag';

export const CREATE_INTEGRATION = gql`
  mutation createIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input)
  }
`;

export type createIntegrationVariables = {
  input: {
    secrets: {
      xVtexApiAppKey: string;
      xVtexApiAppToken: string;
      accountName: string;
      appKey?: string;
      userSecretKey?: string;
      userStoreCode?: string;
      userToken?: string;
      alias?: string;
    };
    type: Integrations;
  };
};

export type createIntegrationMutation = {
  createIntegration: boolean;
};

export enum Integrations {
  VTEX = 'vtex',
  LOJA_INTEGRADA = 'loja_integrada',
  IUGU = 'iugu',
  YAMPI = 'yampi',
  TRAY = 'tray',
  NUVEMSHOP = 'nuvemshop',
}
