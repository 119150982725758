import Tooltip_ from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#666372',
    border: '1px solid #D4D3D8',
    boxShadow: '0px 2px 4px rgba(28, 22, 55, 0.04), 0px 8px 16px rgba(28, 22, 55, 0.06)',
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '18px',
    padding: '12px 16px',
  },
  arrow: {
    color: '#D4D3D8',
    border: '0px solid #D4D3D8',
  },
}))(Tooltip_);

export default Tooltip;
