import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const NetworkWired: React.FC<SvgProps> = props => (
  <SvgIcon fill="#1C1637" viewBox={'0 0 38 44'} width={38} height={44} {...props}>
    <path d="M6.98646 44C10.8359 44 13.9729 40.8866 13.9729 37.0563C13.9729 35.3768 13.3594 33.7464 12.2411 32.4795C11.2516 31.35 9.91562 30.5741 8.46094 30.2696L8.46094 26.0366L19 20.5955L29.5292 26.0366V30.2696C26.3427 30.9473 24.0172 33.7661 24.0172 37.0563C24.0172 40.8866 27.1542 44 31.0036 44C34.8531 44 38 40.8866 38 37.0563C38 35.3768 37.3865 33.7464 36.2682 32.4795C35.2786 31.35 33.9427 30.5741 32.488 30.2696V24.6321C32.488 24.367 32.3396 24.1313 32.1021 24.0134L20.4745 18.042V13.7304C23.6609 13.0527 25.9865 10.2339 25.9865 6.94375C25.9865 3.1134 22.8495 0 19 0C15.1505 0 12.0135 3.1134 12.0135 6.94375C12.0135 8.62321 12.6271 10.2536 13.7453 11.5205C14.7349 12.65 16.0708 13.4259 17.5255 13.7304V18.042L5.89791 24.0134C5.66042 24.1313 5.51198 24.367 5.51198 24.6321V30.2696C2.32552 30.9473 0 33.7661 0 37.0563C0 40.8866 3.13698 44 6.98646 44ZM35.051 37.0563C35.051 39.2661 33.2401 41.0634 31.0135 41.0634C28.787 41.0634 26.976 39.2661 26.976 37.0563C26.976 34.8464 28.787 33.0491 31.0135 33.0491C33.2401 33.0491 35.051 34.8464 35.051 37.0563ZM14.9625 6.94375C14.9625 4.73393 16.7734 2.93661 19 2.93661C21.2266 2.93661 23.0375 4.73393 23.0375 6.94375C23.0375 9.15357 21.2266 10.9509 19 10.9509C16.7734 10.9509 14.9625 9.15357 14.9625 6.94375ZM6.98646 33.0393C9.21302 33.0393 11.024 34.8366 11.024 37.0464C11.024 39.2562 9.21302 41.0536 6.98646 41.0536C4.7599 41.0536 2.94896 39.2562 2.94896 37.0464C2.94896 34.8464 4.7599 33.0393 6.98646 33.0393Z" />
  </SvgIcon>
);

export default NetworkWired;
