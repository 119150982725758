import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Star2: React.FC<SvgProps> = props => (
  <SvgIcon fill="none" viewBox={'0 0 16 16'} width={16} height={16} {...props}>
    <path d="M10.8316 5.14974L16 7.98623L10.8316 10.8227L8.00001 16L5.16838 10.8227L0 7.98623L5.16838 5.14974L8.00001 0L10.8316 5.14974Z" fill="#FFEBAF"/>

  </SvgIcon>
);

export default Star2;