import cookie from 'js-cookie';
import { decompressFromEncodedURIComponent, compressToEncodedURIComponent } from 'lz-string';

const get = (key: string, options?: { json?: boolean }): any => {
  const result = decompressFromEncodedURIComponent(cookie.get(key) ?? '');

  if (!result) {
    return JSON.parse(decodeURIComponent(cookie.get(key) ?? ''));
  }

  if (options?.json) return JSON.parse(result ?? '{}') as any;

  return result as string;
};

const set = (key: string, value: string, options: cookie.CookieAttributes): void => {
  cookie.set(key, compressToEncodedURIComponent(value), options);
};

export default {
  set,
  get,
};
