import React from 'react';
import SvgIcon, { SvgProps } from './SvgIcon';

const Pen: React.FC<SvgProps> = props => (
  <SvgIcon viewBox={'0 0 16 16'} width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5247 0.5V2.49177H15.125V3.56698H13.6335L12.6991 15.5H3.12122L2.18679 3.56698H0.875V2.49177H5.25962V0.5H10.5247ZM9.44656 2.47415V1.55758H6.35577V2.47415H9.44656ZM3.26498 3.54935L4.10955 14.4072H11.6568L12.5194 3.54935H3.26498ZM7.34371 5.10034H8.43986V12.9088H7.34371V5.10034ZM9.35819 12.883L10.4527 12.9413L10.8852 5.12694L9.79074 5.06864L9.35819 12.883ZM5.32928 12.9413L4.89772 5.12693L5.99223 5.06878L6.42379 12.8831L5.32928 12.9413Z"
    />
  </SvgIcon>
);

export default Pen;
