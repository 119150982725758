import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { Table } from '@plug/ui';
import moment from 'moment';
import PaymentInfoItem from '../payment-info-item';
import FinantialGraph from './finantial-graph';
import AdminPaymentsStatus from '../admin-payments-status';
import { organizationPaymentsDetails } from '../../graphql/queries/organization-payments-details';
import { formatBrazilPrice } from '@plug/helpers/normalize-currency';
import { ThemeStore } from '@plug/redux/store';
import { LI_ALLOWED_ORGANIZATION_IDS } from '@plug/config/consts';
import { useAuthContext } from '@plug/contexts/auth-context';
import { Trans, useTranslation } from 'react-i18next';
import { Spinner, Typography } from '@gohubly/design-system';
import { ExclamationTriangle } from '@plug/ui/icons';

interface Props {
  toggleView: Function;
  data: organizationPaymentsDetails | undefined;
  loading: boolean;
  dataRevenue?: any;
}

const AdminPaymentsGeneral = ({ toggleView, data, loading, dataRevenue }: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = ThemeStore.useState(s => s);
  const { currentOrganization } = useAuthContext();

  if (loading)
    return (
      <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );

  const GraphData = data.lastYearFinantialConciliations.map(finantial => {
    return {
      revenue: finantial.revenue,
      commission: finantial.commissionsToPay,
      referenceMonth: finantial.referenceMonth,
    };
  });

  const columns = [
    {
      Header: t('hubly-dashboard.payments.admin.views.general.table.columns.period'),
      accessor: 'referenceMonth',
      Cell: ({ value }: any): string => moment(value, 'YYYY-MM').format('MM/YYYY'),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.general.table.columns.revenue'),
      accessor: 'revenue',
      Cell: ({ value }: any): string => formatBrazilPrice(value),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.general.table.columns.commission'),
      accessor: 'commissionsToPay',
      Cell: ({ value }: any): string => formatBrazilPrice(value),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.general.table.columns.closing-date'),
      accessor: 'close_day',
      Cell: ({ value }: any): string => new Date(value).toLocaleDateString(navigator.language),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.general.table.columns.payment'),
      accessor: 'payment_day',
      Cell: ({ value }: any): string => new Date(value).toLocaleDateString(navigator.language),
    },
    {
      Header: t('hubly-dashboard.payments.admin.views.general.table.columns.status'),
      accessor: 'status',
      Cell: ({ value }: any): JSX.Element => <AdminPaymentsStatus status={value} />,
    },
    {
      Header: 'Relatório financeiro',
      accessor: 'report',
      Cell: ({ value, row }: any): string => {
        const status = row.original.status;
        const report = row.original.status;
        return report
          ? t('hubly-dashboard.payments.admin.views.general.table.body.report.download')
          : status === 'OPEN'
          ? t('hubly-dashboard.payments.admin.views.general.table.body.report.open')
          : t('hubly-dashboard.payments.admin.views.general.table.body.report.waiting');
      },
    },
  ];

  if ((!data || dataRevenue < 0) && !loading)
    return (
      <Box
        height="80vh"
        width="100%"
        backgroundColor={'#F8F9FC'}
        sx={{
          borderRadius: '12px',
        }}
      >
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          alignContent="center"
          justifyContent="center"
          padding="4rem 2rem"
          bg="#F8F9FC"
          sx={{
            borderRadius: '12px',
            gap: '28px',
          }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            width="72px"
            height="72px"
            bg="#EBE9F6"
            m="0 auto"
            sx={{ borderRadius: 9999 }}
          >
            <ExclamationTriangle fill={theme.primary || '#3B24A8'} width={36} height={36} />
          </Flex>

          <Text
            textAlign="center"
            fontFamily="Inter, sans-serif"
            fontSize="20px"
            fontWeight="bold"
            lineHeight="28px"
            color="#1C1637"
          >
            Não encontramos nenhum resultado.
          </Text>
        </Flex>
      </Box>
    );

  return (
    <Flex
      width="100%"
      flexDirection="column"
      sx={{ gap: '12px', '@media(max-width: 768px)': { gap: '8px' } }}
    >
      <Box pb="8px">
        <Typography color="neutralLowDark" size="24" lineHeight="32" fontWeight={500}>
          Pagamentos
        </Typography>
      </Box>

      <Flex
        width="100%"
        flexDirection="column"
        backgroundColor="#fff"
        sx={{ borderRadius: '12px' }}
      >
        <PaymentInfoItem
          data={[
            {
              text: !LI_ALLOWED_ORGANIZATION_IDS.includes(currentOrganization.id)
                ? t('hubly-dashboard.payments.admin.commons.info.orders')?.toString() ?? ''
                : 'Solicitações de resgate',
              value: data.orders,
            },
            {
              ballColor: theme.primary || '#3B24A8',
              text: 'Receita Bruta',
              value: formatBrazilPrice(data.revenue),
              help: 'netRevenue',
            },
            {
              ballColor: '#32CD32',
              text: 'Receita Líquida',
              value: formatBrazilPrice(dataRevenue ?? 0),
              help: 'grossRevenue',
            },
            {
              ballColor: '#ffd500',
              text: t('hubly-dashboard.payments.admin.commons.info.pay')?.toString() ?? '',
              value: formatBrazilPrice(data.commissionsToPay),
            },
          ]}
        />

        <FinantialGraph data={GraphData} />
      </Flex>

      <Flex
        width="100%"
        flexDirection="column"
        backgroundColor="#fff"
        sx={{ borderRadius: '12px' }}
      >
        <Text fontSize="16px" color="#000" sx={{ padding: '24px 24px 8px 24px' }}>
          <Trans
            i18nKey="hubly-dashboard.payments.admin.commons.description"
            components={{
              blue: <Text as="span" color={theme.primary || '#3B24A8'} />,
              red: <Text as="span" color="#F03460" />,
              green: <Text as="span" color="#2EB042" />,
            }}
          />
        </Text>

        <Text fontSize="14px" color="#000" sx={{ padding: '0px 24px 32px 24px' }}>
          Para ver os detalhes sobre o período, clique diretamente nele.
        </Text>

        {data.finantialConciliations && (
          <Table
            responsive
            data={data.finantialConciliations}
            columns={columns}
            padding="0px"
            finantialConciliationGeneralOnClick={toggleView}
            rowDataValue="referenceMonth"
            customFinantialConciliationGeneralIndexClick
          />
        )}
      </Flex>
    </Flex>
  );
};

export default AdminPaymentsGeneral;
