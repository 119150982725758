import React from 'react';
import { Typography, Avatar, Button } from '@gohubly/design-system';
import { Flex } from 'rebass';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { OrganizationSidebarItem, Wrapper, Hr, OverflowYFlex, StyledIcon } from './style';
import {
  listMyOrganizationsQuery,
  LIST_MY_ORGANIZATIONS,
} from '@plug/domains/dashboard/graphql/queries/list-my-organizations';
import { useQuery } from '@apollo/client';
import useSetOrganization from '@plug/hooks/use-set-organization';
import { WhiteLabelStore } from '@plug/redux/store';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAuthContext } from '@plug/contexts/auth-context';
import { OLIST_HOST_NAME } from '@plug/config/consts';
import Skeleton from 'react-loading-skeleton';

const NavSidebarOrganizationList = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: dataListMyOrganizations, loading: loadingListMyOrganizations } = useQuery<
    listMyOrganizationsQuery
  >(LIST_MY_ORGANIZATIONS);

  const {
    openedOrganizationListSection,
    setOpenedOrganizationListSection,
    openedNavigationMenuMobile,
    setOpenedNavigationMenuMobile,
  } = useNavigationMenuContext();

  const { setCurrentOrganization } = useSetOrganization();
  const isWhiteLabel = WhiteLabelStore.useState(s => s.isWhiteLabel);
  const { currentOrganization } = useAuthContext();

  const handleOrganizationChange = async (organization: Organization): Promise<void> => {
    toast.info(
      <>
        A página será recarregada em alguns segundos com as informações da organização{' '}
        <strong>{organization.name}</strong>
      </>,
    );

    await setCurrentOrganization(organization, isWhiteLabel);
    setOpenedNavigationMenuMobile(!openedNavigationMenuMobile);
    setOpenedOrganizationListSection(false);

    window.location.href = `/org/${organization.slug}/overview`;
  };

  return (
    <Wrapper opened={openedOrganizationListSection}>
      <Flex>
        <Typography color="neutralLowLight" size="XXS">
          {t('hubly-dashboard.sidebar.organization.change-org')}
        </Typography>
      </Flex>

      <OverflowYFlex>
        {!loadingListMyOrganizations &&
          dataListMyOrganizations?.listMyOrganizations.map(organization => (
            <OrganizationSidebarItem
              key={`nav-sidebar-organiozation-list-org-${organization.id}`}
              onClick={(): Promise<void> => handleOrganizationChange(organization)}
            >
              <Avatar size="XS" name={organization.name} />
              <Typography color="neutralLow" size="XXS" lineHeight="MD">
                {organization.name}
                {/* {`${organization.name.slice(0, 14)}${organization.name.length > 14 ? `...` : ''}`} */}
              </Typography>

              <StyledIcon size="SM" iconId="chevronRight" stroke="primary" />
            </OrganizationSidebarItem>
          ))}

        {loadingListMyOrganizations && (
          <Skeleton count={3} style={{ margin: '6px 0', height: '66px' }} />
        )}
      </OverflowYFlex>

      {!OLIST_HOST_NAME.includes(window.location.hostname) && (
        <Flex sx={{ gap: '16px' }} flexDirection="column">
          <Hr />
          <Button>Adicionar Organização</Button>
        </Flex>
      )}
    </Wrapper>
  );
};

export default NavSidebarOrganizationList;
