import React from 'react';
import { Flex } from 'rebass';
import ImageWheel from '../image-wheel';
import { useCheckoutDetailedProductContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutDetailedProductContext';
import ReactImageMagnify from 'react-image-magnify';
import { Typography } from '@gohubly/design-system';
import { Select, Icon, Button, Product, CurrencyHelpers } from '@monitfy/ds';
import { useCheckoutCartContext } from '@plug/domains/organization/domains/affiliate/contexts/CheckoutCartContext';
import * as css from './checkout-catalog-detailed-product.styles';
import Specifications from './specifications';

const CheckoutCatalogDetailedProduct = () => {
  const { product, imageIndex, changeImage, selectedSku } = useCheckoutDetailedProductContext();
  const {
    actions: { addCartItem, removeCartItem },
    states: { isAddingItem, isRemovingItem, updatingItemId },
    isAdded,
  } = useCheckoutCartContext();

  const isDisabled = isAddingItem || isRemovingItem;
  const added = isAdded(selectedSku?.id);

  return (
    <Flex padding="64px" flexDirection="column" style={{ gap: '48px' }}>
      <Flex justifyContent="flex-start" style={{ gap: '130px' }}>
        <Flex style={{ gap: '24px' }}>
          <ImageWheel
            onSelect={changeImage}
            index={imageIndex}
            images={product.skus?.[0].images ?? []}
          />

          <ReactImageMagnify
            smallImage={{
              alt: 'Wristwatch by Ted Baker London',
              width: 504,
              height: 504,
              isFluidWidth: false,
              src: product.skus?.[0].images[imageIndex],
            }}
            largeImage={{
              src: product.skus?.[0].images[imageIndex],
              width: 1200,
              height: 1200,
            }}
            imageStyle={{
              height: '100%',
            }}
            enlargedImageContainerStyle={{
              borderRadius: '16px',
              backgroundColor: 'white',
              zIndex: 99,
            }}
          />
        </Flex>

        <css.Container width="100%" maxWidth="400px" flexDirection="column" style={{ gap: '24px' }}>
          <Typography color="textNeutralDefault" fontWeight={400} size="28" lineHeight="36">
            {product.name}
          </Typography>

          <Flex flexDirection="column" style={{ gap: '16px' }}>
            {product.attributes
              .filter(item => item.key === 'skuSpecifications')
              .map((item, idx) => (
                <Select.Root
                  key={item.value}
                  defaultValue={selectedSku?.attributes?.[idx]?.value}
                  onValueChange={console.log}
                >
                  <Flex flexDirection="column" style={{ gap: '8px' }}>
                    <Typography color="textNeutralSubtle" size="16" lineHeight="24">
                      {item.value}
                    </Typography>
                    <Select.Trigger>
                      <Select.Value placeholder="Todos os produtos" />
                      <Select.Icon>
                        <Icon name="chevron-down" />
                      </Select.Icon>
                    </Select.Trigger>

                    <Select.Portal style={{ zIndex: '10' }}>
                      <Select.Content>
                        <Select.Viewport>
                          <Select.Group>
                            {item.items.map(item => (
                              <Select.Item key={item.value} value={item.value}>
                                {item.value}
                              </Select.Item>
                            ))}
                          </Select.Group>
                        </Select.Viewport>
                      </Select.Content>
                    </Select.Portal>
                  </Flex>
                </Select.Root>
              ))}
          </Flex>

          <Flex flexDirection="column">
            {(selectedSku.bestPrice ?? 0) < selectedSku.price && (
              <Product.ListPrice>
                {CurrencyHelpers.format('pt-br', selectedSku.price)}
              </Product.ListPrice>
            )}

            <Product.SellingPrice>
              {CurrencyHelpers.format(
                'pt-br',
                selectedSku.bestPrice < selectedSku.price
                  ? selectedSku.bestPrice
                  : selectedSku.price,
              )}
            </Product.SellingPrice>
          </Flex>

          <Button
            variant={added ? 'filledTonal' : 'filled'}
            disabled={isDisabled}
            loading={updatingItemId === selectedSku?.id}
            onClick={() => {
              if (added) removeCartItem(selectedSku?.id);
              else addCartItem(selectedSku?.id);
            }}
            resizing="fluid"
            iconAfter={added ? 'trash' : 'cart'}
          >
            {added ? 'Remover' : 'Adicionar'}
          </Button>
        </css.Container>
      </Flex>

      <Specifications />
    </Flex>
  );
};

export default CheckoutCatalogDetailedProduct;
